import React, { useState } from 'react';
import { Row, Col, Button, Tabs, Menu, Dropdown, Icon } from 'antd';
import { t } from 'i18next';
import TableInternalRadiologists from './internal';
import TableExternalRadiologists from './external';
import TableProviders from './providers';
import AddProvider from './providers/AddProvider';
import AddRadiologist from './AddRadiologist';

import './index.less';

const { TabPane } = Tabs;

// state = { userId: 'auth0|5c8493443bb45f2e572f4c9b'}
export default () => {
  const [openedModal, setOpenedModal] = useState('0');
  const [selectedTab, setSelectedTab] = useState('1');

  const menu = (
    <Menu onClick={({ key }) => setOpenedModal(key)}>
      <Menu.Item key={1}>Provider</Menu.Item>
      <Menu.Item key={2}>Radiologist</Menu.Item>
    </Menu>
  );
  return (
    <div className='radiologist-index'>
      <div className='radiologist-header'>
        <Row type='flex' justify='space-between' align='middle'>
          <Col>
            <p>{t('manage_radiologists.manage_radiologists_study_manage')}</p>
          </Col>
          <Col /* className='page-cta-wrapper' */>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button type='primary'>
                {t('manage_radiologists.add_new')} <Icon type='down' />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </div>
      <Row className='card-container table-tabs'>
        <Tabs onChange={val => setSelectedTab(val)} type='card'>
          <TabPane tab={t('manage_radiologists.internal_radiologists')} key='1'>
            <TableInternalRadiologists />
          </TabPane>
          <TabPane tab={t('manage_radiologists.external_radiologists')} key='2'>
            <TableExternalRadiologists />
          </TabPane>
          <TabPane tab={t('manage_radiologists.providers')} key='3'>
            <TableProviders />
          </TabPane>
        </Tabs>
      </Row>
      <AddProvider
        visible={openedModal === '1'}
        closeModal={() => setOpenedModal('0')}
      />
      <AddRadiologist
        visible={openedModal === '2'}
        closeModal={() => setOpenedModal('0')}
        selectedTab={selectedTab}
      />
    </div>
  );
};
