import React from 'react';
// import PropTypes from 'prop-types'
import { Typography } from 'antd';

const { Paragraph } = Typography;

const styles = {
  title: {
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '29px',
    color: '#404047'
  },
  text: {
    fontSize: '14px',
    lineHeight: '22px',
    color: 'rgba(0, 0, 0, 0.65)',
    opacity: 0.65
  }
};

function Header({ title, text }) {
  return (
    <div>
      {/* <Title level={4} style={styles.title}>
        {title}
      </Title> */}
      <Paragraph style={styles.text}>{text}</Paragraph>
    </div>
  );
}

export default Header;
