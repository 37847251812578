import type { User, Facility, Physician, SecondOpinion } from '../../schemas';

// Action strings
export const UPDATE_ONBOARDING_FORM = 'UPDATE_ONBOARDING_FORM';

export const INVITE_ONBOARDING_RADIOLOGISTS_REQUEST =
  'INVITE_ONBOARDING_RADIOLOGISTS_REQUEST';
export const INVITE_ONBOARDING_COLLEAGUES_REQUEST =
  'INVITE_ONBOARDING_COLLEAGUES_REQUEST';
export const ADD_ONBOARDING_FACILITIES_REQUEST =
  'ADD_ONBOARDING_FACILITIES_REQUEST';
export const ADD_ONBOARDING_PHYSICIANS_REQUEST =
  'ADD_ONBOARDING_PHYSICIANS_REQUEST';
export const UPDATE_ONBOARDING_SECOND_OPINION_REQUEST =
  'UPDATE_ONBOARDING_SECOND_OPINION_REQUEST';

// Action types
export type UpdateOnboardingFormAction = { type: string };
export type InviteRadiologistsAction = {
  type: string,
  radiologist: Array<User>
};
export type InviteColleaguesAction = { type: string, users: Array<User> };
export type AddFacilitiesAction = { type: string, users: Array<Facility> };
export type AddPhysiciansAction = { type: string, users: Array<Physician> };
export type UpdateSecondOpionSettingAction = {
  type: string,
  users: Array<SecondOpinion>
};

// Action function

export const updateOnboardingFormRequest = (): UpdateOnboardingFormAction => ({
  type: UPDATE_ONBOARDING_FORM
});

export const inviteRadiologistsRequest = (
  radiologists: Array<User>
): InviteRadiologistsAction => {
  return {
    type: INVITE_ONBOARDING_RADIOLOGISTS_REQUEST,
    radiologists
  };
};

export const inviteColleaguesRequest = (
  users: Array<User>
): InviteColleaguesAction => {
  return {
    type: INVITE_ONBOARDING_COLLEAGUES_REQUEST,
    users
  };
};

export const addFacilitiesRequest = (
  facilities: Array<Facility>
): AddFacilitiesAction => {
  return {
    type: ADD_ONBOARDING_FACILITIES_REQUEST,
    facilities
  };
};

export const addPhysiciansRequest = (
  physicians: Array<Physician>
): AddPhysiciansAction => {
  return {
    type: ADD_ONBOARDING_PHYSICIANS_REQUEST,
    physicians
  };
};

export const updateOnboardingSecondOpinionSettingRequest = (
  secondOpinionSetting: Array<SecondOpinion>
): UpdateSecondOpionSettingAction => {
  return {
    type: UPDATE_ONBOARDING_SECOND_OPINION_REQUEST,
    secondOpinionSetting
  };
};
