export const LOAD_TO_EDIT_USER_FORM = 'LOAD_TO_EDIT_USER_FORM';

export const LOAD_EDIT_EXTERNAL_RADIOLOGIST = 'LOAD_EDIT_EXTERNAL_RADIOLOGIST';
export const LOAD_EDIT_INTERNAL_RADIOLOGIST = 'LOAD_EDIT_INTERNAL_RADIOLOGIST';
export const LOAD_EDIT_PROVIDER = 'LOAD_EDIT_PROVIDER';
export const LOAD_ADD_EXTERNAL_RADIOLOGIST = 'LOAD_ADD_EXTERNAL_RADIOLOGIST';
export const LOAD_ADD_INTERNAL_RADIOLOGIST = 'LOAD_ADD_INTERNAL_RADIOLOGIST';
export const LOAD_ADD_PROVIDER = 'LOAD_ADD_PROVIDER';
export const LOAD_ADD_USER_FORM = 'LOAD_ADD_USER_FORM';
export const LOAD_BILLING_ADDRESS_FORM = 'LOAD_BILLING_ADDRESS_FORM';

export const LOAD_ADD_EXTERNAL_FACILITY_FORM =
  'LOAD_ADD_EXTERNAL_FACILITY_FORM';
export const LOAD_ADD_INTERNAL_FACILITY_FORM =
  'LOAD_ADD_INTERNAL_FACILITY_FORM';
export const LOAD_EDIT_INTERNAL_FACILITY_FORM =
  'LOAD_EDIT_INTERNAL_FACILITY_FORM';

export const LOAD_SETTINGS_ACCOUNT_FORM = 'LOAD_SETTINGS_ACCOUNT_FORM';
export const LOAD_SETTINGS_NOTIFICATIONS_FORM =
  'LOAD_SETTINGS_NOTIFICATIONS_FORM';
export const LOAD_SETTINGS_GROUP_FORM = 'LOAD_SETTINGS_GROUP_FORM';
export const LOAD_SETTINGS_LETTERHEAD_FORM = 'LOAD_SETTINGS_LETTERHEAD_FORM';

export const LOAD_ADD_PHYSICIAN_FORM = 'LOAD_ADD_PHYSICIAN_FORM';
export const LOAD_EDIT_PHYSICIAN_FORM = 'LOAD_EDIT_PHYSICIAN_FORM';

export const LOAD_EDIT_FACILITY_FORM = 'LOAD_EDIT_FACILITY_FORM';

export const loadAddPhysicianForm = data => ({
  type: LOAD_ADD_PHYSICIAN_FORM,
  data
});

export const loadEditPhysicianForm = data => ({
  type: LOAD_EDIT_PHYSICIAN_FORM,
  data
});

export const loadAddExternalFacilityForm = data => ({
  type: LOAD_ADD_EXTERNAL_FACILITY_FORM,
  data
});

export const loadAddInternalFacilityForm = data => ({
  type: LOAD_ADD_INTERNAL_FACILITY_FORM,
  data
});

export const loadEditInternalFacilityForm = data => ({
  type: LOAD_EDIT_INTERNAL_FACILITY_FORM,
  data
});

export const loadEditFacilityForm = data => ({
  type: LOAD_EDIT_FACILITY_FORM,
  data
});

export const loadEditUserForm = data => ({
  type: LOAD_TO_EDIT_USER_FORM,
  data
});
export const loadEditExternalRadiologist = data => ({
  type: LOAD_EDIT_EXTERNAL_RADIOLOGIST,
  data
});
export const loadEditInternalRadiologist = data => {
  return {
    type: LOAD_EDIT_INTERNAL_RADIOLOGIST,
    data
  };
};
export const loadEditProvider = data => ({ type: LOAD_EDIT_PROVIDER, data });
export const loadAddExternalRadiologist = data => ({
  type: LOAD_ADD_EXTERNAL_RADIOLOGIST,
  data
});
export const loadAddInternalRadiologist = data => ({
  type: LOAD_ADD_INTERNAL_RADIOLOGIST,
  data
});
export const loadAddProvider = data => ({ type: LOAD_ADD_PROVIDER, data });
export const loadAddUserForm = data => ({ type: LOAD_ADD_USER_FORM, data });
export const loadBillingAddressFormAction = data => ({
  type: LOAD_BILLING_ADDRESS_FORM,
  data
});

export const loadSettingsNotificationsForm = data => ({
  type: LOAD_SETTINGS_NOTIFICATIONS_FORM,
  data
});

export const loadSettingsGroupForm = data => ({
  type: LOAD_SETTINGS_GROUP_FORM,
  data
});

export const loadSettingsAccountForm = data => ({
  type: LOAD_SETTINGS_ACCOUNT_FORM,
  data
});

export const loadSettingsLetterheadForm = data => ({
  type: LOAD_SETTINGS_LETTERHEAD_FORM,
  data
});
