// @flow

import * as React from 'react';
import { Spin } from 'antd';

const fullViewStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%'
};

const smallViewStyle = {
  display: 'flex',
  justifyContent: 'center',
  position: 'center',
  width: '64px',
  margin: '0 auto',
  zIndex: '1011'
};

const tinyViewStyle = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  height: '25px',
  width: '25px',
  top: '12px',
  bottom: 0,
  right: '8px',
  zIndex: '1011'
};

type Props = {
  size: string,
  rootStyle: Object
};

function LoadingSpinner({ size = 'full', rootStyle }: Props) {
  let style;

  switch (size) {
    case 'small':
      style = smallViewStyle;
      break;
    case 'tiny':
      style = tinyViewStyle;
      break;
    default:
      style = fullViewStyle;
  }

  return (
    <div style={{ ...style, ...rootStyle }}>
      <Spin size='large' spinning />
    </div>
  );
}

LoadingSpinner.defaultProps = {
  // eslint-disable-next-line
  rootStyle: {}
};

export default LoadingSpinner;
