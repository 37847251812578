import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddUserForm from './forms/AddUserForm';

import {
  addExternalRadiologistAction,
  loadAddExternalRadiologist
} from '../../../store/actions';

import type { ReduxState } from '../../../schemas';
import { editUserValuesToExtUser } from '../../../api/transformers';

const EMPTY_EXTERNAL_RADIOLOGIST = {
  email: '',
  name: ''
};

export default connect(
  (state: ReduxState) => ({
    form: state.form.addExternalRad,
    loading: state.loading.addExternalRad,
    affiliatedGroup: state.profile.appMetadata.affiliatedGroup,
    modifications: state.externalRadiologists.modifications,
    facilities: state.facilities.internalArray
  }),
  {
    addRadiologistAction: addExternalRadiologistAction,
    loadForm: loadAddExternalRadiologist
  }
)(
  class AddExternalRadiologist extends Component {
    state = {
      modifications: this.props.modifications, // eslint-disable-line react/destructuring-assignment
      close: false
    };

    // We check if the number of created users is incremented (i.e. if the user creation has succeed,
    // and use this information to update the state.
    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.modifications > prevState.modifications) {
        return { modifications: nextProps.modifications, visible: false };
      }
      return null;
    }

    componentDidMount() {
      this.props.loadForm(EMPTY_EXTERNAL_RADIOLOGIST); // eslint-disable-line react/destructuring-assignment
    }

    componentDidUpdate(prevProps) {
      if (this.props.modifications > prevProps.modifications) {
        this.props.closeModal();
      }
    }

    handleCancel = () => {
      this.props.closeModal();
    };

    // eslint-disable-next-line react/destructuring-assignment
    handleCreate = async values => {
      const { affiliatedGroup, facilities } = this.props;
      const data = editUserValuesToExtUser(values, affiliatedGroup, facilities);
      this.props.addRadiologistAction(data);
    };

    render() {
      const { loading } = this.props;
      const { visible } = this.props;
      return (
        <div>
          {/* eslint-disable-next-line */}
          {/* <div role='link' tabIndex='-1' onClick={this.showModal}>
            External radiologist
          </div> */}
          {visible ? (
            // We have to use this trick to force form destruction
            <AddUserForm
              visible={visible}
              loading={loading}
              onCancel={this.handleCancel}
              onSubmit={this.handleCreate}
              setRadiologistType={this.props.setRadiologistType}
              radiologistType={this.props.radiologistType}
            />
          ) : null}
        </div>
      );
    }
  }
);
