// @flow

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Col, Spin, Table, Avatar, Button, Tooltip, Empty } from 'antd';
import Box from 'ui-box';
import { CSVLink } from 'react-csv';
import { t } from 'i18next';
import CardWrapper from '../../common/CardWrapper';
import { selectActiveStudies } from '../../store/selectors';
import { ReactComponent as CsvIcon } from '../../assets/images/csv-svgrepo-com.svg';
import { ActiveStudy, ReduxState } from '../../schemas';
import { labeledStudyPriorities } from '../../store/constants';
import ActiveStudiesCardForm from './form/activeStudiesCardForm';

type Props = {
  activeStudies: { list: Array<ActiveStudy> },
  loading: boolean
};

const styles = {
  reloadBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  reloadIcon: {
    fontSize: 18,
    display: 'flex'
  },
  avatar: {
    marginLeft: 8,
    backgroundColor: '#F5222D'
  }
};

function ActiveStudiesCard({ activeStudies, loading }: Props) {
  const csvHeaders = [
    { label: t('dashboard.table.patient_id'), key: 'patientId' },
    { label: t('dashboard.table.source_facility'), key: 'facility' },
    { label: t('dashboard.table.sent_to'), key: 'sentTo' },
    { label: t('dashboard.table.time'), key: 'timeLabel' },
    { label: 'Mod.', key: 'modality' },
    { label: t('dashboard.table.sla_countdown'), key: 'countdown' }
  ];

  const dataActiveStudies = () => {
    if (activeStudies.list.length > 0) {
      const newData = activeStudies.list.map(item => {
        const dataActive = {
          facility: item.facility,
          facilityId: item.facilityId,
          modality: item.modality,
          patientId: item.patientId,
          priority: item.priority,
          sentTo: item.sentTo || '-',
          time: item.time,
          timeLabel: moment(item.time).format('hh:mm A DD MMM'),
          countdown: moment(item.time).format('HH[H] mm[M] ss[S]')
        };
        return dataActive;
      });
      return newData;
    }

    return [];
  };

  const columns = [
    {
      title: t('dashboard.table.patient_id'),
      dataIndex: 'patientId',
      key: 'patientId'
    },
    {
      title: t('dashboard.table.source_facility'),
      dataIndex: 'facility',
      width: 240,
      key: 'facility'
    },
    {
      title: t('dashboard.table.sent_to'),
      dataIndex: 'sentTo',
      key: 'sentTo',
      width: 150,
      render: (text, record) => text || '-'
    },
    {
      title: t('dashboard.table.time'),
      dataIndex: 'time',
      key: 'time',
      render: (text, record) => moment(text).format('hh:mm A DD MMM')
    },
    {
      title: 'Mod.',
      dataIndex: 'modality',
      key: 'modality',
      width: 120,
      render: (text, record) =>
        labeledStudyPriorities.includes(record.priority) ? (
          <Box display='flex' alignItems='center'>
            {text}
            <Avatar size='small' style={styles.avatar}>
              {record.priority[0].toUpperCase()}
            </Avatar>
          </Box>
        ) : (
          text
        )
    },
    {
      title: t('dashboard.table.sla_countdown'),
      dataIndex: '',
      key: '',
      render: (text, record) => moment(record.time).format('HH[H] mm[M] ss[S]')
    }
  ];

  const tableProps = {
    columns,
    loading,
    dataSource: activeStudies.list,
    // rowKey: ({ patientId }) => `${patientId}`,
    scroll: { x: 768 },
    pagination: {
      pageSize: 5,
      style: {
        float: 'left',
        padding: '0 16px'
      }
    }
  };

  return (
    <div>
      <Box display='flex' justifyContent='flex-end' marginBottom={10}>
        <CSVLink
          headers={csvHeaders}
          data={dataActiveStudies()}
          filename={`active_studies_${new Date().toISOString().split('T')[0]}`}
        >
          <Tooltip placement='left' title={t('dashboard.download_csv')}>
            <Button
              type='default'
              ghost
              style={{ border: 'none', boxShadow: 'none', padding: 0 }}
            >
              <CsvIcon />
            </Button>
          </Tooltip>
        </CSVLink>
      </Box>
      <CardWrapper
        headStyle={{ paddingTop: 10, paddingBottom: 10 }}
        title={t('dashboard.active_studies')}
        extra={
          <Row type='flex' align='middle' justify='end' gutter={[16, 12]}>
            <Col style={{ flex: 1 }}>
              <Spin spinning={loading}>
                <ActiveStudiesCardForm />
              </Spin>
            </Col>
            {/* <Col>
            <Button style={styles.reloadBtn} shape='circle' disabled={loading}>
              <Icon type='reload' style={styles.reloadIcon} />
            </Button>
          </Col> */}
          </Row>
        }
      >
        <Table className='table-dashboard' {...tableProps} 
                locale={{
                  emptyText: (
                    <Empty
                      description={t("table.no_data")}
                    />
                  ),
                }} />
      </CardWrapper>
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => {
  const { loading } = state;

  return {
    activeStudies: selectActiveStudies(state),
    loading: loading.activeStudies
  };
};

export default connect(mapStateToProps, {})(ActiveStudiesCard);
