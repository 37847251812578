import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Field, reduxForm, change } from 'redux-form';
import { Row, Col } from 'antd';
import DateRangePickerField from '../../../common/form-helpers/DateRangePickerField';
// import InputSelectField from '../../../common/form-helpers/InputSelectField';
import { STUDY_OVERVIEW_CARD_FORM_NAME } from '../../../utils/form-helpers';
import {
  dateOptions,
  dateFilters,
  dateFilterStrings
} from '../../../store/constants';
import { SelectOptions, ReduxState } from '../../../schemas';
import {
  selectFacilityOptions,
  selectModalityOptions,
  selectReportingRadiologistOptions
} from '../../../store/selectors';
import { getStudyDetailsRequest } from '../../../store/actions';
import InputSelectField from '../../../common/form-helpers/InputSelectField';

type Props = {
  radioDateOptions: SelectOptions,
  reportingRadiologistOptions: SelectOptions,
  getStudyDetails: Function,
  changeField: Function
};

function StudyOverviewCardForm({
  radioDateOptions,
  reportingRadiologistOptions,
  getStudyDetails,
  changeField,
  facilityOptions,
  modalityOptions
}: Props) {
  const changeDateRange = values => {
    if (values && values.length === 2) {
      changeField('dateFilter');
      getStudyDetails(dateFilterStrings.CUSTOM);
    }
  };

  return (
    <Row
      id='study-details-card-form'
      type='flex'
      align='middle'
      justify='end'
      gutter={[16, 12]}
    >
      <Col xs={24} md={4}>
        <Field
          name='facility'
          labelStyle={{ margin: 0 }}
          placeholder='Select Facility'
          options={facilityOptions}
          width='100%'
          component={InputSelectField}
        />
      </Col>
      <Col xs={24} md={4}>
        <Field
          name='modality'
          labelStyle={{ margin: 0 }}
          placeholder='Select Modality'
          options={modalityOptions}
          width='100%'
          component={InputSelectField}
        />
      </Col>
      <Col xs={24} md={4}>
        <Field
          name='modality'
          labelStyle={{ margin: 0 }}
          placeholder='All Devices'
          options={modalityOptions}
          width='100%'
          component={InputSelectField}
        />
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Field
          name='selectedDate'
          labelStyle={{ margin: 0 }}
          width='100%'
          component={DateRangePickerField}
          // eslint-disable-next-line no-alert
          onChange={values => changeDateRange(values)}
        />
      </Col>
      {/* <Col xs={24} sm={12} md={8}>
        <Field
          name='selectedReport'
          labelStyle={{ margin: 0 }}
          placeholder='Select report'
          options={reportingRadiologistOptions}
          width='100%'
          component={InputSelectField}
        />
      </Col> */}
    </Row>
  );
}

const mapStateToProps = (state: ReduxState) => {
  return {
    radioDateOptions: [dateOptions[0], dateOptions[1]],
    reportingRadiologistOptions: selectReportingRadiologistOptions(state),
    facilityOptions: selectFacilityOptions(state),
    modalityOptions: selectModalityOptions(state),
    initialValues: {
      dateFilter: dateFilters.TODAY,
      selectedDate: '',
      selectedReport: 'all'
    }
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getStudyDetails: getStudyDetailsRequest,
      changeField: fieldName =>
        change(STUDY_OVERVIEW_CARD_FORM_NAME, fieldName, '')
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: STUDY_OVERVIEW_CARD_FORM_NAME
  })(StudyOverviewCardForm)
);
