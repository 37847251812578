/* eslint-disable react/no-unused-state */
// @flow

import * as React from 'react';

type Props = {
  setCountry: string => void,
  children: React.Node
};

type State = {
  hasError: boolean
};

export default class PhoneNumberBoundary extends React.Component<Props, State> {
  state = {
    hasError: false
  };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    this.props.setCountry('sg');
  }

  render() {
    return this.props.children;
  }
}
