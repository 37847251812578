// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Modal, Spin } from 'antd';

import { t } from 'i18next';
import { transformCountries } from '../../../../utils';
import {
  editInternalFacilityAction,
  loadEditInternalFacilityForm,
  uploadCustomLetterhead,
  uploadFacilityLogo
} from '../../../../store/actions';
import EditInternalFacilityForm from './Form/EditInternalFacilityForm';

import type { Country, Facility, ReduxState } from '../../../../schemas';

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  form: Object,
  facility: Facility,
  loading: boolean,
  load: boolean,
  editAction: (Facility, string) => void,
  rawCountries: Array<Country>,
  onCancel: () => void,
  visible: boolean,
  loadForm: Facility => void,
  uploadLetterheadAction: () => void,
  uploadLogoAction: () => void
};

type State = {
  facility: Facility,
  close: boolean,
  countryList: Array<{ text: string, value: string }>
};

export default connect(
  (state: ReduxState) => ({
    form: state.form.editInternalFacilityForm,
    facility: state.facilities.current,
    load: state.loading.facility,
    loading: state.loading.editFacility,
    rawCountries: state.countryList
  }),
  {
    editAction: editInternalFacilityAction,
    loadForm: loadEditInternalFacilityForm,
    uploadLetterheadAction: uploadCustomLetterhead,
    uploadLogoAction: uploadFacilityLogo
  }
)(
  class EditInternalFacility extends React.Component<Props, State> {
    state = {
      facility: {},
      close: false,
      countryList: []
    };

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.facility.guid !== prevState.facility.guid) {
        return { facility: nextProps.facility };
      }
      return null;
    }

    componentDidMount(): void {
      const { rawCountries } = this.props;
      if (Object.entries(this.state.facility).length !== 0) {
        this.updateFacility();
      }
      this.setState({
        countryList: transformCountries(rawCountries)
      });
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
      // eslint-disable-next-line react/destructuring-assignment
      if (prevState.facility.guid !== this.state.facility.guid) {
        this.updateFacility();
      }
    }

    updateFacility = () => this.props.loadForm(this.state.facility);

    handleEdit = async values =>
      this.props.editAction(values, this.props.facility.guid);

    handleCancel = () => {
      const { onCancel, uploadLogoAction, uploadLetterheadAction } = this.props;
      uploadLogoAction();
      uploadLetterheadAction();
      onCancel();
    };

    renderWindow = () => {
      const { loading, visible, onCancel, load } = this.props;
      const { countryList, facility } = this.state;
      return Object.keys(facility).length === 0 || load ? (
        <Modal
          visible={visible}
          title={t('my_facilities.edit_internal_facility')}
          okText='Edit'
          footer={null}
          onCancel={onCancel}
          wrapClassName='lateral-modal'
        >
          <div style={{ textAlign: 'center' }}>
            <Spin size='large' />
          </div>
        </Modal>
      ) : (
        <EditInternalFacilityForm
          visible={visible}
          loading={loading}
          onCancel={this.handleCancel}
          onSubmit={this.handleEdit}
          countryList={countryList}
          facility={facility}
        />
      );
    };

    render() {
      const { visible } = this.props;
      return visible ? this.renderWindow() : null;
    }
  }
);
