export const GET_MEDICAL_GROUPS_INVITATIONS_LIST =
  'GET_MEDICAL_GROUPS_INVITATIONS_LIST';
export const GET_MEDICAL_GROUPS_INVITATIONS_LIST_REQUEST =
  'GET_MEDICAL_GROUPS_INVITATIONS_LIST_REQUEST';
export const GET_MEDICAL_GROUPS_INVITATIONS_LIST_SUCCESS =
  'GET_MEDICAL_GROUPS_INVITATIONS_LIST_SUCCESS';
export const GET_MEDICAL_GROUPS_INVITATIONS_LIST_FAILURE =
  'GET_MEDICAL_GROUPS_INVITATIONS_LIST_FAILURE';

export const getInvitationsListRequest = () => ({
  type: GET_MEDICAL_GROUPS_INVITATIONS_LIST_REQUEST
});

export const POST_RESEND_MEDICAL_GROUPS_INVITATION =
  'POST_RESEND_MEDICAL_GROUPS_INVITATION';
export const POST_RESEND_MEDICAL_GROUPS_INVITATION_REQUEST =
  'POST_RESEND_MEDICAL_GROUPS_INVITATION_REQUEST';
export const POST_RESEND_MEDICAL_GROUPS_INVITATION_SUCCESS =
  'POST_RESEND_MEDICAL_GROUPS_INVITATION_SUCCESS';
export const POST_RESEND_MEDICAL_GROUPS_INVITATION_FAILURE =
  'POST_RESEND_MEDICAL_GROUPS_INVITATION_FAILURE';

export const postResendInvitationRequest = invitationGuid => ({
  type: POST_RESEND_MEDICAL_GROUPS_INVITATION_REQUEST,
  payload: { invitationGuid }
});

export const DELETE_REVOKE_MEDICAL_GROUPS_INVITATION =
  'DELETE_REVOKE_MEDICAL_GROUPS_INVITATION';
export const DELETE_REVOKE_MEDICAL_GROUPS_INVITATION_REQUEST =
  'DELETE_REVOKE_MEDICAL_GROUPS_INVITATION_REQUEST';
export const DELETE_REVOKE_MEDICAL_GROUPS_INVITATION_SUCCESS =
  'DELETE_REVOKE_MEDICAL_GROUPS_INVITATION_SUCCESS';
export const DELETE_REVOKE_MEDICAL_GROUPS_INVITATION_FAILURE =
  'DELETE_REVOKE_MEDICAL_GROUPS_INVITATION_FAILURE';

export const deleteRevokeInvitationRequest = invitationGuid => ({
  type: DELETE_REVOKE_MEDICAL_GROUPS_INVITATION_REQUEST,
  payload: { invitationGuid }
});
