import * as React from 'react';
import { Button, Col, Row } from 'antd';

type Props = {
  onCancel: () => void,
  handleSubmit: Object => void,
  okText: string,
  disabled: boolean,
  loading: boolean
};

export default ({
  onCancel,
  handleSubmit,
  okText,
  disabled,
  loading
}: Props) => (
  <Row type='flex' justify='space-around' align='middle'>
    <Col span={12} offset={12} style={{ textAlign: 'right' }}>
      <Button onClick={onCancel}>Cancel</Button>
      <Button
        disabled={disabled}
        type='primary'
        onClick={handleSubmit}
        loading={loading}
      >
        {okText}
      </Button>
    </Col>
  </Row>
);
