import React, { Fragment } from 'react';
import { PersistGate } from 'redux-persist/integration/react';

export default ({ persistor, children }) =>
  persistor ? (
    <PersistGate loading={null} persistor={persistor}>
      {children}
    </PersistGate>
  ) : (
    <Fragment>{children}</Fragment>
  );
