/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @flow

import * as React from 'react';
import { Typography, Icon, Button } from 'antd';
import { connect } from 'react-redux';
import { ReduxState } from '../../../schemas';

import '../index.less';

type Props = {
  activeId: number,
  data: Object,
  change: Function,
  onSubmit: Function,
  onBack: Function
};

const { Title, Text } = Typography;

const OnboardingFinish = ({
  data,
  activeId,
  change,
  onSubmit,
  onBack
}: Props) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Icon
        type='check-circle'
        theme='filled'
        style={{
          color: '#52C41A',
          fontSize: 80,
          marginBottom: 20,
          marginTop: 40
        }}
      />
      <Title className='onboarding-action-title' level={3}>
        You’re all set!
      </Title>
      <Text className='onboarding-action-subtitle'>
        You’ve successfuly signed up...
      </Text>

      <div className='onboarding-action-buttons' style={{ marginTop: '20vh' }}>
        <Button type='link' size='large' onClick={onBack}>
          Go Back
        </Button>

        <Button type='primary' size='large' onClick={onSubmit}>
          Finish
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingFinish);
