export default values => {
  const errors = {};
  // const { name, email, primaryFacility } = values;

  // if (!name) {
  //   errors.name = 'Required';
  // }
  // if (!email) {
  //   errors.email = 'Required';
  // }
  // if (!primaryFacility || primaryFacility.length === 0) {
  //   errors.primaryFacility = 'Required';
  // }
  return errors;
};
