import moment from 'moment';
import AuthService from '../index';
import Logger from '../../utils/logger';

import {
  jsonToReferringFacilities,
  // jsonToFacility,
  // jsonToProvider,
  jsonToSourceReferringFacilitiesChart,
  jsonToSourceReferringFacilitiesTable
} from '../transformers';
import { dateFilterStrings } from '../../store/constants';

// const GROUP_API_SERVICE: ApiService = 'telerad-settings';
// const GROUP_BASE_ROUTE = '/group';
const API_SERVICE = 'facilities';
const BASE_ROUTE = '/facility';

export const getReferringFacilities = async (profile, filters) => {
  const { selectedDate, dateFilterType: tf } = filters || {};
  let timeframe = '';
  if (tf === dateFilterStrings.TODAY) {
    timeframe = `?from_date=${moment().format(
      'YYYY-MM-DD'
    )}&to_date=${moment().format('YYYY-MM-DD')}`;
  } else if (tf === dateFilterStrings.CUSTOM) {
    if (selectedDate && selectedDate.length === 2) {
      timeframe = `?from_date=${moment(selectedDate[0]).format(
        'YYYY-MM-DD'
      )}&to_date=${moment(selectedDate[1]).format('YYYY-MM-DD')}`;
    } else {
      timeframe = `?from_date=${moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD')}&to_date=${moment().format('YYYY-MM-DD')}`;
    }
  } else {
    timeframe = `?from_date=${moment()
      .subtract(7, 'days')
      .format('YYYY-MM-DD')}&to_date=${moment().format('YYYY-MM-DD')}`;
  }
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service('referring', `/facility-study/aggr/${timeframe}`, {})
    .then(jsonToReferringFacilities);
};

export const createReferringFacility = async (profile, data) => {
  let obj = {
    ...data
  };
  if (data.contact_number_1 && data.contact_number_1.phone) {
    obj = {
      ...obj,
      contact_number_1: data.contact_number_1.phone
    };
  }
  if (data.contact_number_2 && data.contact_number_2.phone) {
    obj = {
      ...obj,
      contact_number_2: data.contact_number_2.phone
    };
  }
  if (data.contact_number_3 && data.contact_number_3.phone) {
    obj = {
      ...obj,
      contact_number_2: data.contact_number_3.phone
    };
  }
  Logger.log('Create facility API endpoint called with', obj);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, BASE_ROUTE, {
    method: 'POST',
    body: JSON.stringify(obj)
  });
};

export const updateReferringFacility = async (profile, data, id) => {
  Logger.log('Update facility API endpoint called with', data);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, `${BASE_ROUTE}/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data)
  });
};

export const getSourceReferringFacilitiesChart = async (
  profile,
  filters,
) => {
  const { selectedDate, facility, modality, referrerName, dateFilterType: tf } = filters || {};
  let timeframe = '';
  if (tf === dateFilterStrings.TODAY) {
    timeframe = `?from_date=${moment().format(
      'YYYY-MM-DD'
    )}&to_date=${moment().format('YYYY-MM-DD')}`;
  } else if (tf === dateFilterStrings.CUSTOM) {
    if (selectedDate && selectedDate.length === 2) {
      timeframe = `?from_date=${moment(selectedDate[0]).format(
        'YYYY-MM-DD'
      )}&to_date=${moment(selectedDate[1]).format('YYYY-MM-DD')}`;
      const dayDifference = moment(selectedDate[1]).diff(moment(selectedDate[0]), 'days');
      // if (dayDifference > 30) {
      //   timeframe = `${timeframe}&timeframe=month`
      // } else if(dayDifference > 14) {
      //   timeframe = `${timeframe}&timeframe=week`
      // }
      if (dayDifference > 14) {
        timeframe = `${timeframe}&timeframe=week`
      }
    } else {
      timeframe = `?from_date=${moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD')}&to_date=${moment().format('YYYY-MM-DD')}`;
    }
  } else {
    timeframe = `?from_date=${moment()
      .subtract(7, 'days')
      .format('YYYY-MM-DD')}&to_date=${moment().format('YYYY-MM-DD')}`;
  }
  if (facility && facility !== 'ALL')
    timeframe = `${timeframe}&facility=${facility}`;
  if (modality && modality.length) {
    if (modality.includes('ALL')) {
      modality.splice(modality.indexOf('ALL'), 1);
      if (modality.length > 0) {
        timeframe = `${timeframe}&modality=${modality}`;
      }
    } else {
      timeframe = `${timeframe}&modality=${modality}`;
    }
  }
  if (referrerName && referrerName !== 'ALL')
    timeframe = `${timeframe}&referrer_name=${referrerName}`;
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service('referring', `/facility-study-chart/aggr/${timeframe}`, {})
    .then(jsonToSourceReferringFacilitiesChart);
};

export const getSourceReferringFacilitiesTable = async (
  profile,
  filters,
) => {
  const { selectedDate, facility, modality, referrerName, dateFilterType: tf } = filters || {};
  let timeframe = '';
  if (tf === dateFilterStrings.TODAY) {
    timeframe = `?from_date=${moment().format(
      'YYYY-MM-DD'
    )}&to_date=${moment().format('YYYY-MM-DD')}`;
  } else if (tf === dateFilterStrings.CUSTOM) {
    if (selectedDate && selectedDate.length === 2) {
      timeframe = `?from_date=${moment(selectedDate[0]).format(
        'YYYY-MM-DD'
      )}&to_date=${moment(selectedDate[1]).format('YYYY-MM-DD')}`;
    } else {
      timeframe = `?from_date=${moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD')}&to_date=${moment().format('YYYY-MM-DD')}`;
    }
  } else {
    timeframe = `?from_date=${moment()
      .subtract(7, 'days')
      .format('YYYY-MM-DD')}&to_date=${moment().format('YYYY-MM-DD')}`;
  }
  if (facility && facility !== 'ALL')
    timeframe = `${timeframe}&facility=${facility}`;
  if (modality && modality.length) {
    if (modality.includes('ALL')) {
      modality.splice(modality.indexOf('ALL'), 1);
      if (modality.length > 0) {
        timeframe = `${timeframe}&modality=${modality}`;
      }
    } else {
      timeframe = `${timeframe}&modality=${modality}`;
    }
  }
  if (referrerName && referrerName !== 'ALL')
    timeframe = `${timeframe}&referrer_name=${referrerName}`;
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service('referring', `/facility-study-urgent/aggr/${timeframe}`, {})
    .then(jsonToSourceReferringFacilitiesTable);
};
