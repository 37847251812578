/* eslint-disable */
import React, { PureComponent } from 'react';
import { Menu, Icon, Dropdown } from 'antd';
import { confirmDeletion } from '../../components/confirmations';

const { Item } = Menu;

type Props = {
  record: {
    id: string,
    name: string
  },
  deleteAction: (string, string) => void
};

export default class GeneralTableMenu extends PureComponent<Props> {
  componentDidMount() {
    const { getAction, record } = this.props;
    // getAction(record.id);
  }

  handleMenuClick = e => {
    const { deleteAction, record, recordName } = this.props;
    switch (e.key) {
      case '1':
        confirmDeletion(deleteAction, record.id, record.name, recordName);
        break;
      case '2':
        e.item.setState({ visible: false });
        break;
      default:
        break;
    }
  };

  render() {
    const { recordName, record } = this.props;
    return (
      <Dropdown
        overlay={
          <Menu onClick={this.handleMenuClick}>
            <Item key='1' id={record.id} name={record.name}>
              Delete {recordName.replace(/\b\w/g, l => l.toUpperCase())}
            </Item>
            <Item key='2' id={record.id}>
              Cancel
            </Item>
          </Menu>
        }
        trigger={['click']}
      >
        <Icon
          component='button'
          type='ellipsis'
          rotate='90'
          style={{ fontSize: '32px' }}
        />
      </Dropdown>
    );
  }
}
