export const HANDLE_COLLAPSE_CHANGE = 'HANDLE_COLLAPSE_CHANGE';

export type HandleCollapseAction = { type: string, payload: boolean };

export const handleCollapseChange = (
  payload: boolean
): HandleCollapseAction => ({
  type: HANDLE_COLLAPSE_CHANGE,
  payload
});
