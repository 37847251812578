import * as React from 'react';
import { Menu, Dropdown } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import type { Physician } from '../../../schemas';
import { confirmDeletion } from '../../../components/confirmations';
import EditPhysician from './EditPhysician';
import { ReactComponent as OptionsVertical } from '../../../static/images/options-vertical.svg';

const { Item } = Menu;

type Props = {
  getAction: () => void,
  deleteAction: string => void,
  record: Physician,
  editVisible: boolean,
  showEdit: () => void,
  hideEdit: () => void
};

export default class TableMenu extends React.PureComponent<Props> {
  componentDidMount() {
    const { getAction, record } = this.props;
    if (record.guid) {
      getAction(record.guid);
    }
  }

  handleMenuClick = (e: ClickParam) => {
    const { getAction, record, deleteAction, showEdit } = this.props;
    switch (e.key) {
      case '1':
        getAction(record.guid);
        showEdit();
        break;
      case '2':
        confirmDeletion(deleteAction, record.guid, record.name, 'physician');
        break;
      case '3':
        e.item.setState({ visible: false });
        break;
      default:
        break;
    }
  };

  render() {
    const { isInternal, record, editVisible, hideEdit } = this.props;
    return (
      <React.Fragment>
        <EditPhysician
          isInternal={isInternal}
          onCancel={hideEdit}
          visible={editVisible}
        />
        <Dropdown
          overlay={
            <Menu onClick={this.handleMenuClick}>
              <Item key='1' id={record.guid}>
                Edit
              </Item>
              {/* <Item key='2' id={record.guid}>
                Remove
              </Item> */}
              <Item key='3' id={record.guid}>
                Cancel
              </Item>
            </Menu>
          }
          trigger={['click']}
        >
          <OptionsVertical />
        </Dropdown>
      </React.Fragment>
    );
  }
}
