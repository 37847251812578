export default values => {
  const errors = { colleague: {} };
  const { colleague } = values;
  const { name, email } = colleague;
  if (!name) {
    errors.colleague.name = 'Required';
  }

  if (!email) {
    errors.colleague.email = 'Required';
  }

  return errors;
};
