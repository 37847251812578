// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Col, Divider, Form, Modal, Row, Typography } from 'antd';
import { Field } from 'redux-form';
import ConfirmRevokeAdminRights from '../../partials/RevokeAdminRights';
import {
  InputPhoneNumberField,
  InputRadioButtonField,
  InputSelectField,
  InputTextField,
  InputCheckboxGroupFacilitiesField
} from '../../../../components/FormParts';
import { RAD_SPECIALTY, USER_TYPE, YES_NO } from '../../../../utils/filters';
import './BaseUserForm.less';
import type { Facility, ReduxState } from '../../../../schemas';
import { t } from 'i18next';

const { Title } = Typography;

const styles = {
  title: {
    fontWeight: 500,
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.65)',
    marginBottom: 14
  },
  divider: {
    margin: '0px 0px 14px'
  }
};

type Props = {
  visible: boolean,
  title: string,
  // Text of the ok button
  okText: string,
  // What to do if the cancel button is pushed. It's required by the  Modal class
  onCancel: () => void,
  isMain: boolean,
  // Initial role for the user
  role: ?string,
  children: React.Node,
  facilities: Array<Facility>,
  selectedFacilities: Array<Facility>
};

/**
 * Contains the controls used to add or edit a user.
 */
export default connect(
  (state: ReduxState) => ({
    facilities: state.facilities.internalArray
  }),
  {}
)((props: Props) => {
  const {
    visible,
    onCancel,
    title,
    okText,
    isMain,
    children,
    facilities,
    selectedFacilities
  } = props;
  const homeFacilities = facilities.map((f: Facility) => ({
    text: f.name,
    value: f.name
  }));
  let groupFacilities = [];
  if (selectedFacilities) {
    groupFacilities = facilities
      .filter((f: Facility) => selectedFacilities.includes(f.guid))
      .map((f: Facility) => f.name);
  }

  // Role is the userType of the edited user. We use it to decide if render
  // the specialty row
  // eslint-disable-next-line
  const [role, setRole] = React.useState<?string>(props.role);
  const [specialtyOpen, openSpecialty] = React.useState(false);
  const [homeFacilitiesOpen, openHomeFacilities] = React.useState(false);
  const [userTypeOpen, openUserType] = React.useState(false);

  return (
    <Modal
      visible={visible}
      title={title}
      okText={okText}
      onCancel={onCancel}
      destroyOnClose={false}
      footer={children}
      wrapClassName='lateral-modal'
    >
      <Form layout='vertical'>
        <Row gutter={32}>
          <Col xs={24} sm={12}>
            <Row gutter={8} type='flex' style={{ flexWrap: 'nowrap' }}>
              <Col>
                <Field
                  style={{ width: 50 }}
                  name={t("user_administration.field.title")}
                  label='Title'
                  placeholder='Dr.'
                  component={InputTextField}
                />
              </Col>
              <Col>
                <Field
                  name='name'
                  label={t("user_administration.field.name")}
                  placeholder='Name'
                  component={InputTextField}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={12}>
            <Field
              name='credentials'
              label={t("user_administration.field.credentials")}
              placeholder='MD, ARRT, FACR, etc'
              component={InputTextField}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Field
              name='userType'
              label={t("user_administration.field.role")}
              placeholder='Please select...'
              component={InputSelectField(
                USER_TYPE,
                userTypeOpen,
                () => openUserType(true),
                () => openUserType(false)
              )}
              disabled
              onChange={value => setRole(value)}
            />
          </Col>
          <Col xs={24} sm={12}>
            {role && role.toLowerCase() === 'radiologist' && (
              <Field
                name='specialty'
                label={t("user_administration.field.specialty")}
                showSearch
                placeholder='Type to search or select'
                component={InputSelectField(
                  RAD_SPECIALTY,
                  specialtyOpen,
                  () => openSpecialty(true),
                  () => openSpecialty(false)
                )}
                mode='multiple'
              />
            )}
          </Col>
          <Col xs={24} sm={12}>
            <Field
              name='email'
              label={t("user_administration.field.email_adress")}
              type='email'
              placeholder='mail@domain.com'
              component={InputTextField}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Field
              name='phoneNumber'
              label={t("user_administration.field.mobile_number")}
              component={InputPhoneNumberField}
              defaultCountry='sg'
              disableAreaCodes
            />
          </Col>
          <Col xs={24} sm={12}>
            <Field
              name='homeFacility'
              label={t("user_administration.field.home_facility")}
              component={InputSelectField(
                homeFacilities,
                homeFacilitiesOpen,
                () => openHomeFacilities(true),
                () => openHomeFacilities(false)
              )}
            />
          </Col>
          <Col xs={24} sm={12}>
            {isMain ? (
              <ConfirmRevokeAdminRights />
            ) : (
              <Field
                name='admin'
                label={t("user_administration.field.admin_access")}
                props={{
                  className: 'admin-access'
                }}
                component={InputRadioButtonField(YES_NO)}
              />
            )}
          </Col>
          <Col xs={24} sm={12}>
            {isMain ? (
              <ConfirmRevokeAdminRights />
            ) : (
              <Field
                name='availableForAssignment'
                label={t("user_administration.field.available_assignment")}
                props={{
                  className: 'admin-access'
                }}
                component={InputRadioButtonField(YES_NO)}
              />
            )}
          </Col>
          <Col xs={24} sm={12}>
            {isMain ? (
              <ConfirmRevokeAdminRights />
            ) : (
              <Field
                name='allowed_to_delete_study'
                label={t("user_administration.field.allowed_delete_studies")}
                props={{
                  className: 'admin-access'
                }}
                component={InputRadioButtonField(YES_NO)}
              />
            )}
          </Col>
        </Row>
        <Divider style={styles.divider} />
        <div>
          <Title level={4} style={styles.title}>
          {t("user_administration.field.allow_user_studies_within_facilities")}
          </Title>
          <Field
            name='groupFacilities'
            label=''
            // component={InputCheckBoxField(HOME_FACILITIES)}
            checkboxType='table'
            props={{ selected: groupFacilities }}
            component={InputCheckboxGroupFacilitiesField(homeFacilities)}
          />
        </div>
      </Form>
    </Modal>
  );
});
