import React from 'react';
import { Form } from 'antd';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { isString } from 'lodash';
import { connect } from 'react-redux';

const FormItem = Form.Item;

const InputPhoneNumberField = ({
  input: { value, onChange },
  meta,
  children,
  hasFeedback,
  label,
  country,
  ...rest
}) => {
  const hasError = meta.touched && meta.invalid;
  return (
    <FormItem
      label={label}
      validateStatus={hasError ? 'error' : 'success'}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
    >
      <ReactPhoneInput
        inputStyle={{ width: '100%', height: '32px' }}
        value={isString(value) ? value : value && value.phone}
        country={(country && country.toLowerCase()) || 'sg'}
        onChange={(phone, extra) => {
          const { name, dialCode } = extra;
          onChange({ phone, countryName: name, dialCode });
          // add the + sign if it is ommitted
          // if (value.indexOf('+') !== 0) {
          //   input.onChange(`+${value}`);
          // } else {
          //   input.onChange(value);
          // }
        }}
        autoFormat={false}
        {...rest}
      />
    </FormItem>
  );
};

const mapStateToProps = state => {
  const { location } = state;
  const { country } = location || {};
  return {
    country
  };
};
export default connect(mapStateToProps)(InputPhoneNumberField);
