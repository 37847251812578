import React from 'react';
import { Row, Col, Button } from 'antd';
import { t } from 'i18next';
import CardFormWrapper from '../partials/CardFormWrapper';
import './index.less';

const USE_HOSTED_UPLOADER_FORM_NAME = 'useHostedUploaderForm';

function HostedUploader({ groupId, disabled }) {
  return (
    <CardFormWrapper
      formName={USE_HOSTED_UPLOADER_FORM_NAME}
      title='Use our hosted uploader'
      text={t('onboarding.link_page_separate_self_contained_uploader')}
      value={`<a href="https://embed.alem.health/?id=${groupId}">Request Second Opinion</a>`}
      type='hosted'
      disabled={disabled}
    >
      <Row type='flex' align='middle' gutter={24}>
        <Col>
          <div className='label'>{t("uploader_settings.preview")}</div>
        </Col>
        <Col>
          <Button
            disabled={disabled}
            href={`https://embed.alem.health/?id=${groupId}`}
          >
            {t("onboarding.view_page")}
          </Button>
        </Col>
      </Row>
    </CardFormWrapper>
  );
}

export default HostedUploader;
