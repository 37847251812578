/* eslint-disable react/no-children-prop */

import React from 'react';
import { Form, Input } from 'antd';

const FormItem = Form.Item;
const { TextArea } = Input;

const InputTextAreaField = ({
  input,
  meta,
  children,
  hasFeedback,
  label,
  rows,
  ...rest
}) => {
  const hasError = meta.touched && meta.invalid;
  return (
    <FormItem
      label={label}
      validateStatus={hasError ? 'error' : 'success'}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
    >
      <TextArea rows={rows} {...input} {...rest} children={children} />
    </FormItem>
  );
};

export default InputTextAreaField;
