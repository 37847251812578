import React from "react";
import { Button, Col, Row, Tooltip } from 'antd';
import CommonHeader from "../common/CommonHeader";

function RadiologistPool() {
  return (
    <div>
      <CommonHeader title="Radiologist Pool" description="Organize radiologists into designated pools and implementing streamlined protocols for workflow management and communication." buttonText="Create Radiologist Pool" />

    </div>);
}

export default RadiologistPool;
