/* eslint-disable no-console */
import React from 'react';
import { Form, Select } from 'antd';

const { Option, OptGroup } = Select;

const InputGroupSelectField = ({
  labelStyle = {},
  label = '',
  labelAlign = 'right',
  colon = false,
  width = '',
  maxWidth = '',
  required = false,
  minWidth = '',
  optionGroups = [],
  defaultValue = undefined,
  placeholder = '',
  formItemLayout = {},
  input,
  meta: { touched, error },
  ...restProps
}) => {
  if (!input.value) input.value = defaultValue;

  return (
    <Form.Item
      style={labelStyle}
      label={label}
      labelAlign={labelAlign}
      colon={colon}
      required={required}
      validateStatus='' // 'success' 'warning' 'error' 'validating'
      help={touched && error ? error : ''}
      {...formItemLayout}
    >
      <Select
        style={{ width, maxWidth, minWidth }}
        placeholder={placeholder}
        optionLabelProp="label"
        {...input}
        {...restProps}
      >
        {optionGroups.map(group => {
          return (
            <OptGroup key={group.label} label={group.label}>
              {group.options.map(({ value, label, text }) => {
                return (
                  <Option 
                    key={value} 
                    value={value} 
                    label={`${group.label} - ${label}`}  // This will be shown in input field
                  >
                    {text || label}
                  </Option>
                );
              })}
            </OptGroup>
          )
        })}
      </Select>
    </Form.Item>
  );
};

export default InputGroupSelectField;
