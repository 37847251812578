// @flow
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Icon, Switch, Table, Tag, Modal, Empty } from 'antd';
import { t } from 'i18next';
import ViewUser from './ViewUser';
import TableMenu from './partials/TableMenu';
import {
  deleteUserAction,
  editUserAction,
  getInternalFacilitiesAction,
  getUserByIdAction,
  getUsersAction,
  postUserSignatureUploadRequest,
  removeUserAction,
  resetUserPasswordAction
} from '../../store/actions';
import { YES_NO, USER_TYPE } from '../../utils/filters';

import type { Facility, ReduxState, User } from '../../schemas';
import { adaptUserData } from '../../utils';
import { upperFirst } from 'lodash';
import { reset } from "redux-form"
import { getSharedAccessSignatureForAzureBlobAPICall } from '../../api';
import UserSignatureForm from './forms/UserSignatureForm';

const { confirm } = Modal;

type Props = {
  data: Array<User>,
  total: number,
  loading: boolean,
  modifications: number,
  facilities: Array<Facility>,
  getAction: () => void,
  getByIdAction: string => void,
  deleteAction: string => void,
  resetPassword: string => void,
  getFacilities: () => void
};

export default connect(
  (state: ReduxState) => ({
    data: state.users.array,
    total: state.users.total,
    loading: state.loading.getUsers,
    user: state.users.current,
    modifications: state.users.modifications,
    facilities: state.facilities.internalArray,
    profile: state.profile
  }),
  {
    getAction: getUsersAction,
    getByIdAction: getUserByIdAction,
    deleteAction: deleteUserAction,
    resetPassword: resetUserPasswordAction,
    getFacilities: getInternalFacilitiesAction,
    editAction: editUserAction,
    postUserSignatureUploadRequest,
    removeUserAction: removeUserAction,
    reset,
  }
)(
  ({
    data,
    total,
    loading,
    modifications,
    getAction,
    deleteAction,
    resetPassword,
    getByIdAction,
    facilities,
    getFacilities,
    editAction,
    user,
    profile,
    removeUserAction,
    postUserSignatureUploadRequest,
    reset
  }: Props) => {
    const [homeFacilities, setHomeFacilities] = useState(
      facilities.map((f: Facility) => ({ text: f.name, value: f.name }))
    );
    const [signatureModal, setSignatureModal] = useState(false);
    const [azureBlobSAS, setAzureBlobSAS] = useState(null);


    React.useEffect(() => {
      getFacilities();
      getAction(-1, -1, '', '');
    }, []);
    React.useEffect(() => {
      if (homeFacilities.length !== facilities.length) {
        setHomeFacilities(
          facilities.map((f: Facility) => ({ text: f.name, value: f.name }))
        );
      }
    }, [facilities, homeFacilities]);

    const [modificationsState, setModificationsState] = React.useState(
      modifications
    );
    /* eslint-disable-next-line */
    const [adaptedData, setAdaptedData] = React.useState(adaptUserData(data));

    React.useEffect(() => {
      if (modificationsState > modifications) {
        setModificationsState(modifications);
        setAdaptedData(adaptUserData(data));
      }
      !azureBlobSAS && getSASForAzureBlob()
    }, [data, modifications]);

    const renderInvitationStatus = (text, record) => {
      let invitationText = record.userMetadata && record.userMetadata.invitationStatus && record.userMetadata.invitationStatus.toLowerCase() || "invited"
      const color = invitationText === "pending" ? "orange" : invitationText === "invited" ? "green" : "magenta";
      // const textString = invitationText === "pending" ? user_administration.field: invitationText === "invited" ? "Invited" : invitationText==="expired" ? "Invitation Expired" :""
      return <Tag color={color}>{t(`user_administration.field.${invitationText}`)}</Tag>;
    }
    const onClickAddSignature = () => {
      setSignatureModal(true);
    };

    const getSASForAzureBlob = async () => {
      const { responseOk, responseJSON } = await getSharedAccessSignatureForAzureBlobAPICall(profile)
      if (responseOk) {
        const { azure_blob_sas: azureBlobSAS } = responseJSON
        setAzureBlobSAS(azureBlobSAS)
      }
    }

    const callbackFunction = (status) => {
      status && setSignatureModal(false);
    }
    const signatureUploadHandler = (data) => {
      const { signature, name, title, credentials, phone_number, qualifications, auto_generated, font_name, signature_name, digitally_signed, signature_text, reporting_date_format, display_phone_number, designation, signatureNew } = data
      let requestBodyData = {
        ...user,
        user_metadata: {
          ...user.user_metadata,
          name: name || "",
          title: title || "",
          credentials: credentials || "",
          phone_number: phone_number || "",
          signature_settings: {
            auto_generated: auto_generated,
            font_name: font_name || "",
            digitally_signed: digitally_signed || false,
            designation: designation || "",
            display_phone_number: display_phone_number || false,
            reporting_date_format: reporting_date_format || "",
            qualifications: qualifications || "",
            signature_text: signature_text || ""
          }
        },
      }
      delete requestBodyData.userMetadata
      delete requestBodyData.appMetadata
      if (auto_generated) {
        postUserSignatureUploadRequest(undefined, true, user.id, requestBodyData, callbackFunction)
      } else {
        if (signature && !signature.file) {
          postUserSignatureUploadRequest(undefined, true, user.id, requestBodyData, callbackFunction)
        } else {
          postUserSignatureUploadRequest(signature.file, false, user.id, requestBodyData, callbackFunction)
        }
      }
    }

    const initialValuesSignature = (isForm) => {
      if (user && Object.keys(user).length > 0) {
        const { userMetadata: { name, credentials, phoneNumber, title, userType, signature, signature_settings } } = user || {}
        if (signature_settings && Object.keys(signature_settings).length > 0) {
          const { auto_generated, digitally_signed, font_name, qualifications, signature_name, signature_text, designation, reporting_date_format, display_phone_number } = signature_settings
          return {
            title: title,
            name: name,
            credentials: credentials,
            phone_number: phoneNumber,
            designation: designation,
            auto_generated: auto_generated,
            signatureNew: signature,
            signature: signature,
            digitally_signed: digitally_signed,
            font_name: font_name,
            reporting_date_format: reporting_date_format,
            qualifications: qualifications || "",
            signature_name: signature_name,
            signature_text: signature_text,
            display_phone_number: display_phone_number
          }
        } else if (isForm) {
          return {
            auto_generated: true
          }
        } else {
          return {}
        }
      }
    }

    const onClickDeleteSignature = () => {
      // const user = this.user;
      let requestBodyData = {
        ...user,
        user_metadata: {
          ...user.user_metadata,
          signature_settings: {}
        }
      }
      delete requestBodyData.userMetadata
      delete requestBodyData.appMetadata
      confirm({
        title: t('user_administration.delete_signature'),
        content: t('user_administration.delete_signature_confirm'),
        okText: t('button.delete'),
        okType: 'danger',
        icon: <Icon type="delete" style={{ color: '#FF4D4F' }} />,
        cancelText: t('button.cancel'),
        onOk: () => {
          postUserSignatureUploadRequest(undefined, true, user.id, requestBodyData)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
    const columns = [
      {
        title: t('user_administration.field.name'),
        dataIndex: 'userMetadata.name',
        sorter: (a, b) =>
          (a.name ? a.name : '')
            .toString()
            .toLowerCase()
            .localeCompare((b.name ? b.name : '').toString().toLowerCase()),
        columnSearch: true,
        render: (text, record) => text
      },
      {
        title: t('user_administration.field.role'),
        dataIndex: 'userType',
        filters: USER_TYPE,
        onFilter: (value, record) => {
          return record.userMetadata.userType.toString().includes(value);
        },
        sorter: (a, b) =>
          (a.userMetadata && a.userMetadata.userType
            ? a.userMetadata.userType
            : ''
          )
            .toString()
            .toLowerCase()
            .localeCompare(
              (b.userMetadata && b.userMetadata.userType
                ? b.userMetadata.userType
                : ''
              )
                .toString()
                .toLowerCase()
            ),
        render: (text, record) => {
          return record.userMetadata.userType
            ? upperFirst(record.userMetadata.userType)
            : '—';
        }
      },
      {
        title: t('user_administration.field.home_facility'),
        dataIndex: 'homeFacility',
        filters: homeFacilities,
        onFilter: (value, record) => {
          return (record.userMetadata && record.userMetadata.homeFacility
            ? record.userMetadata.homeFacility
            : ''
          )
            .toString()
            .includes(value);
        },
        sorter: (a, b) =>
          (a.userMetadata && a.userMetadata.homeFacility
            ? a.userMetadata.homeFacility
            : ''
          )
            .toString()
            .toLowerCase()
            .localeCompare(
              (b.userMetadata && b.userMetadata.homeFacility
                ? b.userMetadata.homeFacility
                : ''
              )
                .toString()
                .toLowerCase()
            ),
        render: (text, record) =>
          record.userMetadata.homeFacility
            ? record.userMetadata.homeFacility
            : '—'
      },
      // {
      //   // TODO: Update when status payload exist
      //   title: t('user_administration.field.invitation_status'),
      //   dataIndex: 'status',
      //   render: renderInvitationStatus,
      // },
      {
        // TODO: Update when status payload exist
        title: t('user_administration.field.status'),
        dataIndex: 'status',
        render: (text, record) => <Tag color="green">{t('user_administration.field.active')}</Tag>
      },
      {
        title: t('user_administration.field.admin_access'),
        dataIndex: 'admin',
        filters: YES_NO,
        onFilter: (value, record) => {
          return 'appMetadata' in record
            ? record.appMetadata.admin.toString().includes(value)
            : '';
        },
        sorter: (a, b) =>
          (a.appMetadata && a.appMetadata.admin ? a.appMetadata.admin : '')
            .toString()
            .toLowerCase()
            .localeCompare(
              (b.appMetadata && b.appMetadata.admin ? b.appMetadata.admin : '')
                .toString()
                .toLowerCase()
            ),
        render: (_text, record) => {
          return <Switch checked={record.appMetadata.admin === '1'} onChange={(e) => {
            let requestBody = {
              ...record,
              appMetadata: {
                ...record.appMetadata,
                admin: e ? '1' : '0'
              }
            }
            delete requestBody.app_metadata;
            delete requestBody.user_metadata;
            editAction(requestBody, record.id, () => {
              getAction(-1, -1, '', '');
            });

          }} />;
          //   if (record.appMetadata && record.appMetadata.admin) {
          //     let text = record.appMetadata.admin === '1' ? 'Yes' : 'No';
          //     text += record.appMetadata.main === '1' ? ' (Main)' : '';
          //     return text;
          //   }
          //   return '—';
        }
      },
      {
        title: t('user_administration.field.action'),
        key: 'action',
        render: (text, record) => <div className='table-action-buttons'>
         <div style={{ marginRight: 30 }}> <ViewUser userId={record.id} azureBlobSAS={azureBlobSAS} onClickDeleteSignature={onClickDeleteSignature} onClickAddSignature={onClickAddSignature} /></div>
          <TableMenu
            record={record}
            user={user}
            reset={reset}
            profile={profile}
            getAction={getByIdAction}
            deleteAction={deleteAction}
            resetPassword={resetPassword}
            removeUserAction={removeUserAction}
            postUserSignatureUploadRequest={postUserSignatureUploadRequest}
          />
        </div>
      }
    ];

    const rowKey = user => user.id;
    return (
      <>
        <Table
          columns={columns}
          className='user-table'
          dataSource={data}
          size='small'
          pagination={{
            size: "large",
          }}
          loading={loading}
          locale={{
            emptyText: (
              <Empty
                description={t("table.no_data")}
              />
            ),
          }}
        />
        {signatureModal && <UserSignatureForm user={user} azureBlobSAS={azureBlobSAS} initialValues={initialValuesSignature(true)} onClose={() => setSignatureModal(false)} onSubmit={signatureUploadHandler} />}
      </>);
  }
);
