import AuthService from '../index';
import Logger from '../../utils/logger';
import {
  jsonToStripeDataInformation,
  jsonToStripeInvoiceData
} from '../transformers';

const API_SERVICE = 'telerad-settings';
const BASE_ROUTE = '/payments';

export const getStripeSessionEndpoint = async (profile, payload) => {
  Logger.log(`POST create stripe sessio called with: ${JSON.stringify(payload, null, 2)}`);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(null, BASE_ROUTE + '/portal-session', {
    method: 'POST',
    body: JSON.stringify(payload)
  }).then(i => {
    return i;
  });
};

export const getStripeDataEndpoint = async (profile) => {
  Logger.log('GET stripe data api called');
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, BASE_ROUTE).then(i => {
    return jsonToStripeDataInformation(i);
  });
};

export const getStripeInvoicesEndpoint = async (
  profile,
  limit,
  first,
  last
) => {
  Logger.log(
    `GET stripe invoices api called: ${limit} invoices ${
      first ? `from ${first}` : `to ${last}`
    }`
  );
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(
      API_SERVICE,
      `${BASE_ROUTE}/invoices?limit=${limit}&${
        last ? `after=${last}` : first && `before=${first}`
      }`
    )
    .then(i => {
      return jsonToStripeInvoiceData(i);
    });
};

export const createStripeChargeEndpoint = async (
  profile,
  payload
) => {
  Logger.log(`POST create stripe charge called with: ${payload}`);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `${BASE_ROUTE}/charge`, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(i => i.token);
};

export const createStripePaymentEndpoint = async (
  profile,
  payload
) => {
  Logger.log(`POST create stripe payment called with: ${payload}`);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `${BASE_ROUTE}/payment`, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(i => i.token);
};

export const checkStripeUnsuscribeEndpoint = async (profile) => {
  Logger.log('PATCH check stripe unsuscribe called');
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(
    API_SERVICE,
    `${BASE_ROUTE}/check-unsuscribe`,
    {
      method: 'PATCH'
    }
  );
};
