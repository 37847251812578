/* eslint-disable */
export const GET_REFERRING_FACILITIES_LIST_REQUEST =
  'GET_REFERRING_FACILITIES_LIST_REQUEST';
export const GET_REFERRING_FACILITIES_LIST_SUCCESS =
  'GET_REFERRING_FACILITIES_LIST_SUCCESS';
export const GET_REFERRING_FACILITIES_LIST_FAILED =
  'GET_REFERRING_FACILITIES_LIST_FAILED';

export const GET_REFERRING_FACILITIES_REQUEST =
  'GET_REFERRING_FACILITIES_REQUEST';
export const GET_REFERRING_FACILITIES_SUCCESS =
  'GET_REFERRING_FACILITIES_SUCCESS';
export const GET_REFERRING_FACILITIES_FAILED =
  'GET_REFERRING_FACILITIES_FAILED';

export const GET_REFERRING_FACILITY_BY_ID_REQUEST =
  'GET_REFERRING_FACILITY_BY_ID_REQUEST';
export const GET_REFERRING_FACILITY_BY_ID_SUCCESS =
  'GET_REFERRING_FACILITY_BY_ID_SUCCESS';
export const GET_REFERRING_FACILITY_BY_ID_FAILED =
  'GET_REFERRING_FACILITY_BY_ID_FAILED';

export const ADD_REFERRING_FACILITY_REQUEST = 'ADD_REFERRING_FACILITY_REQUEST';
export const ADD_REFERRING_FACILITY_SUCCESS = 'ADD_REFERRING_FACILITY_SUCCESS';
export const ADD_REFERRING_FACILITY_FAILED = 'ADD_REFERRING_FACILITY_FAILED';

export const EDIT_REFERRING_FACILITY_REQUEST =
  'EDIT_REFERRING_FACILITY_REQUEST';
export const EDIT_REFERRING_FACILITY_SUCCESS =
  'EDIT_REFERRING_FACILITY_SUCCESS';
export const EDIT_REFERRING_FACILITY_FAILED = 'EDIT_REFERRING_FACILITY_FAILED';

export const DELETE_REFERRING_FACILITY_REQUEST =
  'DELETE_REFERRING_FACILITY_REQUEST';
export const DELETE_REFERRING_FACILITY_SUCCESS =
  'DELETE_REFERRING_FACILITY_SUCCESS';
export const DELETE_REFERRING_FACILITY_FAILED =
  'DELETE_REFERRING_FACILITY_FAILED';

export const GET_SOURCE_REFERRALS_BY_FACILITIES_REQUEST =
  'GET_SOURCE_REFERRALS_BY_FACILITIES_REQUEST';

export const GET_SOURCE_REFERRING_FACILITIES_CHART_REQUEST =
  'GET_SOURCE_REFERRING_FACILITIES_CHART_REQUEST';
export const GET_SOURCE_REFERRING_FACILITIES_CHART_SUCCESS =
  'GET_SOURCE_REFERRING_FACILITIES_CHART_SUCCESS';
export const GET_SOURCE_REFERRING_FACILITIES_CHART_FAILED =
  'GET_SOURCE_REFERRING_FACILITIES_CHART_FAILED';

export const getReferringFacilitiesListAction = () => ({
  type: GET_REFERRING_FACILITIES_LIST_REQUEST
});

export const getReferringFacilitiesAction = () => ({
  type: GET_REFERRING_FACILITIES_REQUEST
});

export const getReferringFacilityByIdAction = facilityId => ({
  type: GET_FACILITY_BY_ID_REQUEST,
  facilityId
});

export const addReferringFacilityAction = facility => ({
  type: ADD_REFERRING_FACILITY_REQUEST,
  facility
});

export const editReferringFacilityAction = (facility, facilityId) => ({
  type: EDIT_REFERRING_FACILITY_REQUEST,
  facility,
  facilityId
});

export const deleteReferringFacilityAction = facilityId => ({
  type: DELETE_FACILITY_REQUEST,
  facilityId
});

export const getSourceReferringFacilitiesChartAction = timeFrame => ({
  type: GET_SOURCE_REFERRING_FACILITIES_CHART_REQUEST,
  timeFrame
});

export const getSourceReferralsByFacilitiesAction = timeFrame => ({
  type: GET_SOURCE_REFERRALS_BY_FACILITIES_REQUEST,
  timeFrame
});
