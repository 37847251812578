import { Button, Checkbox, Dropdown, Form, Icon, Input, Menu } from "antd";
import React, { useState } from "react";
import Text from "antd/lib/typography/Text";
import { useEffect } from "react";
import "./CustomDropdownCheckboxGroupField.less";
import TreeMenu from "./TreeMenu";
import { isEmpty } from "lodash";

const CustomDropdownCheckboxGroupField = ({
  labelStyle = {},
  labelAlign = 'right',
  colon = false,
  formItemLayout = {},
  optionList = [],
  treeOptionList = {},
  dropDownText,
  hideSearch,
  label,
  defaultValues,
  input,
  overlayClassName = "",
  allowAddOption = false,
  hideDialog = false,
  hideInitialOption = false,
  selectAllLabel = 'Select All',
  setAdditionalOption = () => { }
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsLabel, setSelectedOptionsLabel] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [list, setList] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isReverse, setReverse] = useState(false);

  useEffect(() => {
    if (optionList.length > 0) {
      const matches = optionList.map(item => {
        if (item.label.toLowerCase().includes(searchText.toLowerCase()) || item.value.toLowerCase().includes(searchText.toLowerCase())) {
          return item;
        } else {
          return null;
        }
      }).filter(Boolean);
      setList(matches);
    }
  }, [optionList, searchText]);

  useEffect(() => {
    defaultValues && setSelectedOptions(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (input.value) {
      if (input.value.includes('ALL')) {
        const allValues = list.map(item => item.value);
        setSelectedOptions(allValues);
        setSelectedOptionsLabel([selectAllLabel]);
      } else {
        setSelectedOptions(input.value);
        if (optionList.length) {
          const selectedList = optionList.filter(option => input.value.includes(option.value)).map(option => option.label)
          setSelectedOptionsLabel(selectedList);
        }
      }
    }
  }, [input.value]);

  const onCheckOptionHandler = (values) => {
    setSelectedOptions(values);
    input.onChange(values);
  }

  const onAddOptionHandler = (value) => {
    if (!allowAddOption) return
    setAdditionalOption(value);
    input.onChange([...selectedOptions, value]);
    setSearchText("")
  }

  const onSelectAllHandler = (e) => {
    if (e.target.checked) {
      const allValues = list.map(item => item.value);
      setSelectedOptions(allValues);
      input.onChange(['ALL']);
    } else {
      setSelectedOptions([]);
      input.onChange([]);
    }
  }

  const onResetHandler = () => {
    setSelectedOptions([]);
    input.onChange([]);
  }

  const onOkHandler = () => {
    setDropdownVisible(false);
  }

  const allChecked = (list.length > 0 && (selectedOptions.length === list.length)) || selectedOptions.includes('ALL');

  const checkboxElement = [
    !hideInitialOption && <Checkbox key='select-all' style={{ marginBottom: 8, marginTop: 5 }} onChange={onSelectAllHandler} checked={allChecked}>{selectAllLabel}</Checkbox>,
    <div className="field-checkbox-container"><Checkbox.Group value={selectedOptions} onChange={onCheckOptionHandler} key='cb-group' options={list} /></div>,
  ]

  const menu = (
    <Menu>
      <div className="custom-checkbox-container">
        {isReverse && (
          <div className="checkbox-inner-container">
            {checkboxElement.reverse()}
          </div>
        )}
        {(allowAddOption && searchText) && (
          <div className="ant-select-dropdown-menu-item ant-select-dropdown-menu-item-selected"
            onClick={() => onAddOptionHandler(searchText)}
          >
            Add "{searchText}"
          </div>
        )}
        {!hideSearch && <div className="search-box">
          <Input placeholder="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
        </div>}
        {!isReverse && (
          <div className="checkbox-inner-container">
            {checkboxElement}
          </div>
        )}
        {!hideDialog && (
          <div className="dropdown-buttons">
            <Button size="small" onClick={onResetHandler}>Reset</Button>
            <Button size="small" type="primary" onClick={onOkHandler}>OK</Button>
          </div>
        )}
      </div>
    </Menu>)

  useEffect(() => {
    if (dropdownVisible) {
      setReverse(false)
      setTimeout(() => {
        if (document.querySelector('.custom-dropdown-body-part')) {
          let domX = document.querySelector('.custom-dropdown-body-part').getBoundingClientRect().bottom;
          domX > 0 ? setReverse(true) : setReverse(false);
        } else if (document.querySelector('.custom-dropdown-modality')) {
          let domX = document.querySelector('.custom-dropdown-modality').getBoundingClientRect().bottom;
          domX > 0 ? setReverse(true) : setReverse(false);
        } else {
          setReverse(false)
        }
      }, 0);
    }
  }, [dropdownVisible]);

  const displayText = selectedOptionsLabel.length > 0
    ? selectedOptionsLabel.includes('ALL') ? selectAllLabel : selectedOptionsLabel.join(", ")
    : selectedOptions.length > 0 ?
      !isEmpty(treeOptionList) && selectedOptions.length >= 13 ? selectAllLabel :
        selectedOptions.join(", ")
      : dropDownText || "Select";

  return (
    <Form.Item
      style={labelStyle}
      label={label}
      labelAlign={labelAlign}
      colon={colon}
      required={true}
      validateStatus='' // 'success' 'warning' 'error' 'validating'
      {...formItemLayout}
    >
      <Dropdown
        overlay={!isEmpty(treeOptionList) ? (
          <TreeMenu
            setDropdownVisible={setDropdownVisible}
            hideSearch={hideSearch}
            data={treeOptionList}
            onChange={input.onChange}
          />
        ) : menu}
        className={`custom-dropdown ${selectedOptions.length > 0 ? 'active-dropdown' : ''}`}
        trigger={['click']}
        placement={"bottomRight"}
        visible={dropdownVisible}
        overlayClassName={overlayClassName}
        onVisibleChange={(value) => {
          setDropdownVisible(value);
        }}
        style={{ lineHeight: 1.4 }}
      >
        <Button style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 4,
          boxShadow: 'unset'
        }}>
          <span style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: selectedOptionsLabel.length > 0 ? '#666' : '#bfbfbf'
          }}>
            {displayText}
          </span>
          <Icon type="down" style={{ flexShrink: 0, marginLeft: 8, color: '#c6c6c6' }} />
        </Button>
      </Dropdown>
    </Form.Item>
  );
};

export default CustomDropdownCheckboxGroupField;