import * as actions from '../actions/locationActions';

export default (state = null, action) => {
  switch (action.type) {
    case actions.GET_LOCATION_SUCCESS:
      return { ...state, ...action.data };
    default:
      return state;
  }
};
