import React, { Fragment } from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { InputSwitchField } from '../../components/FormParts';
import Buttons from './Buttons';

const SETTINGS_NOTIFICATIONS_FORM = 'settingsNotificationsForm';

const doRow = (title, element1, element2, element3) => (
  <Row gutter={56} type='flex' justify='start'>
    <Col className='gutter-row' span={6}>
      {title}
    </Col>
    <Col className='gutter-row' span={4}>
      {element1}
    </Col>
    <Col className='gutter-row' span={5}>
      {element2}
    </Col>
    <Col className='gutter-row' span={4}>
      {element3}
    </Col>
  </Row>
);

const doSwitchRow = (title, name) =>
  doRow(
    title,
    <Field name={`${name}|alemHealth`} component={InputSwitchField} />,
    <Field name={`${name}|push`} component={InputSwitchField} />,
    <Field name={`${name}|email`} component={InputSwitchField} />
  );

export default connect(state => ({
  initialValues: state.initialForm.settingsNotifications
}))(
  reduxForm({
    form: SETTINGS_NOTIFICATIONS_FORM
  })(({ pristine, submitting, loading, handleSubmit, reset }) => {
    return (
      <Fragment>
        <div style={{ margin: '0 0 76px 0' }}>
          <h3>Set notifications you wish to receive</h3>
          {doRow(
            <b>Type</b>,
            <b>AlemHealth</b>,
            <b>Push Notifications</b>,
            <b>Email</b>
          )}
          {doSwitchRow('New Study', 'newStudy')}
          {doSwitchRow('Urgent Study', 'urgentStudy')}
          {doSwitchRow('SLA Nearing', 'SLANearing')}
          {doSwitchRow('Report Finalised', 'reportFinalised')}
          {doSwitchRow('2nd Opinion Requested', 'secondOpinion')}
          {doSwitchRow('Code Blue: Critical Finding', 'codeBlue')}
        </div>
        <Buttons
          pristine={pristine}
          submitting={submitting}
          loading={loading}
          handleCancel={reset}
          handleSave={handleSubmit}
        />
      </Fragment>
    );
  })
);
