import React from 'react';
import { Cascader, Form } from 'antd';

const FormItem = Form.Item;

const MultiSelectField = ({
  componentProps,
  fieldProps,
  input,
  meta,
  showReadOnlyMode,
  change,
  onChange,
  hasFeedback,
  label,
  ...props
}) => {
  const {
    disabled,
    displayRender,
    expandTrigger,
    options,
    onChange: onChangeValue,
    placeholder,
    searchFilter
  } = componentProps;
  props.onBlur = () => { };

  const filter = (inputValue, path) => {
    return path.some(
      option =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  };

  const render = (inputValue, path) => {
    return (
      <>
        {path.map((item, index) => {
          const startingIndex = item.label
            .toLowerCase()
            .indexOf(inputValue.toLowerCase());

          let modifiedLabel = item.label;
          if (startingIndex > -1) {
            const endingIndex = startingIndex + inputValue.length;
            const searchedString = item.label.slice(startingIndex, endingIndex);
            modifiedLabel = (
              <>
                {item.label.slice(0, startingIndex)}
                <span style={{ color: '#15a9e1' }}>{searchedString}</span>
                {item.label.slice(endingIndex)}
              </>
            );
          }
          return (
            <span>
              {index === 0 ? (
                modifiedLabel
              ) : (
                <>
                  <span> / </span>
                  {modifiedLabel}
                </>
              )}
            </span>
          );
        })}
      </>
    );
  };

  const hasError = meta.touched && meta.invalid;

  return (
    <FormItem
      label={label}
      validateStatus={hasError ? 'error' : 'success'}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
    >
      {searchFilter ? (
        <Cascader
          {...input}
          popupClassName='source-facility-cascader-pop-up'
          showSearch={{ filter, render }}
          disabled={disabled}
          displayRender={displayRender}
          onChange={onChange || onChangeValue}
          expandTrigger={expandTrigger}
          options={options}
          placeholder={placeholder}
          notFoundContent={
            <div style={{ textAlign: 'center' }}>
              <div className='facility-not-found'>
                We couldn’t find a matching facility.
              </div>
            </div>
          }
          {...props}
        />
      ) : (
        <Cascader
          {...input}
          disabled={disabled}
          displayRender={displayRender}
          expandTrigger={expandTrigger}
          options={options}
          placeholder={placeholder}
          {...props}
        />
      )}
    </FormItem>
  );
};

export default MultiSelectField;
