// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  editDeviceAction,
  getInternalFacilitiesAction
} from '../../../store/actions';

import type { ReduxState, Device } from '../../../schemas';
import EditDeviceForm from './forms/EditDeviceForm';
import { confirmDeletion } from '../../../components/confirmations';

type Props = {
  loading: boolean,
  modifications: number,
  deleteAction: any => void,
  editAction: Device => void,
  onCancel: () => void,
  device: Device,
  visible: boolean,
  loadFacilities: () => void
};

type State = {
  visible: boolean,
  modifications: number,
  close: boolean
};

export default connect(
  (state: ReduxState) => ({
    loading: state.loading.editDevice,
    modifications: state.devices.modifications,
    affiliatedGroup: state.profile.appMetadata.affiliatedGroup
  }),
  {
    editAction: editDeviceAction,
    loadFacilities: getInternalFacilitiesAction
  }
)(
  class EditDevice extends Component<Props, State> {
    state = {
      visible: false,
      modifications: this.props.modifications,
      close: false
    };

    // We check if the number of created users is incremented (i.e. if the user creation has succeed,
    // and use this information to update the state.
    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.modifications > prevState.modifications) {
        nextProps.onCancel(false);
        return { modifications: nextProps.modifications, visible: false };
      }
      return null;
    }

    componentDidMount(): * {
      const { loadFacilities } = this.props;
      loadFacilities();
    }

    showModal = () => {
      //   this.setState({ visible: true });
    };

    handleCancel = () => {
      this.props.onCancel();
    };

    handleEdit = async values => {
      const { editAction } = this.props;
      editAction(values);
    };

    render() {
      const { loading, visible, device, deleteAction } = this.props;
      const {
        facility,
        nickname,
        id,
        connectionType,
        manufacturer,
        serialNumber,
        inFacilityLocation,
        dicomAeTitle,
        modality,
        ipAddress,
        dicomPort,
        model
      } = device;
      return (
        <div>
          {visible ? (
            <EditDeviceForm
              visible={visible}
              loading={loading}
              initialValues={{
                facility,
                nickname,
                id,
                connectionType,
                manufacturer: manufacturer || 'AlemHealth',
                modality,
                serialNumber,
                inFacilityLocation,
                dicomAeTitle,
                ipAddress,
                dicomPort,
                model
              }}
              deleteAction={() =>
                confirmDeletion(deleteAction, id, nickname, `device`)
              }
              onCancel={this.handleCancel}
              onSubmit={this.handleEdit}
            />
          ) : null}
        </div>
      );
    }
  }
);
