import React from 'react';
import { Row, Col, Button, Tabs, Menu, Dropdown, Icon } from 'antd';
import { t } from 'i18next';
import AddInternalFacility from './components/Edition/AddInternalFacility';
import AddExternalFacility from './components/Edition/AddExternalFacility';

import './index.less';
import TableInternalFacilities from './components/Table/TableInternalFacilities';
import TableExternalFacilities from './components/Table/TableExternalFacilities';
import SourceFacilitiesChart from './components/Chart/SourceFacilitiesChart';

const { TabPane } = Tabs;

export default () => {
  const menu = (
    <Menu>
      <Menu.Item key='1'>
        <AddInternalFacility />
      </Menu.Item>
      <Menu.Item key='2'>
        <AddExternalFacility />
      </Menu.Item>
    </Menu>
  );
  const addButton = (
    <div className='facilities-header'>
      <Row type='flex' justify='end' align='middle'>
        <Col /* className='page-cta-wrapper' */>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button type='primary'>
              {t('manage_radiologists.add_new')} <Icon type='down' />
            </Button>
          </Dropdown>
        </Col>
      </Row>
    </div>
  );

  return (
    <div className='facilities-index'>
      <SourceFacilitiesChart />
      <Row className='card-container  table-tabs'>
        <Tabs type='card' tabBarExtraContent={addButton}>
          <TabPane tab={t('my_facilities.internal')} key='1'>
            <TableInternalFacilities />
          </TabPane>
          <TabPane tab={t('my_facilities.external')} key='2'>
            <TableExternalFacilities />
          </TabPane>
        </Tabs>
      </Row>
    </div>
  );
};
