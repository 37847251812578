import { Col, Row } from "antd";
import React from "react";
import { Field, reduxForm } from "redux-form";
import CustomInputCheckboxGroupField from "../../../../components/FormParts/CustomDropdownCheckboxGroupField";
import { urgencyList } from "../../../../store/constants/appConstants";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getBodyPartOptionsSuccessAction } from "../../../../store/actions";
import { startCase } from "lodash";

const getMostUsedModality = (list) => {
    return list.filter(item => {
        if (item.isMostUsed) return true
    })
}

const getGeneralModality = (list) => {
    return list.filter(item => {
        if (!item.isMostUsed) return true
    })
}

const StudyDetailForm = ({ 
    newBodyPartsOptions, 
    getBodyPartOptionsSuccessAction,
    newModalityOptions,
}) => {

    const modalityList = {
        mostUsed: {
            title: 'Most Used',
            list: []
        },
        general: {
            title: 'All Modalities',
            list: []
        }
    }

    const mostUsedMod = getMostUsedModality(newModalityOptions)
    const generalMod = getGeneralModality(newModalityOptions)

    modalityList.mostUsed.list = mostUsedMod
    modalityList.general.list = generalMod

    const setAdditionalOptionHandler = (value) => getBodyPartOptionsSuccessAction([...newBodyPartsOptions, { label: startCase(value), value }])

    return (
        <form>
            <Row type="flex" gutter={[16, 16]}>
                <Col span={8}>
                    <Field name='urgency' label='Urgency' dropDownText='Select Urgency' hideSearch optionList={urgencyList} component={CustomInputCheckboxGroupField} />
                </Col>
                <Col span={8}>
                    <Field name='modality' label='Modality' dropDownText='Select Modality' overlayClassName="custom-dropdown-modality" treeOptionList={modalityList} component={CustomInputCheckboxGroupField} />
                </Col>
                <Col span={8}>
                    <Field name='body_parts' label='Body Part' dropDownText='Select Body Part' overlayClassName="custom-dropdown-body-part" optionList={newBodyPartsOptions} component={CustomInputCheckboxGroupField} setAdditionalOption={setAdditionalOptionHandler} allowAddOption />
                </Col>
            </Row>
        </form>
    )
};

const mapStateToProps = state => ({
    newBodyPartsOptions: state.route.bodyPartOptions,
    newModalityOptions: state.route.modalityOptions
})

const mapDispatchToProps = dispatch => bindActionCreators({ getBodyPartOptionsSuccessAction }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "StudyDetailForm",
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
})(StudyDetailForm));
