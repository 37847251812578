// @flow

import * as React from 'react';
import { Col, Form, Modal, Row } from 'antd';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { ReduxState } from '../../../../schemas';
import {
  InputTextField,
  InputTextAreaField
} from '../../../../components/FormParts';

import '../../index.less';
import { ONBOARDING_FORM } from '../../../../utils/form-helpers';
import colleagueValidation from '../forms/colleagueValidation';

type Props = {
  visible: Boolean,
  setVisible: Function,
  handleSubmit: any => void,
  pristine: Boolean,
  submitting: Boolean
};

const InviteColleaguesModal = ({
  visible,
  setVisible,
  handleSubmit,
  pristine,
  submitting
}: Props) => {
  const handleCancel = e => {
    setVisible(false);
  };

  return (
    <>
      <div>
        <Modal
          title='Invite Colleague'
          visible={visible}
          onOk={handleSubmit}
          onCancel={handleCancel}
          okText='Invite'
        >
          <Form layout='vertical'>
            <Row gutter={32}>
              <Col className='gutter-row' span={12}>
                <Field
                  name='colleague.name'
                  label='Name'
                  placeholder='Name'
                  component={InputTextField}
                />
              </Col>
              <Col className='gutter-row' span={12}>
                <Field
                  name='colleague.email'
                  label='Email'
                  type='email'
                  placeholder='Email'
                  component={InputTextField}
                />
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={24}>
                <Field
                  name='colleague.message'
                  label='Message'
                  placeholder='message'
                  component={InputTextAreaField}
                />
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: ONBOARDING_FORM,
    validate: colleagueValidation,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false
  })(InviteColleaguesModal)
);
