import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, change } from 'redux-form';
import { Button, Col, Row } from 'antd';

import { bindActionCreators } from 'redux';
import validation from './validation';
import PhysicianForm from './PhysicianForm';
// import BaseIntRadForm from '../radiologist/internal/forms/BaseIntRadForm';

const ADD_PHYSICIAN_FORM_NAME = 'addPhysicianForm';

type Props = {
  visible: boolean,
  onCancel: () => void,
  loading: boolean,
  // Values passed by reduxForm
  handleSubmit: any => void,
  pristine: boolean,
  submitting: boolean
};

export default connect(
  state => ({
    initialValues: state.initialForm.addPhysician,
    formData: getFormValues(ADD_PHYSICIAN_FORM_NAME)(state)
  }),
  dispatch =>
    bindActionCreators(
      {
        change
      },
      dispatch
    )
)(
  reduxForm({
    form: ADD_PHYSICIAN_FORM_NAME,
    validate: validation
  })(
    ({
      visible,
      onCancel,
      loading,
      change: changeForm,
      handleSubmit,
      pristine,
      formData,
      isInternal,
      submitting
    }: Props) => (
      <PhysicianForm
        title='Add Referring Physician'
        okText='Add'
        visible={visible}
        change={changeForm}
        data={formData}
        isInternal={isInternal}
        onCancel={onCancel}
        loading={loading}
      >
        <Row type='flex' justify='space-around' align='middle'>
          <Col span={12} offset={12} style={{ textAlign: 'right' }}>
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              disabled={pristine || submitting}
              type='primary'
              onClick={handleSubmit}
              loading={loading}
            >
              Add
            </Button>
          </Col>
        </Row>
      </PhysicianForm>
    )
  )
);
