/* eslint-disable no-return-await */
// @flow

import { config } from '../../utils';
import { checkStatus } from '../auth/service';

const ENDPOINT = '/dbconnections/change_password';

export default async (email: string): Promise<Object> => {
  const {
    auth0: { domain, clientId, connection }
  } = config;
  return await fetch(`https://${domain}${ENDPOINT}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ clientId, connection, email })
  })
    .then(checkStatus)
    .then(response => response.status === 200);
};
