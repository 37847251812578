import moment from 'moment';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/referringPhysicianActions';
import {
  GET_SOURCE_REFERRING_PHYSICIANS_CHART_LOADING,
  // ADD_REFERRING_PHYSICIAN_LOADING,
  // DELETE_REFERRING_PHYSICIAN_LOADING,
  // EDIT_REFERRING_PHYSICIAN_LOADING,
  GET_REFERRING_PHYSICIANS_LOADING,
  GET_REFERRING_PHYSICIAN_LOADING,
  GET_REFERRING_PHYSICIANS_LIST_LOADING
} from '../actions/loadingActions';

import { getFormValues, showError } from '../helpers';
import {
  getReferringPhysicianById,
  getReferringPhysicians,
  getSourceReferringPhysiciansChart,
  getSourceReferringPhysiciansTable
} from '../../api/endpoints/referring-physicians';
// import { facilityToJson } from '../../api/transformers';
import {
  getCurrentUser
  // getAffiliatedGroup,
  // getPhysicianLetterhead,
  // getPhysicianLogo
} from '../selectors';
import {
  SOURCE_REFERRING_PHYSICIANS_CHART_FORM_NAME,
  SOURCE_REFERRING_PHYSICIANS_FORM_NAME
} from '../../utils/form-helpers';
import { dateFilterStrings } from '../constants';
import processChartData from '../../utils/physician-converter';
import {
  GET_EXTERNAL_PHYSICIANS_SUCCESS,
  GET_PHYSICIANS_SUCCESS
} from '../actions';
import { t } from 'i18next';

function* getReferringPhysiciansCaller() {
  const profile = yield select(getCurrentUser);
  const filters = yield select(
    getFormValues,
    SOURCE_REFERRING_PHYSICIANS_FORM_NAME
  );
  const referringPhysicians = yield call(
    getReferringPhysicians,
    profile,
    filters
  );
  yield put({
    type: actions.GET_REFERRING_PHYSICIANS_SUCCESS,
    referringPhysicians
  });
}

function* getReferralsByPhysiciansListSaga(action) {
  yield put({ type: GET_REFERRING_PHYSICIANS_LIST_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    const referringPhysicians = yield call(
      getReferringPhysicians,
      profile,
      { selectedDate: [moment('2000-01-01'), moment()] },
      dateFilterStrings.CUSTOM
    );
    yield put({
      type: actions.GET_REFERRING_PHYSICIANS_LIST_SUCCESS,
      referringPhysicians
    });
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.GET_REFERRING_PHYSICIANS_LIST_FAILED, error });
    yield call(showError, { message: t('errors.failed_loading_physicians') });
  } finally {
    yield put({ type: GET_REFERRING_PHYSICIANS_LIST_LOADING, loading: false });
  }
}

function* getReferringPhysicianChart() {
  const profile = yield select(getCurrentUser);
  const filters = yield select(
    getFormValues,
    SOURCE_REFERRING_PHYSICIANS_CHART_FORM_NAME
  );

  const chartData = yield call(
    getSourceReferringPhysiciansChart,
    profile,
    filters
  );

  const tableData = yield call(
    getSourceReferringPhysiciansTable,
    profile,
    filters
  );

  const state = yield select();

  const updatedChart = yield call(
    processChartData,
    chartData,
    'referrerName',
    state
  );
  const updatedTable = yield call(
    processChartData,
    tableData,
    'referrer',
    state
  );

  const chart = { chartData: updatedChart, tableData: updatedTable };

  yield put({
    type: actions.GET_SOURCE_REFERRING_PHYSICIANS_CHART_SUCCESS,
    chart
  });
}

function* getReferralsByPhysiciansSaga(action) {
  yield put({ type: GET_REFERRING_PHYSICIANS_LOADING, loading: true });
  try {
    // yield take(GET_PHYSICIANS_SUCCESS);
    // yield take(GET_EXTERNAL_PHYSICIANS_SUCCESS);
    yield call(getReferringPhysiciansCaller);
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.GET_REFERRING_PHYSICIANS_FAILED, error });
    yield call(showError, { message: t('errors.failed_loading_physicians') });
  } finally {
    yield put({ type: GET_REFERRING_PHYSICIANS_LOADING, loading: false });
  }
}

function* getReferringPhysicianByIdSaga(action) {
  yield put({ type: GET_REFERRING_PHYSICIAN_LOADING, loading: true });
  try {
    const { facilityId } = action;
    const profile = yield select(getCurrentUser);
    const facility = yield call(getReferringPhysicianById, profile, facilityId);
    yield put({
      type: actions.GET_REFERRING_PHYSICIAN_BY_ID_SUCCESS,
      facility
    });
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.GET_REFERRING_PHYSICIAN_BY_ID_SUCCESS, error });
    yield call(showError, { message: t('errors.failed_loading_physicians') });
  } finally {
    yield put({ type: GET_REFERRING_PHYSICIAN_LOADING, loading: false });
  }
}

// function* createReferringPhysicianSaga(action) {}

// function* updateReferringPhysicianSaga(action) {}

function* getSourceReferringPhysiciansChartSaga(action) {
  yield put({
    type: GET_SOURCE_REFERRING_PHYSICIANS_CHART_LOADING,
    loading: true
  });
  try {
    // yield take(GET_PHYSICIANS_SUCCESS);
    yield take(GET_EXTERNAL_PHYSICIANS_SUCCESS);
    yield call(getReferringPhysicianChart);
  } catch (error) {
    console.error(error.message);
    yield put({
      type: actions.GET_SOURCE_REFERRING_PHYSICIANS_CHART_FAILED,
      error
    });
    yield call(showError, { message: t('errors.failed_loading_physicians_chart') });
  } finally {
    yield put({
      type: GET_SOURCE_REFERRING_PHYSICIANS_CHART_LOADING,
      loading: false
    });
  }
}

function* filterReferringPhysiciansChartSaga(action) {
  yield put({
    type: GET_SOURCE_REFERRING_PHYSICIANS_CHART_LOADING,
    loading: true
  });
  try {
    yield call(getReferringPhysicianChart);
  } catch (error) {
  } finally {
    yield put({
      type: GET_SOURCE_REFERRING_PHYSICIANS_CHART_LOADING,
      loading: false
    });
  }
}

function* filterReferringPhysiciansSaga(action) {
  yield put({
    type: GET_REFERRING_PHYSICIANS_LOADING,
    loading: true
  });
  try {
    yield call(getReferringPhysiciansCaller);
  } catch (error) {
  } finally {
    yield put({
      type: GET_REFERRING_PHYSICIANS_LOADING,
      loading: false
    });
  }
}

// function* deleteReferringPhysicianSaga(action) {}

export default function* referringPhysicianSaga() {
  yield takeLatest(
    actions.GET_REFERRING_PHYSICIANS_LIST_REQUEST,
    getReferralsByPhysiciansListSaga
  );
  yield takeLatest(
    actions.GET_SOURCE_REFERRALS_BY_PHYSICIANS_REQUEST,
    getReferralsByPhysiciansSaga
  );
  yield takeLatest(
    actions.GET_REFERRING_PHYSICIAN_BY_ID_REQUEST,
    getReferringPhysicianByIdSaga
  );
  // yield takeLatest(actions.ADD_REFERRING_FACILITY_REQUEST, createReferringPhysicianSaga);
  // yield takeLatest(actions.EDIT_REFERRING_FACILITY_REQUEST, updateReferringPhysicianSaga);
  // yield takeLatest(actions.DELETE_REFERRING_FACILITY_REQUEST, deleteReferringPhysicianSaga);
  yield takeLatest(
    actions.GET_SOURCE_REFERRING_PHYSICIANS_CHART_REQUEST,
    getSourceReferringPhysiciansChartSaga
  );
  yield takeLatest(
    actions.FILTER_REFERRING_PHYSICIANS_CHART_REQUEST,
    filterReferringPhysiciansChartSaga
  );
  yield takeLatest(
    actions.FILTER_REFERRING_PHYSICIANS_REQUEST,
    filterReferringPhysiciansSaga
  );
}
