const processChartData = (data, key, state) => {
  if (!data) return [];
  if (!state.physicians || !state.externalPhysicians.array) return data;

  return data.map(item => {
    const phys = state.physicians.array.find(i => i.guid === item[key]);
    if (phys) {
      item[key] = phys.name;
    } else {
      const extPhys = state.externalPhysicians.array.find(
        i => i.guid === item[key]
      );
      if (extPhys) {
        item[key] = extPhys.name;
      }
    }
    return item;
  });
};

export default processChartData;
