export const GET_STRIPE_DATA_REQUEST = 'GET_STRIPE_DATA_REQUEST';
export const GET_STRIPE_DATA_SUCCESS = 'GET_STRIPE_DATA_SUCCESS';
export const GET_STRIPE_DATA_FAILURE = 'GET_STRIPE_DATA_FAILURE';

export const GET_STRIPE_INVOICES_REQUEST = 'GET_STRIPE_INVOICES_REQUEST';
export const GET_STRIPE_INVOICES_SUCCESS = 'GET_STRIPE_INVOICES_SUCCESS';
export const GET_STRIPE_INVOICES_FAILURE = 'GET_STRIPE_INVOICES_FAILURE';

export const RESTORE_STRIPE_INVOICES = 'RESTORE_STRIPE_INVOICES';

export const STRIPE_CHECK_UNSUSCRIBE_REQUEST =
  'STRIPE_CHECK_UNSUSCRIBE_REQUEST';
export const STRIPE_CHECK_UNSUSCRIBE_SUCCESS =
  'STRIPE_CHECK_UNSUSCRIBE_SUCCESS';
export const STRIPE_CHECK_UNSUSCRIBE_FAILURE =
  'STRIPE_CHECK_UNSUSCRIBE_FAILURE';

export const getStripeDataAction = () => ({ type: GET_STRIPE_DATA_REQUEST });
export const getStripeInvoicesAction = (
  limit,
  first,
  last
) => ({ type: GET_STRIPE_INVOICES_REQUEST, limit, first, last });
export const restoreStripeInvoices = () => ({ type: RESTORE_STRIPE_INVOICES });
export const checkStripeUnsuscribeAction = () => ({
  type: STRIPE_CHECK_UNSUSCRIBE_REQUEST
});

export const GET_STRIPE_SESSION_REQUEST = 'GET_STRIPE_SESSION_REQUEST';
export const GET_STRIPE_SESSION_SUCCESS = 'GET_STRIPE_SESSION_SUCCESS';
export const GET_STRIPE_SESSION_FAILURE = 'GET_STRIPE_SESSION_FAILURE';

export const getStripeSessionAction = () => ({ type: GET_STRIPE_SESSION_REQUEST });
