import { Col, Row } from 'antd'
import React, { useEffect } from 'react'
import QCMetricsSummary from './QCMetricsSummary'
import QCModalityAndBodyPartInsight from './QCModalityAndBodyPartInsight'
import RadiologistPerformance from './RadiologistPerformance'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as QCDashboardActions from '../../store/actions/QCDashboardAction'
import { getUsersAction } from '../../store/actions/usersActions';

const QCDashboard = ({ getQCMetricsSummaryRequest, getQCModalityBodyPartInsightRequest, getQCRadiologistPerformanceRequest, getUsersAction }) => {

  useEffect(() => {
    getQCMetricsSummaryRequest()
    getQCModalityBodyPartInsightRequest()
    getQCRadiologistPerformanceRequest()
    getUsersAction(-1, -1, '', '');
  }, [])

  return (
    <Row gutter={[32, 32]}>
      <Col>
        <QCMetricsSummary />
      </Col>
      <Col>
        <QCModalityAndBodyPartInsight />
      </Col>
      <Col>
        <RadiologistPerformance />
      </Col>
    </Row>
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...QCDashboardActions, getUsersAction }, dispatch)

export default connect(null, mapDispatchToProps)(QCDashboard)