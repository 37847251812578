import { HANDLE_COLLAPSE_CHANGE } from '../actions';
import initialState from '../state';

export default (state = initialState, action) => {
  if (action.type === HANDLE_COLLAPSE_CHANGE) {
    return {
      collapsed: action.payload
    };
  }
  return state;
};
