import React, { useState } from 'react';
import moment from 'moment';
import { Form, DatePicker, Divider, Select } from 'antd';

const { MonthPicker } = DatePicker
const { Option } =  Select

const MonthPickerField = ({
  labelStyle = {},
  label = '',
  colon = false,
  input,
  meta: { touched, error },
  format = 'DD/MM/YYYY',
  dayFilterOptions,
  placeholder,
  ...restProps
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Form.Item
      style={labelStyle}
      colon={colon}
      label={label}
      validateStatus='' // 'success' 'warning' 'error' 'validating'
      help={touched && error ? error : ''}
    >
      <Select
        {...input}
        placeholder={placeholder}
        style={{ width: '100%' }}
        dropdownRender={menu => (
          <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <div
              style={{ padding: '4px 8px', cursor: 'pointer' }}
              onMouseDown={e => e.preventDefault()}
              onClick={() => setIsOpen(true)}
            >
              Select month
            </div>
          </div>
        )}
      >
        {dayFilterOptions.map(data => (
          <Option key={data.value}>{data.label}</Option>
        ))}
      </Select>
      <MonthPicker
        open={isOpen}
        style={{ position: 'absolute', opacity: 0, width: 0 }}
        getPopupContainer={(trigger) => trigger.parentNode}
        onChange={(date, dateString) => {
          input.onChange(dateString)
        }}
        onOpenChange={() => setIsOpen(false)}
      />
    </Form.Item>
  )
};

export default MonthPickerField;
