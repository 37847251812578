import React from 'react';
// import PropTypes from 'prop-types'
import Box from 'ui-box';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { t } from 'i18next';
import Header from '../partials/Header';
import CodeBlock from './CodeBlock';
import HostedUploader from './HostedUploader';
import { getAffiliatedGroup } from '../../../store/selectors';

function UploaderSettings(props) {
  // console.log('UploaderSettings props', props)
  const { groupId } = props;
  const headerProps = {
    title: 'Embedded Uploader Settings',
    text: t('uploader_settings.we_different_uploaders_for_you_your_website')
  };

  return (
    <div>
      <Header {...headerProps} />
      <Box paddingTop={10} paddingBottom={24}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <CodeBlock groupId={groupId} />
          </Col>
          <Col span={24}>
            <HostedUploader groupId={groupId} />
          </Col>
        </Row>
      </Box>
    </div>
  );
}

const mapStateToProps = state => {
  const groupId = getAffiliatedGroup(state);
  return {
    groupId
  };
};

export default connect(mapStateToProps)(UploaderSettings);
