import initialState from '../state';
import * as actions from '../actions/physiciansActions'

import type {PhysiciansState} from '../../schemas';

export default (state: PhysiciansState = initialState, action): PhysiciansState => {
  switch (action.type) {
    case actions.GET_PHYSICIANS_SUCCESS:
      return {...state, array: action.physicians };
    case actions.GET_PHYSICIAN_BY_ID_SUCCESS:
      return { ...state, current: action.physician };
    case actions.ADD_PHYSICIAN_SUCCESS:
    case actions.EDIT_PHYSICIAN_SUCCESS:
    case actions.DELETE_PHYSICIAN_SUCCESS:
      return {...state, modifications: state.modifications + 1 };
    default:
      return state;
  }
}
