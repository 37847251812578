import React from 'react';
import moment from 'moment';
import { Form, DatePicker } from 'antd';

const DatePickerField = ({
  labelStyle = {},
  label = '',
  colon = false,
  fullWidth = false,
  width = '',
  input: { value, onChange, onBlur },
  meta: { touched, error },
  format = 'DD/MM/YYYY',
  ...restProps
}) => (
  <Form.Item
    style={labelStyle}
    colon={colon}
    label={label}
    validateStatus='' // 'success' 'warning' 'error' 'validating'
    help={touched && error ? error : ''}
  >
    <DatePicker
      style={{ width: fullWidth ? '100%' : width }}
      value={value ? moment(value, format) : null}
      format={format}
      onBlur={onBlur}
      onChange={(date, dateString) => {
        onChange(dateString);
      }}
      {...restProps}
    />
  </Form.Item>
);

export default DatePickerField;
