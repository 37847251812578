/* eslint-disable */
import initialState from '../state';
import * as actions from '../actions/referringPhysicianActions';

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_REFERRING_PHYSICIANS_SUCCESS:
      return {
        ...state,
        data: action.referringPhysicians
      };
    case actions.GET_REFERRING_PHYSICIANS_LIST_SUCCESS:
      return {
        ...state,
        list: action.referringPhysicians
      };
    case actions.GET_SOURCE_REFERRING_PHYSICIANS_CHART_SUCCESS:
      return {
        ...state,
        chart: action.chart
      };
    case actions.GET_REFERRING_PHYSICIAN_BY_ID_SUCCESS:
      return {
        ...state,
        current: action.referringPhysicians
      };
    case actions.ADD_REFERRING_PHYSICIAN_SUCCESS:
    case actions.EDIT_REFERRING_PHYSICIAN_SUCCESS:
      return {
        ...state,
        modifications: (state.modifications += 1)
      };
    case actions.GET_REFERRING_PHYSICIANS_LIST_FAILED:
    case actions.GET_REFERRING_PHYSICIANS_FAILED:
    case actions.GET_REFERRING_PHYSICIAN_BY_ID_FAILED:
    case actions.ADD_REFERRING_PHYSICIAN_FAILED:
    case actions.EDIT_REFERRING_PHYSICIAN_FAILED:
      return { ...state, errors: state.errors.concat(action.error) };
    default:
      return state;
  }
};
