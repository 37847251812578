import { Col, Form, Radio, Row, Select } from 'antd';
import { t } from 'i18next';
import React from 'react'
import { Field, reduxForm } from 'redux-form';
import InputSelectField from '../../../common/form-helpers/InputSelectField';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { filterQCDashboard } from '../../../store/actions/QCDashboardAction';
import { dayFilterOptions, bodyPartFilterOptions, urgencyFilterOptions } from '../../../store/constants';
import MonthPickerField from '../../../common/form-helpers/MonthPickerField';
import RadioGroupField from '../../../common/form-helpers/RadioGroupField';
import { DASHBOARD_MODALITY_BODYPART_INSIGHT_FORM } from '../../../utils/form-helpers';

const ModalityBodyPartInsightForm = ({ bodyPartOptions }) => {

  const typeRadioOptions = [
    {
      value: 'modality',
      label: 'Modality'
    },
    {
      value: 'body_part',
      label: 'Body Part'
    },
  ]

  return (
    <Form>
      <Row type='flex' gutter={[16, 12]}>
        <Col xs={24} sm={12} md={8} lg={6} xxl={4} style={{ margin: 'auto 0' }}>
          <Field
            name='type'
            labelStyle={{ margin: 0 }}
            width='100%'
            component={RadioGroupField}
            options={typeRadioOptions}
            defaultValue='modality'
            radioGroupStyle={{ display: 'flex', textAlign: 'center' }}
            buttonStyle="solid" 
            radioBtnStyle={{ width: '100%' }}
            style={{ width: '100%', display: 'flex', textAlign: 'center' }}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
          <Field
            name='selectedDate'
            placeholder={t('dashboard.select_duration')}
            labelStyle={{ margin: 0 }}
            width='100%'
            component={MonthPickerField}
            dayFilterOptions={dayFilterOptions}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
          <Field
            name='bodyPart'
            placeholder={t('dashboard.select_body_part')}
            options={bodyPartOptions}
            labelStyle={{ margin: 0 }}
            width='100%'
            component={InputSelectField}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
          <Field
            name='urgency'
            placeholder={t('dashboard.select_urgency')}
            options={urgencyFilterOptions}
            labelStyle={{ margin: 0 }}
            width='100%'
            component={InputSelectField}
          />
        </Col>
      </Row>
    </Form>
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ filterQCDashboard }, dispatch)

export default connect(null, mapDispatchToProps)(
  reduxForm({
    form: DASHBOARD_MODALITY_BODYPART_INSIGHT_FORM,
    initialValues: {
      type: 'body_part',
      selectedDate: 'last_30_days'
    },
  })(ModalityBodyPartInsightForm)
)