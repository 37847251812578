import React from 'react';
import { Spin } from 'antd';

const style = {
  full: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    top: '50vh',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: '1011'
  },
  small: {
    display: 'flex',
    justifyContent: 'center',
    position: 'center',
    width: '64px',
    margin: '0 auto',
    zIndex: '1011'
  },
  tiny: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    height: '25px',
    width: '25px',
    top: '12px',
    bottom: 0,
    right: '8px',
    zIndex: '1011'
  }
};

const LoadingSpinner = ({ size }) => (
  <div style={style[size]}>
    <Spin size='large' />
  </div>
);

LoadingSpinner.defaultProps = {
  size: 'full'
};

export default LoadingSpinner;
