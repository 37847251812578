/* eslint-disable */
import initialState from '../state';
import * as actions from '../actions/facilityActions';

import type { FacilitiesState } from '../../schemas';

export default (
  state: FacilitiesState = initialState,
  action
): FacilitiesState => {
  switch (action.type) {
    case actions.ADD_FACILITY_CUSTOM_LETTERHEAD:
      return {
        ...state,
        letterhead: action.letterhead
      };
    case actions.UPLOAD_FACILITY_CUSTOM_LETTERHEAD:
      return {
        ...state,
        letterhead: ''
      };
    case actions.GET_INTERNAL_FACILITIES_SUCCESS:
      return {
        ...state,
        internalArray: action.facilities
      };
    case actions.GET_EXTERNAL_FACILITIES_SUCCESS:
      return {
        ...state,
        externalArray: action.facilities
      };
    case actions.GET_SOURCE_FACILITIES_CHART_SUCCESS:
      return {
        ...state,
        chart: action.chart
      };
    case actions.GET_FACILITY_BY_ID_SUCCESS:
      return {
        ...state,
        current: action.facility,
        logo: action.facility.facilityLogo
      };
    case actions.ADD_FACILITY_SUCCESS:
    case actions.EDIT_FACILITY_SUCCESS:
      return {
        ...state,
        modifications: (state.modifications += 1)
      };
    case actions.ADD_NEWLY_CREATED_FACILITY:
      return {
        ...state,
        newlyCreated: action.facility
      }
    case actions.RESET_NEWLY_CREATED_FACILITY:
      return {
        ...state,
        newlyCreated: {}
      }
    case actions.GET_INTERNAL_FACILITIES_FAILED:
    case actions.GET_EXTERNAL_FACILITIES_FAILED:
    case actions.GET_FACILITY_BY_ID_FAILED:
    case actions.ADD_FACILITY_FAILED:
    case actions.EDIT_FACILITY_FAILED:
      return { ...state, errors: state.errors.concat(action.error) };
    case actions.ADD_FACILITY_LOGO:
      const previousLogo = state.logo;
      return {
        ...state,
        previousLogo,
        logo: action.logo
      };
    case actions.UPLOAD_FACILITY_LOGO:
      return {
        ...state,
        logo: '',
        previousLogo: ''
      };
    case actions.REMOVE_FACILITY_LOGO:
      const logo = state.previousLogo ? state.previousLogo : '';
      return {
        ...state,
        previousLogo: '',
        logo
      };
    default:
      return state;
  }
};
