/* eslint-disable */
import initialState from '../state';
import * as actions from '../actions/referringFacilityActions';

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_REFERRING_FACILITIES_SUCCESS:
      return {
        ...state,
        data: action.referringFacilities
      };
    case actions.GET_REFERRING_FACILITIES_LIST_SUCCESS:
      return {
        ...state,
        list: action.referringFacilities
      };
    case actions.GET_SOURCE_REFERRING_FACILITIES_CHART_SUCCESS:
      return {
        ...state,
        chart: action.chart
      };
    case actions.GET_REFERRING_FACILITY_BY_ID_SUCCESS:
      return {
        ...state,
        current: action.referringFacilities
      };
    case actions.ADD_REFERRING_FACILITY_SUCCESS:
    case actions.EDIT_REFERRING_FACILITY_SUCCESS:
      return {
        ...state,
        modifications: (state.modifications += 1)
      };
    case actions.GET_REFERRING_FACILITIES_LIST_FAILED:
    case actions.GET_REFERRING_FACILITIES_FAILED:
    case actions.GET_REFERRING_FACILITY_BY_ID_FAILED:
    case actions.ADD_REFERRING_FACILITY_FAILED:
    case actions.EDIT_REFERRING_FACILITY_FAILED:
      return { ...state, errors: state.errors.concat(action.error) };
    default:
      return state;
  }
};
