import * as React from 'react';

import { connect } from 'react-redux';
import {
  addExternalFacilityAction,
  loadAddExternalFacilityForm
} from '../../../../../../store/actions';

import AddExternalFacilityForm from '../../../../../facilities/components/Edition/Form/AddExternalFacilityForm';

export default connect(
  state => ({
    form: state.form.addExternalFacilityForm,
    loading: state.loading.addFacility,
    modifications: state.facilities.modifications,
    rawCountries: state.countryList
  }),
  {
    createAction: addExternalFacilityAction,
    loadForm: loadAddExternalFacilityForm
  }
)(
  class AddExternalFacility extends React.PureComponent {
    state = {
      visible: false,
      modifications: this.props.modifications, // eslint-disable-line  react/destructuring-assignment
      close: false,
      countryList: []
    };

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.modifications > prevState.modifications) {
        return { modifications: nextProps.modifications, visible: false };
      }
      return null;
    }

    componentDidMount() {
      const { rawCountries, visible } = this.props;

      this.setState({
        countryList: rawCountries.map(c => ({
          text: c.name,
          value: c.alpha
        })),
        visible
      });
    }

    showModal = () => this.setState({ visible: true });

    handleCancel = () => {
      const { onClose } = this.props;

      if (onClose) onClose();
      this.setState({ visible: false });
    };

    handleCreate = async values => this.props.createAction(values);

    render() {
      const { loading } = this.props;
      const { visible, countryList } = this.state;
      return (
        <div>
          {visible ? (
            <AddExternalFacilityForm
              visible={visible}
              loading={loading}
              onCancel={this.handleCancel}
              onSubmit={this.handleCreate}
              countryList={countryList}
            />
          ) : null}
        </div>
      );
    }
  }
);
