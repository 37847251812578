import { Col, DatePicker, Divider, Form, Row, Select } from 'antd';
import { t } from 'i18next';
import React, { useState } from 'react'
import { Field, reduxForm } from 'redux-form';
import InputSelectField from '../../../common/form-helpers/InputSelectField';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { filterQCDashboard } from '../../../store/actions/QCDashboardAction';
import MonthPickerField from '../../../common/form-helpers/MonthPickerField';
import { DASHBOARD_QC_RADIOLOGIST_PERFORMANCE_FORM } from '../../../utils/form-helpers';
import CustomDropdownCheckboxGroupField from '../../../components/FormParts/CustomDropdownCheckboxGroupField';
import InputGroupSelectField from '../../../common/form-helpers/InputGroupSelectField';
import { dayFilterOptions, urgencyFilterOptions } from '../../../store/constants';

const QCRadiologistPerformanceForm = ({ modalityOptions, radiologistOptions, bodyPartOptions }) => {

  const sortOptions = [
    {
      label: t("dashboard.total_studies"),
      options: [
        {
          label: t("dashboard.highest_first"),
          value: "total_highest"
        },
        {
          label: t("dashboard.lowest_first"),
          value: "total_lowest"
        }
      ]
    },
    {
      label: t("dashboard.resolved_studies"),
      options: [
        {
          label: t("dashboard.highest_first"),
          value: "resolved_highest"
        },
        {
          label: t("dashboard.lowest_first"),
          value: "resolved_lowest"
        }
      ]
    },
    {
      label: t("dashboard.qc_flagged_studies"),
      options: [
        {
          label: t("dashboard.highest_first"),
          value: "flagged_highest"
        },
        {
          label: t("dashboard.lowest_first"),
          value: "flagged_lowest"
        }
      ]
    }
  ]

  return (
    <Form>
      <Row type='flex' gutter={[16, 12]}>
        <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
          <Field
            name='sorting'
            optionGroups={sortOptions}
            labelStyle={{ margin: 0 }}
            width='100%'
            placeholder={t('select_sort')}
            component={InputGroupSelectField}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
          <Field
            name='radiologists'
            dropDownText={t('dashboard.select_radiologist')}
            optionList={radiologistOptions}
            labelStyle={{ margin: 0 }}
            width='100%'
            component={CustomDropdownCheckboxGroupField}
            hideSearch
            hideDialog
            selectAllLabel={t('table.all_reporting_radiologists')}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
          <Field
            name='selectedDate'
            placeholder={t('dashboard.select_duration')}
            labelStyle={{ margin: 0 }}
            width='100%'
            component={MonthPickerField}
            dayFilterOptions={dayFilterOptions}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
          <Field
            name='modality'
            placeholder={t('dashboard.select_modality')}
            options={modalityOptions}
            labelStyle={{ margin: 0 }}
            width='100%'
            component={InputSelectField}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
          <Field
            name='bodyPart'
            placeholder={t('dashboard.select_body_part')}
            options={bodyPartOptions}
            labelStyle={{ margin: 0 }}
            width='100%'
            component={InputSelectField}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
          <Field
            name='urgency'
            placeholder={t('dashboard.select_urgency')}
            options={urgencyFilterOptions}
            labelStyle={{ margin: 0 }}
            width='100%'
            component={InputSelectField}
          />
        </Col>
      </Row>
    </Form>
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ filterQCDashboard }, dispatch)

export default connect(null, mapDispatchToProps)(
  reduxForm({
    form: DASHBOARD_QC_RADIOLOGIST_PERFORMANCE_FORM,
    initialValues: {
      sorting: 'total_highest',
      selectedDate: 'last_30_days'
    }
  })(QCRadiologistPerformanceForm)
)