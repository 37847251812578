import AuthService from '../index';
import Logger from '../../utils/logger';
import { jsonToPhysician, physicianToJson } from '../transformers';
import type { Physician, UserProfile } from '../../schemas';

// const GROUP_API_SERVICE = 'telerad-settings';
// const GROUP_BASE_ROUTE = '/group';
const API_SERVICE = 'physicians';

export const getPhysiciansApiCall = async (profile: UserProfile) => {
  Logger.log('Get physicians called');
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(
      API_SERVICE,
      `/api/organization/${profile.appMetadata.affiliatedGroup}/physicians/`,
      {}
    )
    .then(r => {
      return r;
    })
    .then((r: { physicians: Array<Object> }) =>
      r.physicians && r.physicians.length > 0
        ? r.physicians
            .filter(p => !(p instanceof Error))
            .map(p => jsonToPhysician(p))
        : []
    );
};

export const getExternalPhysiciansApiCall = async (profile: UserProfile) => {
  Logger.log('Get physicians called');
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(
      API_SERVICE,
      `/api/organization/${profile.appMetadata.affiliatedGroup}/external-physicians/`,
      {}
    )
    .then(r => {
      return r;
    })
    .then((r: { physicians: Array<Object> }) =>
      r.physicians && r.physicians.length > 0
        ? r.physicians
            .filter(p => !(p instanceof Error))
            .map(p => jsonToPhysician(p))
        : []
    );
};

export const getPhysicianByIdApiCall = async (
  profile: UserProfile,
  guid: string
) => {
  Logger.log('Get physician by id called with ', guid);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `/api/physicians/${guid}/`, {})
    .then(r => {
      return r;
    })
    .then(r => jsonToPhysician(r));
};

export const createPhysicianApiCall = async (
  profile: UserProfile,
  physician: Physician
) => {
  Logger.log('Create physician by id called with ', physician);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, `/api/physicians/`, {
    method: 'POST',
    body: JSON.stringify(physicianToJson(physician))
  });
};

export const updatePhysicianApiCall = async (
  profile: UserProfile,
  physician: Physician
) => {
  Logger.log('Update physician by id called with ', physician);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(
    API_SERVICE,
    `/api/physicians/${physician.guid}/`,
    {
      method: 'PUT',
      body: JSON.stringify(physicianToJson(physician))
    }
  );
};

export const deletePhysicianApiCall = async (
  profile: UserProfile,
  guid: string
) => {
  Logger.log('Delete physician by id called with ', guid);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, `/api/physicians/${guid}/`, {
    method: 'PUT',
    body: JSON.stringify({ organization_guid: '' })
  });
};
