import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Row, Col } from 'antd';
import InputSelectField from '../../../common/form-helpers/InputSelectField';
import { ACTIVE_STUDIES_CARD_FORM_NAME } from '../../../utils/form-helpers';
import {
  selectFacilityOptions,
  selectSentToOptions,
  selectModalityOptions
} from '../../../store/selectors';
import { SelectOptions, ReduxState } from '../../../schemas';
import { t } from 'i18next';

type Props = {
  facilityOptions: SelectOptions,
  sentToOptions: SelectOptions,
  modalityOptions: SelectOptions
};

function ActiveStudiesCardForm({
  facilityOptions,
  sentToOptions,
  modalityOptions
}: Props) {
  return (
    <Row type='flex' align='middle' justify='end' gutter={[16, 12]}>
      <Col xs={24} sm={24} md={8} lg={8} xl={7}>
        <Field
          name='facility'
          labelStyle={{ margin: 0 }}
          placeholder={t('referral_patterns.select_facility')}
          options={facilityOptions}
          width='100%'
          component={InputSelectField}
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={7}>
        <Field
          name='sentTo'
          labelStyle={{ margin: 0 }}
          placeholder={t("referral_patterns.select_sent_to")}
          options={sentToOptions}
          width='100%'
          component={InputSelectField}
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={5}>
        <Field
          name='modality'
          labelStyle={{ margin: 0 }}
          placeholder={t('referral_patterns.select_modality')}
          options={modalityOptions}
          width='100%'
          component={InputSelectField}
        />
      </Col>
    </Row>
  );
}

const mapStateToProps = (state: ReduxState) => {
  return {
    facilityOptions: selectFacilityOptions(state),
    sentToOptions: selectSentToOptions(state),
    modalityOptions: selectModalityOptions(state),
    initialValues: {
      facility: 'ALL',
      sentTo: 'ALL',
      modality: 'ALL'
    }
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: ACTIVE_STUDIES_CARD_FORM_NAME
  })(ActiveStudiesCardForm)
);
