/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @flow

import * as React from 'react';
import { Typography, Table, Icon, Button, Empty } from 'antd';
import { connect } from 'react-redux';
import { ReduxState } from '../../../schemas';

import '../index.less';
import InviteRadiologistsModal from './modals/InviteRadiologistsModal';
import { t } from 'i18next';

type Props = {
  activeId: number,
  data: Object,
  change: Function,
  onSubmit: Function,
  onBack: Function
};

const { Title, Text } = Typography;

const InviteRadiologists = ({
  data,
  activeId,
  change,
  onSubmit,
  onBack
}: Props) => {
  const [visible, setVisible] = React.useState(false);
  const [editedIndex, setEditedIndex] = React.useState(-1);

  const onAdd = values => {
    if (editedIndex >= 0) {
      change('inviteRadiologists', [
        ...data.slice(0, editedIndex),
        { id: editedIndex, ...values.radiologist },
        ...data.slice(editedIndex + 1, data.length)
      ]);
    } else {
      change('inviteRadiologists', [
        ...data,
        { id: data.length + 1, ...values.radiologist }
      ]);
    }
    setEditedIndex(-1);
    change('radiologist', {});
    setVisible(false);
  };

  const onEdit = index => {
    setVisible(true);
    setEditedIndex(index);
    change('radiologist', { ...data[index] });
  };

  const showModal = () => {
    setVisible(true);
  };

  const onRemove = selectedIndex => {
    change(
      'inviteRadiologists',
      data.filter((item, index) => index !== selectedIndex)
    );
  };

  const columns = [
    {
      title: t('dashboard.table.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('user_administration.field.email_adress'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: t('user_administration.field.specialty'),
      dataIndex: 'specialty',
      key: 'specialty'
    },
    {
      title: t('onboarding.permission'),
      key: 'userType',
      dataIndex: 'userType',
      render: text =>
        text && text.toLowerCase() === 'radiologist'
          ? 'View All'
          : 'Only Assigned'
    },
    {
      title: '',
      key: 'id',
      render: (text, record) => (
        <span>
          <a style={{ marginRight: 10 }} onClick={() => onEdit(record.index)}>
            <Icon type='edit' />
          </a>
          <a onClick={() => onRemove(record.index)}>Remove</a>
        </span>
      )
    }
  ];

  const dataSource =
    data &&
    data.map((item, index) => {
      const title = item.title !== undefined ? `${item.title} ` : '';
      const credentials =
        item.credentials !== undefined ? ` ${item.credentials}` : '';
      const name = `${title}${item.name}${credentials}`;
      return {
        index,
        key: item.id,
        id: item.id,
        name,
        email: item.email,
        specialty: item.specialty && item.specialty.join(', '),
        userType: item.userType
      };
    });

  return (
    <>
      <Title className='onboarding-action-title' level={3}>
        {t("onboarding.invite_radiologists")}
      </Title>
      <Text className='onboarding-action-subtitle'>
      {t("onboarding.invite_colleagues_collaborate_alemHealth_chat")}
      </Text>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowKey='id'
        locale={{
          emptyText: (
            <Empty
              description={t("table.no_data")}
            />
          ),
        }}
      />
      <Button
        className='onboarding-action-add-another'
        onClick={() => showModal()}
        type='dashed'
      >
        <Icon type='plus-circle' /> {t("onboarding.add_another")}
      </Button>
      <InviteRadiologistsModal
        visible={visible}
        setVisible={setVisible}
        onSubmit={values => onAdd(values)}
        selectedFacilities={[]}
      />
      <div className='onboarding-action-buttons'>
        <Button type='link' size='large' onClick={onBack}>
          {t("onboarding.go_back")}
        </Button>

        <Button type='primary' size='large' onClick={onSubmit}>
          {t("onboarding.continue")}
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InviteRadiologists);
