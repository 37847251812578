import * as actions from '../actions/planActions';
import initialState from '../state';

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_PLAN_INFORMATION_SUCCESS:
      return {
        ...state,
        modifications: state.modifications + 1,
        ...action.data
      };
    case actions.GET_PLAN_INFORMATION_FAILED:
    case actions.CREATE_CUSTOMER_FAILED:
    case actions.CHANGE_CARD_INFORMATION_FAILED:
    case actions.CHANGE_BILLING_ADDRESS_FAILED:
    case actions.UPGRADE_PLAN_FAILED:
      return { ...state, errors: state.errors.concat(action.error.message) };

    case actions.CHANGE_BILLING_ADDRESS_SUCCESS:
      return {
        ...state,
        paymentInformation: {
          ...state.paymentInformation,
          billingAddress: action.billingAddress
        }
      };

    case actions.CHANGE_CARD_INFORMATION_SUCCESS:
      return {
        ...state,
        paymentInformation: {
          billingAddress: state.paymentInformation.billingAddress,
          type: 'card',
          ...action.card
        }
      };
    case actions.UPGRADE_PLAN_SUCCESS:
    case actions.UPGRADE_HARDWARE_SUCCESS:
      return {
        ...state,
        modifications: state.modifications + 1
      };
    case actions.GET_PORTAL_URL_SUCCESS:
      return {
        ...state,
        url: action.url
      };
    case actions.SET_BANK_PAYMENT_SUCCESS:
      return {
        ...state,
        invoiceAmount: action.amount
      };
    case actions.DISABLE_NOTIFY_SUB_MIGRATION:
      return {
        ...state,
        notifySubscriptionMigration: false,
      };
    default:
      return state;
  }
};
