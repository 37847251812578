/* eslint-disable camelcase */
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { v4 as uuidV4 } from 'uuid';
import {
  AnonymousCredential,
  ContainerURL,
  ServiceURL,
  StorageURL
} from '@azure/storage-blob';
import decrypt from '../../utils/crypto';
import { showError /* showSuccess */, showSuccess } from '../helpers';
import * as actions from '../actions';
import { getCurrentUser } from '../selectors';
import {
  getSecondOpinionSettingApiCall,
  updateSecondOpinionSettingApiCall,
  getSecondOpinionListApiCall,
  sendSecondOpinionQuotationApiCall,
  sendSecondOpinionPaymentRequestApiCall,
  sendSecondOpinionReportApiCall,
  sendSecondOpinionCodeApiCall,
  getUserGroupApiCall,
  updateGroupAPiCall
} from '../../api';
import { uploadFile } from '../../utils/azure-blob-helpers';
import {
  showNotificationForUploadInProgress,
  showNotificationForUploadFailed
} from '../../utils/notification-helpers';
import { getAzureAddressApiCall } from '../../api/endpoints/options';
import { t } from 'i18next';

export function* getSecondOpinionSettingRequestSaga() {
  const profile = yield select(getCurrentUser);
  yield put({ type: actions.SECOND_OPINION_LOADING, loading: true });
  try {
    const secondOpinionSetting = yield call(
      getSecondOpinionSettingApiCall,
      profile
    );
    const group = yield call(getUserGroupApiCall, profile);
    yield put({
      type: actions.GET_SECOND_OPINION_SETTING_SUCCESS,
      secondOpinionSetting: { ...secondOpinionSetting, ...group }
    });
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.GET_SECOND_OPINION_SETTING_FAILURE, error });
    yield call(showError, { message: t('errors.failed_loading_second_opinion') });
  } finally {
    yield put({ type: actions.SECOND_OPINION_LOADING, loading: false });
  }
}

export function* updateSecondOpinionSettingRequestSaga(action) {
  const profile = yield select(getCurrentUser);
  yield put({ type: actions.SECOND_OPINION_LOADING, loading: true });

  try {
    const { data } = action;

    const { logo_file_location } = data;
    const requestBody = { ...data };
    if (logo_file_location && typeof logo_file_location !== 'string') {
      const file = logo_file_location;
      const notification = {
        notificationKey: 'upload',
        message: 'Uploader Logo Upload',
        content: 'Uploading Image file'
      };
      const containerName = 'grouplogos';
      const azureAddress = yield call(getAzureAddressApiCall, profile);
      const anonymousCredential = new AnonymousCredential();
      const pipeline = StorageURL.newPipeline(anonymousCredential);
      const serviceURL = new ServiceURL(
        decrypt(profile.accessToken, azureAddress),
        pipeline
      );
      const containerURL = ContainerURL.fromServiceURL(
        serviceURL,
        containerName
      );

      const fileName = `secondopinionbranding-${uuidV4()}.${
        file.name.split('.', -1)[1]
      }`;

      // console.log('RESPONSE 1', containerURL)
      const response = yield call(uploadFile, {
        containerURL,
        file,
        fileName,
        showNotificationForUploadInProgress,
        showNotificationForUploadFailed,
        notification
      });
      if (response) {
        requestBody.logo_file_location = `https://teleradsettings.blob.core.windows.net/${containerName}/${fileName}`;
      }
    }

    const secondOpinionSetting = yield call(
      updateSecondOpinionSettingApiCall,
      profile,
      requestBody
    );
    const groupData = {
      name: data.name,
      location: data.location,
      officeNumber: data.officeNumber,
      emailAddress: data.emailAddress,
      website: data.website,
      logo: data.logo
    };
    yield call(updateGroupAPiCall, profile, groupData);

    yield put({
      type: actions.UPDATE_SECOND_OPINION_SETTING_SUCCESS,
      secondOpinionSetting
    });

    yield call(showSuccess, `Branding settings updated.`);
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.UPDATE_SECOND_OPINION_SETTING_FAILURE, error });
    yield call(showError, { message: t('errors.failed_updating_second_opinion') });
  } finally {
    yield put({ type: actions.SECOND_OPINION_LOADING, loading: false });
  }
}

export function* getSecondOpinionSettingListSaga() {
  const profile = yield select(getCurrentUser);
  try {
    const secondOpinionList = yield call(getSecondOpinionListApiCall, profile);
    yield put({
      type: actions.GET_SECOND_OPINION_LIST_SUCCESS,
      secondOpinionList
    });
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.GET_SECOND_OPINION_LIST_FAILURE, error });
    yield call(showError, { message: t('errors.failed_loading_second_opinion') });
  }
}

export function* sendSecondOpinionQuotationSaga(action) {
  const profile = yield select(getCurrentUser);
  try {
    const secondOpinionList = yield call(
      sendSecondOpinionQuotationApiCall,
      profile,
      action.id
    );
    yield put({
      type: actions.SEND_SECOND_OPINION_QUOTATION_SUCCESS,
      secondOpinionList
    });
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.SEND_SECOND_OPINION_QUOTATION_FAILURE, error });
    yield call(showError, { message: t('errors.failed_sending_second_opinion_quotation') });
  }
}

export function* sendSecondOpinionPaymentRequestSaga(action) {
  const profile = yield select(getCurrentUser);
  try {
    const secondOpinionList = yield call(
      sendSecondOpinionPaymentRequestApiCall,
      profile,
      action.id
    );
    yield put({
      type: actions.SEND_SECOND_OPINION_PAYMENT_REQUEST_SUCCESS,
      secondOpinionList
    });
  } catch (error) {
    console.error(error.message);
    yield put({
      type: actions.SEND_SECOND_OPINION_PAYMENT_REQUEST_FAILURE,
      error
    });
    yield call(showError, { message: t('errors.failed_sending_second_opinion_payment') });
  }
}

export function* sendSecondOpinionReportSaga(action) {
  const profile = yield select(getCurrentUser);
  try {
    const secondOpinionList = yield call(
      sendSecondOpinionReportApiCall,
      profile,
      action.id
    );
    yield put({
      type: actions.SEND_SECOND_OPINION_REPORT_SUCCESS,
      secondOpinionList
    });
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.SEND_SECOND_OPINION_REPORT_FAILURE, error });
    yield call(showError, { message: t('errors.failed_sending_second_opinion_report') });
  }
}

export function* sendSecondOpinionCodeSaga(action) {
  const profile = yield select(getCurrentUser);
  try {
    const { secondOpinionCodeParam } = action;
    const requestBody = [
      {
        email: secondOpinionCodeParam.email,
        context: {
          name: profile.name,
          embed_guid: profile.appMetadata.affiliatedGroup
        }
      }
    ];

    const secondOpinionList = yield call(
      sendSecondOpinionCodeApiCall,
      profile,
      requestBody
    );
    yield put({
      type: actions.SEND_SECOND_OPINION_CODE_SUCCESS,
      secondOpinionList
    });

    yield call(
      showSuccess,
      `Installation instructions shared with ${secondOpinionCodeParam.email}`
    );
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.SEND_SECOND_OPINION_CODE_FAILURE, error });
    yield call(showError, { message: t('errors.failed_sending_second_opinion_code') });
  }
}

export default function* secondOpinionSaga() {
  yield takeLatest(
    actions.GET_SECOND_OPINION_SETTING_REQUEST,
    getSecondOpinionSettingRequestSaga
  );
  yield takeLatest(
    actions.UPDATE_SECOND_OPINION_SETTING_REQUEST,
    updateSecondOpinionSettingRequestSaga
  );
  yield takeLatest(
    actions.GET_SECOND_OPINION_LIST_REQUEST,
    getSecondOpinionSettingListSaga
  );
  yield takeLatest(
    actions.SEND_SECOND_OPINION_QUOTATION_REQUEST,
    sendSecondOpinionQuotationSaga
  );
  yield takeLatest(
    actions.SEND_SECOND_OPINION_PAYMENT_REQUEST_REQUEST,
    sendSecondOpinionPaymentRequestSaga
  );
  yield takeLatest(
    actions.SEND_SECOND_OPINION_REPORT_REQUEST,
    sendSecondOpinionReportSaga
  );
  yield takeLatest(
    actions.SEND_SECOND_OPINION_CODE_REQUEST,
    sendSecondOpinionCodeSaga
  );
}
