import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import { Row, Col } from 'antd';
import { bindActionCreators } from 'redux';
import {
  dateOptions,
  dateFilters,
  dateFilterStrings
} from '../../../store/constants/appConstants';
import { RADIOLOGIST_PERFORMANCE_CARD_FORM_NAME } from '../../../utils/form-helpers';
import { selectFacilityOptions } from '../../../store/selectors';
import { SelectOptions, ReduxState } from '../../../schemas';
import { getRadiologistPerformanceRequest } from '../../../store/actions';
import RadioGroupField from '../../../common/form-helpers/RadioGroupField';
import DateRangePickerField from '../../../common/form-helpers/DateRangePickerField';

type Props = {
  facilityOptions: SelectOptions,
  getRadiologistPerformance: Function
};

function RadiologistPerformanceCardForm({
  radioDateOptions,
  facilityOptions,
  getRadiologistPerformance,
  changeField
}: Props) {
  const formValues = useSelector(
    state => state.form.radiologistPerformanceCardForm.values
  );
  const changeDateFilter = event => {
    changeField('selectedDate');
    if (event.target && event.target.value === dateFilters.TODAY) {
      getRadiologistPerformance(dateFilterStrings.TODAY);
    } else {
      getRadiologistPerformance(dateFilterStrings.THIS_WEEK);
    }
  };

  const changeDateRange = values => {
    if (values && values.length === 2) {
      changeField('dateFilter');
    }
  };

  useEffect(() => {
    if (
      formValues &&
      formValues.selectedDate &&
      formValues.selectedDate.length === 2
    )
      getRadiologistPerformance(dateFilterStrings.CUSTOM);
  }, [formValues]);

  return (
    <Row
      id='radiologist-performance-card-form'
      type='flex'
      align='middle'
      justify='end'
      gutter={[16, 12]}
    >
      {/* <Col xs={24} sm={24} md={8} lg={8} xl={7}>
        <Field
          name='view'
          labelStyle={{ margin: 0 }}
          placeholder='Select view'
          options={viewOptions}
          width='100%'
          component={InputSelectField}
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={7}>
        <Field
          name='facility'
          labelStyle={{ margin: 0 }}
          placeholder='Select facility'
          options={facilityOptions}
          width='100%'
          component={InputSelectField}
        />
      </Col> */}
      {/* <Col xs={24} sm={24} md={8} lg={8} xl={5}>
        <Field
          name='dateFilter'
          placeholder='Select date'
          options={dateOptions}
          suffixIcon={<Icon type='calendar' />}
          labelStyle={{ margin: 0 }}
          width='100%'
          component={InputSelectField}
          onChange={event => changeDateFilter(event)}
        />
      </Col> */}
      <Col>
        <Field
          name='dateFilter'
          labelStyle={{ margin: 0 }}
          radioBtnType='outline'
          options={radioDateOptions}
          component={RadioGroupField}
          onChange={event => changeDateFilter(event)}
        />
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Field
          name='selectedDate'
          labelStyle={{ margin: 0 }}
          width='100%'
          component={DateRangePickerField}
          // eslint-disable-next-line no-alert
          onChange={values => changeDateRange(values)}
        />
      </Col>
    </Row>
  );
}

const mapStateToProps = (state: ReduxState) => {
  return {
    radioDateOptions: [dateOptions[0], dateOptions[1]],
    facilityOptions: selectFacilityOptions(state),
    initialValues: {
      view: 'RVU',
      facility: 'ALL',
      dateFilter: dateFilters.TODAY
    }
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getRadiologistPerformance: getRadiologistPerformanceRequest,
      changeField: fieldName =>
        change(RADIOLOGIST_PERFORMANCE_CARD_FORM_NAME, fieldName, '')
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: RADIOLOGIST_PERFORMANCE_CARD_FORM_NAME
  })(RadiologistPerformanceCardForm)
);
