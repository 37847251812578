import { put, select, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/paymentsActions';
import * as lActions from '../actions/loadingActions';
import { getCurrentUser, getPayment } from '../selectors';
import {
  checkStripeUnsuscribeEndpoint,
  getStripeDataEndpoint,
  getStripeInvoicesEndpoint,
  getStripeSessionEndpoint
} from '../../api/endpoints/payments';
import { message } from 'antd';
import { t } from '../../i18n';

function* getStripeSessionSaga() {
  yield put({ type: lActions.GET_STRIPE_SESSION_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  const payment = yield select(getPayment);
  if (!payment?.data?.id) return
  message.loading(t('plan_usage.loading_stripe_customer_portal'))
  try {
    const requestBody = {
      return_url: window.location.origin,
      customer_id: payment.data.id
    }
    const payload = yield call(getStripeSessionEndpoint, profile, requestBody);
    message.destroy()
    if (payload.url) yield put({ type: actions.GET_STRIPE_SESSION_SUCCESS, payload });
  } catch (error) {
    yield put({ type: actions.GET_STRIPE_SESSION_FAILURE, error });
  } finally {
    yield put({ type: lActions.GET_STRIPE_SESSION_LOADING, loading: false });
  }
}

function* getStripeDataSaga() {
  yield put({ type: lActions.GET_STRIPE_DATA_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  try {
    const payload = yield call(getStripeDataEndpoint, profile);
    yield put({ type: actions.GET_STRIPE_DATA_SUCCESS, payload });
  } catch (error) {
    yield put({ type: actions.GET_STRIPE_DATA_FAILURE, error });
  } finally {
    yield put({ type: lActions.GET_STRIPE_DATA_LOADING, loading: false });
  }
}

function* getStripeInvoicesSaga(action) {
  yield put({ type: lActions.GET_STRIPE_DATA_LOADING, loading: true });
  const { limit, first, last } = action;
  const profile = yield select(getCurrentUser);
  try {
    const payload = yield call(
      getStripeInvoicesEndpoint,
      profile,
      limit,
      first,
      last
    );
    yield put({ type: actions.GET_STRIPE_INVOICES_SUCCESS, payload });
  } catch (error) {
    yield put({ type: actions.GET_STRIPE_INVOICES_FAILURE, error });
  } finally {
    yield put({ type: lActions.GET_STRIPE_DATA_LOADING, loading: false });
  }
}

function* checkStripeUnsuscribeSaga() {
  yield put({ type: lActions.GET_STRIPE_DATA_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    yield call(checkStripeUnsuscribeEndpoint, profile);
    yield put({ type: actions.STRIPE_CHECK_UNSUSCRIBE_SUCCESS });
    yield call(getStripeDataSaga);
  } catch (e) {
    yield put({ type: actions.STRIPE_CHECK_UNSUSCRIBE_FAILURE, e });
  } finally {
    yield put({ type: lActions.GET_STRIPE_DATA_LOADING, loading: false });
  }
}

export default function* paymentsSaga() {
  yield takeLatest(actions.GET_STRIPE_SESSION_REQUEST, getStripeSessionSaga);
  yield takeLatest(actions.GET_STRIPE_DATA_REQUEST, getStripeDataSaga);
  yield takeLatest(actions.GET_STRIPE_INVOICES_REQUEST, getStripeInvoicesSaga);
  yield takeLatest(
    actions.STRIPE_CHECK_UNSUSCRIBE_REQUEST,
    checkStripeUnsuscribeSaga
  );
}
