import { call, put, takeLatest } from 'redux-saga/effects';

import { isEmpty } from '../../utils'
import {
  GET_COUNTRY_LIST_FAILURE,
  GET_COUNTRY_LIST_REQUEST,
  GET_COUNTRY_LIST_SUCCESS
} from '../actions/countryListActions';
import { getCountryList } from '../../api';
import {
  getCountryListFromLocalStorage,
  saveCountryListToLocalStorage
} from '../storage';

// eslint-disable-next-line import/prefer-default-export
function* getCountryListHandler() {
  try {
    let data = yield call(getCountryListFromLocalStorage);
    if (isEmpty(data)) {
      data = yield call(getCountryList);
      data = data.map(c => ({
        name: c.name,
        alpha: c.alpha2Code,
        extension: c.callingCodes.length > 0 ? c.callingCodes[0] : ''
      }));
      yield call(saveCountryListToLocalStorage, data);
    }
    yield put({ type: GET_COUNTRY_LIST_SUCCESS, data });
  } catch (e) {
    yield put({
      type: GET_COUNTRY_LIST_FAILURE,
      message: e.message
    });
  }
}

export default function*() {
  yield takeLatest(GET_COUNTRY_LIST_REQUEST, getCountryListHandler);
}
