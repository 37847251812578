import moment from 'moment';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/referringFacilityActions';
import {
  GET_REFERRING_FACILITIES_LOADING,
  GET_SOURCE_REFERRING_FACILITIES_CHART_LOADING,
  GET_REFERRING_FACILITIES_LIST_LOADING
  // ADD_FACILITY_LOADING,
  // DELETE_FACILITY_LOADING,
  // EDIT_FACILITY_LOADING,
  // GET_FACILITIES_LOADING,
  // GET_FACILITY_LOADING
} from '../actions/loadingActions';

import { getFormValues, showError } from '../helpers';
import {
  getReferringFacilities,
  getSourceReferringFacilitiesChart,
  getSourceReferringFacilitiesTable
} from '../../api/endpoints/referring-facilities';
// import { facilityToJson } from '../../api/transformers';
import {
  getCurrentUser
  // getAffiliatedGroup,
  // getFacilityLetterhead,
  // getFacilityLogo
} from '../selectors';
import {
  SOURCE_REFERRING_FACILITIES_CHART_FORM_NAME,
  SOURCE_REFERRING_FACILITIES_FORM_NAME
} from '../../utils/form-helpers';
import { dateFilterStrings } from '../constants';
// import { isValidUuid } from '../../utils/index';
import facilityDataConverter from '../../utils/facility-converter';
import { t } from 'i18next';

function* getReferralsByFacilitiesListSaga(action) {
  yield put({ type: GET_REFERRING_FACILITIES_LIST_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    const referringFacilities = yield call(getReferringFacilities, profile, {
      selectedDate: [moment('2000-01-01'), moment()],
      dateFilterType: dateFilterStrings.CUSTOM
    });
    const state = yield select();
    const updatedReferringFacilities = facilityDataConverter(
      referringFacilities.data,
      'facilityName',
      state
    );
    yield put({
      type: actions.GET_REFERRING_FACILITIES_LIST_SUCCESS,
      referringFacilities: updatedReferringFacilities
    });
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.GET_REFERRING_FACILITIES_LIST_FAILED, error });
    yield call(showError, { message: t('errors.failed_loading_facilities') });
  } finally {
    yield put({ type: GET_REFERRING_FACILITIES_LIST_LOADING, loading: false });
  }
}

function* getReferralsByFacilitiesSaga(action) {
  yield put({ type: GET_REFERRING_FACILITIES_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    const filters = yield select(
      getFormValues,
      SOURCE_REFERRING_FACILITIES_FORM_NAME
    );
    const referringFacilities = yield call(
      getReferringFacilities,
      profile,
      filters,
      action.timeFrame
    );
    const state = yield select();
    referringFacilities.data = facilityDataConverter(
      referringFacilities.data,
      'facilityName',
      state
    );
    yield put({
      type: actions.GET_REFERRING_FACILITIES_SUCCESS,
      referringFacilities
    });
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.GET_REFERRING_FACILITIES_FAILED, error });
    yield call(showError, { message: t('errors.failed_loading_facilities') });
  } finally {
    yield put({ type: GET_REFERRING_FACILITIES_LOADING, loading: false });
  }
}

// function* getReferringFacilityByIdSaga(action) {
//   yield put({ type: GET_FACILITY_LOADING, loading: true });
//   try {
//     const { facilityId } = action;
//     const profile = yield select(getCurrentUser);
//     const facility = yield call(getFacilityById, profile, facilityId);
//     yield put({ type: actions.GET_REFERRING_FACILITY_BY_ID_SUCCESS, facility });
//   } catch (error) {
//     yield put({ type: actions.GET_REFERRING_FACILITY_BY_ID_FAILED, error });
//     yield call(showError, error);
//   } finally {
//     yield put({ type: GET_FACILITY_LOADING, loading: false });
//   }
// }

// function* createReferringFacilitySaga(action) {
//   yield put({ type: ADD_FACILITY_LOADING, loading: true });
//   try {
//     const profile = yield select(getCurrentUser);
//     let facility = {};
//     const group = yield select(getAffiliatedGroup);
//     const facilityLogo = yield select(getFacilityLogo);
//     const letterhead = yield select(getFacilityLetterhead);
//     facility = yield call(
//       facilityToJson,
//       { letterhead, facilityLogo, ...action.facility },
//       group
//     );
//     yield call(createFacility, profile, facility);
//     yield call(getReferringFacilitiesSaga);
//     yield call(showSuccess, 'New Referring facility added');
//     yield put({ type: actions.ADD_FACILITY_SUCCESS });
//   } catch (error) {
//     yield put({ type: actions.ADD_FACILITY_FAILED, error });
//     yield call(showError, error);
//   } finally {
//     yield put({ type: ADD_FACILITY_LOADING, loading: false });
//   }
// }

// function* updateReferringFacilitySaga(action) {
//   yield put({ type: EDIT_FACILITY_LOADING, loading: true });
//   try {
//     const profile = yield select(getCurrentUser);
//     let { facility, facilityId } = action;
//     if (action.type === actions.EDIT_REFERRING_FACILITY_REQUEST) {
//       const facilityLogo = yield select(getFacilityLogo);
//       const letterhead = yield select(getFacilityLetterhead);
//       facility = yield call(facilityToJson, {
//         ...facility,
//         facilityLogo,
//         letterhead
//       });
//       yield call(updateFacility, profile, facility, facilityId);
//     } else {
//       facility = yield call(facilityToJson, facility);
//       yield call(updateFacility, profile, facility, facilityId);
//     }
//     yield call(showSuccess, 'Referring Facility updated');
//     yield call(getReferringFacilitiesSaga);
//     yield put({ type: actions.EDIT_REFERRING_FACILITY_SUCCESS });
//   } catch (error) {
//     yield put({ type: actions.EDIT_REFERRING_FACILITY_FAILED, error });
//     yield call(showError, error);
//   } finally {
//     yield put({ type: EDIT_FACILITY_LOADING, loading: false });
//   }
// }

function* getSourceReferringFacilitiesChartSaga(action) {
  yield put({
    type: GET_SOURCE_REFERRING_FACILITIES_CHART_LOADING,
    loading: true
  });
  try {
    const profile = yield select(getCurrentUser);
    const filters = yield select(
      getFormValues,
      SOURCE_REFERRING_FACILITIES_CHART_FORM_NAME
    );
    const chartData = yield call(
      getSourceReferringFacilitiesChart,
      profile,
      filters,
      action.timeFrame
    );
    const tableData = yield call(
      getSourceReferringFacilitiesTable,
      profile,
      filters,
      action.timeFrame
    );
    const state = yield select();
    const updatedChart = facilityDataConverter(
      chartData,
      'referrerName',
      state
    );
    const updatedTable = facilityDataConverter(
      tableData,
      'referrerName',
      state
    );
    const chart = { chartData: updatedChart, tableData: updatedTable };
    yield put({
      type: actions.GET_SOURCE_REFERRING_FACILITIES_CHART_SUCCESS,
      chart
    });
  } catch (error) {
    console.error(error.message);
    yield put({
      type: actions.GET_SOURCE_REFERRING_FACILITIES_CHART_FAILED,
      error
    });
    yield call(showError, { message: t('errors.failed_loading_facilities') });
  } finally {
    yield put({
      type: GET_SOURCE_REFERRING_FACILITIES_CHART_LOADING,
      loading: false
    });
  }
}

// function* watchSourceReferringFacilitiesChartSaga() {
//   while (true) {
//     yield take(actions.GET_SOURCE_REFERRING_FACILITIES_CHART_SUCCESS);

//     const physicians = yield select(state => state.physicians.array);
//     const chart = yield select(state => state.referringFacilities.chart);
//     const chartData = chart.chartData.map(referringFacility => {
//       if (isValidUuid(referringFacility.referrerName)) {
//         const foundPhysician = physicians.find(
//           physician => physician.guid === referringFacility.referrerName
//         );
//         if (foundPhysician)
//           referringFacility.referrerName = foundPhysician.name;
//       }
//       return referringFacility;
//     });
//     const updatedChart = { chartData, tableData: chart.tableData };
//     yield put({
//       type: actions.GET_SOURCE_REFERRING_FACILITIES_CHART_SUCCESS,
//       chart: updatedChart
//     });
//   }
// }

// function* deleteReferringFacilitySaga(action) {
//   yield put({ type: DELETE_FACILITY_LOADING, loading: true });
//   try {
//     yield put({ type: actions.DELETE_REFERRING_FACILITY_SUCCESS });
//   } catch (error) {
//     yield put({ type: actions.DELETE_REFERRING_FACILITY_FAILED, error });
//     yield call(showError, error);
//   } finally {
//     yield put({ type: DELETE_FACILITY_LOADING, loading: false });
//   }
// }

export default function* referringFacilitySaga() {
  yield takeLatest(
    actions.GET_REFERRING_FACILITIES_LIST_REQUEST,
    getReferralsByFacilitiesListSaga
  );
  yield takeLatest(
    actions.GET_SOURCE_REFERRALS_BY_FACILITIES_REQUEST,
    getReferralsByFacilitiesSaga
  );
  // yield takeLatest(actions.GET_REFERRING_FACILITY_BY_ID_REQUEST, getReferringFacilityByIdSaga);
  // yield takeLatest(actions.ADD_REFERRING_FACILITY_REQUEST, createReferringFacilitySaga);
  // yield takeLatest(actions.EDIT_REFERRING_FACILITY_REQUEST, updateReferringFacilitySaga);
  // yield takeLatest(actions.DELETE_REFERRING_FACILITY_REQUEST, deleteReferringFacilitySaga);
  yield takeLatest(
    actions.GET_SOURCE_REFERRING_FACILITIES_CHART_REQUEST,
    getSourceReferringFacilitiesChartSaga
  );
  // yield fork(watchSourceReferringFacilitiesChartSaga);
}
