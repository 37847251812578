import type { RadiologistInvitation } from '../../schemas';
import type { GetUserAction, RadiologistIdAction } from './types';

// Action strings

export const GET_EXTERNAL_RADIOLOGISTS_REQUEST =
  'GET_EXTERNAL_RADIOLOGISTS_REQUEST';
export const GET_EXTERNAL_RADIOLOGISTS_SUCCESS =
  'GET_EXTERNAL_RADIOLOGISTS_SUCCESS';
export const GET_EXTERNAL_RADIOLOGISTS_FAILED =
  'GET_EXTERNAL_RADIOLOGISTS_FAILED';

export const GET_EXTERNAL_RAD_BY_ID_REQUEST = 'GET_EXTERNAL_RAD_BY_ID_REQUEST';
export const GET_EXTERNAL_RAD_BY_ID_SUCCESS = 'GET_EXTERNAL_RAD_BY_ID_SUCCESS';
export const GET_EXTERNAL_RAD_BY_ID_FAILED = 'GET_EXTERNAL_RAD_BY_ID_FAILED';

export const ADD_EXTERNAL_RAD_REQUEST = 'ADD_EXTERNAL_RAD_REQUEST';
export const ADD_EXTERNAL_RAD_SUCCESS = 'ADD_EXTERNAL_RAD_SUCCESS';
export const ADD_EXTERNAL_RAD_FAILED = 'ADD_EXTERNAL_RAD_FAILED';

export const DELETE_EXTERNAL_RAD_REQUEST = 'DELETE_EXTERNAL_RAD_REQUEST';
export const DELETE_EXTERNAL_RAD_SUCCESS = 'DELETE_EXTERNAL_RAD_SUCCESS';
export const DELETE_EXTERNAL_RAD_FAILED = 'DELETE_EXTERNAL_RAD_FAILED';

export const UPDATE_LAST_EXTERNAL_RADIOLOGISTS_SEARCH =
  'UPDATE_LAST_EXTERNAL_RADIOLOGISTS_SEARCH';

// Action types

export type AddERAction = { type: string, radiologist: RadiologistInvitation };

// Action functions

export const getExternalRadiologistsAction = (
  perPage: number,
  page: number,
  sort: string,
  query: string
): GetUserAction => ({
  type: GET_EXTERNAL_RADIOLOGISTS_REQUEST,
  perPage,
  page,
  sort,
  query
});

export const getExternalRadiologistByIdAction = (
  radId: string
): RadiologistIdAction => ({
  type: GET_EXTERNAL_RAD_BY_ID_REQUEST,
  radId
});

export const addExternalRadiologistAction = (
  radiologist: RadiologistInvitation
): AddERAction => ({ type: ADD_EXTERNAL_RAD_REQUEST, radiologist });

export const deleteExternalRadiologistAction = (
  radId: string
): RadiologistIdAction => ({
  type: DELETE_EXTERNAL_RAD_REQUEST,
  radId
});
