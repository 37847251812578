import React from 'react';
import { connect } from 'react-redux';

import { t } from 'i18next';
import {
  deleteProvider,
  getProviderById,
  getProviders
} from '../../../store/actions';
import { STUDIES } from '../../../utils/filters';
import Table from '../../../components/Table';
import TableMenu from '../GeneralTableMenu';
import ViewProvider from './ViewProvider';
import { Empty } from 'antd';

export default connect(
  state => ({
    data: state.providers.array,
    loading: state.loading.providers,
    modifications: state.providers.modifications
  }),
  {
    getProvidersAction: getProviders,
    getAction: getProviderById,
    deleteAction: deleteProvider
  }
)(
  ({
    data,
    loading,
    modifications,
    getProvidersAction,
    getAction,
    deleteAction
  }) => {
    const columns = [
      {
        title: t('manage_radiologists.table.name'),
        dataIndex: 'name',
        sorter: (a, b) =>
          (a.name ? a.name : '')
            .toString()
            .toLowerCase()
            .localeCompare((b.name ? b.name : '').toString().toLowerCase()),
        columnSearch: true
      },
      {
        title: t('manage_radiologists.table.primary_contact'),
        dataIndex: 'primaryContact',
        sorter: (a, b) =>
          (a.primaryContact ? a.primaryContact : '')
            .toString()
            .toLowerCase()
            .localeCompare(
              (b.primaryContact ? b.primaryContact : '')
                .toString()
                .toLowerCase()
            ),
        columnSearch: true
      },
      {
        title: t('manage_radiologists.table.study_access'),
        dataIndex: 'studyAccess',
        filters: STUDIES,
        onFilter: (value, record) =>
          (record.studyAccess ? record.studyAccess : '')
            .toString()
            .includes(value)
      },
      {
        title: '',
        dataIndex: 'details',
        key: 'details',
        render: (text, record) => <ViewProvider providerId={record.id} />
      },
      {
        title: '',
        key: 'action',
        width: 50,
        render: (text, record) => (
          <TableMenu
            record={record}
            getAction={getAction}
            deleteAction={deleteAction}
            recordName='provider'
          />
        )
      }
    ];
    const rowKey = record => record.id;
    return (
      <Table
        columns={columns}
        data={data}
        modifications={modifications}
        rowKey={rowKey}
        loading={loading}
        getAction={getProvidersAction}
        locale={{
          emptyText: (
            <Empty
              description={t("table.no_data")}
            />
          ),
        }}
      />
    );
  }
);
