import initialState from '../state';
import * as actions from '../actions/usersActions';

export default (state = initialState.users, action) => {
  switch (action.type) {
    case actions.GET_USERS_SUCCESS:
      return { ...state, array: action.data.array, total: action.data.total };
    case actions.GET_ALL_USERS_SUCCESS:
      return { ...state, all: action.data.array };
    case actions.GET_USER_BY_ID_SUCCESS:
      return { ...state, current: action.data };
    case actions.REMOVE_CURRENT_USER:
      return { ...state, current: {} };
    case actions.ADD_USER_SUCCESS:
    case actions.EDIT_USER_SUCCESS:
    case actions.DELETE_USER_SUCCESS:
    case actions.REVOKE_ADMIN_RIGHTS_SUCCESS:
      return {
        ...state,
        modifications: (state.modifications += 1)
      };
    case actions.GET_ALL_USERS_FAILED:
    case actions.GET_USERS_FAILED:
    case actions.GET_USER_BY_ID_FAILED:
    case actions.ADD_USER_FAILED:
    case actions.EDIT_USER_FAILED:
    case actions.DELETE_USER_FAILED:
    case actions.REVOKE_ADMIN_RIGHTS_FAILED:
      return { ...state, errors: state.errors.concat(action.error) };
    case actions.UPDATE_LAST_USERS_SEARCH:
      return {
        ...state,
        currentSearch: action.search
      };
    default:
      return state;
  }
};
