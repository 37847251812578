// @flow

import * as React from 'react';
import { Modal, Row, Col, Button } from 'antd';
import { connect } from 'react-redux';
import type { /* Facility, */ Physician, ReduxState } from '../../../schemas';
import { getPhysicianByIdAction } from '../../../store/actions';

type Props = {
  data: { guid: string, name: string },
  loading: boolean,
  visible: boolean,
  handleCancel: () => void,
  handleEdit: () => void,
  handleDelete: () => void,
  getPhysician: string => void,
  physician: Physician
};

export default connect(
  (state: ReduxState) => ({
    physician: state.physicians.current
  }),
  {
    getPhysician: getPhysicianByIdAction
  }
)(
  ({
    data,
    visible,
    handleCancel,
    handleEdit,
    handleDelete,
    getPhysician,
    physician
  }: Props) => {
    const [guid, setGuid] = React.useState('');
    const [physicianState, setPhysicianState] = React.useState({ guid: '' });
    React.useEffect(() => {
      if (data.guid !== guid) {
        setGuid(data.guid);
        getPhysician(data.guid);
      }
      if (physicianState.guid !== physician.guid) {
        setPhysicianState(physician);
      }
    }, [data, physician, physicianState]);
    const {
      name,
      email,
      phone,
      primaryFacility,
      specialty
    }: Physician = physicianState;
    const renderColumn = (title: string, value: string, span: number = 12) => (
      <Col className='gutter-row' span={span}>
        <p>{title}</p>
        <p style={{ color: 'black' }}>{value || '-'}</p>
      </Col>
    );
    return (
      <Modal
        title='Referring Physician'
        okText='Edit'
        visible={visible}
        onCancel={handleCancel}
        destroyOnClose
        footer={
          <Row type='flex' justify='space-around' align='middle'>
            <Col span={8} style={{ textAlign: 'left' }}>
              {/* <Button
                className='custom-link'
                align='left'
                onClick={handleDelete}
              >
                Remove
              </Button> */}
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={handleCancel}>Close</Button>
              <Button onClick={handleEdit}>Edit</Button>
            </Col>
          </Row>
        }
      >
        <Row gutter={32}>
          {renderColumn('Name', name)}
          {renderColumn('Specialty', specialty)}
        </Row>
        <Row gutter={32}>
          {renderColumn('Email address', email)}
          {renderColumn('Mobile number', phone)}
        </Row>
        <Row gutter={32}>
          {renderColumn(
            'Facility',
            primaryFacility && primaryFacility.name
              ? primaryFacility.name
              : '-',
            24
          )}
        </Row>
      </Modal>
    );
  }
);
