import * as React from 'react';
import { connect } from 'react-redux';

import { transformCountries } from '../../../../utils';
import {
  uploadCustomLetterhead,
  addInternalFacilityAction,
  loadAddInternalFacilityForm,
  uploadFacilityLogo
} from '../../../../store/actions';
import AddInternalFacilityForm from './Form/AddInternalFacilityForm';

import type { Country, Facility, ReduxState } from '../../../../schemas';

type Props = {
  loading: boolean,
  modifications: number,
  createAction: Facility => void,
  rawCountries: Array<Country>,
  uploadLetterheadAction: () => void,
  uploadLogoAction: () => void
};

type State = {
  visible: boolean,
  modifications: number,
  close: boolean,
  countryList: Array<{ text: string, value: string }>
};

export default connect(
  (state: ReduxState) => ({
    form: state.form.addInternalFacilityForm,
    loading: state.loading.addFacility,
    modifications: state.facilities.modifications,
    rawCountries: state.countryList
  }),
  {
    createAction: addInternalFacilityAction,
    loadForm: loadAddInternalFacilityForm,
    uploadLetterheadAction: uploadCustomLetterhead,
    uploadLogoAction: uploadFacilityLogo
  }
)(
  class AddExternalFacility extends React.PureComponent<Props, State> {
    state = {
      visible: false,
      modifications: this.props.modifications, // eslint-disable-line  react/destructuring-assignment
      close: false,
      countryList: []
    };

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.modifications > prevState.modifications) {
        return { modifications: nextProps.modifications, visible: false };
      }
      return null;
    }

    componentDidMount(): void {
      const { rawCountries } = this.props;
      this.setState({
        countryList: transformCountries(rawCountries)
      });
    }

    showModal = () => this.setState({ visible: true });

    handleCancel = () => {
      const { uploadLetterheadAction, uploadLogoAction } = this.props;
      uploadLetterheadAction();
      uploadLogoAction();
      this.setState({ visible: false });
    };

    handleCreate = async values => this.props.createAction(values);

    render() {
      const { loading } = this.props;
      const { visible, countryList } = this.state;
      return (
        <div>
          {/* eslint-disable-next-line */}
          <div role='link' tabIndex='-1' onClick={this.showModal}>
            Internal Facility
          </div>
          {visible ? (
            <AddInternalFacilityForm
              visible={visible}
              loading={loading}
              onCancel={this.handleCancel}
              onSubmit={this.handleCreate}
              countryList={countryList}
            />
          ) : null}
        </div>
      );
    }
  }
);
