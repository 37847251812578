// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import { addProvider, loadAddProvider } from '../../../store/actions';
import AddProviderForm from './forms/AddProviderForm';

import type { ReduxState, Provider } from '../../../schemas';

type Props = {
  visible: boolean,
  loading: boolean,
  modifications: number,
  createAction: Provider => void,
  closeModal: () => void
};

type State = {
  modifications: number,
  close: boolean
};

export default connect(
  (state: ReduxState) => ({
    form: state.form.addProviderForm,
    loading: state.loading.addProvider,
    modifications: state.providers.modifications
  }),
  {
    createAction: addProvider,
    loadForm: loadAddProvider
  }
)(
  class AddProvider extends React.Component<Props, State> {
    state = {
      modifications: this.props.modifications, // eslint-disable-line  react/destructuring-assignment
      close: false
    };

    // We check if the number of created users is incremented (i.e. if the user creation has succeed,
    // and use this information to update the state.
    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.modifications > prevState.modifications) {
        return { modifications: nextProps.modifications, visible: false };
      }
      return null;
    }

    handleCancel = () => {
      this.props.closeModal();
    };

    handleCreate = async values => this.props.createAction(values); // eslint-disable-line  react/destructuring-assignment

    render() {
      const { loading } = this.props;
      const { visible } = this.props;
      return (
        <div>
          {/* eslint-disable-next-line */}
          {visible ? (
            // We have to use this trick to force form destruction
            <AddProviderForm
              visible={visible}
              loading={loading}
              onCancel={this.handleCancel}
              onSubmit={this.handleCreate}
            />
          ) : null}
        </div>
      );
    }
  }
);
