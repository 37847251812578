export default values => {
  const errors = { physician: {} };
  const { physician } = values;
  const { name, email, phone } = physician;
  if (!name) {
    errors.physician.name = 'Required';
  }

  if (!email) {
    errors.physician.email = 'Required';
  }

  if (!phone) {
    errors.physician.phone = 'Required';
  }

  return errors;
};
