// @flow
import * as React from 'react';
import { Button, Form, Row, Col, Divider } from 'antd';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import i18next, { t } from 'i18next';
import store from 'store'
import userValidation from '../users/forms/userValidation';
import {
  InputPhoneNumberField,
  InputSelectField,
  InputTextField
} from '../../components/FormParts';
import Buttons from './Buttons';

import './Account.less';
import { languageMap, RAD_SPECIALTY, USER_TYPE } from '../../utils/filters';
import CheckboxField from '../../components/FormParts/CheckBoxField';

const SETTINGS_ACCOUNT_FORM = 'settingsAccountForm';

type InitialValues = {
  name: string,
  email: string,
  phoneNumber: string,
  title: string,
  facility: string
};

type Props = {
  loading: boolean,
  handleResetPassword: () => void,
  // Passed by redux to load the initial state of the form
  initialValues: InitialValues,
  // Values passed by reduxForm
  reset: () => void,
  handleSubmit: any => void,
  pristine: boolean,
  submitting: boolean,
  userType: string
};

export default connect(state => ({
  // initialValues: state.initialForm.settingsAccount
}))(
  reduxForm({
    form: SETTINGS_ACCOUNT_FORM,
    validate: userValidation
  })(
    ({
      loading,
      reset,
      handleResetPassword,
      handleSubmit,
      pristine,
      submitting,
      userType,
      initialValues
    }) => {
      const [role, setRole] = React.useState(userType);
      const [specialtyOpen, openSpecialty] = React.useState(false);
      const [userTypeOpen, openUserType] = React.useState(false);
      return (
        <Form layout='vertical'>
          <Row gutter={56} type='flex' justify='start'>
            <Col className='gutter-row' span={9}>
              <Row gutter={8} type='flex' style={{ flexWrap: 'nowrap' }}>
                <Col>
                  <Field
                    style={{ width: 50 }}
                    name='title'
                    label={t('user_administration.field.title')}
                    placeholder='Dr.'
                    component={InputTextField}
                  />
                </Col>
                <Col style={{ flexGrow: 1 }}>
                  <Field
                    name='name'
                    label={t('user_administration.field.name')}
                    placeholder='Name'
                    component={InputTextField}
                  />
                </Col>
              </Row>
            </Col>
            <Col className='gutter-row' span={9}>
              <Field
                name='credentials'
                label={t('user_administration.field.credentials')}
                placeholder='MD, ARRT, FACR, etc'
                component={InputTextField}
              />
            </Col>
          </Row>
          <Row gutter={56} type='flex' justify='start'>
            <Col className='gutter-row' span={9}>
              <Field
                name='phone'
                label={t('user_administration.field.mobile_number')}
                component={InputPhoneNumberField}
                defaultCountry='sg'
                disableAreaCodes
              />
            </Col>
            <Col className='gutter-row' span={9}>
              <Field
                name='email'
                label={t('user_administration.field.email_adress')}
                type='email'
                placeholder='mail@domain.com'
                component={InputTextField}
              />
            </Col>
          </Row>
          <Row gutter={56} type='flex' justify='start'>
            <Col className='gutter-row' span={9}>
              <Field
                name='userType'
                label={t('user_administration.field.user_role')}
                placeholder='Please select...'
                component={InputSelectField(
                  USER_TYPE,
                  userTypeOpen,
                  () => openUserType(true),
                  () => openUserType(false)
                )}
                onChange={value => setRole(value)}
              />
            </Col>
            <Col className='gutter-row' span={9}>
              {role && role.toLowerCase() === 'radiologist' && (
                <Field
                  name='specialty'
                  label={t('user_administration.field.specialty')}
                  showSearch
                  placeholder='Type to search or select'
                  component={InputSelectField(
                    RAD_SPECIALTY,
                    specialtyOpen,
                    () => openSpecialty(true),
                    () => openSpecialty(false)
                  )}
                  mode='multiple'
                />
              )}
            </Col>
          </Row>
          <Divider />

          <Col className='language_field' span={9}>
            <Field
              name='language'
              label={t('user_administration.field.language')}
              placeholder='Please select language...'
              component={InputSelectField(languageMap)}
              onChange={value => {i18next.changeLanguage(value); 
                store.set("telerad-setting-portal-langSelected", value);}}
            />
          </Col>

          <Divider />

        <div style={{ marginTop: 15 , marginBottom: 15}}>
          <Field
            component={CheckboxField}
            name='languageCheckMark'
            checked={initialValues.languageCheckMark}
            componentProps={{checkBoxLabel: t('user_administration.save_language_login')}}
            fieldProps={{}}/>
        </div>
          <br />
          <Row gutter={16}>
            <Col>
              <div className='reset-password'>
                <Button onClick={handleResetPassword}>{t('button.reset_password')}</Button>
                <p>
                  {t('user_administration.you_receive_email_reset_password')}
                </p>
              </div>
            </Col>
          </Row>
          <Buttons
            pristine={pristine}
            submitting={submitting}
            loading={loading}
            handleCancel={reset}
            handleSave={handleSubmit}
          />
        </Form>
      );
    }
  )
);
