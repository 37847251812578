import * as React from 'react';
import './Labeled.less';

export default ({ label, children }) => (
  <div className='labeled'>
    <div
      style={{ visibility: label ? 'visible' : 'hidden' }}
      className='labeled-label'
    >
      <p>{label || '-'}</p>
    </div>
    {children}
  </div>
);
