import React from 'react';
import { Form, Input } from 'antd';

const InputSearchField = ({
  labelStyle = {},
  label = '',
  colon = false,
  required = false,
  // fullWidth = false,
  // width = '',
  defaultValue = undefined,
  input,
  meta: { touched, error },
  placeholder,
  ...restProps
}) => {
  if (!input.value) input.value = defaultValue;

  return (
    <Form.Item
      style={labelStyle}
      colon={colon}
      label={label ? <span style={{ fontWeight: 600 }}>{label}</span> : ""}
      required={required}
      validateStatus='' // 'success' 'warning' 'error' 'validating'
      help={touched && error ? error : ''}
    >
      <Input.Search {...input} placeholder={placeholder}{...restProps} />
    </Form.Item>
  );
};

export default InputSearchField;
