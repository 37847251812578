import type { User } from '../../schemas';
import type { GetUserAction, RadiologistIdAction } from './types';

// Action strings

export const GET_INTERNAL_RADIOLOGISTS_REQUEST =
  'GET_INTERNAL_RADIOLOGISTS_REQUEST';
export const GET_INTERNAL_RADIOLOGISTS_SUCCESS =
  'GET_INTERNAL_RADIOLOGISTS_SUCCESS';
export const GET_INTERNAL_RADIOLOGISTS_FAILED =
  'GET_INTERNAL_RADIOLOGISTS_FAILED';

export const GET_INTERNAL_RADIOLOGISTS_STATUS_REQUEST =
  'GET_INTERNAL_RADIOLOGISTS_STATUS_REQUEST';
export const GET_INTERNAL_RADIOLOGISTS_STATUS_SUCCESS =
  'GET_INTERNAL_RADIOLOGISTS_STATUS_SUCCESS';
export const GET_INTERNAL_RADIOLOGISTS_STATUS_FAILED =
  'GET_INTERNAL_RADIOLOGISTS_STATUS_FAILED';

export const GET_INTERNAL_RAD_BY_ID_REQUEST = 'GET_INTERNAL_RAD_BY_ID_REQUEST';
export const GET_INTERNAL_RAD_BY_ID_SUCCESS = 'GET_INTERNAL_RAD_BY_ID_SUCCESS';
export const GET_INTERNAL_RAD_BY_ID_FAILED = 'GET_INTERNAL_RAD_BY_ID_FAILED';

export const ADD_INTERNAL_RAD_REQUEST = 'ADD_INTERNAL_RAD_REQUEST';
export const ADD_INTERNAL_RAD_SUCCESS = 'ADD_INTERNAL_RAD_SUCCESS';
export const ADD_INTERNAL_RAD_FAILED = 'ADD_INTERNAL_RAD_FAILED';

export const EDIT_INTERNAL_RAD_REQUEST = 'EDIT_INTERNAL_RAD_REQUEST';
export const EDIT_INTERNAL_RAD_SUCCESS = 'EDIT_INTERNAL_RAD_SUCCESS';
export const EDIT_INTERNAL_RAD_FAILED = 'EDIT_INTERNAL_RAD_FAILED';

export const DELETE_INTERNAL_RAD_REQUEST = 'DELETE_INTERNAL_RAD_REQUEST';
export const DELETE_INTERNAL_RAD_SUCCESS = 'DELETE_INTERNAL_RAD_SUCCESS';
export const DELETE_INTERNAL_RAD_FAILED = 'DELETE_INTERNAL_RAD_FAILED';

export const UPDATE_LAST_INTERNAL_RADIOLOGISTS_SEARCH =
  'UPDATE_LAST_INTERNAL_RADIOLOGISTS_SEARCH';

// Action types

export type AddIRAction = { type: string, radiologist: User };
export type EditIRAction = { type: string, radiologist: User, radId: string };

// Action function

export const getInternalRadiologistsAction = (
  perPage: number,
  page: number,
  sort: string,
  query: string
): GetUserAction => ({
  type: GET_INTERNAL_RADIOLOGISTS_REQUEST,
  perPage,
  page,
  sort,
  query
});

export const getInternalRadiologistsStatusAction = () => ({ type: GET_INTERNAL_RADIOLOGISTS_STATUS_REQUEST });

export const getInternalRadiologistsStatusSuccessAction = (data) => ({ type: GET_INTERNAL_RADIOLOGISTS_STATUS_SUCCESS, data });

export const getInternalRadiologistByIdAction = (
  radId: string
): RadiologistIdAction => ({
  type: GET_INTERNAL_RAD_BY_ID_REQUEST,
  radId
});

export const addInternalRadiologistAction = (
  radiologist: User
): AddIRAction => ({
  type: ADD_INTERNAL_RAD_REQUEST,
  radiologist
});

export const editInternalRadiologistAction = (
  radiologist: User,
  radId: string
): EditIRAction => ({
  type: EDIT_INTERNAL_RAD_REQUEST,
  radiologist,
  radId
});

export const deleteInternalRadiologistAction = (
  radId: string
): RadiologistIdAction => ({
  type: DELETE_INTERNAL_RAD_REQUEST,
  radId
});
