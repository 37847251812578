const Service = authenticated => {
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_MOCK_API === '1' &&
    authenticated
  ) {
    return import('./auth/mock_service').then(mockService => {
      return mockService.default;
    });
  }
  return import('./auth/service').then(authService => {
    return authService.default;
  });
};

export default Service;
export * from './auth/auth_v2';
export * from './endpoints/external_radiologists';
export * from './endpoints/internal_radiologists';
export * from './endpoints/groups';
export * from './endpoints/sas-token';
export * from './endpoints/providers';
export * from './endpoints/users';
export * from './endpoints/country_list';
export * from './endpoints/dashboard';
export * from './endpoints/second_opinion';
export * from './endpoints/onboarding';
export * from './endpoints/facilities';
export * from './endpoints/devices';
export * from './endpoints/groupInvitation';
export * from './endpoints/referring-facilities';
export { default as forgotPasswordApiEndpoint } from './endpoints/forgot_password';
