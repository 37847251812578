import React from 'react';
import { Button, Row, Col } from 'antd';
import { reduxForm, formValueSelector } from 'redux-form';

import { connect } from 'react-redux';
import BaseDeviceForm from './BaseDeviceForm';
import deviceValidation from './deviceValidation';
import { connectionTypeOptionsWithDicomInfo } from '../../../../store/constants';
import { t } from 'i18next';

const ADD_DEVICE_FORM_NAME = 'createDeviceForm';

type Props = {
  visible: boolean,
  onCancel: () => void,
  loading: boolean,
  handleSubmit: any => void,
  pristine: boolean,
  submitting: boolean,
  connectionTypeData: string
};
const selector = formValueSelector(ADD_DEVICE_FORM_NAME);
export default connect(state => {
  return {
    showDicomInfo: connectionTypeOptionsWithDicomInfo.includes(
      selector(state, 'connectionType')
    ),
    connectionTypeData: selector(state, 'connectionType')
  };
})(
  reduxForm({
    form: ADD_DEVICE_FORM_NAME,
    validate: deviceValidation,
    enableReinitialize: true,
    destroyOnUnmount: true
  })(
    ({
      visible,
      onCancel,
      loading,
      handleSubmit,
      pristine,
      submitting,
      showDicomInfo,
      initialValues,
      connectionTypeData
    }: Props) => (
      <BaseDeviceForm
        loading={loading}
        onCancel={onCancel}
        visible={visible}
        title={t('referral_patterns.add_new_device')}
        okText='Add'
        selectedFacilities={[]}
        showDicomInfo={showDicomInfo}
        connectionTypeData={connectionTypeData}
      >
        <Row type='flex' justify='space-around' align='middle'>
          <Col span={16} style={{ textAlign: 'left' }}>
            <div style={{ marginLeft: '-70px' }}>
              <Button onClick={onCancel}>{t("button.cancel")}</Button>
              <Button
                disabled={pristine || submitting}
                type='primary'
                onClick={handleSubmit}
                loading={loading}
              >
                  {t('referral_patterns.add_new_device')}
              </Button>
            </div>
          </Col>
        </Row>
      </BaseDeviceForm>
    )
  )
);
