import moment from 'moment';
import AuthService from '../index';
import Logger from '../../utils/logger';

import type {
  // ApiService,
  Facility,
  // Provider,
  UserProfile
} from '../../schemas';
import {
  jsonToFacility,
  // jsonToProvider,
  jsonToSourceFacilitiesChart
} from '../transformers';

// const GROUP_API_SERVICE: ApiService = 'telerad-settings';
// const GROUP_BASE_ROUTE = '/group';
const API_SERVICE = 'facilities';
const BASE_ROUTE = '/facility';

export const getIngroupFacilities = async (profile: UserProfile) => {
  Logger.log('Get in group facilities called');
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, '/facilities/ingroup/', {})
    .then((r: { facilities: Array<Object> }) =>
      r.facilities.map(jsonToFacility)
    );
};

export const getExternalFacilities = async (profile: UserProfile) => {
  Logger.log('Get external facilities called');
  const Auth = await AuthService(profile.accessToken.length > 0);
  // const group: Provider = await new Auth(profile)
  //   .service(GROUP_API_SERVICE, GROUP_BASE_ROUTE, {})
  //   .then(p => jsonToProvider(p));
  // if (group.availableTo && group.availableTo.length > 0) {
  return new Auth(profile)
    .service(API_SERVICE, `/facilities/external/condensed`)
    .then((r: { facilities: Array<Object> }) =>
      r.facilities.map(jsonToFacility)
    );
  // }
  // Logger.log('Group not a provider, returning an empty facilities array');
  // return [];
};

export const getAllFacilities = async (profile: UserProfile) => {
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, BASE_ROUTE, {});
};

export const createFacility = async (profile: UserProfile, data: Facility) => { 
  let obj = {
    ...data
  }
  if (data.contact_number_1 && data.contact_number_1.phone) {
    obj={
      ...obj,
      contact_number_1:data.contact_number_1.phone
    }    
  }
  if (data.contact_number_2 && data.contact_number_2.phone) {
    obj={
      ...obj,
      contact_number_2:data.contact_number_2.phone
    }    
  }
  if (data.contact_number_3 && data.contact_number_3.phone) {
    obj={
      ...obj,
      contact_number_2:data.contact_number_3.phone
    }    
  }
  Logger.log('Create facility API endpoint called with', obj);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, BASE_ROUTE, {
    method: 'POST',
    body: JSON.stringify(obj)
  });
};

export const updateFacility = async (
  profile: UserProfile,
  data: Facility,
  id: string
) => {
  Logger.log('Update facility API endpoint called with', data);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, `${BASE_ROUTE}/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data)
  });
};

export const getFacilityById = async (profile: UserProfile, id: string) => {
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `${BASE_ROUTE}/${id}`, {})
    .then(jsonToFacility);
};

export const getSourceFacilitiesChart = async (
  profile: UserProfile,
  filters
) => {
  // const { selectedDate, facility, modality } = filters;
  const { selectedDate } = filters || {};
  let timeframe = `?timeframe=custom&from_date=${moment()
    .subtract(7, 'days')
    .format('YYYY-MM-DD')}&to_date=${moment().format('YYYY-MM-DD')}`;
  if (selectedDate && selectedDate.length === 2) {
    timeframe = `?timeframe=custom&from_date=${moment(selectedDate[0]).format(
      'YYYY-MM-DD'
    )}&to_date=${moment(selectedDate[1]).format('YYYY-MM-DD')}`;
  }
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service('dashboard', `/study-counts-per-facility/${timeframe}`, {})
    .then(jsonToSourceFacilitiesChart);
};

// export const getSourceFacilitiesChart = async (profile: UserProfile) => {
//   Logger.log('Get in source group facilities chart called');
//   // const Auth = await AuthService(profile.accessToken.length > 0);
//   return {
//     chart: [
//       {
//         date: '08-07-2021',
//         value: 0,
//         category: 'MR'
//       },
//       {
//         date: '08-07-2021',
//         value: 0,
//         category: 'MT'
//       },
//       {
//         date: '08-07-2021',
//         value: 0,
//         category: 'XR'
//       },
//       {
//         date: '08-07-2021',
//         value: 0,
//         category: 'XA'
//       },
//       {
//         date: '09-07-2021',
//         value: 10,
//         category: 'MR'
//       },
//       {
//         date: '09-07-2021',
//         value: 10,
//         category: 'MT'
//       },
//       {
//         date: '09-07-2021',
//         value: 10,
//         category: 'XR'
//       },
//       {
//         date: '09-07-2021',
//         value: 10,
//         category: 'XA'
//       },
//       {
//         date: '10-07-2021',
//         value: 10,
//         category: 'MR'
//       },
//       {
//         date: '10-07-2021',
//         value: 10,
//         category: 'MT'
//       },
//       {
//         date: '10-07-2021',
//         value: 10,
//         category: 'XR'
//       },
//       {
//         date: '10-07-2021',
//         value: 0,
//         category: 'XA'
//       },
//       {
//         date: '11-07-2021',
//         value: 30,
//         category: 'MR'
//       },
//       {
//         date: '11-07-2021',
//         value: 40,
//         category: 'MT'
//       },
//       {
//         date: '11-07-2021',
//         value: 50,
//         category: 'XR'
//       },
//       {
//         date: '11-07-2021',
//         value: 40,
//         category: 'XA'
//       }
//     ],
//     table: [
//       {
//         key: 1,
//         sourceFacility: 'Source Facility 01',
//         modality: 'All',
//         urgent: '11',
//         routine: '10',
//         children: [
//           {
//             key: 2,
//             sourceFacility: '',
//             modality: 'MR',
//             urgent: '10',
//             routine: '10'
//           }
//         ]
//       },
//       {
//         key: 4,
//         sourceFacility: 'Source Facility 02',
//         modality: 'All',
//         urgent: '10',
//         routine: '10',
//         children: [
//           {
//             key: 5,
//             sourceFacility: '',
//             modality: 'XA',
//             urgent: '10',
//             routine: '10'
//           }
//         ]
//       }
//     ]
//   };
// };
