/* eslint-disable react/no-children-prop */

import React from 'react';
import { Form, Input } from 'antd';

const FormItem = Form.Item;

const InputTextField = ({
  input,
  meta,
  children,
  hasFeedback,
  label,
  required,
  ...rest
}) => {
  const hasError = meta.touched && meta.invalid;
  return (
    <FormItem
      label={required ? <span className='label'>{label} <span style={{ color: 'red', marginLeft: 5 }}>*</span></span> : label}
      validateStatus={hasError ? 'error' : 'success'}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
    >
      <Input {...input} {...rest} children={children} />
    </FormItem>
  );
};

export default InputTextField;
