// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Divider, Form, Modal, Row, Typography } from 'antd';
import { Field } from 'redux-form';
// import ConfirmRevokeAdminRights from '../../partials/RevokeAdminRights';
import InputSelectField from '../../../../common/form-helpers/InputSelectField';
import InputField from '../../../../common/form-helpers/InputField';
import './BaseDeviceForm.less';
import type { Facility, ReduxState } from '../../../../schemas';
import {
  connectionTypeOptions,
  fullModalityOptions,
  manufacturerOptions
} from '../../../../store/constants';
import AutoCompleteField from '../../../../common/form-helpers/AutoCompleteField';

const { Title } = Typography;

const styles = {
  title: {
    fontWeight: 500,
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.65)',
    marginBottom: 14
  },
  divider: {
    margin: '0px 0px 14px'
  }
};

type Props = {
  visible: boolean,
  title: string,
  // Text of the ok button
  okText: string,
  // What to do if the cancel button is pushed. It's required by the  Modal class
  onCancel: () => void,
  isMain: boolean,
  hasEditButton: boolean,
  // Initial role for the user
  role: ?string,
  children: React.Node,
  facilities: Array<Facility>,
  selectedFacilities: Array<Facility>,
  showDicomInfo: boolean,
  connectionTypeData: string
};

/**
 * Contains the controls used to add or edit a user.
 */

export default connect(
  (state: ReduxState) => ({
    facilities: state.facilities.internalArray
  }),
  {}
)((props: Props) => {
  const {
    visible,
    onCancel,
    title,
    okText,
    children,
    hasEditButton,
    connectionTypeData
  } = props;

  // eslint-disable-next-line
  const [role, setRole] = React.useState<?string>(props.role);
  const [footerVisibility, setFooterVisibility] = React.useState<?boolean>(
    false
  );

  return (
    <Modal
      visible={visible}
      title={title}
      okText={okText}
      onCancel={onCancel}
      destroyOnClose={false}
      footer={
        (hasEditButton && footerVisibility) || !hasEditButton ? children : null
      }
      wrapClassName='lateral-modal'
    >
      <Form layout='vertical'>
        <Row gutter={32}>
          <Col xs={24} sm={12}>
            <Field
              name='connectionType'
              label='Connection Type'
              placeholder='Connection Type'
              required
              options={connectionTypeOptions}
              component={InputSelectField}
            />
          </Col>
          {connectionTypeData !== 'Imaging Modality' ? null : (
            <Col xs={24} sm={12}>
              <Field
                name='modality'
                label='Modality'
                placeholder='Modality'
                required
                options={fullModalityOptions}
                component={InputSelectField}
              />
            </Col>
          )}
        </Row>
        <Row gutter={32}>
          <Col xs={24} sm={12}>
            <Field
              name='manufacturer'
              label='Manufacturer'
              placeholder='Manufacturer'
              dataSource={manufacturerOptions.map(item => item.value)}
              component={AutoCompleteField}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Field
              name='model'
              label='Model'
              placeholder='Model'
              component={InputField}
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col xs={24} sm={12}>
            <Field
              name='nickname'
              label='Nickname or Asset Code'
              placeholder='Nickname or Asset Code'
              type='text'
              component={InputField}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Field
              name='serialNumber'
              label='Serial Number'
              placeholder='Serial Number'
              type='text'
              component={InputField}
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col xs={24} sm={12}>
            <Field
              name='inFacilityLocation'
              label='In-Facility Location'
              placeholder='In-Facility Location'
              type='text'
              component={InputField}
            />
          </Col>
        </Row>
        <Divider style={styles.divider} />
        <div>
          <Title level={4} style={styles.title}>
            DICOM Information
          </Title>
          <Row gutter={32}>
            <Col xs={24} sm={12}>
              <Field
                name='dicomAeTitle'
                label='DICOM AE Title'
                placeholder='DICOM AE Title'
                type='text'
                required
                component={InputField}
              />
            </Col>
            <Col xs={24} sm={12}>
              <Field
                name='ipAddress'
                label='IP Address'
                placeholder='IP Address'
                type='text'
                component={InputField}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col xs={24} sm={12}>
              <Field
                name='dicomPort'
                label='DICOM Port'
                placeholder='DICOM Port'
                type='text'
                component={InputField}
              />
            </Col>
          </Row>
        </div>
      </Form>
      {hasEditButton && !footerVisibility ? (
        <Row>
          <Col>
            <Button
              type='primary'
              icon='edit'
              onClick={() => setFooterVisibility(true)}
            >
              Update Details
            </Button>
          </Col>
        </Row>
      ) : null}
    </Modal>
  );
});
