import {
  GET_ACTIVE_STUDIES_REQUEST,
  GET_ACTIVE_STUDIES_SUCCESS,
  GET_ACTIVE_STUDIES_FAILURE,
  GET_FACILITIES_REQUEST,
  GET_FACILITIES_SUCCESS,
  GET_FACILITIES_FAILURE,
  GET_OVERVIEW_REQUEST,
  GET_OVERVIEW_SUCCESS,
  GET_OVERVIEW_FAILURE,
  GET_PROVIDER_PERFORMANCE_REQUEST,
  GET_PROVIDER_PERFORMANCE_SUCCESS,
  GET_PROVIDER_PERFORMANCE_FAILURE,
  GET_STUDY_DETAILS_REQUEST,
  GET_STUDY_DETAILS_SUCCESS,
  GET_STUDY_DETAILS_FAILURE,
  GET_RADIOLOGIST_PERFORMANCE_REQUEST,
  GET_RADIOLOGIST_PERFORMANCE_SUCCESS,
  GET_RADIOLOGIST_PERFORMANCE_FAILURE,
  GET_GROUP_DETAIL_REQUEST,
  GET_GROUP_DETAIL_SUCCESS,
  GET_GROUP_DETAIL_FAILURE,
  GET_MODALITIES_REQUEST,
  GET_MODALITIES_SUCCESS,
  GET_MODALITIES_FAILURE,
  GET_REPORTING_RADIOLOGISTS_REQUEST,
  GET_REPORTING_RADIOLOGISTS_SUCCESS,
  GET_REPORTING_RADIOLOGISTS_FAILURE,
  GET_SENT_TOS_REQUEST,
  GET_SENT_TOS_SUCCESS,
  GET_SENT_TOS_FAILURE
} from '../constants';

export const getActiveStudiesRequest = (filter = 'all') => ({
  type: GET_ACTIVE_STUDIES_REQUEST,
  filter
});

export const getActiveStudiesSuccess = data => ({
  type: GET_ACTIVE_STUDIES_SUCCESS,
  data
});

export const getActiveStudiesFailure = error => ({
  type: GET_ACTIVE_STUDIES_FAILURE,
  error
});

export const getFacilitiesRequest = () => ({
  type: GET_FACILITIES_REQUEST
});

export const getFacilitiesSuccess = data => ({
  type: GET_FACILITIES_SUCCESS,
  data
});

export const getFacilitiesFailure = error => ({
  type: GET_FACILITIES_FAILURE,
  error
});

export const getProviderPerformanceRequest = () => ({
  type: GET_PROVIDER_PERFORMANCE_REQUEST
});

export const getProviderPerformanceSuccess = data => ({
  type: GET_PROVIDER_PERFORMANCE_SUCCESS,
  data
});

export const getProviderPerformanceFailure = error => ({
  type: GET_PROVIDER_PERFORMANCE_FAILURE,
  error
});

export const getOverviewRequest = data => ({
  type: GET_OVERVIEW_REQUEST,
  data
});

export const getOverviewSuccess = data => ({
  type: GET_OVERVIEW_SUCCESS,
  data
});

export const getOverviewFailure = error => ({
  type: GET_OVERVIEW_FAILURE,
  error
});

export const getStudyDetailsRequest = timeFrame => ({
  type: GET_STUDY_DETAILS_REQUEST,
  timeFrame
});

export const getStudyDetailsSuccess = data => ({
  type: GET_STUDY_DETAILS_SUCCESS,
  data
});

export const getStudyDetailsFailure = error => ({
  type: GET_STUDY_DETAILS_FAILURE,
  error
});

export const getRadiologistPerformanceRequest = timeFrame => ({
  type: GET_RADIOLOGIST_PERFORMANCE_REQUEST,
  timeFrame
});

export const getRadiologistPerformanceSuccess = data => ({
  type: GET_RADIOLOGIST_PERFORMANCE_SUCCESS,
  data
});

export const getRadiologistPerformanceFailure = error => ({
  type: GET_RADIOLOGIST_PERFORMANCE_FAILURE,
  error
});

export const getGroupDetailRequest = () => ({
  type: GET_GROUP_DETAIL_REQUEST
});

export const getGroupDetailSuccess = data => ({
  type: GET_GROUP_DETAIL_SUCCESS,
  data
});

export const getGroupDetailFailure = error => ({
  type: GET_GROUP_DETAIL_FAILURE,
  error
});

export const getModalitiesRequest = () => ({
  type: GET_MODALITIES_REQUEST
});

export const getModalitiesSuccess = data => ({
  type: GET_MODALITIES_SUCCESS,
  data
});

export const getModalitiesFailure = error => ({
  type: GET_MODALITIES_FAILURE,
  error
});

export const getReportingRadiologistRequest = () => ({
  type: GET_REPORTING_RADIOLOGISTS_REQUEST
});

export const getReportingRadiologistSuccess = data => ({
  type: GET_REPORTING_RADIOLOGISTS_SUCCESS,
  data
});

export const getReportingRadiologistFailure = error => ({
  type: GET_REPORTING_RADIOLOGISTS_FAILURE,
  error
});

export const getSentTosRequest = () => ({
  type: GET_SENT_TOS_REQUEST
});

export const getSentTosSuccess = data => ({
  type: GET_SENT_TOS_SUCCESS,
  data
});

export const getSentTosFailure = error => ({
  type: GET_SENT_TOS_FAILURE,
  error
});
