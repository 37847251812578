import React, { useState } from 'react';
import { Col, Form, Modal, Row, Select } from 'antd';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { forEach } from 'lodash';
import { isValidUuid } from '@azure/ms-rest-js';
import {
  InputPhoneNumberField,
  InputTextField
} from '../../../components/FormParts';
import SelectField from '../../../components/FormParts/SelectField';
import { PHYSICIAN_SPECIALTY } from '../../../utils/filters';
import { selectFieldFilterHandler, setFieldOptions } from '../../../utils';
import AddInternalFacility from './facilities/AddInternalFacility';
import AddExternalFacility from './facilities/AddExternalFacility';
import MultiSelectField from '../../../components/FormParts/MultiSelectField';

export const valuesToPhysician = (values, facilities) => {
  const value = values.primaryFacility && values.primaryFacility[values.primaryFacility.length - 1];
  return {
    ...values,
    phone: values.phone ? values.phone.phone : undefined,
    primaryFacility:
      values.primaryFacility && values.primaryFacility.length
        ? {
            name: value,
            guid: isValidUuid(value)
              ? value
              : facilities.find(f => f.name === value).guid
          }
        : undefined
  };
};

export default connect(state => {
  const { facilities } = state;
  const internalFacilities = facilities.internalArray;
  const externalFacilities = facilities.externalArray;

  return {
    internalFacilities,
    externalFacilities
  };
}, {})(
  ({
    title,
    okText,
    data,
    visible,
    onCancel,
    change,
    children,
    isInternal,
    internalFacilities,
    externalFacilities
  }) => {
    const [facilityModal, openFacilityModal] = useState(false);
    const { specialty } = data || {};

    const prepareOptions = () => {
      const externalSourceFacilities = externalFacilities;
      const internalSourceFacilities = internalFacilities;

      const optionsArray = [];

      const listOfFacilityNames = [];
      if (isInternal) {
        const internalOptions = {
          key: 'aaa',
          value: 'internal-facility-guid',
          label: 'My Facilities',
          children: []
        };
        if (internalSourceFacilities && internalSourceFacilities.length > 0) {
          forEach(internalSourceFacilities, ({ name }, key) => {
            listOfFacilityNames.push(name);
            internalOptions.children.push({ key, label: name, value: name });
          });
          optionsArray.push(internalOptions);
        }
      } else {
        const externalOptions = {
          key: 'bbb',
          value: 'external-facility-guid',
          label: 'Other Facilities',
          children: []
        };
        if (externalSourceFacilities && externalSourceFacilities.length > 0) {
          forEach(externalSourceFacilities, ({ name }, key) => {
            listOfFacilityNames.push(name);
            externalOptions.children.push({ key, label: name, value: name });
          });
          optionsArray.push(externalOptions);
        }
      }

      optionsArray.push({
        key: 'eee',
        value: 'add-new-facilities',
        label: 'Add New Facility',
        children: []
      });

      return optionsArray;
    };

    return (
      <Modal
        visible={visible}
        title={title}
        okText={okText}
        onCancel={onCancel}
        destroyOnClose={false}
        footer={children}
      >
        <Form layout='vertical'>
          <Row gutter={32}>
            <Col className='gutter-row' span={12}>
              <Field
                name='name'
                label='Name'
                placeholder='Name'
                component={InputTextField}
              />
            </Col>
            <Col className='gutter-row' span={12}>
              {specialty && specialty === 'Custom' ? (
                <Field
                  name='custom_specialty'
                  label='Specialty'
                  placeholder='Specialty'
                  component={InputTextField}
                />
              ) : (
                <Field
                  name='specialty'
                  label='Specialty'
                  placeholder='Please select...'
                  component={SelectField}
                  componentProps={{
                    filterOption: selectFieldFilterHandler,
                    optionType: Select.Option,
                    options: setFieldOptions(PHYSICIAN_SPECIALTY),
                    showSearch: true
                  }}
                  fieldProps={{
                    targetType: 'SelectField'
                  }}
                />
              )}
            </Col>
          </Row>
          <Row gutter={32}>
            <Col className='gutter-row' span={12}>
              <Field
                name='email'
                label='Email'
                type='email'
                placeholder='mail@domain.com'
                component={InputTextField}
              />
            </Col>
            <Col className='gutter-row' span={12}>
              <Field
                name='phone'
                label='Mobile Number (Optional)'
                component={InputPhoneNumberField}
                countryCodeEditable={false}
                defaultCountry='sg'
                disableAreaCodes
              />
            </Col>
          </Row>
          <Row type='flex' align='middle' gutter={32}>
            <Col className='gutter-row' span={24}>
              <Field
                name='primaryFacility'
                label='Facility'
                component={MultiSelectField}
                componentProps={{
                  placeholder: 'Set the Facility',
                  displayRender: label => label[label.length - 1],
                  expandTrigger: 'hover',
                  searchFilter: true,
                  onChange: changeVal => {
                    if (changeVal.includes('add-new-facilities')) {
                      openFacilityModal(true);
                    } else {
                      change('primaryFacility', changeVal);
                    }
                  },
                  options: prepareOptions()
                  // disabled: isStudyLoading
                }}
                change={change}
                fieldProps={{
                  targetType: 'CascaderField'
                }}
              />
            </Col>
          </Row>
          {facilityModal &&
            (isInternal ? (
              <AddInternalFacility
                onClose={() => openFacilityModal(false)}
                visible={facilityModal}
              />
            ) : (
              <AddExternalFacility
                onClose={() => openFacilityModal(false)}
                visible={facilityModal}
              />
            ))}
        </Form>
      </Modal>
    );
  }
);
