import {
  GET_SECOND_OPINION_SETTING_SUCCESS,
  UPDATE_SECOND_OPINION_SETTING_SUCCESS
} from '../actions';
import initialState from '../state';

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SECOND_OPINION_SETTING_SUCCESS:
      return {
        ...state,
        secondOpinionSetting: action.secondOpinionSetting
      };
    case UPDATE_SECOND_OPINION_SETTING_SUCCESS:
      return {
        ...state,
        secondOpinionSetting: action.secondOpinionSetting
      };
    default:
      return state;
  }
};
