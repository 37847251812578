// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Modal, Spin } from 'antd';
import {
  editPhysicianAction,
  loadEditPhysicianForm
} from '../../../store/actions';
import EditPhysicianForm from './EditPhysicianForm';
import type { Physician, ReduxState } from '../../../schemas';
import type { PhysicianFormValues } from './PhysicianForm';
import { valuesToPhysician } from './PhysicianForm';

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  form: Object,
  loading: boolean,
  load: boolean,
  physician: Physician,
  editAction: Physician => void,
  loadForm: Physician => void,
  // facilities: Array<Facility>,
  // Pass it
  onCancel: () => void,
  visible: boolean
};

type State = {
  physician: Physician
};

export default connect(
  (state: ReduxState) => ({
    form: state.form.editPhysicianForm,
    modifications: state.physicians.modifications,
    physician: state.physicians.current,
    load: state.loading.getPhysicianById,
    loading: state.loading.updatePhysician,
    internalFacilities: state.facilities.internalArray,
    externalFacilities: state.facilities.externalArray
  }),
  {
    editAction: editPhysicianAction,
    loadForm: loadEditPhysicianForm
  }
)(
  class EditPhysician extends React.Component<Props, State> {
    state = {
      physician: {},
      modifications: this.props.modifications
    };

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
      if (nextProps.physician.guid !== prevState.physician.guid) {
        return { physician: nextProps.physician };
      }
      if (nextProps.modifications > prevState.modifications) {
        return { modifications: nextProps.modifications };
      }
      return null;
    }

    componentDidMount(): void {
      if (Object.entries(this.state.physician).length !== 0) {
        this.updatePhysician();
      }
    }

    componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
      // eslint-disable-next-line react/destructuring-assignment
      if (prevState.physician.guid !== this.state.physician.guid) {
        this.updatePhysician();
      }
      const { onCancel, loadForm } = this.props;
      if (prevState.modifications < this.state.modifications) {
        loadForm({});
        onCancel();
      }
    }

    updatePhysician = (): PhysicianFormValues =>
      this.props.loadForm({
        name: this.props.physician.name,
        email: this.props.physician.email,
        phone: this.props.physician.phone,
        primaryFacility: this.props.physician.primaryFacility
          ? this.props.physician.primaryFacility.name
          : null,
        specialty: this.props.physician.specialty
      });

    handleEdit = async values => {
      const { internalFacilities, externalFacilities } = this.props;
      this.props.editAction({
        ...valuesToPhysician(values, [
          ...internalFacilities,
          ...externalFacilities
        ]),
        guid: this.props.physician.guid
      });
    };

    renderWindow = () => {
      const { isInternal, loading, visible, onCancel, load } = this.props;
      const { physician } = this.props;
      return Object.keys(physician).length === 0 || load ? (
        <Modal
          visible={visible}
          title='Edit Referring Radiologist'
          okText='Edit'
          footer={null}
          onCancel={onCancel}
        >
          <div style={{ textAlign: 'center' }}>
            <Spin size='large' />
          </div>
        </Modal>
      ) : (
        <EditPhysicianForm
          visible={visible}
          loading={loading}
          isInternal={isInternal}
          onCancel={onCancel}
          onSubmit={this.handleEdit}
        />
      );
    };

    render() {
      const { visible } = this.props;
      return visible ? this.renderWindow() : null;
    }
  }
);
