import * as React from 'react';
import { connect } from 'react-redux';

import {
  getPhysicianByIdAction,
  deletePhysicianAction,
  getAllExternalPhysiciansAction
} from '../../../store/actions';

import Table from '../../../components/Table';
import ActionLink from '../../../components/ActionLink';
import TableMenu from './TableMenu';
import ViewPhysician from './ViewPhysician';
import { confirmDeletion } from '../../../components/confirmations';
import { Empty } from 'antd';
import { t } from 'i18next';

export default connect(
  state => ({
    data: state.externalPhysicians.array,
    loading: state.loading.getPhysicians,
    modifications: state.physicians.modifications
  }),
  {
    getPhysicians: getAllExternalPhysiciansAction,
    getPhysician: getPhysicianByIdAction,
    deletePhysician: deletePhysicianAction
  }
)(
  ({
    data,
    loading,
    modifications,
    getPhysicians,
    getPhysician,
    deletePhysician
  }) => {
    const [showViewPhysician, setShowViewPhysician] = React.useState(false);
    const [editVisible, setEditVisible] = React.useState(false);
    const openEdit = () => setEditVisible(true);
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) =>
          a.name
            .toString()
            .toLowerCase()
            .localeCompare(b.name.toString().toLowerCase()),
        columnSearch: true,
        align: 'left'
      },
      {
        title: 'Facility',
        dataIndex: 'primaryFacility',
        sorter: (a, b) =>
          (a.primaryFacility && a.primaryFacility.name
            ? a.primaryFacility.name
            : ''
          )
            .toString()
            .toLowerCase()
            .localeCompare(
              (b.primaryFacility && b.primaryFacility.name
                ? b.primaryFacility.name
                : ''
              )
                .toString()
                .toLowerCase()
            ),
        render: (text, record) =>
          record.primaryFacility && record.primaryFacility.name
            ? record.primaryFacility.name
            : '-',
        align: 'left'
      },
      {
        title: 'Specialty',
        dataIndex: 'specialty',
        sorter: (a, b) =>
          (a.specialty && a.specialty.length > 0 && a.specialty[0].name
            ? a.specialty[0].name
            : '-'
          )
            .toString()
            .toLowerCase()
            .localeCompare(
              (b.specialty && b.specialty.length > 0 && b.specialty[0].name
                ? b.specialty[0].name
                : '-'
              )
                .toString()
                .toLowerCase()
            ),
        render: (text, record) =>
          record.specialty &&
          record.specialty.length > 0 &&
          record.specialty[0].name
            ? record.specialty[0].name
            : '-',
        align: 'left'
      },
      {
        title: '',
        key: 'view',
        render: (text, record) => (
          <React.Fragment>
            <ActionLink click={() => setShowViewPhysician(true)} text='View' />
            <ViewPhysician
              data={record}
              handleCancel={() => {
                setShowViewPhysician(false);
                setEditVisible(false);
              }}
              handleDelete={() =>
                confirmDeletion(
                  deletePhysician,
                  record.guid,
                  record.name,
                  'referring physician'
                )
              }
              handleEdit={openEdit}
              visible={showViewPhysician}
            />
          </React.Fragment>
        )
      },
      {
        title: '',
        key: 'action',
        width: 50,
        render: (text, record) => (
          <TableMenu
            getAction={getPhysician}
            deleteAction={deletePhysician}
            record={record}
            editVisible={editVisible}
            hideEdit={() => {
              setShowViewPhysician(false);
              setEditVisible(false);
            }}
            showEdit={() => setEditVisible(true)}
          />
        )
      }
    ];
    const rowKey = record => record.guid;

    return (
      <Table
        columns={columns}
        data={data}
        modifications={modifications}
        rowKey={rowKey}
        loading={loading}
        getAction={getPhysicians}
        locale={{
          emptyText: (
            <Empty
              description={t("table.no_data")}
            />
          ),
        }}
      />
    );
  }
);
