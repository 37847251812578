/* eslint-disable camelcase */

export default values => {
  const errors = {};
  const { name, email, userType, admin } = values;
  if (!name) {
    errors.name = 'Required';
  }
  // if (!homeFacility) {
  //   errors.homeFacility = 'Required';
  // }
  if (!userType) {
    errors.userType = 'Required';
  }
  if (!admin) {
    errors.admin = 'Required';
  }
  if (!email) {
    errors.email = 'Required';
  }
  if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    errors.email = 'Invalid email';
  }
  return errors;
};
