/* eslint-disable camelcase */

export default values => {
  const errors = {};
  const { name, email, userType, admin, availableForAssignment } = values;
  if (!name) {
    errors.name = 'Required';
  }
  // if (!homeFacility) {
  //   errors.homeFacility = 'Required';
  // }
  if (!userType) {
    errors.userType = 'Required';
  }
  if (!admin) {
    errors.admin = 'Required';
  }
  if (!email) {
    errors.email = 'Required';
  }
  if (!availableForAssignment) {
    errors.availableForAssignment = 'Required';
  }  
  return errors;
};
