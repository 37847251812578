import * as React from 'react';
import { Field } from 'redux-form';
import { Col, Form, Modal, Row } from 'antd';

import {
  InputPhoneNumberField,
  InputSelectField,
  InputTextField
} from '../../../../components/FormParts';
import { STUDIES /* , countryFilter */ } from '../../../../utils/filters';
import { transformCountries } from '../../../../utils';
import { t } from 'i18next';

type Props = {
  visible: boolean,
  title: string,
  okText: string,
  onCancel: () => void,
  children: React.Node
};

export default ({
  title,
  okText,
  visible,
  onCancel,
  countryList,
  children
}: Props) => {
  const [studiesOpen, openStudies] = React.useState(false);
  const [countriesOpen, openCountries] = React.useState(false);
  const [countries /* , setCountries */] = React.useState(
    transformCountries(countryList)
  );
  return (
    <Modal
      visible={visible}
      title={title}
      okText={okText}
      onCancel={onCancel}
      destroyOnClose={false}
      footer={children}
    >
      <Form layout='vertical'>
        <Row gutter={32}>
          <Col className='gutter-row' span={12}>
            <Field
              name='name'
              label={t("manage_radiologists.form.provider_name")}
              placeholder='Name'
              component={InputTextField}
            />
          </Col>
          <Col className='gutter-row' span={12}>
            <Field
              name='studyAccess'
              label={t("manage_radiologists.form.study_access")}
              placeholder='Please select...'
              component={InputSelectField(
                STUDIES,
                studiesOpen,
                () => openStudies(true),
                () => openStudies(false)
              )}
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col className='gutter-row' span={12}>
            <Field
              name='country'
              label={t("manage_radiologists.form.country")}
              placeholder='Please select...'
              component={InputSelectField(
                countries,
                countriesOpen,
                () => openCountries(true),
                () => openCountries(false)
              )}
            />
          </Col>
          <Col className='gutter-row' span={12}>
            <Field
              name='primaryContact'
              label={t('manage_radiologists.table.primary_contact')}
              placeholder='Name'
              component={InputTextField}
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col className='gutter-row' span={12}>
            <Field
              name='email'
              label={t('user_administration.field.email_adress')}
              type='email'
              placeholder='mail@domain.com'
              component={InputTextField}
            />
          </Col>
          <Col className='gutter-row' span={12}>
            <Field
              name='phoneNumber'
              label={t('user_administration.field.phone_number')}
              component={InputPhoneNumberField}
              defaultCountry='sg'
              disableAreaCodes
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
