import store from 'store';
import { newBodyPartsOptions, newModalityOptions } from './constants';

export default {
  countryList: [],
  initialForm: {
    editUser: {},
    settingsAccount: {},
    settingsGroup: {},
    settingsNotifications: {},
    settingsPrint: {},
    editExternalRad: {},
    editInternalRad: {
      userType: 'Radiologist'
    },
    editProvider: {},
    addExternalRad: {},
    addProvider: {},
    addUser: {
      groupFacilities: []
    },
    addInternalRad: {
      groupFacilities: [],
      userType: 'Radiologist'
    },
    addInternalFacility: {},
    addExternalFacility: {},
    editInternalFacility: {},
    addPhysician: {},
    editPhysician: {},
    addFacility: {},
    editFacility: {}
  },
  form: {},
  aspect: {
    collapsed: store.get('collapsed') || false
  },
  loading: {
    global: false,
    login: false,
    changePlan: false,
    getUsers: false,
    getUserById: false,
    createUser: false,
    updateUser: false,
    updateSettings: false,
    externalRads: false,
    internalRads: false,
    externalRad: false,
    internalRad: false,
    addExternalRad: false,
    addInternalRad: false,
    editExternalRad: false,
    editInternalRad: false,
    deleteExternalRad: false,
    deleteInternalRad: false,
    providers: false,
    provider: false,
    addProvider: false,
    deleteProvider: false,
    requestProvider: false,
    facilities: false,
    facility: false,
    addFacility: false,
    editFacility: false,
    deleteFacility: false,
    getPhysicians: false,
    getPhysicianById: false,
    createPhysician: false,
    updatePhysician: false,
    deletePhysician: false,
    upgradePlan: false,
    getPortal: false,
    setBankPayment: false,
    getStripeData: false,
    createStripeSession: false,
    secondOpinion: false,
    onboarding: false,
    getSourceFacilitiesChart: false,
    getSourceReferringFacilitiesChart: false,
    getSourceReferringPhysiciansChart: false,
    getRouteList: false,
    getRoute:false,
    deleteRoute: false,
    addRouting: false,
    editRouting: false,
    deleteRouting: false,
    patchUserSignature: false,
  },
  profile: {
    ...(store.get('profile') || {
      name: '',
      email: '',
      avatar: '',
      id: '',
      accessToken: '',
      idToken: '',
      logins_count: 0,
      userMetadata: {
        name: '',
        phoneNumber: '',
        prefix: '',
        userType: '',
        homeFacility: '',
        specialty: [],
        signature: '',
        title: '',
        language: ''
      },
      appMetadata: {
        affiliatedGroup: '',
        admin: '0',
        main: '0',
        approvedFacilities: [],
        associatedGroups: []
      },
      userMetadata: {
        userType: '',
      }
    }),
    azure: '',
    notificationSettings: {
      newStudy: ['1', '0', '1'],
      urgentStudy: ['1', '1', '1'],
      SLANearing: ['0', '0', '0'],
      reportFinalised: ['1', '0', '0'],
      secondOpinion: ['1', '0', '1'],
      codeBlue: ['1', '1', '1']
    },
    notifications: [
      {
        title: 'Your subscriptions has an actualization.',
        date: new Date(Date.now() - 1000000)
      }
    ]
  },
  group: {
    name: '',
    location: '',
    officeNumber: '',
    emailAddress: '',
    website: '',
    logo: '',
    previousLogo: ''
  },
  users: {
    array: [],
    total: 0,
    current: {},
    modifications: 0,
    currentSearch: null,
    errors: [],
    all: []
  },
  externalRadiologists: {
    array: [],
    total: 0,
    current: {},
    modifications: 0,
    currentSearch: null,
    errors: []
  },
  internalRadiologists: {
    array: [],
    total: 0,
    current: {},
    modifications: 0,
    currentSearch: null,
    errors: []
  },
  providers: {
    array: [],
    current: {},
    modifications: 0,
    emailsSent: 0,
    currentSort: '',
    errors: []
  },
  facilities: {
    externalArray: [],
    internalArray: [],
    current: {},
    modifications: 0,
    errors: [],
    logo: '',
    previousLogo: '',
    chart: {},
    newlyCreated: {},
  },
  plan: {
    paymentInformation: {
      billingAddress: {
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        phone: '',
        line1: '',
        line2: '',
        line3: '',
        city: '',
        stateCode: '',
        state: '',
        zip: '',
        country: ''
      },
      type: ''
    },
    subscriptions: [],
    addons: [],
    planSituation: {
      renewalDate: '',
      periodicity: '',
      firstDayPeriod: '',
      lastDayPeriod: '',
      totalPaymentPeriod: 0,
      currency: '',
      balance: 0
    },
    errors: [],
    invoiceAmount: 0,
    modifications: 0,
    notifySubscriptionMigration: true,
  },
  payments: {
    data: {
      loaded: false,
      balance: 0,
      renewal: new Date(Date.now()),
      currency: '',
      invoices: {
        data: [],
        hasMore: false
      },
      subscriptions: {
        main: {
          id: '',
          name: '',
          description: '',
          unitAmount: 0,
          currency: '',
          interval: '',
          intervalCount: 0,
          start: new Date(Date.now()),
          quantity: 0,
          latestInvoice: '',
          startDate: new Date(Date.now()),
          currentPeriodStart: new Date(Date.now()),
          currentPeriodEnd: new Date(Date.now())
        },
        addOns: []
      }
    },
    invoices: {
      data: [],
      hasMore: false
    }
  },
  physicians: {
    array: [],
    current: {},
    modifications: 0
  },
  dashboard: {
    modalities: {
      list: []
    },
    reportingRadiologists: {
      list: []
    },
    sentTos: {
      list: []
    },
    activeStudies: {
      list: []
    },
    facilities: {
      list: [],
      statusList: []
    },
    providerPerformance: {
      list: []
    },
    overview: {
      study: {
        created: 0,
        stored: 0
      },
      report: {
        ready: 0,
        draft: 0,
        preliminary: 0,
        final: 0
      }
    },
    studyDetails: {
      modalityCount: [],
      studyCount: []
    },
    radiologistPerformance: {
      list: []
    },
    groupDetail: {},
    errors: []
  },
  secondOpinion: {
    secondOpinionSetting: {}
  },
  location: null,
  devices: {
    array: [],
    total: 0,
    current: {},
    modifications: 0,
    currentSearch: null,
    errors: [],
    all: [],
    devicesStatus: {}
  },
  referringFacilities: {
    data: [],
    list: [],
    current: {},
    modifications: 0,
    errors: [],
    chart: {}
  },
  referringPhysicians: {
    data: [],
    list: [],
    current: {},
    modifications: 0,
    errors: [],
    chart: {}
  },
  route: {
    list: [],   
    current: {}, 
    scheduleList: [],
    bodyPartOptions: newBodyPartsOptions,
    modalityOptions: newModalityOptions,
  }
};
