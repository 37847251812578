/* eslint-disable react/sort-comp */
/* eslint-disable no-unused-vars */
// @flow

import * as React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, Drawer, Modal, Row, Col, Button } from 'antd';
import { enquireScreen, unenquireScreen } from 'enquire-js';
import i18next, { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import store from 'store';
import { AppLayout } from '../components';
import manageSubscriptionTutorial from '../assets/videos/manage-subscription-tutorial.webm';

import { config, pathMatchRegexp } from '../utils';
import Error from '../pages/404';
import { routeList, getRoutes } from '../utils/routes';
import {
  disableNotifySubMigration,
  getCountryListRequest,
  getExternalFacilitiesAction,
  getInternalFacilitiesAction,
  getReferringFacilitiesListAction,
  getStripeSessionAction,
  handleCollapseChange,
  updateAccountSettings
} from '../store/actions';

import './PrimaryLayout.less';
import { getHasShownSubscriptionMigration } from '../store/storage';
import { isFeatureEnabled } from '../store/helpers';

const { Content, Footer } = Layout;
const { Header, Bread, Sider } = AppLayout;

export default withRouter(
  connect(
    state => ({
      user: state.profile,
      collapsed: state.aspect.collapsed,
      token: state.profile.tokem,
      notifySubscriptionMigration: state.plan.notifySubscriptionMigration
    }),
    {
      onCollapseChange: handleCollapseChange,
      getCountryList: getCountryListRequest,
      getInternalFacilities: getInternalFacilitiesAction,
      getExternalFacilities: getExternalFacilitiesAction,
      getReferringFacilities: getReferringFacilitiesListAction,
      updateAccountSettingsAction: updateAccountSettings,
      disableNotifySubMigration,
      getStripeSessionAction,
    }
  )(withTranslation()(
    class PrimaryLayout extends React.PureComponent {
      constructor(props) {
        super(props);
        this.state = { isMobile: false, hasShownSubscriptionMigration: getHasShownSubscriptionMigration() };
        this.handleCloseNotifyModal = this.handleCloseNotifyModal.bind(this);
        this.handleOkModal = this.handleOkModal.bind(this);
      }
      enquireHandler;

      componentDidMount() {
        const {
          getInternalFacilities,
          getExternalFacilities,
          getReferringFacilities,
          getCountryList,
          token,
          user
        } = this.props;

        const {
          userMetadata: { userType, language, credentials, name, title, email }, accessToken
        } = user;
        const locale =  store.get("telerad-setting-portal-langSelected");

        if (userType && userType.toLowerCase() === 'independent radiologist') {
          window.location.href = 'https://form.typeform.com/to/Zd5YxR5r';
        }

        this.enquireHandler = enquireScreen(mobile => {
          const { isMobile } = this.state;
          if (isMobile !== mobile) {
            this.setState({
              isMobile: mobile
            });
          }
        });
        const primaryData = {
          credentials,
          email,
          language,
          name,
          title,
          userType
        }
        if(accessToken) {
          if( locale &&  language !== locale){
            i18next.changeLanguage(locale);
            this.props.updateAccountSettingsAction({ ...primaryData ,language: locale})
          }else if(language){
            i18next.changeLanguage(language);
            store.set("telerad-setting-portal-langSelected", language);
          }
        }
        
        getInternalFacilities();
        getExternalFacilities();
        getReferringFacilities();
        getCountryList();
      }

      componentWillUnmount() {
        unenquireScreen(this.enquireHandler);
      }

      // eslint-disable-next-line react/destructuring-assignment
      onCollapseChange = collapsed => this.props.onCollapseChange(collapsed);

      handleCloseNotifyModal() {
        this.props.disableNotifySubMigration();
      }

      handleOkModal() {
        this.props.getStripeSessionAction()
        this.handleCloseNotifyModal()
      }

      render() {
        const {
          user,
          onCollapseChange,
          collapsed,
          location,
          children,
          noTitle
        } = this.props;
        const { notifications } = user;
        const {
          appMetadata: { admin }
        } = user;
        const permissions = getRoutes(admin);

        const { isMobile } = this.state;

        // Find a route that matches the pathname.
        const currentRoute = routeList.find(
          _ => _.route && pathMatchRegexp(_.route, location.pathname)
        );

        // Query whether you have permission to enter this page
        const hasPermission = currentRoute
          ? permissions.includes(currentRoute.id)
          : true;

        // MenuParentId is equal to -1 is not a available menu.
        let menus = routeList.filter(route => route.menuParentId !== '-1');
        if (!isFeatureEnabled("admin-routine")) {
          menus =  routeList.filter(route => !["12", "13", "14"].includes(route.id));          
        }
        const newMenu = menus.map(item => {
          return {
            ...item,
            name: t(`side_bar.${item.name}`)
          };
        });

        const headerProps = {
          menus: newMenu,
          collapsed,
          notifications,
          onCollapseChange,
          avatar: user.avatar,
          username: user.name,
          fixed: config.interface.fixedHeader
        };
        const siderProps = {
          menus: newMenu,
          isMobile,
          collapsed,
          onCollapseChange
        };

        return (
          <>
            <Modal
              title={t('plan_usage.migration_modal_title')}
              visible={this.props.notifySubscriptionMigration && !this.state.hasShownSubscriptionMigration}
              destroyOnClose={true}
              onCancel={this.handleCloseNotifyModal}
              footer={[
                <Button key="submit" type="primary" onClick={this.handleOkModal}>
                  {t('plan_usage.manage_subscription')}
                </Button>,
              ]}
            >
              <Row gutter={[24, 24]}>
                <Col>{t('plan_usage.migration_notify_top')}</Col>
                <Col>
                  <video src={manageSubscriptionTutorial} loop autoPlay muted playsInline width="100%">
                    Sorry, your browser doesn't support embedded videos.
                  </video>
                </Col>
                <Col>{t('plan_usage.migration_notify_bottom')}</Col>
              </Row>
            </Modal>
            <Layout style={{ background: 'white' }}>
              {isMobile ? (
                <Drawer
                  // maskClosable
                  closable={false}
                  onClose={onCollapseChange.bind(this, !collapsed)} // eslint-disable-line react/jsx-no-bind
                  visible={!collapsed}
                  placement='left'
                  // width={200}
                  bodyStyle={{
                    padding: 0,
                    height: '100vh'
                  }}
                  style={{
                    padding: 0,
                    height: '100vh'
                  }}
                >
                  <Sider {...siderProps} collapsed={false} />
                </Drawer>
              ) : (
                <Sider {...siderProps} />
              )}
              <Layout style={{ marginTop: 60, background: 'white' }}>
                <Header {...headerProps} />
                <Content className={`content ${location.pathname.includes('user') ? "white-background" : ""}`}>
                  {noTitle ? null : <Bread routeList={routeList} />}
                  {hasPermission ? children : <Error />}
                </Content>
                {!location.pathname.includes("create-route") && (
                  <Footer style={{ textAlign: 'center' }}>
                    Copyright © AlemHealth {moment().year()}
                    <div style={{ fontSize: '12px' }}>
                      version {process.env.REACT_APP_VERSION}
                    </div>
                  </Footer>
                )}
              </Layout>
            </Layout>
          </>
        );
      }
    }
  )
));
