import React from 'react';
import { Form, DatePicker } from 'antd';
import moment from 'moment';
import { t } from 'i18next';

const DateRangePickerField = ({
  labelStyle = {},
  label = '',
  colon = false,
  fullWidth = false,
  width = '',
  input: { value, onChange, onBlur },
  meta: { touched, error },
  format = 'DD/MM/YYYY',
  ...restProps
}) => {
  const { RangePicker } = DatePicker;

  const disabledDate = current => {
    if (!value || value.length === 0) {
      return false;
    }
    // const tooLate = value[0] && current.diff(value[0], 'days') > 30;
    // const tooEarly = value[1] && value[1].diff(current, 'days') > 30;
    const isFuture = current.isAfter(moment());
    return isFuture;
  };

  return (
    <Form.Item
      style={labelStyle}
      colon={colon}
      label={label}
      validateStatus='' // 'success' 'warning' 'error' 'validating'
      help={touched && error ? error : ''}
    >
      <RangePicker
        style={{ width: fullWidth ? '100%' : width }}
        value={value && value.length === 2 ? [moment(value[0]), moment(value[1])] : [moment().subtract(1, 'month'), moment()]}
        format={format}
        defaultPickerValue={[moment().subtract(1, 'month'), moment()]}
        onFocus={e => e.preventDefault()}
        onBlur={e => e.preventDefault()}
        disabledDate={disabledDate}
        // eslint-disable-next-line no-shadow
        onCalendarChange={onChange}
        placeholder={[t('table.start_date'), t('table.end_date')]}
        {...restProps}
      />
    </Form.Item>
  );
};

export default DateRangePickerField;
