import { isValidUuid } from '.';

const chartDataConverter = (data, key, state) => {
  if (!data) return [];
  if (!state.facilities.externalArray && !state.facilities.internalArray)
    return data;

  return data.map(item => {
    if (!isValidUuid(item[key])) return item;

    const intArray = state.facilities.internalArray.find(
      i => i.guid === item[key]
    );
    if (intArray) {
      item[key] = intArray.name;
      item.facilityType = intArray.facilityType;
    }
    const extArray = state.facilities.externalArray.find(
      i => i.guid === item[key]
    );
    if (extArray) {
      item[key] = extArray.name;
      item.facilityType = extArray.facilityType;
    }
    return item;
  });
};

export default chartDataConverter;
