// Action strings

export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILED = 'CREATE_CUSTOMER_FAILED';

export const GET_PLAN_INFORMATION_REQUEST = 'GET_PLAN_INFORMATION_REQUEST';
export const GET_PLAN_INFORMATION_SUCCESS = 'GET_PLAN_INFORMATION_SUCCESS';
export const GET_PLAN_INFORMATION_FAILED = 'GET_PLAN_INFORMATION_FAILED';

export const CHANGE_CARD_INFORMATION_REQUEST =
  'CHANGE_CARD_INFORMATION_REQUEST';
export const CHANGE_CARD_INFORMATION_SUCCESS =
  'CHANGE_CARD_INFORMATION_SUCCESS';
export const CHANGE_CARD_INFORMATION_FAILED = 'CHANGE_CARD_INFORMATION_FAILED';

export const CHANGE_BILLING_ADDRESS_REQUEST = 'CHANGE_BILLING_ADDRESS_REQUEST';
export const CHANGE_BILLING_ADDRESS_SUCCESS = 'CHANGE_BILLING_ADDRESS_SUCCESS';
export const CHANGE_BILLING_ADDRESS_FAILED = 'CHANGE_BILLING_ADDRESS_FAILED';

export const UPGRADE_PLAN_REQUEST = 'UPGRADE_PLAN_REQUEST';
export const UPGRADE_PLAN_SUCCESS = 'UPGRADE_PLAN_SUCCESS';
export const UPGRADE_PLAN_FAILED = 'UPGRADE_PLAN_FAILED';

export const UPGRADE_HARDWARE_REQUEST = 'UPGRADE_HARDWARE_REQUEST';
export const UPGRADE_HARDWARE_SUCCESS = 'UPGRADE_HARDWARE_SUCCESS';
export const UPGRADE_HARDWARE_FAILED = 'UPGRADE_HARDWARE_FAILED';

export const GET_PORTAL_URL_REQUEST = 'GET_PORTAL_URL_REQUEST';
export const GET_PORTAL_URL_SUCCESS = 'GET_PORTAL_URL_SUCCESS';
export const GET_PORTAL_URL_FAILED = 'GET_PORTAL_URL_FAILED';

export const SET_BANK_PAYMENT_REQUEST = 'SET_BANK_PAYMENT_REQUEST';
export const SET_BANK_PAYMENT_SUCCESS = 'SET_BANK_PAYMENT_SUCCESS';
export const SET_BANK_PAYMENT_FAILED = 'SET_BANK_PAYMENT_FAILED';

export const DISABLE_NOTIFY_SUB_MIGRATION = 'DISABLE_NOTIFY_SUB_MIGRATION';
// Action types

export const createCustomerAction = () => ({
  type: CREATE_CUSTOMER_REQUEST
});

export const getPlanInformationAction = () => ({
  type: GET_PLAN_INFORMATION_REQUEST
});

export const setBankPaymentAction = (data) => ({
  type: SET_BANK_PAYMENT_REQUEST,
  data
});

export const changeCardInformationAction = (
  token,
  error
) => ({
  type: CHANGE_CARD_INFORMATION_REQUEST,
  token,
  error
});

export const changeBillingAddressAction = (
  address
) => ({
  type: CHANGE_BILLING_ADDRESS_REQUEST,
  address
});

export const upgradePlanAction = (data) => ({
  type: UPGRADE_PLAN_REQUEST,
  data
});

export const upgradeHardwareAction = (
  data
) => ({
  type: UPGRADE_HARDWARE_REQUEST,
  data
});

export const getPortalAction = () => ({
  type: GET_PORTAL_URL_REQUEST
});

export const disableNotifySubMigration = () => ({ type: DISABLE_NOTIFY_SUB_MIGRATION });