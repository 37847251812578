export const GET_ACTIVE_STUDIES_REQUEST =
  'dashboard/GET_ACTIVE_STUDIES_REQUEST';
export const GET_ACTIVE_STUDIES_SUCCESS =
  'dashboard/GET_ACTIVE_STUDIES_SUCCESS';
export const GET_ACTIVE_STUDIES_FAILURE =
  'dashboard/GET_ACTIVE_STUDIES_FAILURE';

export const GET_FACILITIES_REQUEST = 'dashboard/GET_FACILITIES_REQUEST';
export const GET_FACILITIES_SUCCESS = 'dashboard/GET_FACILITIES_SUCCESS';
export const GET_FACILITIES_FAILURE = 'dashboard/GET_FACILITIES_FAILURE';
export const GET_FACILITIES_STATUS_SUCCESS =
  'dashboard/GET_FACILITIES_STATUS_SUCCESS';

export const GET_PROVIDER_PERFORMANCE_REQUEST =
  'dashboard/GET_PROVIDER_PERFORMANCE_REQUEST';
export const GET_PROVIDER_PERFORMANCE_SUCCESS =
  'dashboard/GET_PROVIDER_PERFORMANCE_SUCCESS';
export const GET_PROVIDER_PERFORMANCE_FAILURE =
  'dashboard/GET_PROVIDER_PERFORMANCE_FAILURE';

export const GET_OVERVIEW_REQUEST = 'dashboard/GET_OVERVIEW_REQUEST';
export const GET_OVERVIEW_SUCCESS = 'dashboard/GET_OVERVIEW_SUCCESS';
export const GET_OVERVIEW_FAILURE = 'dashboard/GET_OVERVIEW_FAILURE';

export const GET_STUDY_DETAILS_REQUEST = 'dashboard/GET_STUDY_DETAILS_REQUEST';
export const GET_STUDY_DETAILS_SUCCESS = 'dashboard/GET_STUDY_DETAILS_SUCCESS';
export const GET_STUDY_DETAILS_FAILURE = 'dashboard/GET_STUDY_DETAILS_FAILURE';

export const GET_RADIOLOGIST_PERFORMANCE_REQUEST =
  'dashboard/GET_RADIOLOGIST_PERFORMANCE_REQUEST';
export const GET_RADIOLOGIST_PERFORMANCE_SUCCESS =
  'dashboard/GET_RADIOLOGIST_PERFORMANCE_SUCCESS';
export const GET_RADIOLOGIST_PERFORMANCE_FAILURE =
  'dashboard/GET_RADIOLOGIST_PERFORMANCE_FAILURE';
export const GET_GROUP_DETAIL_REQUEST = 'dashboard/GET_GROUP_DETAIL_REQUEST';
export const GET_GROUP_DETAIL_SUCCESS = 'dashboard/GET_GROUP_DETAIL_SUCCESS';
export const GET_GROUP_DETAIL_FAILURE = 'dashboard/GET_GROUP_DETAIL_FAILURE';

export const GET_MODALITIES_REQUEST = 'dashboard/GET_MODALITIES_REQUEST';
export const GET_MODALITIES_SUCCESS = 'dashboard/GET_MODALITIES_SUCCESS';
export const GET_MODALITIES_FAILURE = 'dashboard/GET_MODALITIES_FAILURE';

export const GET_REPORTING_RADIOLOGISTS_REQUEST =
  'dashboard/GET_REPORTING_RADIOLOGISTS_REQUEST';
export const GET_REPORTING_RADIOLOGISTS_SUCCESS =
  'dashboard/GET_REPORTING_RADIOLOGISTS_SUCCESS';
export const GET_REPORTING_RADIOLOGISTS_FAILURE =
  'dashboard/GET_REPORTING_RADIOLOGISTS_FAILURE';

export const GET_SENT_TOS_REQUEST = 'dashboard/GET_SENT_TOS_REQUEST';
export const GET_SENT_TOS_SUCCESS = 'dashboard/GET_SENT_TOS_SUCCESS';
export const GET_SENT_TOS_FAILURE = 'dashboard/GET_SENT_TOS_FAILURE';
