/* eslint-disable */
import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/QCDashboardAction';
import { DOWNLOAD_QC_MODALITY_BODYPART_INSIGHT_LOADING, DOWNLOAD_QC_RADIOLOGIST_PERFORMANCE_LOADING, FILTER_QC_DASHBOARD_LOADING, GET_QC_BODY_PART_LIST_LOADING, GET_QC_METRICS_SUMMARY_LOADING, GET_QC_MODALITY_BODYPART_INSIGHT_LOADING, GET_QC_RADIOLOGIST_PERFORMANCE_LOADING } from '../actions';

import {
  createGroupApiCall,
  getQCBodyPartListApiCall,
  getQCMetricsSummaryApiCall,
  getQCModalityBodypartInsightsApiCall,
  getQCRadiologistPerformanceApiCall,
  getUserGroupApiCall,
  updateGroupAPiCall
} from '../../api';
import { getFormValues, showError } from '../helpers';
import { getCurrentUser } from '../selectors';
import { t } from 'i18next';
import moment from 'moment';
import { actionTypes, change } from 'redux-form';
import { DASHBOARD_MODALITY_BODYPART_INSIGHT_FORM, DASHBOARD_QC_DOWNLOAD_FORM, DASHBOARD_QC_METRICS_SUMMARY_FORM, DASHBOARD_QC_RADIOLOGIST_PERFORMANCE_FORM } from '../../utils/form-helpers';
import { getDateRangeArray } from '../../utils';
import { message } from 'antd';

function* handleFilterChange(action) {
  const { payload, meta: { field, form } } = action
  if (form === DASHBOARD_QC_METRICS_SUMMARY_FORM) yield call(getQCMetricsSummarySaga)
  if (form === DASHBOARD_MODALITY_BODYPART_INSIGHT_FORM) yield call(getQCModalityBodypartInsightSaga)
  if (form === DASHBOARD_QC_RADIOLOGIST_PERFORMANCE_FORM) yield call(getQCRadiologistPerformanceSaga)
}

function* getQCMetricsSummarySaga() {
  yield put({ type: GET_QC_METRICS_SUMMARY_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  const filters = yield select(getFormValues, DASHBOARD_QC_METRICS_SUMMARY_FORM)

  try {
    const params = {
      selectedDate: [moment().subtract(1, 'M'), moment()],
    }
    if (filters?.selectedDate) params.selectedDate = getDateRangeArray(filters.selectedDate)
    if (filters?.bodyPart) {
      params.bodyPart = filters.bodyPart
      if (filters.bodyPart.toLowerCase().startsWith('all')) params.bodyPart = null
    }
    if (filters?.modality) {
      params.modality = filters.modality
      if (filters.modality.toLowerCase().startsWith('all')) params.modality = null
    }
    if (filters?.urgency) {
      params.urgency = filters.urgency
      if (filters.urgency.toLowerCase().startsWith('all')) params.urgency = null
    }
    if (filters?.radiologists?.length > 0) {
      let radValue = filters.radiologists
      if (Array.isArray(filters?.radiologists)) {
        if (filters?.radiologists.find(val => val?.toLowerCase() === 'all')) radValue = []
      } else {
        if (payload.value.toLowerCase().startsWith('all')) radValue = []
      }
      params.radiologistIds = radValue
    }
    const qcMetricsSummary = yield call(getQCMetricsSummaryApiCall, profile, params);
    yield put({ type: actions.GET_QC_METRICS_SUMMARY_SUCCESS, payload: { data: qcMetricsSummary } });

    yield call(getQCBodyPartListSaga, { payload: { params, section: 'qcMetricsSummary' } })
  } catch (e) {
    console.error(e.message);
    yield put({ type: actions.GET_QC_METRICS_SUMMARY_FAILURE });
    yield call(showError, { message: t('errors.failed_loading_qc_metrics_summary') });
  } finally {
    yield put({ type: GET_QC_METRICS_SUMMARY_LOADING, loading: false });
  }
}

function* getQCModalityBodypartInsightSaga() {
  yield put({ type: GET_QC_MODALITY_BODYPART_INSIGHT_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  const filters = yield select(getFormValues, DASHBOARD_MODALITY_BODYPART_INSIGHT_FORM);

  try {
    const params = {
      selectedDate: [moment('2000-01-01'), moment()],
    }
    if (filters?.selectedDate) params.selectedDate = getDateRangeArray(filters.selectedDate)
    if (filters?.type) params.type = filters.type
    if (filters?.bodyPart) {
      params.bodyPart = filters.bodyPart
      if (filters.bodyPart.toLowerCase().startsWith('all')) params.bodyPart = ''
    }
    if (filters?.urgency) {
      params.urgency = filters.urgency
      if (filters.urgency.toLowerCase().startsWith('all')) params.urgency = null
    }
    const qcModalityBodypartInsight = yield call(getQCModalityBodypartInsightsApiCall, profile, params);
    yield put({ type: actions.GET_QC_MODALITY_BODYPART_INSIGHT_SUCCESS, payload: { data: qcModalityBodypartInsight } });

    yield call(getQCBodyPartListSaga, { payload: { params, section: 'modalityBodyPartInsight' } })
  } catch (e) {
    console.error(e.message);
    yield put({ type: actions.GET_QC_MODALITY_BODYPART_INSIGHT_FAILURE });
    yield call(showError, { message: t('errors.failed_loading_qc_modality_bodypart_insight') });
  } finally {
    yield put({ type: GET_QC_MODALITY_BODYPART_INSIGHT_LOADING, loading: false });
  }
}

function* getQCRadiologistPerformanceSaga() {
  yield put({ type: GET_QC_RADIOLOGIST_PERFORMANCE_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  const filters = yield select(getFormValues, DASHBOARD_QC_RADIOLOGIST_PERFORMANCE_FORM);

  try {
    const params = {
      selectedDate: [moment().subtract(1, 'M'), moment()],
    }
    if (filters?.selectedDate) params.selectedDate = getDateRangeArray(filters.selectedDate)
    if (filters?.bodyPart) {
      params.bodyPart = filters.bodyPart
      if (filters.bodyPart.toLowerCase().startsWith('all')) params.bodyPart = null
    }
    if (filters?.modality) {
      params.modality = filters.modality
      if (filters.modality.toLowerCase().startsWith('all')) params.modality = null
    }
    if (filters?.urgency) {
      params.urgency = filters.urgency
      if (filters.urgency.toLowerCase().startsWith('all')) params.urgency = null
    }
    if (filters?.radiologists?.length > 0) {
      let radValue = filters.radiologists
      if (Array.isArray(filters?.radiologists)) {
        if (filters?.radiologists.find(val => val?.toLowerCase() === 'all')) radValue = []
      } else {
        if (payload.value.toLowerCase().startsWith('all')) radValue = []
      }
      params.radiologistIds = radValue
    }
    const qcRadiologistPerformance = yield call(getQCRadiologistPerformanceApiCall, profile, params);
    yield put({ type: actions.GET_QC_RADIOLOGIST_PERFORMANCE_SUCCESS, payload: { data: qcRadiologistPerformance } });
    yield call(handleSortRadPerformanceChange, { payload: filters?.sorting ?? 'total_highest' })

    yield call(getQCBodyPartListSaga, { payload: { params, section: 'radiologistPerformance' } })
  } catch (e) {
    console.error(e.message);
    yield put({ type: actions.GET_QC_RADIOLOGIST_PERFORMANCE_FAILURE });
    yield call(showError, { message: t('errors.failed_loading_qc_radiologist_performance') });
  } finally {
    yield put({ type: GET_QC_RADIOLOGIST_PERFORMANCE_LOADING, loading: false });
  }
}

const SORT_MAPPER = {
  total: 'total_studies',
  resolved: 'resolved_cases',
  flagged: 'flagged_for_qc'
};

function* handleSortRadPerformanceChange(action) {
  try {
    if (!action.payload) {
      console.warn('Sort action received with empty payload');
      return;
    }

    // Get sort parameters
    const [group, value] = action.payload.split('_');

    // Validate inputs
    if (!group || !value || !SORT_MAPPER[group]) {
      console.warn('Invalid sort parameters:', { group, value });
      return;
    }

    // Get current state
    const currentState = yield select(state => state.QCDashboard.radiologistPerformance.data);

    // Ensure we have data to sort
    if (!currentState?.data || !Array.isArray(currentState.data)) {
      console.warn('No valid data to sort');
      return;
    }

    // Create new sorted array without mutating original
    const sortField = SORT_MAPPER[group];
    const sortedData = [...currentState.data].sort((a, b) => {
      const aValue = a[sortField] ?? 0;  // Fallback to 0 if undefined
      const bValue = b[sortField] ?? 0;
      return value === 'highest' ? bValue - aValue : aValue - bValue;
    });

    // Dispatch sorted results
    yield put({
      type: actions.GET_QC_RADIOLOGIST_PERFORMANCE_SUCCESS,
      payload: { data: { data: sortedData } }
    });

  } catch (error) {
    yield put({
      type: actions.GET_QC_RADIOLOGIST_PERFORMANCE_FAILURE,
      error: error.message
    });
  }
}

function* downloadQCModalityBodypartInsightSaga({ payload }) {
  yield put({ type: DOWNLOAD_QC_MODALITY_BODYPART_INSIGHT_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  const filters = yield select(getFormValues, DASHBOARD_QC_DOWNLOAD_FORM);

  try {
    const params = {}
    if (payload?.sectionType) params.type = payload.sectionType
    if (filters?.selectedDate) params.selectedDate = getDateRangeArray(filters.selectedDate)
    if (filters?.selectedMonth) params.selectedDate = getDateRangeArray(filters.selectedMonth)
    if (filters?.selectedDateRange) params.selectedDate = filters.selectedDateRange
    if (filters?.bodyPart) {
      params.bodyPart = filters.bodyPart
      if (filters.bodyPart.toLowerCase().startsWith('all')) params.bodyPart = ''
    }
    if (filters?.facility) {
      params.facilityIds = [filters.facility]
      if (filters.facility.toLowerCase().startsWith('all')) params.facilityIds = null
    }
    if (filters?.modality) {
      params.modality = filters.modality
      if (filters.modality.toLowerCase().startsWith('all')) params.modality = null
    }
    if (filters?.urgency) {
      params.urgency = filters.urgency
      if (filters.urgency.toLowerCase().startsWith('all')) params.urgency = null
    }
    if (filters?.radiologists?.length > 0) {
      let radValue = filters.radiologists
      if (Array.isArray(filters?.radiologists)) {
        if (filters?.radiologists.find(val => val?.toLowerCase() === 'all')) radValue = []
      } else {
        if (payload.value.toLowerCase().startsWith('all')) radValue = []
      }
      params.radiologistIds = radValue
    }

    const qcModalityBodypartInsight = yield call(getQCModalityBodypartInsightsApiCall, profile, params);
    if (qcModalityBodypartInsight.data && !qcModalityBodypartInsight.data.length) message.warn(t('warning.no_data_to_export'))
    yield put(actions.setDownloadQCModalityBodyPartInsight(qcModalityBodypartInsight));
  } catch (e) {
    console.error(e.message);
    yield put({ type: actions.DOWNLOAD_QC_MODALITY_BODYPART_INSIGHT_FAILURE });
    yield call(showError, { message: t('errors.failed_downloading_qc_modality_bodypart_insight') });
  } finally {
    yield put({ type: DOWNLOAD_QC_MODALITY_BODYPART_INSIGHT_LOADING, loading: false });
  }
}

function* downloadQCRadiologistPerformanceSaga() {
  yield put({ type: DOWNLOAD_QC_RADIOLOGIST_PERFORMANCE_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  const filters = yield select(getFormValues, DASHBOARD_QC_DOWNLOAD_FORM);

  try {
    const params = {}
    if (filters?.selectedDate) params.selectedDate = getDateRangeArray(filters.selectedDate)
    if (filters?.selectedMonth) params.selectedDate = getDateRangeArray(filters.selectedMonth)
    if (filters?.selectedDateRange) params.selectedDate = filters.selectedDateRange
    if (filters?.bodyPart) {
      params.bodyPart = filters.bodyPart
      if (filters.bodyPart.toLowerCase().startsWith('all')) params.bodyPart = null
    }
    if (filters?.facility) {
      params.facilityIds = [filters.facility]
      if (filters.facility.toLowerCase().startsWith('all')) params.facilityIds = null
    }
    if (filters?.modality) {
      params.modality = filters.modality
      if (filters.modality.toLowerCase().startsWith('all')) params.modality = null
    }
    if (filters?.urgency) {
      params.urgency = filters.urgency
      if (filters.urgency.toLowerCase().startsWith('all')) params.urgency = null
    }
    if (filters?.radiologists?.length > 0) {
      let radValue = filters.radiologists
      if (Array.isArray(filters?.radiologists)) {
        if (filters?.radiologists.find(val => val?.toLowerCase() === 'all')) radValue = []
      } else {
        if (payload.value.toLowerCase().startsWith('all')) radValue = []
      }
      params.radiologistIds = radValue
    }

    const qcRadiologistPerformance = yield call(getQCRadiologistPerformanceApiCall, profile, params);
    if (qcRadiologistPerformance.data && !qcRadiologistPerformance.data.length) message.warn(t('warning.no_data_to_export'))
    yield put(actions.setDownloadQCRadiologistPerformance(qcRadiologistPerformance));
  } catch (e) {
    console.error(e.message);
    yield put({ type: actions.DOWNLOAD_QC_RADIOLOGIST_PERFORMANCE_FAILURE });
    yield call(showError, { message: t('errors.failed_downloading_qc_radiologist_performance') });
  } finally {
    yield put({ type: DOWNLOAD_QC_RADIOLOGIST_PERFORMANCE_LOADING, loading: false });
  }
}

function* getQCBodyPartListSaga({ payload }) {
  yield put({ type: GET_QC_BODY_PART_LIST_LOADING, loading: true });
  const profile = yield select(getCurrentUser);

  try {
    const qcBodyPartList = yield call(getQCBodyPartListApiCall, profile, payload.params);
    yield put({ type: actions.GET_QC_BODY_PART_LIST_SUCCESS, payload: { section: payload.section, data: qcBodyPartList.data } });
  } catch (e) {
    console.error(e.message);
    yield put({ type: actions.GET_QC_BODY_PART_LIST_FAILURE });
  } finally {
    yield put({ type: GET_QC_BODY_PART_LIST_LOADING, loading: false });
  }
}

export default function* QCDashboard() {
  yield takeLatest(actions.GET_QC_METRICS_SUMMARY_REQUEST, getQCMetricsSummarySaga);
  yield takeLatest(actions.GET_QC_MODALITY_BODYPART_INSIGHT_REQUEST, getQCModalityBodypartInsightSaga);
  yield takeLatest(actions.GET_QC_RADIOLOGIST_PERFORMANCE_REQUEST, getQCRadiologistPerformanceSaga);
  yield takeLatest(actions.DOWNLOAD_QC_MODALITY_BODYPART_INSIGHT_REQUEST, downloadQCModalityBodypartInsightSaga);
  yield takeLatest(actions.DOWNLOAD_QC_RADIOLOGIST_PERFORMANCE_REQUEST, downloadQCRadiologistPerformanceSaga);
  yield takeLatest(actions.GET_QC_BODY_PART_LIST_REQUEST, getQCBodyPartListSaga);
  yield takeLatest(
    action =>
      action.type === actionTypes.CHANGE &&
      [
        DASHBOARD_QC_METRICS_SUMMARY_FORM,
        DASHBOARD_MODALITY_BODYPART_INSIGHT_FORM,
        DASHBOARD_QC_RADIOLOGIST_PERFORMANCE_FORM
      ].includes(action.meta.form),
    handleFilterChange
  );
  yield takeLatest(
    action =>
      action.type === actionTypes.CHANGE &&
      action.meta.form === DASHBOARD_QC_RADIOLOGIST_PERFORMANCE_FORM &&
      action.meta.field === 'sorting',
    handleSortRadPerformanceChange
  );
}
