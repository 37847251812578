import type { EmptyAction, ErrorAction } from './types';

export const GET_COUNTRY_LIST = 'GET_COUNTRY_LIST';
export const GET_COUNTRY_LIST_REQUEST = 'GET_COUNTRY_LIST_REQUEST';
export const GET_COUNTRY_LIST_SUCCESS = 'GET_COUNTRY_LIST_SUCCESS';
export const GET_COUNTRY_LIST_FAILURE = 'GET_COUNTRY_LIST_FAILURE';


export type CountriesSuccessAction = { type: string, data: Array<Object> }

export const getCountryListRequest = (): EmptyAction => ({
  type: GET_COUNTRY_LIST_REQUEST
});
export const getCountryListSuccess = (data: Array<Object>): CountriesSuccessAction => ({
  type: GET_COUNTRY_LIST_SUCCESS,
  data
});
export const getCountryListFailure = (error: Error): ErrorAction => ({
  type: GET_COUNTRY_LIST_FAILURE,
  error
});
