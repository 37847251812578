import React from 'react';
// import PropTypes from 'prop-types'
import Box from 'ui-box';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BrandingForm from '../forms/BrandingForm';

function Branding(props) {
  // console.log('Branding props', props)
  const { onSubmit } = props;
  const onCancel = () => {};

  const brandingFormProps = {
    onCancel,
    onSubmit
  };

  return (
    <div>
      <Box paddingTop={10}>
        <BrandingForm {...brandingFormProps} />
      </Box>
    </div>
  );
}

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(null, mapDispatchToProps)(Branding);
