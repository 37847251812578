import { Button, Checkbox, Input, Menu, Tree } from 'antd'
import React, { useEffect, useState } from 'react'
import "./CustomDropdownCheckboxGroupField.less";
import { isEmpty } from 'lodash';

const searchFilterList = (dataOriginal, searchText) => {
  const data = JSON.parse(JSON.stringify(dataOriginal)); 
  const filteredData = data.filter((item) => {
    if (
      item.title.toLowerCase().includes(searchText.toLowerCase()) ||
      item.key.toLowerCase().includes(searchText.toLowerCase())
    ) {
      if (item.children) {
        item.children = searchFilterList(item.children, searchText);
      }
      return true;
    } else if (item.children) {
      const filteredChildren = searchFilterList(item.children, searchText);
      if (filteredChildren.length > 0) {
        item.children = filteredChildren;
        return true;
      }
    }
    return false;
  });
  return filteredData;
};

const getAllKeys = (data) => {
  const keys = [];
  data.forEach((item) => {
    keys.push(item.key);
    if (item.children) {
      keys.push(...getAllKeys(item.children));
    }
  });
  return keys;
};

const filterCoreKeys = (data) => {
  let keys = [];
  data.forEach((item) => {
    if (item.children) {
      keys = [...keys, ...filterCoreKeys(item.children)]
    } else {
      keys.push(item.key);
    }
  });
  return keys;
};

const TreeMenu = ({
  hideSearch,
  onChange,
  data,
  setDropdownVisible,
  ...rest
}) => {
  const [searchText, setSearchText] = useState("");
  const [dataSource, setDataSource] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    if (!isEmpty(data)) {
      const filtered = {}
      Object.keys(data).forEach(key => {
        filtered[key] = searchFilterList(data[key].list, searchText)
      })
      setDataSource(filtered);
    }
  }, [data, searchText]);

  useEffect(() => {
    let cond = false
    if (Object.keys(selectedOptions).length) cond = Object.keys(selectedOptions).every(key => selectedOptions[key].length === getAllKeys(dataSource[key]).length)
    setAllChecked(cond)
  
    let allValues = []
    for (const key of Object.keys(selectedOptions)) {
      allValues = [...allValues, ...selectedOptions[key]]
    }
    onChange(allValues);
  }, [selectedOptions])

  const onCheckOptionHandler = (values, key) => {
    let coreKeys = []
    for (const key of Object.keys(dataSource)) {
      const filteredCoreKeys = filterCoreKeys(dataSource[key])
      coreKeys = [...coreKeys, ...filteredCoreKeys]
    }
    values = values.filter(value => coreKeys.some(key => key === value))
    setSelectedOptions(curr => ({ ...curr, [key]: values }));
  }

  const onSelectAllAdvanceHandler = (e) => {
    if (e.target.checked) {
      Object.keys(dataSource).forEach(key => {
        const allKeys = getAllKeys(dataSource[key])
        setSelectedOptions(curr => ({ ...curr, [key]: allKeys }));
      })
    } else {
      setSelectedOptions({});
      onChange([]);
    }
  }

  const onResetHandler = () => {
    setSelectedOptions({});
    onChange([]);
  }

  const onOkHandler = () => {
    setDropdownVisible(false);
  }

  return (
    <Menu {...rest}>
      <div className="custom-checkbox-container">
        {!hideSearch && <div className="search-box">
          <Input placeholder="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
        </div>}
        <Checkbox style={{ marginBottom: 8 }} onChange={onSelectAllAdvanceHandler} checked={allChecked}>Select All</Checkbox>
        <Menu>
          {Object.keys(dataSource).map(key => (
            <Menu.ItemGroup key={key} title={data[key].title}>
              <Tree
                checkable
                defaultExpandedKeys={getAllKeys(dataSource[key])}
                switcherIcon={null}
                showLine={false}
                expandAction="doubleClick"
                onCheck={(values) => onCheckOptionHandler(values, key)}
                treeData={dataSource[key]}
                checkedKeys={selectedOptions[key]}
                selectable={false}
              />
            </Menu.ItemGroup>
          ))}
        </Menu>
        <div className="dropdown-buttons">
          <Button size="small" onClick={onResetHandler}>Reset</Button>
          <Button size="small" type="primary" onClick={onOkHandler}>OK</Button>
        </div>
      </div>
    </Menu>
  )
}

export default TreeMenu