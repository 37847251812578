/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Col, Form, Icon, Row } from 'antd';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import config from '../../../utils/config';
import { validateLogin } from './validators';
import { InputTextField } from '../../../components/FormParts';
import ActionLink from '../../../components/ActionLink';
import './LoginForm.less';

const mailIcon = <Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />;
const keyIcon = <Icon type='key' style={{ color: 'rgba(0,0,0,.25)' }} />;

const {
  api: { signUpAddress }
} = config;

const LoginForm = ({
  handleSubmit,
  invalid,
  pristine,
  submitting,
  toForgotPassword,
  columnSettings
}) => (
  <Form onSubmit={handleSubmit}>
    <Row type='flex'>
      <Col span={24}>
        <Row style={{ marginTop: '20px' }}>
          <Col xs={24}>
            <Row>
              <Col span={24}>
                <p className='login-field-label'>
                  {t('loginForm.email.label')}
                </p>
                <Field
                  name='username'
                  component={InputTextField}
                  type='text'
                  placeholder={t('loginForm.email.placeholder')}
                  prefix={mailIcon}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <p className='login-field-label'>
                  {t('loginForm.password.label')}
                </p>
                <Field
                  name='password'
                  component={InputTextField}
                  type='password'
                  placeholder={t('loginForm.password.placeholder')}
                  prefix={keyIcon}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '6px' }}>
              <Col span={24}>
                <Button
                  type='primary'
                  htmlType='submit'
                  className='login-form-button'
                  disabled={invalid || pristine || submitting}
                  onClick={handleSubmit}
                  style={{ width: '100%', textAlign: 'center' }}
                >
                  {t('loginForm.login')}
                </Button>

                <Col>
                  <ActionLink
                    click={toForgotPassword}
                    text={t('loginForm.forgot_your_password')}
                    style={{
                      marginTop: '20px',
                      width: '100%',
                      textAlign: 'justify',
                      fontWeight: 600,
                      fontSize: '12px',
                      color: '#15a9e1'
                    }}
                  />
                </Col>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  </Form>
);

export default reduxForm({ form: 'Login', validate: validateLogin })(
  withTranslation()(LoginForm)
);
