import React, { useEffect } from 'react';
// import PropTypes from 'prop-types'
import 'rc-color-picker/assets/index.css';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { Button, Card, Col, Form, Row } from 'antd';
import ColorPicker from 'rc-color-picker';
import Box from 'ui-box';
import { bindActionCreators } from 'redux';
import { t } from 'i18next';
import brandingValidation from './brandingValidation';
import {
  InputPhoneNumberField,
  InputTextField
} from '../../../components/FormParts';
import UploadBranding from '../branding/UploadBranding';
import { getSecondOpinionSettingRequest } from '../../../store/actions/secondOpinionActions';
import { ReduxState } from '../../../schemas';
import LoadingSpinner from '../../../common/LoadingSpinner';

const BRANDING_FORM_NAME = 'brandingForm';

const mapStateToProps = (state: ReduxState) => {
  return {
    initialValues: state.secondOpinion.secondOpinionSetting,
    loading: state.loading.secondOpinion,
    color: state.secondOpinion.secondOpinionSetting.color
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSecondOpinionSetting: getSecondOpinionSettingRequest,
      changeField: change
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: BRANDING_FORM_NAME,
    enableReinitialize: true,
    validate: brandingValidation
  })(props => {
    // console.log('BrandingForm props', props)
    const {
      handleSubmit,
      submitting,
      getSecondOpinionSetting,
      loading,
      changeField,
      color
    } = props;

    // const onUpload = useCallback(acceptedFiles => {
    //   const files = [
    //     ...logo,
    //     ...acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }))
    //   ]
    //   setLogo(files[0])
    // }, []);

    useEffect(() => {
      getSecondOpinionSetting();
    }, []);

    return (
      <Card>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Form layout='vertical' onSubmit={handleSubmit}>
            <Box marginBottom={100}>
              <Row gutter={[24, 24]}>
                <Col xs={24} lg={12} xl={8}>
                  <Field name='logo_file_location' component={UploadBranding} />
                  <Box marginTop={23}>
                    <Row gutter={24} type='flex'>
                      <Col xs={24} sm={12}>
                        <Field
                          name='color_code'
                          label={t('branding.colour_scheme')}
                          placeholder='#FFFFFF'
                          component={InputTextField}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <Box
                          height='100%'
                          display='flex'
                          alignItems='flex-end'
                          paddingBottom='32px'
                        >
                          <ColorPicker
                            color={`#${color}`}
                            enableAlpha={false}
                            onChange={selectedColor =>
                              changeField(
                                BRANDING_FORM_NAME,
                                'color_code',
                                selectedColor.color.replace('#', '')
                              )
                            }
                          >
                            <div>
                              <Button
                                type='primary'
                                style={{ backgroundColor: 'red !important' }}
                              >
                                {t('button.select_colour')}
                              </Button>
                            </div>
                          </ColorPicker>
                        </Box>
                      </Col>
                    </Row>
                  </Box>
                </Col>
                <Col xs={24} lg={12} xl={16}>
                  <Row gutter={48}>
                    <Col xs={24} md={12} lg={24} xl={12}>
                      <Field
                        name='website'
                        label={t('branding.website')}
                        placeholder='Input website link'
                        component={InputTextField}
                      />
                      <Field
                        name='officeNumber'
                        label={t('branding.office_number')}
                        component={InputPhoneNumberField}
                        defaultCountry='sg'
                        disableAreaCodes
                      />
                    </Col>
                    <Col xs={24} md={12} lg={24} xl={12}>
                      <Field
                        name='location'
                        label={t('branding.country')}
                        placeholder='Input your country'
                        component={InputTextField}
                      />
                      <Field
                        name='emailAddress'
                        label={t('branding.email_address')}
                        type='email'
                        placeholder='Input your email address'
                        component={InputTextField}
                      />
                    </Col>
                  </Row>
                </Col>
                {/* <Col xs={24} lg={12} xl={16}>
                <Row gutter={48}>
                  <Col xs={24} md={12} lg={24} xl={12}>
                    <Field
                      name='website'
                      label='Website'
                      placeholder='Input website link'
                      component={InputTextField}
                    />
                    <Field
                      name='address.address1'
                      label='Street Address 1'
                      placeholder='Input your address 1'
                      component={InputTextField}
                    />
                    <Row gutter={24}>
                      <Col xs={24} sm={12}>
                        <Field
                          name='address.location'
                          label='City'
                          placeholder='Your city'
                          component={InputTextField}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <Field
                          name='address.state'
                          label='State'
                          placeholder='Your state'
                          component={InputTextField}
                        />
                      </Col>
                    </Row>
                    <Field
                      name='phone'
                      label='Phone Number'
                      component={InputPhoneNumberField}
                      defaultCountry='sg'
                      disableAreaCodes
                    />
                  </Col>
                  <Col xs={24} md={12} lg={24} xl={12}>
                    <Field
                      name='address.country'
                      label='Country'
                      placeholder='Input your country'
                      component={InputTextField}
                    />
                    <Field
                      name='address.address2'
                      label='Street Address 2 (Optional)'
                      placeholder='Input your address 2'
                      component={InputTextField}
                    />
                    <Row>
                      <Col xs={24} sm={12}>
                        <Field
                          name='address.postal'
                          label='Postal'
                          placeholder='Postal code'
                          component={InputTextField}
                        />
                      </Col>
                    </Row>
                    <Field
                      name='email'
                      label='Email Address'
                      type='email'
                      placeholder='Input your email address'
                      component={InputTextField}
                    />
                  </Col>
                </Row>
              </Col> */}
              </Row>
            </Box>
            <Row gutter={16} type='flex' justify='end'>
              <Col>
                <Button>{t('button.cancel')}</Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  onClick={handleSubmit}
                  disabled={submitting}
                >
                  {t('button.confirm')}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Card>
    );
  })
);
