/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-console */
/* eslint-disable no-alert */
import React from 'react';
import { Row, Collapse, Breadcrumb, Col } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Search from 'antd/lib/input/Search';

import { t } from 'i18next';
import './index.less';
import TableDevices from './TableDevices';
import AddDevice from './AddDevice';
import {
  getDevicesRequest,
  getInternalFacilitiesAction,
  getDevicesStatusRequest
} from '../../../store/actions';

const { Panel } = Collapse;

const AddDeviceLink = ({ facility, facilities }) => {
  return (
    <div onClick={e => e.stopPropagation()}>
      <AddDevice facility={facility} facilities={facilities} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    facilities: state.facilities.internalArray
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getFacilities: getInternalFacilitiesAction,
      getDevices: getDevicesRequest,
      getDevicesStatus: getDevicesStatusRequest
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(({ facilities, getFacilities, getDevices, getDevicesStatus }) => {
  React.useEffect(() => {
    getFacilities();
    getDevices();
    getDevicesStatus();
  }, []);

  const [searchText, setSearchText] = React.useState('');
  const filteredFacilities = facilities.filter(item => {
    if (searchText === '') {
      return true;
    }
    return item.name.includes(searchText);
  });
  return (
    <>
      <Row type='flex' justify='space-between' align='middle'>
        <Col>
          <Breadcrumb className='bread'>
            <Breadcrumb.Item>
              {t('side_bar.On-Premises Devices')}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col>
          <Search
            placeholder={t('branding.search_facility')}
            onSearch={value => setSearchText(value)}
            enterButton
          />
        </Col>
      </Row>

      <div>
        <Row className='card-container'>
          <div>
            <Collapse defaultActiveKey={['1']} onChange={() => {}}>
              {filteredFacilities.map((item, index) => {
                return (
                  <Panel
                    header={item.name}
                    key={item.guid}
                    extra={
                      <AddDeviceLink facility={item} facilities={facilities} />
                    }
                  >
                    <Row>
                      <TableDevices facility={item} />
                    </Row>
                  </Panel>
                );
              })}
            </Collapse>
          </div>
        </Row>
      </div>
    </>
  );
});
