/* eslint-disable no-case-declarations */
import initialState from '../state';
import * as actions from '../actions/devicesActions';

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_DEVICES_SUCCESS:
      return { ...state, array: action.data.array, total: action.data.total };
    // case actions.GET_ALL_USERS_SUCCESS:
    //   return { ...state, all: action.data.array };
    // case actions.GET_USER_BY_ID_SUCCESS:
    //   return { ...state, current: action.data };
    case actions.ADD_DEVICE_SUCCESS:
    case actions.EDIT_DEVICE_SUCCESS:
      return {
        ...state,
        modifications: (state.modifications += 1)
      };
    // case actions.GET_ALL_USERS_FAILED:
    case actions.GET_DEVICES_FAILED:
      // case actions.GET_USER_BY_ID_FAILED:
      // case actions.ADD_USER_FAILED:
      // case actions.EDIT_USER_FAILED:
      // case actions.DELETE_USER_FAILED:
      // case actions.REVOKE_ADMIN_RIGHTS_FAILED:
      return { ...state, errors: state.errors.concat(action.error) };
    // case actions.UPDATE_LAST_USERS_SEARCH:
    //   return {
    //     ...state,
    //     currentSearch: action.search
    //   };
    case actions.GET_DEVICES_STATUS_SUCCESS:
      const devicesStatus = state.devicesStatus ? state.devicesStatus : {};
      return {
        ...state,
        devicesStatus: {
          ...devicesStatus,
          [action.data.device_guid]: action.data
        }
      };
    default:
      return state;
  }
};
