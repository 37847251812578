import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions';
import { showError, showSuccess } from '../helpers';
import {
  createProviderApiCall,
  deleteProviderApiCall,
  getProviderByIdApiCall,
  getProvidersApiCall,
  requestProvidersServiceApiCall
} from '../../api';
import { getCurrentUser, getProvidersLastSort } from '../selectors';
import { t } from 'i18next';

function* getProvidersSaga(action) {
  yield put({ type: actions.GET_PROVIDERS_LOADING, loading: true });
  try {
    const { sort } = action;
    const profile = yield select(getCurrentUser);
    const providers = yield call(getProvidersApiCall, profile, sort);
    yield put({ type: actions.UPDATE_LAST_PROVIDERS_SORT, sort });
    yield put({ type: actions.GET_PROVIDERS_SUCCESS, data: providers });
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.GET_PROVIDERS_FAILED, error });
    yield call(showError, { message: t('errors.failed_loading_providers') });
  } finally {
    yield put({ type: actions.GET_PROVIDERS_LOADING, loading: false });
  }
}

function* getProviderByIdSaga(action) {
  yield put({ type: actions.GET_PROVIDER_LOADING, loading: true });
  try {
    const { providerId } = action;
    const profile = yield select(getCurrentUser);
    const data = yield call(getProviderByIdApiCall, profile, providerId);
    yield put({ type: actions.GET_PROVIDER_BY_ID_SUCCESS, data });
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.GET_PROVIDER_BY_ID_FAILED, error });
    yield call(showError, { message: t('errors.failed_loading_providers') });
  } finally {
    yield put({ type: actions.GET_PROVIDER_LOADING, loading: false });
  }
}

function* addProviderSaga(action) {
  yield put({ type: actions.ADD_PROVIDER_LOADING, loading: true });
  try {
    const { provider } = action;
    const profile = yield select(getCurrentUser);
    yield call(createProviderApiCall, profile, provider);
    const sort = yield select(getProvidersLastSort);
    yield call(getProvidersSaga, { sort });
    yield put({ type: actions.ADD_PROVIDER_SUCCESS });
    yield call(showSuccess, 'Provider added');
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.ADD_PROVIDER_FAILED, error });
    yield call(showError, { message: t('errors.failed_creating_provider') });
  } finally {
    yield put({ type: actions.ADD_PROVIDER_LOADING, loading: false });
  }
}

function* deleteProviderSaga(action) {
  yield put({ type: actions.DELETE_PROVIDER_LOADING, loading: true });
  try {
    const { providerId, name } = action;
    const profile = yield select(getCurrentUser);
    yield call(deleteProviderApiCall, profile, providerId);
    const sort = yield select(getProvidersLastSort);
    yield call(getProvidersSaga, { sort });
    yield put({ type: actions.DELETE_PROVIDER_SUCCESS });
    yield call(showSuccess, `${name} has been deleted`);
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.DELETE_PROVIDER_FAILED, error });
    yield call(showError, { message: t('errors.failed_deleting_provider') });
  } finally {
    yield put({ type: actions.DELETE_PROVIDER_LOADING, loading: false });
  }
}

function* requestServiceSaga(action) {
  yield put({ type: actions.REQUEST_PROVIDER_SERVICE_LOADING, loading: true });
  try {
    const { providers, modalities } = action;
    const profile = yield select(getCurrentUser);
    yield call(requestProvidersServiceApiCall, profile, providers, modalities);
    yield put({ type: actions.REQUEST_SERVICE_PROVIDER_SUCCESS });
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.REQUEST_SERVICE_PROVIDER_FAILED, error });
    yield call(showError, { message: t('errors.failed_requesting_provider_service') });
  } finally {
    yield put({
      type: actions.REQUEST_PROVIDER_SERVICE_LOADING,
      loading: false
    });
  }
}

export default function* providersSaga() {
  yield takeLatest(actions.GET_PROVIDERS_REQUEST, getProvidersSaga);
  yield takeLatest(actions.GET_PROVIDER_BY_ID_REQUEST, getProviderByIdSaga);
  yield takeLatest(actions.ADD_PROVIDER_REQUEST, addProviderSaga);
  yield takeLatest(actions.DELETE_PROVIDER_REQUEST, deleteProviderSaga);
  yield takeLatest(
    actions.REQUEST_SERVICE_PROVIDER_REQUEST,
    requestServiceSaga
  );
}
