// @flow
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Badge, Col, Empty, Row, Switch } from 'antd';
import Table from '../../../components/Table';

import {
  deleteDeviceAction,
  getUserByIdAction,
  getDevicesRequest,
  editDeviceAction,
  testDeviceStatusRequest
} from '../../../store/actions';

import type { Facility, ReduxState, User } from '../../../schemas';
import EditDevice from './EditDevice';
import { t } from 'i18next';

const DeviceStatus = ({ deviceStatus = {} }) => {
  const { status } = deviceStatus;
  return (
    <Row type='flex' align='middle' gutter={[0, 6]}>
      <Col>
        <Badge
          status={status === 'AVAILABLE' ? 'success' : 'error'}
          style={{ display: 'flex' }}
        />
      </Col>
      <Col style={{ textTransform: 'capitalize' }}>
        {status ? status.toLowerCase() : 'No Response'}
      </Col>
    </Row>
  );
};

const DeviceName = ({ name, facility, device, deleteAction }) => {
  const [visible, setVisible] = React.useState(false);
  return (
    <>
      <span
        className='text-link'
        onClick={() => setVisible(true)}
        onKeyDown={() => setVisible(true)}
      >
        {name || 'Device'}
      </span>
      <EditDevice
        visible={visible}
        deleteAction={deleteAction}
        onCancel={() => setVisible(false)}
        facility={facility}
        device={device}
      />
    </>
  );
};

type Props = {
  data: Array<User>,
  total: number,
  loading: boolean,
  modifications: number,
  facilities: Array<Facility>,
  facility: Facility,
  getAction: () => void,
  testDeviceStatusAction: string => void,
  getByIdAction: string => void,
  deleteAction: string => void,
  editAction: string => void,
  deleteAction: string => void,
  resetPassword: string => void,
  getFacilities: () => void,
  devicesStatus: Array<any>,
  searchText: string
};

export default connect(
  (state: ReduxState) => ({
    data: state.devices.array,
    devicesStatus: state.devices.devicesStatus,
    total: state.devices.total,
    loading: state.loading.getDevices,
    modifications: state.devices.modifications,
    facilities: state.facilities.internalArray
  }),
  {
    getAction: getDevicesRequest,
    getByIdAction: getUserByIdAction,
    deleteAction: deleteDeviceAction,
    testDeviceStatusAction: testDeviceStatusRequest,
    editAction: editDeviceAction
  }
)(
  ({
    data,
    total,
    loading,
    modifications,
    getAction,
    deleteAction,
    getByIdAction,
    facilities,
    facility,
    editAction,
    devicesStatus,
    testDeviceStatusAction,
    searchText,
    getFacilities
  }: Props) => {
    const [homeFacilities, setHomeFacilities] = useState(
      facilities.map((f: Facility) => ({ text: f.name, value: f.name }))
    );

    React.useEffect(() => {
      // getFacilities();
    }, []);
    React.useEffect(() => {
      if (homeFacilities.length !== facilities.length) {
        setHomeFacilities(
          facilities.map((f: Facility) => ({ text: f.name, value: f.name }))
        );
      }
    }, [facilities, homeFacilities]);

    const [modificationsState, setModificationsState] = React.useState(
      modifications
    );
    /* eslint-disable-next-line */
    const [adaptedData, setAdaptedData] = React.useState(data);

    React.useEffect(() => {
      if (modificationsState > modifications) {
        setModificationsState(modifications);
        setAdaptedData(data);
      }
    }, [data, modifications]);

    const columns = [
      {
        title: t('referral_patterns.device'),
        dataIndex: 'nickname',
        sorter: (a, b) =>
          (a.nickname ? a.nickname : '')
            .toString()
            .toLowerCase()
            .localeCompare(
              (b.nickname ? b.nickname : '').toString().toLowerCase()
            ),
        render: (text, record) => (
          <DeviceName
            name={text}
            facility={facility}
            device={record}
            deleteAction={deleteAction}
          />
        )
      },
      {
        title: t('referral_patterns.conn_type'),
        dataIndex: 'connectionType',
        sorter: (a, b) =>
          (a.name ? a.name : '')
            .toString()
            .toLowerCase()
            .localeCompare((b.name ? b.name : '').toString().toLowerCase()),
        render: (text, record) => text
      },
      {
        title: t('referral_patterns.location'),
        dataIndex: 'inFacilityLocation',
        sorter: (a, b) =>
          (a.name ? a.name : '')
            .toString()
            .toLowerCase()
            .localeCompare((b.name ? b.name : '').toString().toLowerCase()),
        render: (text, record) => text
      },
      {
        title: t('my_facilities.modality'),
        dataIndex: 'modality',
        sorter: (a, b) =>
          (a.name ? a.name : '')
            .toString()
            .toLowerCase()
            .localeCompare((b.name ? b.name : '').toString().toLowerCase()),
        render: (text, record) => text
      },
      {
        title: t('referral_patterns.mwl_active'),
        dataIndex: 'modalityWorklistActive',
        render: (text, record) => {
          return (
            <Switch
              defaultChecked={text}
              onChange={status => {
                editAction({ ...record, modalityWorklistActive: status });
              }}
              size='small'
            />
          );
        }
      },
      {
        title: t('manage_radiologists.table.status'),
        dataIndex: 'status',
        sorter: (a, b) =>
          (a.status ? a.status : '')
            .toString()
            .toLowerCase()
            .localeCompare((b.status ? b.status : '').toString().toLowerCase()),
        render: (text, record) => (
          <DeviceStatus
            deviceStatus={devicesStatus ? devicesStatus[record.id] : {}}
          />
        )
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <a href='#' onClick={() => testDeviceStatusAction(record)}>
            Test Availability
          </a>
        )
      }
    ];
    const rowKey = user => user.id;

    const filteredData = data.filter(item => {
      if (item.facility) {
        return false;
      }
      if (searchText === '') {
        return true;
      }
      return item.nickname.includes(searchText);
    });
    return (
      <>
        <Table
          columns={columns}
          total={total}
          data={filteredData}
          modifications={modifications}
          rowKey={rowKey}
          loading={loading}
          hidePagination
          getAction={() => {}}
          locale={{
            emptyText: (
              <Empty
                description={t("table.no_data")}
              />
            ),
          }}
        />
      </>
    );
  }
);
