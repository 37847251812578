import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { t } from 'i18next';
import { Button, Col, Form, Icon, Row } from 'antd';
import { validateForgotPassword } from './validators';
import { InputTextField } from '../../../components/FormParts';
import ActionLink from '../../../components/ActionLink';
import './LoginForm.less';

const FormItem = Form.Item;
const mailIcon = <Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />;

export default reduxForm({
  form: 'ForgotPassword',
  validate: validateForgotPassword
})(
  ({
    handleSubmit,
    invalid,
    pristine,
    submitting,
    columnSettings,
    toLogin
  }) => (
    <Form onSubmit={handleSubmit}>
      <Row type='flex' justify='space-around' align='middle'>
        <Col span={24}>
          <Row>
            <Col span={24}>{/* <div className='login-title' /> */}</Col>
          </Row>
          <Row>
            <Col>
              <div className='login-sub-title'>
                <div className='login-form-forgot-password'>
                  {t('login_form.forgot_password2')}
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col>
              <Row>
                <Col offset={1} span={22}>
                  <FormItem>
                    <div className='login-field-label'>
                      {t('loginForm.email.label')}
                    </div>
                    <Field
                      name='email'
                      component={InputTextField}
                      type='text'
                      placeholder={t('loginForm.email.label')}
                      prefix={mailIcon}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col offset={1} span={22}>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='login-form-button'
                    disabled={invalid || pristine || submitting}
                    onClick={handleSubmit}
                    style={{ width: '100%', textAlign: 'center' }}
                  >
                    Send
                  </Button>
                </Col>
              </Row>
              <Row>
                <ActionLink
                  click={toLogin}
                  text={t('loginForm.do_you_want_login')}
                  style={{
                    marginTop: '20px',
                    width: '100%',
                    textAlign: 'center'
                  }}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  )
);
