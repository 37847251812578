import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { t } from 'i18next';
import FacilityForm from './FacilityForm';
import validation from './validation';
import { Facility } from '../../../../../schemas';
import {
  addCustomLetterhead,
  addFacilityLogo,
  removeFacilityLogo
} from '../../../../../store/actions';
import FacilityFormFooter from './FacilityFormFooter';

const EDIT_INTERNAL_FACILITY_FORM_NAME = 'editInternalFacilityForm';

type Props = {
  visible: boolean,
  loading: boolean,
  onCancel: () => void,
  onSubmit: Object => void, // eslint-disable-line react/no-unused-prop-types
  handleSubmit: Object => void,
  pristine: boolean,
  submitting: boolean,
  countryList: Array<{ text: string, value: string }>,
  facility: Facility,
  addLogoAction: string => void,
  removeLogoAction: () => void,
  uploadLogoAction: () => void,
  previousLogo: string,
  logo: string,
  letterhead: string,
  addLetterheadAction: string => void
};

export default connect(
  state => ({
    initialValues: state.initialForm.editInternalFacility,
    logo: state.facilities.logo,
    previousLogo: state.facilities.previousLogo,
    letterhead: state.facilities.letterhead
  }),
  {
    addLogoAction: addFacilityLogo,
    removeLogoAction: removeFacilityLogo,
    addLetterheadAction: addCustomLetterhead
  }
)(
  reduxForm({
    form: EDIT_INTERNAL_FACILITY_FORM_NAME,
    validate: validation
  })(
    ({
      visible,
      onCancel,
      loading,
      handleSubmit,
      pristine,
      submitting,
      countryList,
      facility,
      addLogoAction,
      removeLogoAction,
      logo,
      previousLogo,
      letterhead,
      addLetterheadAction
    }: Props) => {
      const [logoChanged, setLogoChanged] = React.useState(false);
      const [letterHeadChanged, setLetterHeadChanged] = React.useState(false);
      const handleSubmitWithLogo = () => {
        setLogoChanged(false);
        setLetterHeadChanged(false);
        handleSubmit();
      };
      return (
        <FacilityForm
          title={t('my_facilities.edit_internal_facility')}
          okText='Edit'
          visible={visible}
          onCancel={onCancel}
          loading={loading}
          countryList={countryList}
          internalProps={{
            internal: true,
            facility,
            setLogoChanged,
            setLetterHeadChanged,
            addLogoAction,
            removeLogoAction,
            logo,
            hasPreviousLogo: previousLogo !== '',
            hasLetterhead: letterhead && letterhead !== '',
            addLetterheadAction
          }}
        >
          <FacilityFormFooter
            disabled={
              (pristine || submitting) && !logoChanged && !letterHeadChanged
            }
            handleSubmit={handleSubmitWithLogo}
            loading={loading}
            okText={t('my_facilities.edit_facility')}
            onCancel={onCancel}
          />
        </FacilityForm>
      );
    }
  )
);
