import React from 'react';
import { Button, message, notification, Progress, Spin } from 'antd';

export const NOTIFICATION_MESSAGE_DURATION = 5;

export const basicNotification = (
  textToShow,
  duration = NOTIFICATION_MESSAGE_DURATION
) => {
  return message.info(message, duration);
};

export const errorNotification = (
  textToShow,
  duration = NOTIFICATION_MESSAGE_DURATION
) => {
  return message.error(textToShow, duration);
};

export const successNotification = (
  textToShow,
  duration = NOTIFICATION_MESSAGE_DURATION
) => {
  return message.success(textToShow, duration);
};

export const warningNotification = (
  textToShow,
  duration = NOTIFICATION_MESSAGE_DURATION
) => {
  return message.warning(textToShow, duration);
};

export const dataLoadingNotification = textToShow => {
  return message.loading(textToShow, 0);
};

export const clearNotification = () => {
  message.destroy();
};

export const getAssigneeName = (
  payload,
  listOfAssignee,
  currentUsername,
  isPost = false
) => {
  const {
    facility_guid: facilityGuid = null,
    radiologist_id: radiologistGuid = null
  } = payload;
  const guidToSeek = facilityGuid || radiologistGuid;
  return listOfAssignee[guidToSeek] || currentUsername;
};

export const getAssignmentMessage = (assigneeName, actionTaken) => {
  let messageToReturn = 'Study has been successfully assigned to ';
  switch (actionTaken) {
    case 'accepted':
      messageToReturn = 'Study has been accepted by ';
      break;
    case 'declined':
      messageToReturn = 'Study has been declined by ';
      break;
    case 'rejected':
      messageToReturn = 'Study has been rejected by ';
      break;
    default:
      break;
  }
  return messageToReturn + assigneeName;
};

export const getLoadingMessage = (actionName, actionTaken) => {
  let messageToReturn = `${actionName} in progress...`;
  switch (actionTaken) {
    case 'accepted':
      messageToReturn = 'Accepting Study';
      break;
    case 'declined':
      messageToReturn = 'Declining Study';
      break;
    case 'rejected':
      messageToReturn = 'Rejecting Study';
      break;
    default:
      break;
  }
  return messageToReturn;
};

export const getStudyDeleteInitNotificationText = () => {
  return 'Deleting selected study.';
};

export const getStudyDeleteSuccessNotificationText = () => {
  return 'Study has been deleted successfully.';
};

export const getStudyUploadInitMessage = () => {
  return 'Creating Study...';
};

export const getStudyUploadJSONInitMessage = () => {
  return 'Uploading Study JSON file';
};

export const getStudyUploadJSONFailedMessage = () => {
  return 'Failed to upload Study JSON file';
};

export const getStudyUploadJSONSuccessMessage = () => {
  return 'Successfully uploaded Study JSON file';
};

export const getStudyUploadDICOMInitMessage = () => {
  return 'Uploading DICOM file';
};

export const getStudyUploadDICOMFailedMessage = () => {
  return 'Failed to upload DICOM file';
};

export const getStudyUploadDICOMSuccessMessage = () => {
  return 'Successfully uploaded DICOM file(s)';
};

export const showNotificationCard = props => {
  const { description, showLoader, ...rest } = props;
  const customDescription =
    description && showLoader ? (
      <div>
        {description} <Spin />
      </div>
    ) : (
      description
    );
  notification.open({ description: customDescription, ...rest });
};

export const showNotificationForDICOMDownloadStart = (
  notificationKey,
  studyGuid
) => {
  showNotificationCard({
    message: 'Downloading DICOM file',
    description: (
      <div>
        <b>Download in progress</b>
        <p>{`File: 'dicom-zip-of-study-${studyGuid}.zip'`}</p>
      </div>
    ),
    duration: 0,
    key: notificationKey,
    placement: 'bottomRight'
  });
};

export const showNotificationForDICOMDOwnloadInProgress = (
  { notificationKey, studyGuid, progress },
  onCLoseCallback
) => {
  showNotificationCard({
    message: 'Downloading DICOM file',
    description: (
      <div>
        <b>Download in Progress</b>
        <p>{`File: 'dicom-zip-of-study-${studyGuid}.zip'`}</p>
        <Progress percent={parseInt(progress, 10)} size='small' />
      </div>
    ),
    duration: 0,
    key: notificationKey,
    placement: 'bottomRight',
    onClose: onCLoseCallback,
    btn: (
      <Button
        type='primary'
        size='small'
        onClick={() => {
          notification.close(notificationKey);
          onCLoseCallback();
        }}
      >
        Cancel Download
      </Button>
    )
  });
};

export const showNotificationForDICOMDownloadEnd = (
  notificationKey,
  studyGuid
) => {
  showNotificationCard({
    message: 'Downloading DICOM file',
    description: (
      <div>
        <b>Download finished successfully</b>
        <p>{`File: 'dicom-zip-of-study-${studyGuid}.zip'`}</p>
      </div>
    ),
    duration: 4.5,
    key: notificationKey,
    placement: 'bottomRight'
  });
};

export const showNotificationForDICOMDownloadFailed = (
  notificationKey,
  studyGuid
) => {
  showNotificationCard({
    message: 'Downloading DICOM file',
    description: (
      <div>
        <b>Download incomplete. Please try again.</b>
        <p>{`File: 'dicom-zip-of-study-${studyGuid}.zip'`}</p>
      </div>
    ),
    duration: 4.5,
    key: notificationKey,
    placement: 'bottomRight'
  });
};

export const showNotificationForStudyUploadInProgress = ({
  notificationKey,
  content
}) => {
  showNotificationCard({
    message: 'Study Upload',
    description: (
      <div>
        <b>Upload in Progress</b>
        <p>{content}</p>
      </div>
    ),
    duration: 0,
    key: notificationKey,
    placement: 'bottomRight',
    showLoader: true
  });
};

export const showNotificationForStudyUploadEnd = ({
  notificationKey,
  content
}) => {
  showNotificationCard({
    message: 'Study Upload',
    description: (
      <div>
        <b>Upload Completed</b>
        <p>{content}</p>
      </div>
    ),
    duration: 4.5,
    key: notificationKey,
    placement: 'bottomRight'
  });
};

export const showNotificationForStudyUploadFailed = ({
  notificationKey,
  content
}) => {
  showNotificationCard({
    message: 'Study Upload',
    description: (
      <div>
        <b>Upload Incomplete. Please try again</b>
        <p>{content}</p>
      </div>
    ),
    duration: 4.5,
    key: notificationKey,
    placement: 'bottomRight'
  });
};

export const showNotificationForUploadInProgress = ({
  notificationKey,
  message,
  content,
  progress
}) => {
  showNotificationCard({
    message,
    description: (
      <div>
        <b>Upload in Progress</b>
        <p>{content}</p>
        <Progress percent={parseInt(progress, 10)} size='small' />
      </div>
    ),
    duration: 0,
    key: notificationKey,
    placement: 'bottomRight'
  });
};

export const showNotificationForUploadEnd = ({
  notificationKey,
  message,
  content
}) => {
  showNotificationCard({
    message,
    description: (
      <div>
        <b>Upload Completed</b>
        <p>{content}</p>
      </div>
    ),
    duration: 4.5,
    key: notificationKey,
    placement: 'bottomRight'
  });
};

export const showNotificationForUploadFailed = ({
  notificationKey,
  message,
  content
}) => {
  showNotificationCard({
    message,
    description: (
      <div>
        <b>{`${message} Failed`}</b>
        <p>{content}</p>
      </div>
    ),
    duration: 4.5,
    key: notificationKey,
    placement: 'bottomRight'
  });
};
