// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'antd';

import ActionLink from '../../../../components/ActionLink';
import type { Facility, ReduxState } from '../../../../schemas';
import type { FacilityIdAction } from '../../../../store/actions';
import { getFacilityByIdAction } from '../../../../store/actions';
import ViewFacility from './ViewFacility';

type Props = {
  data: Facility,
  facilityId: string,
  loading: boolean,
  getAction: string => FacilityIdAction
};

export default connect(
  (state: ReduxState) => ({
    data: state.facilities.current,
    loading: state.loading.facility
  }),
  {
    getAction: getFacilityByIdAction
  }
)(({ data, facilityId, loading, getAction }: Props) => {
  const [visible, setVisibility] = React.useState(false);

  const showModal = () => {
    getAction(facilityId);
    setVisibility(true);
  };

  const handleCancel = () => {
    setVisibility(false);
  };

  const renderFooter = () => (
    <Row type='flex' justify='space-around' align='right'>
      <Col span={8} style={{ textAlign: 'right' }}>
        <Button onClick={handleCancel}>Close</Button>
      </Col>
    </Row>
  );

  return (
    <div>
      <ActionLink click={showModal} text='View' />
      <ViewFacility
        data={data}
        loading={loading}
        handleCancel={handleCancel}
        renderFooter={renderFooter}
        visible={visible}
        kind='External'
      />
    </div>
  );
});
