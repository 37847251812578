/* eslint-disable react/no-children-prop */

import React from 'react';
import { Checkbox, Form, Row } from 'antd';

const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;

const renderCheckBox = ({ text, value }, index) => (
  <div className='check-box-element' key={index}>
    <Row>
      <Checkbox value={value} key={index}>
        {text}
      </Checkbox>
    </Row>
  </div>
);

const InputCheckBoxField = Options => ({
  input,
  meta,
  children,
  hasFeedback,
  label,
  ...rest
}) => {
  const hasError = meta.touched && meta.invalid;
  return (
    <FormItem
      label={label}
      validateStatus={hasError ? 'error' : 'success'}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
    >
      <CheckboxGroup {...input} {...rest} children={children}>
        {Options.map(renderCheckBox)}
      </CheckboxGroup>
    </FormItem>
  );
};

export default InputCheckBoxField;
