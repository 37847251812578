// @flow

import store from 'store';

import { config, isEmpty } from '../utils';


export const clearAllStorage = () => {
  store.clearAll()
};

export const getProfileFromLocalStorage = () => {
  return store.get('profile');
};

export const saveProfileToLocalStorage = (profile) => {
  store.set('profile', profile);
};

export const deleteProfileFromLocalStorage = () => {
  store.remove('profile');
};

export const saveSSODataToLocalStorage = (data) =>
  store.set('sso', data);

export const getSSODataFromLocalStorage = () => store.get('sso');

export const saveCountryListToLocalStorage = data => {
  store.set(config.basic.localStorageCountryListKey, JSON.stringify(data));
};

export const getCountryListFromLocalStorage = () => {
  const data = store.get(config.basic.localStorageCountryListKey);
  if (!isEmpty(data)) {
    return JSON.parse(data);
  }
  return {};
};

export const getHasShownSubscriptionMigration = () => {
  const data = store.get(config.basic.localStoragePrefix + 'hssm');
  return data
};

export const setHasShownSubscriptionMigration = data => {
  store.set(config.basic.localStoragePrefix + 'hssm', data);
};