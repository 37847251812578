import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Row, Col } from 'antd';
import DateRangePickerField from '../../../../../common/form-helpers/DateRangePickerField';
import { SOURCE_FACILITIES_CHART_FORM_NAME } from '../../../../../utils/form-helpers';
import { SelectOptions } from '../../../../../schemas';
// import InputSelectField from '../../../../../common/form-helpers/InputSelectField';

type Props = {
  radioDateOptions: SelectOptions,
  reportingRadiologistOptions: SelectOptions
};

function SourceFacilitiesChartCardForm({
  radioDateOptions,
  reportingRadiologistOptions,
  facilityOptions,
  modalityOptions
}: Props) {
  return (
    <Row
      id='study-details-card-form'
      type='flex'
      align='middle'
      justify='end'
      gutter={[16, 12]}
    >
      {/* <Col xs={24} sm={24} md={8} lg={8} xl={7}>
        <Field
          name='facility'
          labelStyle={{ margin: 0 }}
          placeholder='Select Facility'
          options={facilityOptions}
          width='100%'
          component={InputSelectField}
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={5}>
        <Field
          name='modality'
          labelStyle={{ margin: 0 }}
          placeholder='Select Modality'
          options={modalityOptions}
          width='100%'
          component={InputSelectField}
        />
      </Col> */}
      <Col xs={24} sm={12} md={8}>
        <Field
          name='selectedDate'
          labelStyle={{ margin: 0 }}
          width='100%'
          component={DateRangePickerField}
        />
      </Col>
    </Row>
  );
}

export default reduxForm({
  form: SOURCE_FACILITIES_CHART_FORM_NAME
})(SourceFacilitiesChartCardForm);
