import * as React from 'react';
import { getFormValues, reduxForm, change } from 'redux-form';

import { Button, Col, Row } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validation from './validation';
import PhysicianForm from './PhysicianForm';

const EDIT_PHYSICIAN_FORM_NAME = 'editPhysicianForm';

type Props = {
  visible: boolean,
  onCancel: () => void,
  loading: boolean,
  // Called when the delete button is pushed
  onDelete: () => void,
  // Values passed by reduxForm
  handleSubmit: any => void,
  pristine: boolean,
  submitting: boolean
};

export default connect(
  state => ({
    initialValues: state.initialForm.editPhysician,
    formData: getFormValues(EDIT_PHYSICIAN_FORM_NAME)(state)
  }),
  dispatch =>
    bindActionCreators(
      {
        change
      },
      dispatch
    )
)(
  reduxForm({
    form: EDIT_PHYSICIAN_FORM_NAME,
    validate: validation
  })(
    ({
      visible,
      onCancel,
      loading,
      onDelete,
      change: changeForm,
      formData,
      handleSubmit,
      isInternal,
      pristine,
      submitting
    }: Props) => {
      React.useEffect(() => {
        const { primaryFacility } = formData || {};
        if (
          primaryFacility &&
          typeof primaryFacility === 'string' &&
          primaryFacility !== ''
        ) {
          changeForm('primaryFacility', [
            isInternal ? 'internal-facility-guid' : 'external-facility-guid',
            primaryFacility
          ]);
        }
      }, [isInternal]);
      return (
        <PhysicianForm
          title='Edit Referring Physician'
          okText='Edit'
          visible={visible}
          change={changeForm}
          isInternal={isInternal}
          data={formData}
          loading={loading}
          onCancel={onCancel}
        >
          <Row type='flex' justify='space-around' align='middle'>
            <Col span={8} style={{ textAlign: 'left' }}>
              {/* <Button className='custom-link' aling='left' onClick={onDelete}>
                Remove
              </Button> */}
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={onCancel}>Close</Button>
              <Button
                disabled={pristine || submitting}
                type='primary'
                onClick={handleSubmit}
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </PhysicianForm>
      );
    }
  )
);
