// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Divider, Form, Modal, Row, Typography } from 'antd';
import { Field } from 'redux-form';
import { t } from 'i18next';
// import ConfirmRevokeAdminRights from '../../partials/RevokeAdminRights';
import InputSelectField from '../../../../common/form-helpers/InputSelectField';
import InputField from '../../../../common/form-helpers/InputField';
import './BaseDeviceForm.less';
import { Facility, ReduxState } from '../../../../schemas';
import {
  connectionTypeOptions,
  deviceModalOptions,
  fullModalityOptions,
  manufacturerOptions
} from '../../../../store/constants';
import AutoCompleteField from '../../../../common/form-helpers/AutoCompleteField';

const { Title } = Typography;

const styles = {
  title: {
    fontWeight: 500,
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.65)',
    marginBottom: 14
  },
  divider: {
    margin: '0px 0px 14px'
  }
};

type Props = {
  visible: boolean,
  title: string,
  // Text of the ok button
  okText: string,
  // What to do if the cancel button is pushed. It's required by the  Modal class
  onCancel: () => void,
  isMain: boolean,
  // Initial role for the user
  role: ?string,
  children: React.Node,
  facilities: Array<Facility>,
  selectedFacilities: Array<Facility>,
  hasEditButton: boolean,
  showDicomInfo: boolean,
  connectionTypeData: string
};

/**
 * Contains the controls used to add or edit a user.
 */
export default connect(
  (state: ReduxState) => ({
    facilities: state.facilities.internalArray
  }),
  {}
)((props: Props) => {
  const {
    visible,
    onCancel,
    title,
    okText,
    children,
    facilities,
    hasEditButton,
    connectionTypeData,
    manufacturerData,
  } = props;
  const homeFacilities = facilities.map((f: Facility) => ({
    label: f.name,
    value: f.guid
  }));

  // eslint-disable-next-line
  const [role, setRole] = React.useState<?string>(props.role);
  const [footerVisibility, setFooterVisibility] = React.useState<?boolean>(
    false
  );

  return (
    <Modal
      visible={visible}
      title={title}
      okText={okText}
      onCancel={onCancel}
      destroyOnClose={false}
      footer={
        (hasEditButton && footerVisibility) || !hasEditButton ? children : null
      }
      wrapClassName='lateral-modal'
    >
      <Form layout='vertical'>
        <Row gutter={32}>
          <Col xs={24} sm={24}>
            <Row gutter={8} type='flex' style={{ flexWrap: 'nowrap' }}>
              <Col xs={24}>
                <Field
                  name='facility'
                  label={t("my_facilities.facility_name")}
                  placeholder={t("my_facilities.facility_name")}
                  required
                  options={homeFacilities}
                  component={InputSelectField}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col xs={24} sm={12}>
            <Field
              name='connectionType'
              label={t("my_facilities.connection_type")}
              placeholder={t("my_facilities.connection_type")}
              required
              options={connectionTypeOptions}
              component={InputSelectField}
            />
          </Col>
          {connectionTypeData !== 'Imaging Modality' ? null : (
            <Col xs={24} sm={12}>
              <Field
                name='modality'
                label={t('my_facilities.modality')}
                placeholder={t('my_facilities.modality')}
                required
                options={fullModalityOptions}
                component={InputSelectField}
              />
            </Col>
          )}
        </Row>
        <Row gutter={32}>
          <Col xs={24} sm={12}>
            <Field
              name='manufacturer'
              label={t('my_facilities.manufacturer')}
              placeholder={t('my_facilities.manufacturer')}
              dataSource={manufacturerOptions.map(item => item.value)}
              component={AutoCompleteField}
            />
          </Col>
          <Col xs={24} sm={12}>
            {manufacturerData == "AlemHealth" ?
              <Field
                name='model'
                label={t('my_facilities.model')}
                placeholder={t('my_facilities.model')}
                options={deviceModalOptions}
                component={InputSelectField}
              /> :
              <Field
                name='model'
                label={t('my_facilities.model')}
                placeholder={t('my_facilities.model')}
                component={InputField}
              />
            }
          </Col>
        </Row>
        <Row gutter={32}>
          <Col xs={24} sm={12}>
            <Field
              name='nickname'
              label={t('my_facilities.nickname_asset_code')}
              placeholder={t('my_facilities.nickname_asset_code')}
              type='text'
              component={InputField}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Field
              name='serialNumber'
              label={t('my_facilities.serial_number')}
              placeholder={t('my_facilities.serial_number')}
              type='text'
              component={InputField}
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col xs={24} sm={12}>
            <Field
              name='inFacilityLocation'
              label={t('my_facilities.in_facilit_location')}
              placeholder={t('my_facilities.in_facilit_location')}
              type='text'
              component={InputField}
            />
          </Col>
        </Row>
        <Divider style={styles.divider} />
        <div>
          <Title level={4} style={styles.title}>
            {t('my_facilities.dicom_information')}
          </Title>
          <Row gutter={32}>
            <Col xs={24} sm={12}>
              <Field
                name='dicomAeTitle'
                label={t('my_facilities.dicom_title')}
                placeholder={t('my_facilities.dicom_title')}
                type='text'
                required
                component={InputField}
              />
            </Col>
            <Col xs={24} sm={12}>
              <Field
                name='ipAddress'
                label={t('my_facilities.ip_address')}
                placeholder={t('my_facilities.ip_address')}
                type='text'
                component={InputField}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col xs={24} sm={12}>
              <Field
                name='dicomPort'
                label={t('my_facilities.dicom_port')}
                placeholder={t('my_facilities.dicom_port')}
                type='text'
                component={InputField}
              />
            </Col>
          </Row>
        </div>
      </Form>
      {hasEditButton && !footerVisibility ? (
        <Row>
          <Col>
            <Button
              type='primary'
              icon='edit'
              onClick={() => setFooterVisibility(true)}
            >
                {t('my_facilities.update_details')}
            </Button>
          </Col>
        </Row>
      ) : null}
    </Modal>
  );
});
