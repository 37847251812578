/* eslint-disable camelcase */

import * as React from 'react';
import { Modal, Spin, Row, Col, Button } from 'antd/lib/index';
import { connect } from 'react-redux';

import { deleteProvider, getProviderById } from '../../../store/actions';
import { confirmDeletion } from '../../../components/confirmations';
import ActionLink from '../../../components/ActionLink';

import type { ReduxState, Provider } from '../../../schemas';
import { t } from 'i18next';

type Props = {
  data: Provider,
  providerId: string,
  loading: boolean,
  getAction: string => void,
  deleteAction: (string, string) => void
};

type State = {
  visible: boolean
};

export default connect(
  (state: ReduxState) => ({
    data: state.providers.current,
    loading: state.loading.provider
  }),
  {
    getAction: getProviderById,
    deleteAction: deleteProvider
  }
)(
  class ViewProvider extends React.PureComponent<Props, State> {
    state = {
      visible: false
    };

    showModal = () => {
      const { getAction, providerId } = this.props;
      getAction(providerId);
      this.setState({ visible: true });
    };

    handleCancel = () => this.setState({ visible: false });

    handleOk = () => this.setState({ visible: false });

    handleDelete = () => {
      const {
        deleteAction,
        data: { id, name }
      } = this.props;
      confirmDeletion(deleteAction, id, name, 'provider');
    };

    render() {
      const { data, loading } = this.props;
      const {
        name,
        studyAccess,
        country,
        phoneNumber,
        email,
        primaryContact
      } = data;
      const { visible } = this.state;
      if (Object.entries(data).length === 0 || loading) {
        return (
          <div>
            <ActionLink click={this.showModal} text='View' />
            <Modal
              visible={visible}
              title='Provider Details'
              okText='Edit'
              footer={null}
              onCancel={this.handleCancel}
            >
              <div style={{ textAlign: 'center' }}>
                <Spin size='large' />
              </div>
            </Modal>
          </div>
        );
      }
      return (
        <div>
          <ActionLink click={this.showModal} text='View' />
          <Modal
            title='Provider Details'
            okText='Edit'
            visible={visible}
            onCancel={this.handleCancel}
            destroyOnClose
            footer={
              <Row type='flex' justify='space-around' align='middle'>
                <Col span={8} style={{ textAlign: 'left' }}>
                  <Button
                    className='custom-link'
                    aling='left'
                    onClick={this.handleDelete}
                  >
                    Delete Provider
                  </Button>
                </Col>
                <Col span={8} offset={8} style={{ textAlign: 'right' }}>
                  <Button onClick={this.handleCancel}>Close</Button>
                </Col>
              </Row>
            }
          >
            <Row gutter={32}>
              <Col className='gutter-row' span={12}>
                <p>{t("manage_radiologists.form.provider_name")}</p>
                <p style={{ color: 'black' }}>{name}</p>
              </Col>
              <Col className='gutter-row' span={12}>
                <p>{t("manage_radiologists.form.study_access")}</p>
                <p style={{ color: 'black' }}>{studyAccess}</p>
              </Col>
            </Row>
            <br />
            <Row gutter={32}>
              <Col className='gutter-row' span={12}>
                <p>{t("manage_radiologists.form.country")}</p>
                <p style={{ color: 'black' }}>{country}</p>
              </Col>
              <Col className='gutter-row' span={12}>
                <p>{t("manage_radiologists.form.primary_contact")}</p>
                <p style={{ color: 'black' }}>{primaryContact}</p>
              </Col>
            </Row>
            <br />
            <Row gutter={32}>
              <Col className='gutter-row' span={12}>
                <p>{t("my_facilities.email")}</p>
                <p style={{ color: 'black' }}>{email}</p>
              </Col>
              <Col className='gutter-row' span={12}>
                <p>{t("user_administration.field.mobile_number")}</p>
                <p style={{ color: 'black' }}>{phoneNumber}</p>
              </Col>
            </Row>
          </Modal>
        </div>
      );
    }
  }
);
