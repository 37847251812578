// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'antd';

import { Facility, ReduxState } from '../../../../schemas';
import { deleteFacilityAction } from '../../../../store/actions';
import EditInternalFacility from '../Edition/EditInternalFacility';
import ViewFacility from './ViewFacility';

type Props = {
  data: Facility,
  facilityId: string,
  loading: boolean,
  setVisibility: boolean => void,
  visible: boolean
};

export default connect(
  (state: ReduxState) => ({
    data: state.facilities.current,
    loading: state.loading.facility
  }),
  {
    deleteAction: deleteFacilityAction
  }
)(({ data, loading, visible, setVisibility }: Props) => {
  const [edit, setEdit] = React.useState(false);

  const handleCancel = () => {
    setVisibility(false);
    setEdit(false);
  };

  const handleEdit = () => {
    setVisibility(false);
    setEdit(true);
  };

  const renderFooter = () => (
    <Row type='flex' justify='space-around' align='middle'>
      <Col span={12} offset={12} style={{ textAlign: 'right' }}>
        <Button onClick={handleCancel}>Close</Button>
        <Button onClick={handleEdit}>Edit</Button>
      </Col>
    </Row>
  );

  return (
    <React.Fragment>
      <EditInternalFacility visible={edit} onCancel={handleCancel} />
      <ViewFacility
        data={data}
        loading={loading}
        handleCancel={handleCancel}
        renderFooter={renderFooter}
        visible={visible}
        kind='Internal'
      />
    </React.Fragment>
  );
});
