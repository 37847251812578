/* eslint-disable*/
import * as React from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import type { ReduxState, UserProfile } from '../schemas';
import { adaptUserData } from '../utils';
import { getLoginData } from '../store/actions';
import LoadingSpinner from '../components/LoadingSpinner';

type Props = {
  handleLoginAction: () => void
};

export default connect(
  (state: ReduxState) => ({
    profile: state.profile
  }),
  {
    handleLoginAction: getLoginData
  }
)(({ profile, handleLoginAction, ...props }: Props) => {
  React.useEffect(() => {
    debounce(handleLoginAction, 2000);
  }, []);
  return <LoadingSpinner {...props} />;
});
