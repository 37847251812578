import React from 'react';
import { Icon } from 'antd';
import { Page } from '../components';
import './404.less';

const Error = () => (
  <Page inner>
    <div className='error'>
      <Icon type='question-circle' style={{ fontSize: 100 }} />
      <h1>404 Not Found</h1>
    </div>
  </Page>
);

export default Error;
