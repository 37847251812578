import React from 'react';
import { Col, Row } from 'antd';
import Box from 'ui-box';
import { t } from 'i18next';
import CardFormWrapper from '../partials/CardFormWrapper';
// import { ReactComponent as DefaultPreviewSvg } from '../../../assets/images/default-preview.svg';
import './index.less';

const DROP_IN_CODE_BLOCK_FORM_NAME = 'dropInCodeBlockForm';

function CodeBlock({ groupId, disabled }) {
  return (
    <CardFormWrapper
      formName={DROP_IN_CODE_BLOCK_FORM_NAME}
      title={t('uploader_settings.drop_code_block')}
      text={t('uploader_settings.paste_code_block_page_uploader')}
      value={`<iframe title="Second Opinion Embed App" src="https://embed.alem.health/?id=${groupId}" frameBorder="0"></iframe>`}
      type='code'
      disabled={disabled}
      // eslint-disable-next-line no-console
    >
      <Row>
        <Col>
          <div className='label'>{t('uploader_settings.preview')}</div>
        </Col>
        <Col>
          <Box
            marginTop={12}
            borderRadius={4}
            height={400}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <iframe
              width='100%'
              height='400px'
              style={{ border: '1px solid #e6e6e6' }}
              title={t('uploader_settings.second_opinion_embed_app')}
              src={`https://embed.alem.health/?id=${groupId}`}
              frameBorder='0'
            ></iframe>
          </Box>
        </Col>
      </Row>
    </CardFormWrapper>
  );
}

export default CodeBlock;
