/* eslint-disable */
import {
  ADD_GROUP_LOGO,
  REMOVE_GROUP_LOGO,
  UPLOAD_GROUP_LOGO
} from './groupActions';

export const GET_INTERNAL_FACILITIES_REQUEST =
  'GET_INTERNAL_FACILITIES_REQUEST';
export const GET_INTERNAL_FACILITIES_SUCCESS =
  'GET_INTERNAL_FACILITIES_SUCCESS';
export const GET_INTERNAL_FACILITIES_FAILED = 'GET_INTERNAL_FACILITIES_FAILED';

export const GET_EXTERNAL_FACILITIES_REQUEST =
  'GET_EXTERNAL_FACILITIES_REQUEST';
export const GET_EXTERNAL_FACILITIES_SUCCESS =
  'GET_EXTERNAL_FACILITIES_SUCCESS';
export const GET_EXTERNAL_FACILITIES_FAILED = 'GET_EXTERNAL_FACILITIES_FAILED';

export const GET_FACILITY_BY_ID_REQUEST = 'GET_FACILITY_BY_ID_REQUEST';
export const GET_FACILITY_BY_ID_SUCCESS = 'GET_FACILITY_BY_ID_SUCCESS';
export const GET_FACILITY_BY_ID_FAILED = 'GET_FACILITY_BY_ID_FAILED';

export const ADD_INTERNAL_FACILITY_REQUEST = 'ADD_INTERNAL_FACILITY_REQUEST';
export const ADD_EXTERNAL_FACILITY_REQUEST = 'ADD_EXTERNAL_FACILITY_REQUEST';
export const ADD_FACILITY_SUCCESS = 'ADD_FACILITY_SUCCESS';
export const ADD_FACILITY_FAILED = 'ADD_FACILITY_FAILED';

export const ADD_NEWLY_CREATED_FACILITY = 'ADD_NEWLY_CREATED_FACILITY';
export const RESET_NEWLY_CREATED_FACILITY = 'RESET_NEWLY_CREATED_FACILITY';

export const EDIT_INTERNAL_FACILITY_REQUEST = 'EDIT_INTERNAL_FACILITY_REQUEST';
export const EDIT_EXTERNAL_FACILITY_REQUEST = 'EDIT_EXTERNAL_FACILITY_REQUEST';
export const EDIT_FACILITY_SUCCESS = 'EDIT_FACILITY_SUCCESS';
export const EDIT_FACILITY_FAILED = 'EDIT_FACILITY_FAILED';

export const DELETE_FACILITY_REQUEST = 'DELETE_FACILITY_REQUEST';
export const DELETE_FACILITY_SUCCESS = 'DELETE_FACILITY_SUCCESS';
export const DELETE_FACILITY_FAILED = 'DELETE_FACILITY_FAILED';

export const ADD_FACILITY_LOGO = 'ADD_FACILITY_LOGO';
export const REMOVE_FACILITY_LOGO = 'REMOVE_FACILITY_LOGO';
export const UPLOAD_FACILITY_LOGO = 'UPLOAD_FACILITY_LOGO';

export const ADD_FACILITY_CUSTOM_LETTERHEAD = 'ADD_FACILITY_CUSTOM_LETTERHEAD';
export const UPLOAD_FACILITY_CUSTOM_LETTERHEAD =
  'UPLOAD_FACILITY_CUSTOM_LETTERHEAD';

export const GET_SOURCE_FACILITIES_CHART_REQUEST =
  'GET_SOURCE_FACILITIES_CHART_REQUEST';
export const GET_SOURCE_FACILITIES_CHART_SUCCESS =
  'GET_SOURCE_FACILITIES_CHART_SUCCESS';
export const GET_SOURCE_FACILITIES_CHART_FAILED =
  'GET_SOURCE_FACILITIES_CHART_FAILED';

export const getInternalFacilitiesAction = () => ({
  type: GET_INTERNAL_FACILITIES_REQUEST
});

export const getExternalFacilitiesAction = () => ({
  type: GET_EXTERNAL_FACILITIES_REQUEST
});

export const getFacilityByIdAction = facilityId => ({
  type: GET_FACILITY_BY_ID_REQUEST,
  facilityId
});

export const addInternalFacilityAction = facility => ({
  type: ADD_INTERNAL_FACILITY_REQUEST,
  facility
});

export const addExternalFacilityAction = facility => ({
  type: ADD_EXTERNAL_FACILITY_REQUEST,
  facility
});

export const editInternalFacilityAction = (facility, facilityId) => ({
  type: EDIT_INTERNAL_FACILITY_REQUEST,
  facility,
  facilityId
});

export const editExternalFacilityAction = (facility, facilityId) => ({
  type: EDIT_EXTERNAL_FACILITY_REQUEST,
  facility,
  facilityId
});

export const deleteFacilityAction = facilityId => ({
  type: DELETE_FACILITY_REQUEST,
  facilityId
});

export const addFacilityLogo = logo => ({
  type: ADD_FACILITY_LOGO,
  logo
});
export const removeFacilityLogo = () => ({ type: REMOVE_FACILITY_LOGO });
export const uploadFacilityLogo = () => ({ type: UPLOAD_FACILITY_LOGO });

export const addCustomLetterhead = letterhead => ({
  type: ADD_FACILITY_CUSTOM_LETTERHEAD,
  letterhead
});
export const uploadCustomLetterhead = () => ({
  type: UPLOAD_FACILITY_CUSTOM_LETTERHEAD
});

export const getSourceFacilitiesChartAction = () => ({
  type: GET_SOURCE_FACILITIES_CHART_REQUEST
});

export const addNewlyCreatedFacility = facility => ({
  type: ADD_NEWLY_CREATED_FACILITY,
  facility
});

export const resetNewlyCreatedFacility = () => ({
  type: RESET_NEWLY_CREATED_FACILITY
});