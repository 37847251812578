import { put, call, select, takeLatest } from 'redux-saga/effects';
import { showError, showSuccess } from '../helpers';
import * as actions from '../actions/physiciansActions';
import {
  GET_PHYSICIANS_LOADING,
  GET_PHYSICIAN_BY_ID_LOADING,
  CREATE_PHYSICIAN_LOADING,
  UPDATE_PHYSICIAN_LOADING,
  DELETE_PHYSICIAN_LOADING
} from '../actions/loadingActions';
import {
  createPhysicianApiCall,
  deletePhysicianApiCall,
  getExternalPhysiciansApiCall,
  getPhysicianByIdApiCall,
  getPhysiciansApiCall,
  updatePhysicianApiCall
} from '../../api/endpoints/physicians';

import { getAffiliatedGroup, getCurrentUser } from '../selectors';
import { createGroupApiCall, updateGroupAPiCall } from '../../api';
import { RESET_NEWLY_CREATED_FACILITY } from '../actions';
import { t } from 'i18next';

function* getPhysiciansSaga(action) {
  yield put({ type: GET_PHYSICIANS_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  try {
    const physicians = yield call(getPhysiciansApiCall, profile);
    yield put({ type: actions.GET_PHYSICIANS_SUCCESS, physicians });
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.GET_PHYSICIANS_FAILED, error });
    yield call(showError, { message: t('errors.failed_loading_physicians')});
  } finally {
    yield put({ type: GET_PHYSICIANS_LOADING, loading: false });
  }
}

function* getExternalPhysiciansSaga(action) {
  yield put({ type: GET_PHYSICIANS_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  try {
    const physicians = yield call(getExternalPhysiciansApiCall, profile);
    yield put({ type: actions.GET_EXTERNAL_PHYSICIANS_SUCCESS, physicians });
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.GET_PHYSICIANS_FAILED, error });
    yield call(showError, { message: t('errors.failed_loading_physicians')});
  } finally {
    yield put({ type: GET_PHYSICIANS_LOADING, loading: false });
  }
}

function* getPhysicianByIdSaga(action) {
  yield put({ type: GET_PHYSICIAN_BY_ID_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  try {
    const physician = yield call(getPhysicianByIdApiCall, profile, action.guid);
    yield put({ type: actions.GET_PHYSICIAN_BY_ID_SUCCESS, physician });
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.GET_PHYSICIAN_BY_ID_FAILED, error });
    yield call(showError, { message: t('errors.failed_loading_physicians')});
  } finally {
    yield put({ type: GET_PHYSICIAN_BY_ID_LOADING, loading: false });
  }
}

function* createPhysicianSaga(action) {
  const { payload } = action;
  const { isInternal, physician } = payload || {};
  const group = yield select(getAffiliatedGroup);

  yield put({ type: CREATE_PHYSICIAN_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  try {
    const { group_id: groupId } = yield call(
      createGroupApiCall,
      profile,
      physician
    );
    if (groupId) {
      yield call(createPhysicianApiCall, profile, {
        ...physician,
        organizationGuid: isInternal
          ? profile.appMetadata.affiliatedGroup
          : groupId
      });
      if (!isInternal) {
        yield call(updateGroupAPiCall, profile, {
          groupId,
          availableTo: isInternal ? group : groupId
        });
      }

      yield put({ type: actions.ADD_PHYSICIAN_SUCCESS });
      yield put({ type: RESET_NEWLY_CREATED_FACILITY});
      yield call(showSuccess, 'Physician added');
    }
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.ADD_PHYSICIAN_FAILED, error });
    yield call(showError, { message: t('errors.failed_creating_physicians')});
  } finally {
    yield put({ type: CREATE_PHYSICIAN_LOADING, loading: false });
  }
}

function* updatePhysicianSaga(action) {
  yield put({ type: UPDATE_PHYSICIAN_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  try {
    yield call(updatePhysicianApiCall, profile, action.physician);
    yield put({ type: actions.EDIT_PHYSICIAN_SUCCESS });
    yield call(showSuccess, 'Physician updated');
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.EDIT_PHYSICIAN_FAILED, error });
    yield call(showError, { message: t('errors.failed_updating_physician')});
  } finally {
    yield put({ type: UPDATE_PHYSICIAN_LOADING, loading: false });
  }
}

function* deletePhysicianSaga(action) {
  yield put({ type: DELETE_PHYSICIAN_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  try {
    yield call(deletePhysicianApiCall, profile, action.guid);
    yield put({ type: actions.DELETE_PHYSICIAN_SUCCESS });
    yield call(showSuccess, 'Physician deleted');
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.DELETE_PHYSICIAN_FAILED, error });
    yield call(showError, { message: t('errors.failed_updating_physician')});
  } finally {
    yield put({ type: DELETE_PHYSICIAN_LOADING, loading: false });
  }
}

export default function* physiciansSaga() {
  yield takeLatest(actions.GET_PHYSICIANS_REQUEST, getPhysiciansSaga);
  yield takeLatest(
    actions.GET_EXTERNAL_PHYSICIANS_REQUEST,
    getExternalPhysiciansSaga
  );
  yield takeLatest(actions.GET_PHYSICIAN_BY_ID_REQUEST, getPhysicianByIdSaga);
  yield takeLatest(actions.ADD_PHYSICIAN_REQUEST, createPhysicianSaga);
  yield takeLatest(actions.EDIT_PHYSICIAN_REQUEST, updatePhysicianSaga);
  yield takeLatest(actions.DELETE_PHYSICIAN_REQUEST, deletePhysicianSaga);
}
