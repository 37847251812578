import React, { useState } from "react";
import { Button, Col, Row, Tooltip, Typography, Dropdown, Menu, Icon } from 'antd';
import Search from 'antd/lib/input/Search';

const { SubMenu } = Menu;

const ScheduleFilter = () => {
  const [searchText, setSearchText] = useState("");
  const menu = (
    <Menu className="day-filter-menu">
      <Menu.Item>Today</Menu.Item>
      <Menu.Item>Week</Menu.Item>
      <SubMenu title="Show X Days" className="submenu">
        <Menu.Item>2 Days</Menu.Item>
        <Menu.Item>3 Days</Menu.Item>
        <Menu.Item>4 Days</Menu.Item>
        <Menu.Item>5 Days</Menu.Item>
      </SubMenu>

    </Menu>
  );
  return (
    <div className="schedule-filter-wrapper">
      <Row type="flex" justify="space-between">
        <Col>
          <div className="search-container">
            <Search
              placeholder='Search'
              value={searchText}
              style={{ width: "unset", marginRight: 10 }}
              onSearch={value => setSearchText(value)}
              enterButton
            />
            <Dropdown overlay={menu} trigger={['click']} className="day-filter-dropdown">
              <Button> 
                Select <Icon type="down" />
              </Button>
            </Dropdown>
          </div>
        </Col>
        <Col>
          <div>
            <Button icon="delete" type="danger" style={{ marginRight: 10 }} ghost>Delete</Button>
            <Button icon="edit" style={{ marginRight: 10 }}>Edit Schedule</Button>
            <Button type="primary">Save Schedule</Button>
          </div>
        </Col>
      </Row>
    </div>
  )
};

export default ScheduleFilter;
