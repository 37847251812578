/* eslint-disable */
import * as React from 'react';
import { Col, Divider, Row, Typography } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { t } from 'i18next';
import {
  loginSucceed,
  forgotPassword,
  loginRequest
} from '../../store/actions';
import LoginForm from './components/LoginForm';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import ChangeLanguage from './components/ChangeLanguage';
import './deprecated/loginForm.less';
import { withTranslation } from 'react-i18next';

const DEBOUNCE_DELAY_IN_MILLISECONDS = 1000;
const { Title, Paragraph } = Typography;

const Login = ({ loginRequestAction, forgotPasswordAction }) => {
  const [page, setPage] = React.useState('login');
  const columnSettings = {
    tiny: { offset: 1, span: 22 },
    small: { offset: 3, span: 18 },
    medium: { offset: 5, span: 14 },
    large: { offset: 6, span: 24 },
    extraLarge: { offset: 8, span: 8 },
    superExtraLarge: { offset: 9, span: 6 }
  };
  const formInitialValues = {
    username: '',
    password: ''
  };
  return (
    <Row className='home-login'>
      <Col className='home-login-right-col' xs={24} sm={24} md={8}>
        <section className='home-login-right-col-content'>
          <div className='home-login-right-col-body'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className='home-login-right-col-logo' />
              <ChangeLanguage />
            </div>
            <Title level={3} className='home-login-right-col-title'>
              {t('loginForm.welcome')}
            </Title>
            <Paragraph className='home-login-right-col-paragraph'>
              {t('loginForm.desc')}
            </Paragraph>
            {page === 'login' ? (
              <LoginForm
                onSubmit={loginRequestAction}
                initialValues={formInitialValues}
                columnSettings={columnSettings}
                toForgotPassword={() => setPage('forgot')}
              />
            ) : (
              <ForgotPasswordForm
                onSubmit={forgotPasswordAction}
                initialValues={formInitialValues}
                columnSettings={columnSettings}
                toLogin={() => setPage('login')}
              />
            )}
            <div className='home-login-right-col-links'>
              <a
                href='https://www.alemhealth.com/termsofservice'
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('loginForm.terms')}
              </a>
              <Divider type='vertical' />
              <a
                href='https://www.alemhealth.com/privacy-policy'
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('loginForm.privacy')}
              </a>
            </div>
          </div>
        </section>
      </Col>
      <Col className='home-login-main-col' xs={24} sm={24} md={16}>
        {/* {history?.location?.state?.sub && <Alert
          style={{maxWidth:500,position:"absolute", top:30, left:30}}
          description={
          <div>
            <Text>Please verify your email to continue using AlemHealth Connect Resend Verification Email</Text>
            <Button size="small" type="link" onClick={this.onResendMail}>
                Resend Verification Email
            </Button>
          </div>
          }
          type="info"
          icon={<div><Icon type="info-circle" /></div>}  
          showIcon            
        />} */}
        <section className='home-login-main-col-background-img' />
      </Col>
    </Row>
  );
}


export default withRouter(
  connect(
    state => ({
      loading: state.loading.login
    }),
    {
      loginAction: loginSucceed,
      forgotPasswordAction: forgotPassword,
      loginRequestAction: loginRequest
    }
  )(withTranslation()(Login)
));
