export const alemHealthDescription =
  'About this provider about this provider about this provider about this provider ' +
  'about this provider about this provider about this provider about this provider';

export const maxHealthCareDescription =
  'About this provider about this provider about this provider about this provider ' +
  'about this provider about this provider about this provider about this provider';

export const apolloHospitalsDescription =
  'About this provider about this provider about this provider about this provider ' +
  'about this provider about this provider about this provider about this provider';

export const cancelAccountReasons = [
  'I have found a better product',
  "I don't have enough budget",
  'I have problems with the service',
  "I don't like the application",
  'I am moving outside the country'
];
