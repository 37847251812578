/* eslint-disable camelcase */

import { connectionTypeOptionsWithDicomInfo } from '../../../../store/constants';

export default values => {
  const errors = {};
  const { connectionType, modality, dicomAeTitle } = values;

  if (!connectionType) {
    errors.connectionType = 'Required';
  }

  if (!modality) {
    if (connectionType === 'Imaging Modality') {
      errors.modality = 'Required';
    }
  }

  if (!dicomAeTitle) {
    if (connectionTypeOptionsWithDicomInfo.includes(connectionType)) {
      errors.dicomAeTitle = 'Required';
    }
  }

  return errors;
};
