import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { t } from 'i18next';
import AddFacilityForm from './FacilityForm';
import validation from './validation';
import {
  addCustomLetterhead,
  addFacilityLogo,
  removeFacilityLogo,
  uploadFacilityLogo
} from '../../../../../store/actions';
import type { Facility } from '../../../../../schemas';
import FacilityFormFooter from './FacilityFormFooter';

const ADD_INTERNAL_FACILITY_FORM_NAME = 'addInternalFacilityForm';

type Props = {
  visible: boolean,
  loading: boolean,
  onCancel: () => void,
  onSubmit: Object => void, // eslint-disable-line react/no-unused-prop-types
  handleSubmit: Object => void,
  pristine: boolean,
  submitting: boolean,
  countryList: Array<{ text: string, value: string }>,
  addLogoAction: string => void,
  removeLogoAction: () => void,
  uploadLogoAction: () => void,
  previousLogo: string,
  logo: string,
  initialValues: Facility,
  letterhead: string,
  addLetterheadAction: string => void
};

export default connect(
  state => ({
    initialValues: state.initialForm.addInternalFacility,
    logo: state.facilities.logo,
    previousLogo: state.facilities.previousLogo,
    letterhead: state.facilities.letterhead
  }),
  {
    uploadLogoAction: uploadFacilityLogo,
    addLogoAction: addFacilityLogo,
    removeLogoAction: removeFacilityLogo,
    addLetterheadAction: addCustomLetterhead
  }
)(
  reduxForm({
    form: ADD_INTERNAL_FACILITY_FORM_NAME,
    validate: validation
  })(
    ({
      visible,
      onCancel,
      loading,
      handleSubmit,
      pristine,
      submitting,
      countryList,
      uploadLogoAction,
      addLogoAction,
      removeLogoAction,
      logo,
      previousLogo,
      letterhead,
      addLetterheadAction
    }: Props) => {
      const [logoChanged, setLogoChanged] = React.useState(false);
      const handleSubmitWithLogo = () => {
        setLogoChanged(false);
        handleSubmit();
        uploadLogoAction();
      };
      return (
        <AddFacilityForm
          title={t('referral_patterns.table.add_internal_facility')}
          okText={t('button.add')}
          visible={visible}
          onCancel={onCancel}
          loading={loading}
          countryList={countryList}
          internalProps={{
            internal: true,
            facility: null,
            setLogoChanged,
            addLogoAction,
            removeLogoAction,
            logo,
            hasPreviousLogo: previousLogo && previousLogo !== '',
            hasLetterhead: letterhead && letterhead !== '',
            addLetterheadAction
          }}
        >
          <FacilityFormFooter
            disabled={(pristine || submitting) && !logoChanged}
            handleSubmit={handleSubmitWithLogo}
            loading={loading}
            okText={t('referral_patterns.table.add_facility')}
            onCancel={onCancel}
          />
        </AddFacilityForm>
      );
    }
  )
);
