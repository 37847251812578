/* eslint-disable no-console */
import React from 'react';
import { Form, Select } from 'antd';
import { isString } from 'lodash';

const { Option } = Select;

const InputSelectField = ({
  labelStyle = {},
  label = '',
  labelAlign = 'right',
  colon = false,
  width = '',
  maxWidth = '',
  required = false,
  minWidth = '',
  options = [],
  defaultValue = undefined,
  placeholder = '',
  formItemLayout = {},
  input,
  meta: { touched, error },
  ...restProps
}) => {
  if (!input.value) input.value = defaultValue;

  return (
    <Form.Item
      style={labelStyle}
      label={label}
      labelAlign={labelAlign}
      colon={colon}
      required={required}
      validateStatus='' // 'success' 'warning' 'error' 'validating'
      help={touched && error ? error : ''}
      {...formItemLayout}
    >
      <Select
        style={{ width, maxWidth, minWidth }}
        placeholder={placeholder}
        {...input}
        {...restProps}
        filterOption={(inputValue, option) =>
          isString(option.props.children) &&
          option.props.children
            .toLowerCase()
            .indexOf(inputValue.toLowerCase()) >= 0
        }
        getPopupContainer={trigger => trigger.parentNode}
      >
        {/* eslint-disable-next-line */}
        {options.map(({ value, label, text }) => {
          if (text) {
            return (
              <Option key={value} value={value}>
                {text}
              </Option>
            );
          }
          return (
            <Option key={value} value={value}>
              {label}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default InputSelectField;
