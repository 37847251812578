/* eslint-disable jsx-a11y/aria-role */
import React from 'react';
import { Button, Row, Col, Tooltip } from 'antd';
import { Field, reduxForm } from 'redux-form';

import { connect } from 'react-redux';
import userValidation from './userValidation';
import BaseUserForm from './BaseUserForm';
import { InputSelectField } from '../../../../components/FormParts';
import { t } from 'i18next';

const ADD_EXTERNAL_RAD_FORM_NAME = 'addExternalRadForm';

type Props = {
  visible: boolean,
  onCancel: () => void,
  loading: boolean,
  // Values passed by reduxForm
  handleSubmit: any => void,
  pristine: boolean,
  submitting: boolean
};

export default connect(
  state => ({
    initialValues: {
      ...state.initialForm.addInternalRad,
      radiologistGroup: 'external-radiologist'
    }
  }),
  {}
)(
  reduxForm({
    form: ADD_EXTERNAL_RAD_FORM_NAME,
    validate: userValidation
  })(
    ({
      visible,
      onCancel,
      loading,
      handleSubmit,
      pristine,
      submitting,
      setRadiologistType,
      radiologistType
    }: Props) => (
      <BaseUserForm
        loading={loading}
        onCancel={onCancel}
        visible={visible}
        title={t("button.add_new_user")}
        okText='Add'
        selectedFacilities={[]}
        role='Radiologist'
      >
        <Row
          type='flex'
          justify='space-between'
          align='bottom'
          className='user-form__actions'
          gutter={10}
        >
          <Col span={8}>
            <Field
              name='radiologistGroup'
              label='Invite user with'
              style={{ width: '100%' }}
              onChange={val => setRadiologistType(val)}
              component={InputSelectField([
                {
                  text: (
                    <Tooltip
                      placement='right'
                      title='Limited access allows a user to only see studies he or she has been assigned or shared.'
                    >
                      Limited Access
                    </Tooltip>
                  ),
                  value: 'external-radiologist'
                },
                {
                  text: (
                    <Tooltip
                      placement='right'
                      title="Full access allows the user to see all the group's medical images"
                    >
                      Full Access
                    </Tooltip>
                  ),
                  value: 'internal-radiologist'
                }
              ])}
            />
          </Col>
          <Col span={16} style={{ textAlign: 'right' }}>
            <div>
              <Button onClick={onCancel}>{t("button.cancel")}</Button>
              <Button
                disabled={pristine || submitting}
                type='primary'
                onClick={handleSubmit}
                loading={loading}
              >
                Add User
              </Button>
            </div>
          </Col>
        </Row>
      </BaseUserForm>
    )
  )
);
