import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Checkbox, Col, Modal, Radio, Row, Select, Table, Tooltip, Typography } from 'antd'
import { Column } from '@ant-design/charts'
import { bindActionCreators } from 'redux'
import * as QCDashboardActions from '../../store/actions/QCDashboardAction'
import { connect } from 'react-redux'
import QCRadiologistPerformanceForm from './form/QCRadiologistPerformanceForm'
import { selectFacilityOptions, selectModalityOptions, selectQCBodyPartList, selectUserOptions } from '../../store/selectors'
import { CSVLink } from 'react-csv'
import { t } from 'i18next'
import { reset } from 'redux-form'
import { DASHBOARD_QC_DOWNLOAD_FORM } from '../../utils/form-helpers'
import QCDownloadForm from './form/QCDownloadForm'
import { checkIsOldChart, getScrollableChartRatio } from '../../utils'

const { Text, Title } = Typography

const RadiologistPerformance = ({ 
  modalityOptions, 
  radiologistPerformance, 
  radiologistOptions, 
  facilityOptions,
  downloadQCRadiologistPerformance,
  setDownloadQCRadiologistPerformance,
  downloadableData,
  loadingChart,
  bodyPartOptions,
}) => {
  const [showChart, setShowChart] = useState(true)
  const [showTable, setShowTable] = useState(true)
  const [showQCScoreOnHover, setShowQCScoreOnHover] = useState(true)
  const [showRadiologistOnHover, setShowRadiologistOnHover] = useState(true)
  const [downloadModalVisible, setDownloadModalVisible] = useState(false)

  const csvLinkRef = useRef()
  const chartRef = useRef()

  const transformData = (data = []) => {
    return data?.flatMap(item => [
      {
        name: 'Resolved Studies',
        xField: item.radiologist,
        value: item.resolved_cases
      },
      {
        name: 'QC Flagged Studies',
        xField: item.radiologist,
        value: item.flagged_for_qc
      },
      {
        name: 'Total Studies',
        xField: item.radiologist,
        value: item.total_studies
      },
    ]);
  };

  const isOldChart = checkIsOldChart()

  const transformedData = transformData(radiologistPerformance?.data)
  const chartRatio = getScrollableChartRatio(transformedData.length)
  const config = isOldChart ? {
    data: transformedData,
    isGroup: true,
    xField: 'xField',
    yField: 'value',
    seriesField: 'name',
    color: ['#15A9E1', '#FF7A45', '#034159'],
    label: {
      position: 'top',
      style: {
        fill: '#8C8C8C',
        opacity: 1,
      },
    },
    xAxis: {
      label: {
        autoRotate: false,
        fontSize: 12,
        autoHide: false,
      }
    },
    yAxis: {
      grid: {
        line: {
          style: {
            lineDash: [0, 0],
            stroke: '#000000',
            strokeOpacity: 0.15
          }
        }
      }
    },
    legend: {
      position: 'bottom-left',
    },
    scrollbar: {
      type: 'horizontal',
      categorySize: 90,
    },
    width: undefined,
    height: 500,
  } : {
    ref: chartRef,
    data: transformedData,
    xField: 'xField',
    yField: 'value',
    seriesField: 'name',
    colorField: 'name',
    group: true,
    loading: loadingChart,
    axis: {
      x: {
        labelAutoRotate: false,
        labelAutoHide: false,
        labelAutoEllipsis: true,
        labelAutoWrap: true,
        labelFontSize: 12,
        labelAlign: 'horizontal',
      },
      y: {
        gridLineDash: [0, 0],
        gridStroke: '#000000',
        gridStrokeOpacity: 0.15
      }
    },
    scale: {
      color: {
        range: ['#15A9E1', '#FF7A45', '#034159']
      },
    },
    label: {
      textBaseline: 'bottom',
    },
    legend: {
      color: {
        position: 'bottom',
        colPadding: 16,
        itemWidth: 130,
        itemPadding: 200,
        width: 1000,
      },
    },
    scrollbar: chartRatio < 1 && {
      x: {
        ratio: chartRatio,
        style: {
          trackSize: 8,
          paddingBottom: 20,
          marginBottom: 20,
        },
      },
      y: false
    },
    width: undefined,
    height: 500,
    marginBottom: 48,
    animate: { enter: { type: 'growInY' } },
  };

  const columns = [
    {
      title: 'Radiologist',
      dataIndex: 'radiologist',
      width: 100,
      render: (text, record) => (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    },
    {
      title: 'QC Score',
      dataIndex: 'qc_score',
      width: 80,
    },
    {
      title: 'Total Studies',
      dataIndex: 'total_studies',
      width: 80,
    },
    {
      title: 'Flagged for QC',
      dataIndex: 'flagged_for_qc',
      width: 80,
    },
    {
      title: 'Resolved Cases',
      dataIndex: 'resolved_cases',
    },
    {
      title: 'Avg Resolution Time',
      dataIndex: 'average_resolution_time',
    },
  ];

  const csvHeaders = [
    {
      label: 'Radiologist',
      key: 'radiologist',
    },
    {
      label: 'QC Score',
      key: 'qc_score',
    },
    {
      label: 'Total Studies',
      key: 'total_studies',
    },
    {
      label: 'Flagged for QC',
      key: 'flagged_for_qc',
    },
    {
      label: 'Resolved Cases',
      key: 'resolved_cases',
    },
    {
      label: 'Avg Resolution Time',
      key: 'average_resolution_time',
    },
  ];

  const csvData = downloadableData ? downloadableData.map(item => ({
    radiologist: item.radiologist ?? '',
    qc_score: Object.values(item.qc_score ?? {}).join('\n'),
    total_studies: item.total_studies ?? '',
    flagged_for_qc: item.flagged_for_qc ?? '',
    resolved_cases: item.resolved_cases ?? '',
    average_resolution_time: item.average_resolution_time ?? '',
  })) : [];

  const expandedRowRender = (record) => {
    const qcScore = record.qc_score;
    if (!qcScore) return null;
    
    const columns = [
      { render: () => null, dataIndex: 'radiologist', width: 100, },
      { dataIndex: 'score', key: 'score', width: 80 },
      { dataIndex: 'total', key: 'total', width: 80, },
      { dataIndex: 'flagged', key: 'flagged', width: 80, },
      { render: () => null, dataIndex: 'resolved_cases' },
      { render: () => null, dataIndex: 'average_resolution_time' },
    ];

    const data = [];

    for (const i of Object.keys(qcScore)) {
      data.push({
        score: i,
        total: i === '1' ? qcScore[i] : null,
        flagged: i !== '1' ? qcScore[i] : null,
      });
    }

    return <Table columns={columns} dataSource={data} showHeader={false} className='table-expanded-row' pagination={false} />;
  };

  const handleDownload = () => {
    downloadQCRadiologistPerformance()
  }

  const handleCancelDownload = () => {
    setDownloadModalVisible(false)
  }

  useEffect(() => {
    if (downloadableData?.length) {
      csvLinkRef.current.link.click()
      setDownloadQCRadiologistPerformance([])
      handleCancelDownload()
      reset(DASHBOARD_QC_DOWNLOAD_FORM)
    }
  }, [downloadableData])

  useEffect(() => {
    if (chartRef.current) chartRef.current.chart.render();
    if (chartRef.current) chartRef.current.chart.forceFit();
  }, [showChart, showTable])

  return (
    <Row gutter={[16, 16]}>
      <Col>
        <Row type='flex' style={{ justifyContent: 'space-between' }}>
          <Col><Title level={4}>{t('dashboard.radiologist_performance')}</Title></Col>
          <Col>
            <CSVLink
              ref={csvLinkRef}
              headers={csvHeaders}
              data={csvData}
              filename={`QC_dashboard_radiologist_performance_${new Date().toISOString().split('T')[0]
                }`}
            />
            <Tooltip placement='left' title={t("dashboard.download_csv")}>
              <Button type='primary' onClick={() => setDownloadModalVisible(true)}>{t('dashboard.download_as_csv')}</Button>
            </Tooltip>
            <Modal
              title={t('dashboard.download_as_csv')}
              visible={downloadModalVisible}
              onOk={handleDownload}
              onCancel={handleCancelDownload}
              okText='Download'
              width={643}
            >
              <QCDownloadForm modalityOptions={modalityOptions} radiologistOptions={radiologistOptions} facilityOptions={facilityOptions} bodyPartOptions={bodyPartOptions} />
            </Modal>
          </Col>
        </Row>
      </Col>
      <Col>
        <QCRadiologistPerformanceForm modalityOptions={modalityOptions} radiologistOptions={radiologistOptions} bodyPartOptions={bodyPartOptions} />
      </Col>
      <Col>
        <Row type='flex' gutter={[32, 12]}>
          <Col>
            <Row type='flex' gutter={8}>
              <Col>
                <Text>{t('dashboard.display_option')}</Text>
              </Col>
              <Col>
                <Checkbox checked={showChart} onChange={e => setShowChart(e.target.checked)}>{t('dashboard.label_chart')}</Checkbox>
                <Checkbox checked={showTable} onChange={e => setShowTable(e.target.checked)}>{t('dashboard.label_table')}</Checkbox>
              </Col>
            </Row>
          </Col>
          {/* <Col>
            <Row type='flex' gutter={8}>
              <Col>
                <Text>Show on Hover</Text>
              </Col>
              <Col>
                <Checkbox checked={showQCScoreOnHover} onChange={e => setShowQCScoreOnHover(e.target.checked)}>QC Score</Checkbox>
                <Checkbox checked={showRadiologistOnHover} onChange={e => setShowRadiologistOnHover(e.target.checked)}>Radiologist</Checkbox>
              </Col>
            </Row>
          </Col> */}
        </Row>
      </Col>
      <Col>
        <Row type='flex' gutter={32}>
          {showChart && (
            <Col xs={24} lg={showTable ? 14 : 24}>
              <Column {...config} />
            </Col>
          )}
          {showTable && (
            <Col xs={24} lg={showChart ? 10 : 24} className='qc-table'>
              <Table
                columns={columns}
                dataSource={radiologistPerformance?.data}
                size="small"
                defaultExpandAllRows={true}
                expandRowByClick={true}
                expandedRowRender={expandedRowRender}
                scroll={{ x: true }}
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => ({
  radiologistPerformance: state.QCDashboard.radiologistPerformance.data,
  downloadableData: state.QCDashboard.radiologistPerformance.downloadableData?.data,
  modalityOptions: selectModalityOptions(state),
  radiologistOptions: selectUserOptions(state),
  facilityOptions: selectFacilityOptions(state),
  loadingChart: state.loading.getQCRadiologistPerformance,
  bodyPartOptions: selectQCBodyPartList('radiologistPerformance')(state),
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...QCDashboardActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(RadiologistPerformance)