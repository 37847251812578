/* eslint-disable */
import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/groupActions';
import { GET_GROUP_LOADING, UPDATE_GROUP_LOADING } from '../actions';

import {
  createGroupApiCall,
  getUserGroupApiCall,
  updateGroupAPiCall
} from '../../api';
import { showError, showSuccess } from '../helpers';
import { getCurrentUser } from '../selectors';
import { getGroupLetterhead, getGroupNewLetterhead } from '../selectors';
import { t } from 'i18next';

function* getGroupSaga() {
  yield put({ type: GET_GROUP_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  try {
    const group = yield call(getUserGroupApiCall, profile);
    yield put({ type: actions.GET_GROUP_SUCCESS, group });
  } catch (e) {
    try {
      yield call(createGroupApiCall, profile, {
        group_id: profile.appMetadata.affiliatedGroup,
        name: ''
      });
      const group = yield call(getUserGroupApiCall, profile);
      yield put({ type: actions.GET_GROUP_SUCCESS, group });
    } catch (err) {
      console.error(err.message);
      yield put({ type: actions.GET_GROUP_FAILURE });
      yield call(showError, { message: t('errors.failed_loading_group') });
    }
  } finally {
    yield put({ type: GET_GROUP_LOADING, loading: false });
  }
}

function* updateGroupSaga(action) {
  yield put({ type: UPDATE_GROUP_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  try {
    yield call(updateGroupAPiCall, profile, action.group);
    const group = yield call(getUserGroupApiCall, profile);
    yield put({ type: actions.GET_GROUP_SUCCESS, group });
  } catch (e) {
    console.error(e.message);
    yield put({ type: actions.UPDATE_GROUP_FAILURE });
    yield call(showError, { message: t('errors.failed_update_group') });
  } finally {
    yield put({ type: UPDATE_GROUP_LOADING, loading: false });
  }
}

function* updateGroupPrint(action) {
  yield put({ type: UPDATE_GROUP_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  const oldLetterhead = yield select(getGroupLetterhead);
  const newLetterhead = yield select(getGroupNewLetterhead);
  const letterhead = newLetterhead || oldLetterhead;
  try {
    yield call(updateGroupAPiCall, profile, { ...action.data, letterhead });
    const group = yield call(getUserGroupApiCall, profile);
    yield put({ type: actions.GET_GROUP_SUCCESS, group });
    yield call(showSuccess, 'Print group settings updated');
  } catch (e) {
    console.error(e.message);
    yield put({ type: actions.UPDATE_GROUP_FAILURE });
    yield call(showError, { message: t('errors.failed_update_group') });
  } finally {
    yield put({ type: UPDATE_GROUP_LOADING, loading: false });
  }
}

export default function* groupSaga() {
  yield takeLatest(actions.GET_GROUP_REQUEST, getGroupSaga);
  yield takeLatest(actions.UPDATE_GROUP_REQUEST, updateGroupSaga);
  yield takeLatest(actions.UPDATE_GROUP_PRINT, updateGroupPrint);
}
