/* eslint-disable react/no-children-prop */

import React from 'react';
import { Form, Radio } from 'antd';

import './InputCheckBoxField.less';

const FormItem = Form.Item;

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const renderOptions = ({ text, value }, index) => (
  <RadioButton key={index} value={value}>
    {text}
  </RadioButton>
);

const InputRadioButtonField = Options => ({
  input,
  meta,
  children,
  hasFeedback,
  label,
  labelStyle = {},
  ...rest
}) => {
  const hasError = meta.touched && meta.invalid;
  return (
    <FormItem
      label={label}
      validateStatus={hasError ? 'error' : 'success'}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
      style={labelStyle}
    >
      <RadioGroup {...input} {...rest} children={children}>
        {Options.map(renderOptions)}
      </RadioGroup>
    </FormItem>
  );
};

export default InputRadioButtonField;
