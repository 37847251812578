/* eslint-disable no-console */
import Logger from '../../utils/logger';

const COUNTRY_LIST_ENDPOINT = 'https://restcountries.com/v2/all';

// eslint-disable-next-line import/prefer-default-export
export const getCountryList = async () => {
  try {
    const config = {
      method: 'GET'
    };
    const response = await window.fetch(COUNTRY_LIST_ENDPOINT, config);
    const data = await response.json();
    Logger.log('API; COUNTRY LIST: ', data);
    return data;
  } catch (e) {
    Logger.log('API; COUNTRY LIST; ERROR: ', e);
    console.error('API; COUNTRY LIST; ERROR: ', e);
    throw e;
  }
};
