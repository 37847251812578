import React from 'react';
import { Col, Form, Row } from 'antd';
import { Field, reduxForm } from 'redux-form';
import InputTextField from '../../../../components/FormParts/InputTextField';
import TextAreaField from '../../../../components/FormParts/TextAreaField';
import InputTextAreaField from '../../../../components/FormParts/InputTextAreaField';
const validateForm = values => {
    const errors = {};
    if (!values.name) {
        errors.name = 'You must enter a name for the route.';
    }
    return errors;
};
const RouteForm = props => {
    return (
        <Form className='route-form'>
            <Row>
                <Col span={24}>
                    <Field
                        label="Route Name"
                        name="name"
                        placeholder="Add route name"
                        component={InputTextField}
                        autoFocus
                        hasFeedback
                        required
                    />
                </Col>
                <Col span={24}>
                    <Field
                        name="description"
                        placeholder="Add description"
                        label={<div className='custom-label'><span>Description </span> <span className="optional-text">{"(optional)"}</span></div>}
                        component={InputTextAreaField}
                        rows={4}
                    />
                </Col>
            </Row>
        </Form>
    );
};

export default reduxForm({
    form: 'routeForm',
    validate: validateForm,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
})(RouteForm);
