// @flow
import React from 'react';
import { Button, Row, Col, Icon } from 'antd';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';

import BaseDeviceForm from './BaseDeviceForm';

import type { User } from '../../../../schemas';
import deviceValidation from './deviceValidation';
import { connectionTypeOptionsWithDicomInfo } from '../../../../store/constants';

const EDIT_DEVICE_FORM_NAME = 'editDeviceForm';

type Props = {
  visible: boolean,
  onCancel: () => void,
  loading: boolean,
  // Called when the delete button is pushed
  onDelete: () => void,
  // User to edit, used to extract its role and if it's main
  user: User,
  // Values passed by reduxForm
  handleSubmit: any => void,
  deleteAction: any => void,
  pristine: boolean,
  submitting: boolean,
  isActiveUser: boolean,
  showDicomInfo: boolean,
  connectionTypeData: string
};

/**
 * Adapts the BaseDeviceForm to edit a user. It's connected to reduxForm.
 */
const selector = formValueSelector(EDIT_DEVICE_FORM_NAME);
export default connect(state => {
  return {
    showDicomInfo: connectionTypeOptionsWithDicomInfo.includes(
      selector(state, 'connectionType')
    ),
    connectionTypeData: selector(state, 'connectionType'),
    manufacturerData: selector(state, 'manufacturer')
  };
})(
  reduxForm({
    form: EDIT_DEVICE_FORM_NAME,
    validate: deviceValidation
  })((props: Props) => {
    const {
      visible,
      onCancel,
      loading,
      handleSubmit,
      deleteAction,
      pristine,
      showDicomInfo,
      connectionTypeData,
      submitting,
      manufacturerData,
    } = props;
    return (
      <BaseDeviceForm
        loading={loading}
        onCancel={onCancel}
        visible={visible}
        title='Edit Device Details'
        okText='Add'
        showDicomInfo={showDicomInfo}
        selectedFacilities={[]}
        connectionTypeData={connectionTypeData}
        manufacturerData={manufacturerData}
        hasEditButton
      >
        <Row type='flex' justify='space-between' align='middle'>
          <Col span={16} style={{ textAlign: 'left' }}>
            <div>
              <Button onClick={onCancel}>Cancel</Button>
              <Button
                disabled={pristine || submitting}
                type='primary'
                onClick={handleSubmit}
                loading={loading}
              >
                Update Details
              </Button>
            </div>
          </Col>
          <Col>
            <a href='#' onClick={deleteAction} style={{ color: '#ff4d4f' }}>
              <Icon type='delete' /> Delete
            </a>
          </Col>
        </Row>
      </BaseDeviceForm>
    );
  })
);
