// @flow

import * as React from 'react';
import { Modal, Spin, Row, Col, Divider } from 'antd';

import { t } from 'i18next';

export default ({
  data,
  loading,
  visible,
  renderFooter,
  handleCancel,
  kind
}) => {
  const [loadingState, setLoadingState] = React.useState(false);
  React.useEffect(() => {
    if (loading && !loadingState) {
      setLoadingState(true);
    } else if (!loading && loadingState) {
      setLoadingState(false);
    }
  }, [loading, loadingState]);
  const renderColumn = (title, value) => (
    <Col className='gutter-row' span={12}>
      <p>{title}</p>
      <p style={{ color: 'black' }}>{value || '-'}</p>
    </Col>
  );

  if (Object.entries(data).length === 0 || loadingState) {
    return (
      <Modal
        visible={visible}
        title='Internal Facility Details'
        okText='Edit details'
        footer={null}
        onCancel={handleCancel}
        wrapClassName='lateral-modal'
      >
        <div style={{ textAlign: 'center' }}>
          <Spin size='large' />
        </div>
      </Modal>
    );
  }
  const {
    name,
    facilityType,
    country,
    phoneNumber,
    email,
    website,
    primaryName,
    primaryEmail,
    contactNumber1,
    contactNumber2,
    contactNumber3
  } = data;
  return (
    <Modal
      title={t(`my_facilities.view_kind_source_facility`, { kind })}
      okText='Edit details'
      visible={visible}
      onCancel={handleCancel}
      destroyOnClose
      footer={renderFooter()}
      wrapClassName='lateral-modal'
    >
      <Row gutter={32}>
        {renderColumn(t('my_facilities.facility_name'), name)}
        {renderColumn(t('my_facilities.facility_type'), facilityType)}
      </Row>
      <Row gutter={32}>
        {renderColumn(t('my_facilities.country'), country)}
        {renderColumn(t('my_facilities.office_number'), phoneNumber)}
      </Row>
      <Row gutter={32}>
        {renderColumn(t('my_facilities.table.email_adress'), email)}
        {renderColumn(t('my_facilities.website'), website)}
      </Row>
      <Divider orientation='left'>
        {t('my_facilities.primary_contact_details')}
      </Divider>
      <Row gutter={32}>
        {renderColumn(t('my_facilities.table.name'), primaryName)}
        {renderColumn(t('my_facilities.table.email_adress'), primaryEmail)}
      </Row>
      <Row gutter={32}>
        {[contactNumber1, contactNumber2]
          .filter(p => !!p)
          .map((p, i) =>
            renderColumn(
              t('my_facilities.table.phone_number') + ` ${i + 1}`,
              p && p.phone ? p.phone : p
            )
          )}
      </Row>
      {contactNumber3 && (
        <Row gutter={32}>
          {renderColumn(
            t('my_facilities.table.phone_number') + ' 3',
            contactNumber3
          )}
        </Row>
      )}
    </Modal>
  );
};
