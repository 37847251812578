import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, change } from 'redux-form';
import { Button, Col, Row } from 'antd';

import { bindActionCreators } from 'redux';
import { t } from 'i18next';
import validation from './Table/validation';
import PhysicianForm from './Table/PhysicianForm';
// import BaseIntRadForm from '../radiologist/internal/forms/BaseIntRadForm';

const ADD_PHYSICIAN_FORM_NAME = 'addPhysicianForm';

export default connect(
  state => ({
    initialValues: state.initialForm.addPhysician,
    formData: getFormValues(ADD_PHYSICIAN_FORM_NAME)(state)
  }),
  dispatch =>
    bindActionCreators(
      {
        change
      },
      dispatch
    )
)(
  reduxForm({
    form: ADD_PHYSICIAN_FORM_NAME,
    validate: validation
  })(
    ({
      visible,
      onCancel,
      loading,
      change: changeForm,
      handleSubmit,
      pristine,
      formData,
      isInternal,
      submitting
    }) => (
      <PhysicianForm
        title={t('referral_patterns.add_referring_physician')}
        okText='Add'
        visible={visible}
        change={changeForm}
        data={formData}
        isInternal={isInternal}
        onCancel={onCancel}
        loading={loading}
      >
        <Row type='flex' justify='space-around' align='middle'>
          <Col span={12} offset={12} style={{ textAlign: 'right' }}>
            <Button onClick={onCancel}>{t('button.cancel')}</Button>
            <Button
              disabled={pristine || submitting}
              type='primary'
              onClick={handleSubmit}
              loading={loading}
            >
              {t('button.add')}
            </Button>
          </Col>
        </Row>
      </PhysicianForm>
    )
  )
);
