import React, { useState } from 'react';
import { Col, Form, Modal, Row } from 'antd';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';
import {
  InputPhoneNumberField,
  InputTextField
} from '../../../../../components/FormParts';
import { FACILITY_TYPE } from '../../../../../utils/filters';
import AddInternalFacility from './facilities/AddInternalFacility';
import AddExternalFacility from './facilities/AddExternalFacility';
import InputSelectField from '../../../../../common/form-helpers/InputSelectField';

export const valuesToFacility = (values, facilities) => ({
  ...values,
  phone: values.phone ? values.phone.phone : undefined,
  primaryFacility:
    values.primaryFacility && values.primaryFacility.length
      ? {
          name: values.primaryFacility[values.primaryFacility.length - 1],
          guid: facilities.find(
            f =>
              f.name ===
              values.primaryFacility[values.primaryFacility.length - 1]
          ).guid
        }
      : undefined
});

export default connect(state => {
  const { facilities } = state;
  const internalFacilities = facilities.internalArray;
  const externalFacilities = facilities.externalArray;

  return {
    internalFacilities,
    externalFacilities
  };
}, {})(
  ({
    title,
    okText,
    data,
    visible,
    onCancel,
    change,
    children,
    isInternal,
    internalFacilities,
    externalFacilities
  }) => {
    const [facilityModal, openFacilityModal] = useState(false);

    return (
      <Modal
        visible={visible}
        title={title}
        okText={okText}
        onCancel={onCancel}
        destroyOnClose={false}
        footer={children}
      >
        <Form layout='vertical'>
          <Row gutter={32}>
            <Col className='gutter-row' span={12}>
              <Field
                name='name'
                label={t('referral_patterns.table.name')}
                placeholder='Name'
                component={InputTextField}
              />
            </Col>
            <Col className='gutter-row' span={12}>
              <Field
                name='facilityType'
                label={t('referral_patterns.table.facility_type')}
                placeholder='Select the type of the facility...'
                options={FACILITY_TYPE}
                component={InputSelectField}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col className='gutter-row' span={12}>
              <Field
                name='email'
                label={t('referral_patterns.table.contact_email')}
                type='email'
                placeholder='mail@domain.com'
                component={InputTextField}
              />
            </Col>
            <Col className='gutter-row' span={12}>
              <Field
                name='phone'
                label={t('referral_patterns.table.mobile_number_optional')}
                component={InputPhoneNumberField}
                countryCodeEditable={false}
                defaultCountry='sg'
                disableAreaCodes
              />
            </Col>
          </Row>
          {facilityModal &&
            (isInternal ? (
              <AddInternalFacility
                onClose={() => openFacilityModal(false)}
                visible={facilityModal}
              />
            ) : (
              <AddExternalFacility
                onClose={() => openFacilityModal(false)}
                visible={facilityModal}
              />
            ))}
        </Form>
      </Modal>
    );
  }
);
