import { createSelector } from 'reselect';

const selectPhysiciansInput = state => state.physicians;

export const selectPhysicianOptions = createSelector(
  selectPhysiciansInput,
  physicians => {
    const physiciansOptions = [
      { value: 'ALL', label: 'All Source Facilities' }
    ];
    try {
      if (physicians.array) {
        physicians.array.forEach(physician => {
          physiciansOptions.push({
            value: physician.name,
            label: physician.name
          });
        });
      }
      return physiciansOptions;
    } catch (err) {
      return physiciansOptions;
    }
  }
);
