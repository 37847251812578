import Logger from '../../utils/logger';

const getUserLocationAPI = async () => {
  try {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const response = await window.fetch(
      `https://ipinfo.io/json?token=b00a4f8da7be54`,
      config
    );
    return await response.json();
  } catch (error) {
    Logger.log('error', error);
    return 0;
  }
};

export default getUserLocationAPI;
