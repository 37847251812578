import React from 'react';
import { Form, AutoComplete } from 'antd';

const AutoCompleteField = ({
  labelStyle = {},
  label = '',
  colon = false,
  required = false,
  // fullWidth = false,
  // width = '',
  defaultValue = undefined,
  input,
  meta: { touched, error },
  placeholder,
  dataSource = []
  // ...restProps
}) => {
  if (!input.value) input.value = defaultValue;

  return (
    <Form.Item
      style={labelStyle}
      colon={colon}
      label={label}
      required={required}
      validateStatus='' // 'success' 'warning' 'error' 'validating'
      help={touched && error ? error : ''}
    >
      <AutoComplete
        {...input}
        placeholder={placeholder}
        dataSource={dataSource}
      />
    </Form.Item>
  );
};

export default AutoCompleteField;
