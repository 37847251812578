import React, { useEffect, useState } from 'react';
import AddInternalRadiologist from './internal/AddInternalRadiologist';
import AddExternalRadiologist from './external/AddExternalRadiologist';

export default function AddRadiologist({ visible, closeModal, selectedTab }) {
  const [radiologistType, setRadiologist] = useState();

  useEffect(() => {
    setRadiologist(
      selectedTab === '1' ? 'internal-radiologist' : 'external-radiologist'
    );
  }, [selectedTab]);

  return radiologistType ? (
    radiologistType === 'external-radiologist' ? (
      <AddExternalRadiologist
        visible={visible}
        closeModal={closeModal}
        radiologistType={radiologistType}
        setRadiologistType={setRadiologist}
      />
    ) : (
      <AddInternalRadiologist
        visible={visible}
        closeModal={closeModal}
        radiologistType={radiologistType}
        setRadiologistType={setRadiologist}
      />
    )
  ) : null;
}
