/* eslint-disable */
import type { Group } from '../../schemas';
import {
  ADD_FACILITY_CUSTOM_LETTERHEAD,
  UPLOAD_FACILITY_CUSTOM_LETTERHEAD
} from './facilityActions';

export const GET_GROUP_REQUEST = 'GET_GROUP_REQUEST';
export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS';
export const GET_GROUP_FAILURE = 'GET_GROUP_FAILURE';

export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE';

export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';

export const ADD_GROUP_LOGO = 'ADD_GROUP_LOGO';
export const REMOVE_GROUP_LOGO = 'REMOVE_GROUP_LOGO';
export const UPLOAD_GROUP_LOGO = 'UPLOAD_GROUP_LOGO';

export const UPDATE_GROUP_PRINT = 'UPDATE_GROUP_PRINT';
export const ADD_GROUP_LETTERHEAD = 'ADD_GROUP_LETTERHEAD';
export const UPLOAD_GROUP_LETTERHEAD = 'UPLOAD_GROUP_LETTERHEAD';

export type UpdateGroupAction = { type: string, group: Group };
export type UpdatePrintAction = {
  type: string,
  data: { hasLetterhead: boolean }
};
export type UploadLetterheadAction = { type: string, letterhead: string };

export const getGroupRequest = () => ({ type: GET_GROUP_REQUEST });
export const updateGroupRequest = (group: Group) => ({
  type: UPDATE_GROUP_REQUEST,
  group
});
export const updatePrint = (data: { hasLetterhead: boolean }) => ({
  type: UPDATE_GROUP_PRINT,
  data
});
export const addGroupLogo = (logo: string) => ({ type: ADD_GROUP_LOGO, logo });
export const removeGroupLogo = () => ({ type: REMOVE_GROUP_LOGO });
export const uploadGroupLogo = () => ({ type: UPLOAD_GROUP_LOGO });

export const addGroupLetterhead = (letterhead: string) => ({
  type: ADD_GROUP_LETTERHEAD,
  letterhead
});
export const uploadGroupLetterhead = () => ({ type: UPLOAD_GROUP_LETTERHEAD });
