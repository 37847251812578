import React from "react";
import { Button, Col, Row, Tooltip, Typography } from 'antd';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { pages } from "../../../utils/routes";
const { Title } = Typography;

const CommonHeader = ({ title, description, buttonText, hideBtn, onSubmit }) => {
    return (
        <div>
            <Row type='flex' justify='space-between' align='middle'>
                <Col>
                    <Title level={3} style={{ color: "#000", fontWeight: 600 }}> {title}</Title>
                    <p>{description}</p>
                </Col>
                {!hideBtn && <Col>
                    <Link to={pages.createRoute}><Button type="primary" onClick={onSubmit}>{buttonText}</Button></Link>
                </Col>}
            </Row>
        </div>
    )
};

export default CommonHeader;
