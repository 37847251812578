// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Col, Form, Modal, Row } from 'antd';
import { Field } from 'redux-form';
import {
  InputPhoneNumberField,
  InputSelectField,
  InputTextField
} from '../../../../components/FormParts';
import { RAD_SPECIALTY, USER_TYPE } from '../../../../utils/filters';
import './BaseUserForm.less';
import type { Facility, ReduxState } from '../../../../schemas';

type Props = {
  visible: boolean,
  title: string,
  // Text of the ok button
  okText: string,
  // What to do if the cancel button is pushed. It's required by the  Modal class
  onCancel: () => void,
  isMain: boolean,
  // Initial role for the user
  role: ?string,
  children: React.Node,
  facilities: Array<Facility>,
  selectedFacilities: Array<Facility>
};

/**
 * Contains the controls used to add or edit a user.
 */
export default connect(
  (state: ReduxState) => ({
    facilities: state.facilities.internalArray
  }),
  {}
)((props: Props) => {
  const { visible, onCancel, title, okText, children } = props;

  // Role is the userType of the edited user. We use it to decide if render
  // the specialty row
  // eslint-disable-next-line
  const [role, setRole] = React.useState<?string>(props.role);
  const [specialtyOpen, openSpecialty] = React.useState(false);
  const [userTypeOpen, openUserType] = React.useState(false);

  return (
    <Modal
      visible={visible}
      title={title}
      okText={okText}
      onCancel={onCancel}
      destroyOnClose={false}
      footer={children}
      wrapClassName='lateral-modal'
    >
      <Form layout='vertical'>
        <Row gutter={32}>
          <Col xs={24} sm={12}>
            <Row gutter={8} type='flex' style={{ flexWrap: 'nowrap' }}>
              <Col>
                <Field
                  style={{ width: 50 }}
                  name='title'
                  label='Title'
                  placeholder='Dr.'
                  component={InputTextField}
                />
              </Col>
              <Col>
                <Field
                  name='name'
                  label='Name'
                  placeholder='Name'
                  component={InputTextField}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={12}>
            <Field
              name='credentials'
              label='Credentials'
              placeholder='MD, ARRT, FACR, etc'
              component={InputTextField}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Field
              name='userType'
              label='Role'
              placeholder='Please select...'
              component={InputSelectField(
                USER_TYPE,
                userTypeOpen,
                () => openUserType(true),
                () => openUserType(false)
              )}
              disabled
              onChange={value => setRole(value)}
            />
          </Col>
          <Col xs={24} sm={12}>
            {role && role.toLowerCase() === 'radiologist' && (
              <Field
                name='specialty'
                label='Specialty'
                showSearch
                placeholder='Type to search or select'
                component={InputSelectField(
                  RAD_SPECIALTY,
                  specialtyOpen,
                  () => openSpecialty(true),
                  () => openSpecialty(false)
                )}
                mode='multiple'
              />
            )}
          </Col>
          <Col xs={24} sm={12}>
            <Field
              name='email'
              label='Email Address'
              type='email'
              placeholder='mail@domain.com'
              component={InputTextField}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Field
              name='phoneNumber'
              label='Mobile Number'
              component={InputPhoneNumberField}
              defaultCountry='sg'
              disableAreaCodes
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
