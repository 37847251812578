import type { Group } from '../../schemas';
import {
  ADD_GROUP_LETTERHEAD,
  ADD_GROUP_LOGO,
  GET_GROUP_SUCCESS,
  REMOVE_GROUP_LOGO
} from '../actions';

export default (state: Group & { previousLogo: string } = {}, action) => {
  if (action.type === GET_GROUP_SUCCESS) {
    const group = state;
    Object.assign(group, action.group);
    return group;
  }
  if (action.type === ADD_GROUP_LOGO) {
    const previousLogo = state.logo;
    return {
      ...state,
      previousLogo,
      logo: action.logo
    };
  }
  if (action.type === ADD_GROUP_LETTERHEAD) {
    const previousLetterhead = state.letterhead;
    return {
      ...state,
      previousLetterhead,
      newLetterhead: action.letterhead
    };
  }
  if (action.type === REMOVE_GROUP_LOGO) {
    const logo = state.previousLogo ? state.previousLogo : '';
    return {
      ...state,
      previousLogo: '',
      logo
    };
  }
  return state;
};
