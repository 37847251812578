import type { Physician } from '../../schemas';

export const GET_PHYSICIANS_REQUEST = 'GET_PHYSICIANS_REQUEST';
export const GET_PHYSICIANS_SUCCESS = 'GET_PHYSICIANS_SUCCESS';
export const GET_PHYSICIANS_FAILED = 'GET_PHYSICIANS_FAILED';

export const GET_EXTERNAL_PHYSICIANS_REQUEST =
  'GET_EXTERNAL_PHYSICIANS_REQUEST';
export const GET_EXTERNAL_PHYSICIANS_SUCCESS =
  'GET_EXTERNAL_PHYSICIANS_SUCCESS';
export const GET_EXTERNAL_PHYSICIANS_FAILED = 'GET_EXTERNAL_PHYSICIANS_FAILED';

export const GET_PHYSICIAN_BY_ID_REQUEST = 'GET_PHYSICIAN_BY_ID_REQUEST';
export const GET_PHYSICIAN_BY_ID_SUCCESS = 'GET_PHYSICIAN_BY_ID_SUCCESS';
export const GET_PHYSICIAN_BY_ID_FAILED = 'GET_PHYSICIAN_BY_ID_FAILED';

export const ADD_PHYSICIAN_REQUEST = 'ADD_PHYSICIAN_REQUEST';
export const ADD_PHYSICIAN_SUCCESS = 'ADD_PHYSICIAN_SUCCESS';
export const ADD_PHYSICIAN_FAILED = 'ADD_PHYSICIAN_FAILED';

export const EDIT_PHYSICIAN_REQUEST = 'EDIT_PHYSICIAN_REQUEST';
export const EDIT_PHYSICIAN_SUCCESS = 'EDIT_PHYSICIAN_SUCCESS';
export const EDIT_PHYSICIAN_FAILED = 'EDIT_PHYSICIAN_FAILED';

export const DELETE_PHYSICIAN_REQUEST = 'DELETE_PHYSICIAN_REQUEST';
export const DELETE_PHYSICIAN_SUCCESS = 'DELETE_PHYSICIAN_SUCCESS';
export const DELETE_PHYSICIAN_FAILED = 'DELETE_PHYSICIAN_FAILED';

export const getAllPhysiciansAction = () => ({ type: GET_PHYSICIANS_REQUEST });
export const getAllExternalPhysiciansAction = () => ({
  type: GET_EXTERNAL_PHYSICIANS_REQUEST
});
export const getPhysicianByIdAction = (guid: string) => ({
  type: GET_PHYSICIAN_BY_ID_REQUEST,
  guid
});
export const createPhysicianAction = (physician: Physician, isInternal) => ({
  type: ADD_PHYSICIAN_REQUEST,
  payload: { physician, isInternal }
});
export const editPhysicianAction = (physician: Physician) => ({
  type: EDIT_PHYSICIAN_REQUEST,
  physician
});
export const deletePhysicianAction = (guid: string) => ({
  type: DELETE_PHYSICIAN_REQUEST,
  guid
});
