import type { SecondOpinionSetting } from '../../schemas';

export const GET_SECOND_OPINION_SETTING_REQUEST =
  'GET_SECOND_OPINION_SETTING_REQUEST';
export const GET_SECOND_OPINION_SETTING_SUCCESS =
  'GET_SECOND_OPINION_SETTING_SUCCESS';
export const GET_SECOND_OPINION_SETTING_FAILURE =
  'GET_SECOND_OPINION_SETTING_FAILURE';

export const UPDATE_SECOND_OPINION_SETTING_REQUEST =
  'UPDATE_SECOND_OPINION_SETTING_REQUEST';
export const UPDATE_SECOND_OPINION_SETTING_SUCCESS =
  'UPDATE_SECOND_OPINION_SETTING_SUCCESS';
export const UPDATE_SECOND_OPINION_SETTING_FAILURE =
  'UPDATE_SECOND_OPINION_SETTING_FAILURE';

export const GET_SECOND_OPINION_LIST_REQUEST =
  'GET_SECOND_OPINION_LIST_REQUEST';
export const GET_SECOND_OPINION_LIST_SUCCESS =
  'GET_SECOND_OPINION_LIST_SUCCESS';
export const GET_SECOND_OPINION_LIST_FAILURE =
  'GET_SECOND_OPINION_LIST_FAILURE';

export const getSecondOpinionSettingRequest = () => ({
  type: GET_SECOND_OPINION_SETTING_REQUEST
});

export const getSecondOpinionSettingSuccess = (data: SecondOpinionSetting) => ({
  type: GET_SECOND_OPINION_SETTING_SUCCESS,
  data
});

export const getSecondOpinionSettingFailure = (error: string) => ({
  type: GET_SECOND_OPINION_SETTING_FAILURE,
  error
});

export const updateSecondOpinionSettingRequest = (
  data: SecondOpinionSetting
) => ({
  type: UPDATE_SECOND_OPINION_SETTING_REQUEST,
  data
});

export const updateSecondOpinionSettingSuccess = (
  data: SecondOpinionSetting
) => ({
  type: UPDATE_SECOND_OPINION_SETTING_SUCCESS,
  data
});

export const updateSecondOpinionSettingFailure = (error: string) => ({
  type: UPDATE_SECOND_OPINION_SETTING_FAILURE,
  error
});

export const getSecondOpinionListRequest = () => ({
  type: GET_SECOND_OPINION_LIST_REQUEST
});

export const getSecondOpinionListSuccess = (data: any) => ({
  type: GET_SECOND_OPINION_LIST_SUCCESS,
  data
});

export const getSecondOpinionListFailure = (error: string) => ({
  type: GET_SECOND_OPINION_LIST_FAILURE,
  error
});

export const SEND_SECOND_OPINION_QUOTATION_REQUEST =
  'SEND_SECOND_OPINION_QUOTATION_REQUEST';
export const SEND_SECOND_OPINION_QUOTATION_SUCCESS =
  'SEND_SECOND_OPINION_QUOTATION_SUCCESS';
export const SEND_SECOND_OPINION_QUOTATION_FAILURE =
  'SEND_SECOND_OPINION_QUOTATION_FAILURE';

export const sendSecondOpinionQuotationRequest = (id: string) => ({
  type: SEND_SECOND_OPINION_QUOTATION_REQUEST,
  id
});

export const SEND_SECOND_OPINION_PAYMENT_REQUEST_REQUEST =
  'SEND_SECOND_OPINION_PAYMENT_REQUEST_REQUEST';
export const SEND_SECOND_OPINION_PAYMENT_REQUEST_SUCCESS =
  'SEND_SECOND_OPINION_PAYMENT_REQUEST_SUCCESS';
export const SEND_SECOND_OPINION_PAYMENT_REQUEST_FAILURE =
  'SEND_SECOND_OPINION_PAYMENT_REQUEST_FAILURE';

export const sendSecondOpinionPaymentRequestRequest = (id: string) => ({
  type: SEND_SECOND_OPINION_PAYMENT_REQUEST_REQUEST,
  id
});

export const SEND_SECOND_OPINION_REPORT_REQUEST =
  'SEND_SECOND_OPINION_REPORT_REQUEST';
export const SEND_SECOND_OPINION_REPORT_SUCCESS =
  'SEND_SECOND_OPINION_REPORT_SUCCESS';
export const SEND_SECOND_OPINION_REPORT_FAILURE =
  'SEND_SECOND_OPINION_REPORT_FAILURE';

export const sendSecondOpinionReportRequest = (id: string) => ({
  type: SEND_SECOND_OPINION_REPORT_REQUEST,
  id
});

export const SEND_SECOND_OPINION_CODE_REQUEST =
  'SEND_SECOND_OPINION_CODE_REQUEST';

export const SEND_SECOND_OPINION_CODE_SUCCESS =
  'SEND_SECOND_OPINION_CODE_SUCCESS';

export const SEND_SECOND_OPINION_CODE_FAILURE =
  'SEND_SECOND_OPINION_CODE_FAILURE';

export const sendSecondOpinionCodeRequest = (secondOpinionCodeParam: {
  email: string,
  type: string
}) => ({
  type: SEND_SECOND_OPINION_CODE_REQUEST,
  secondOpinionCodeParam
});
