export const FILTER_QC_DASHBOARD = 'FILTER_QC_DASHBOARD';
export const FILTER_QC_DASHBOARD_SUCCESS = 'FILTER_QC_DASHBOARD_SUCCESS';
export const FILTER_QC_DASHBOARD_FAILURE = 'FILTER_QC_DASHBOARD_FAILURE';

export const GET_QC_METRICS_SUMMARY_REQUEST = 'GET_QC_METRICS_SUMMARY_REQUEST';
export const GET_QC_METRICS_SUMMARY_SUCCESS = 'GET_QC_METRICS_SUMMARY_SUCCESS';
export const GET_QC_METRICS_SUMMARY_FAILURE = 'GET_QC_METRICS_SUMMARY_FAILURE';

export const GET_QC_MODALITY_BODYPART_INSIGHT_REQUEST = 'GET_QC_MODALITY_BODYPART_INSIGHT_REQUEST';
export const GET_QC_MODALITY_BODYPART_INSIGHT_SUCCESS = 'GET_QC_MODALITY_BODYPART_INSIGHT_SUCCESS';
export const GET_QC_MODALITY_BODYPART_INSIGHT_FAILURE = 'GET_QC_MODALITY_BODYPART_INSIGHT_FAILURE';

export const GET_QC_RADIOLOGIST_PERFORMANCE_REQUEST = 'GET_QC_RADIOLOGIST_PERFORMANCE_REQUEST';
export const GET_QC_RADIOLOGIST_PERFORMANCE_SUCCESS = 'GET_QC_RADIOLOGIST_PERFORMANCE_SUCCESS';
export const GET_QC_RADIOLOGIST_PERFORMANCE_FAILURE = 'GET_QC_RADIOLOGIST_PERFORMANCE_FAILURE';

export const DOWNLOAD_QC_MODALITY_BODYPART_INSIGHT_REQUEST = 'DOWNLOAD_QC_MODALITY_BODYPART_INSIGHT_REQUEST';
export const DOWNLOAD_QC_MODALITY_BODYPART_INSIGHT_SUCCESS = 'DOWNLOAD_QC_MODALITY_BODYPART_INSIGHT_SUCCESS';
export const DOWNLOAD_QC_MODALITY_BODYPART_INSIGHT_FAILURE = 'DOWNLOAD_QC_MODALITY_BODYPART_INSIGHT_FAILURE';

export const DOWNLOAD_QC_RADIOLOGIST_PERFORMANCE_REQUEST = 'DOWNLOAD_QC_RADIOLOGIST_PERFORMANCE_REQUEST';
export const DOWNLOAD_QC_RADIOLOGIST_PERFORMANCE_SUCCESS = 'DOWNLOAD_QC_RADIOLOGIST_PERFORMANCE_SUCCESS';
export const DOWNLOAD_QC_RADIOLOGIST_PERFORMANCE_FAILURE = 'DOWNLOAD_QC_RADIOLOGIST_PERFORMANCE_FAILURE';

export const GET_QC_BODY_PART_LIST_REQUEST = 'GET_QC_BODY_PART_LIST_REQUEST';
export const GET_QC_BODY_PART_LIST_SUCCESS = 'GET_QC_BODY_PART_LIST_SUCCESS';
export const GET_QC_BODY_PART_LIST_FAILURE = 'GET_QC_BODY_PART_LIST_FAILURE';

export const downloadQCModalityBodyPartInsight = (sectionType) => ({ type: DOWNLOAD_QC_MODALITY_BODYPART_INSIGHT_REQUEST, payload: { sectionType } });
export const setDownloadQCModalityBodyPartInsight = (payload) => ({ type: DOWNLOAD_QC_MODALITY_BODYPART_INSIGHT_SUCCESS, payload });

export const downloadQCRadiologistPerformance = () => ({ type: DOWNLOAD_QC_RADIOLOGIST_PERFORMANCE_REQUEST });
export const setDownloadQCRadiologistPerformance = (payload) => ({ type: DOWNLOAD_QC_RADIOLOGIST_PERFORMANCE_SUCCESS, payload });

export const filterQCDashboard = (section, filterType, value) => ({ type: FILTER_QC_DASHBOARD, payload: { section, filterType, value } });
export const filterQCDashboardSuccess = (section, data) => ({ type: FILTER_QC_DASHBOARD_SUCCESS, payload: { section, data } });

export const getQCMetricsSummaryRequest = () => ({ type: GET_QC_METRICS_SUMMARY_REQUEST });
export const getQCMetricsSummarySuccess = (data) => ({ type: GET_QC_METRICS_SUMMARY_SUCCESS, payload: { data } });

export const getQCModalityBodyPartInsightRequest = () => ({ type: GET_QC_MODALITY_BODYPART_INSIGHT_REQUEST });
export const getQCModalityBodyPartInsightSuccess = (data) => ({ type: GET_QC_MODALITY_BODYPART_INSIGHT_SUCCESS, payload: { data } });

export const getQCRadiologistPerformanceRequest = () => ({ type: GET_QC_RADIOLOGIST_PERFORMANCE_REQUEST });
export const getQCRadiologistPerformanceSuccess = (data) => ({ type: GET_QC_RADIOLOGIST_PERFORMANCE_SUCCESS, payload: { data } });
