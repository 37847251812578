import React from 'react';
import { t } from 'i18next';
import { Icon, Modal } from 'antd/lib/index';

import Logger from '../utils/logger';

const { confirm } = Modal;

export function confirmDeletion(deleteFunc, id, name, description) {
  Logger.log('CONFIRM_DELETION', id, name, description);
  confirm({
    title: t('button.confirm_deletion'),
    content: `Delete this ${description}?`,
    okText: t('button.delete'),
    cancelText: t('button.cancel'),
    icon: (
      <Icon
        type='exclamation-circle'
        theme='filled'
        style={{ color: '#EEAF40' }}
      />
    ),
    onOk() {
      deleteFunc(id, name);
    },
    onCancel() {}
  });
}

export function confirmResetPassword(resetPassword, email) {
  confirm({
    title: 'Reset password?',
    content: t('user_administration.user_receive_email_link_reset'),
    okText: t('button.confirm'),
    cancelText: t('button.cancel'),
    onOk() {
      resetPassword(email);
    },
    onCancel() {}
  });
}
