import React from 'react'
import { change, Field, reduxForm } from 'redux-form'
import { DASHBOARD_QC_DOWNLOAD_FORM } from '../../../utils/form-helpers'
import { InputRadioButtonField } from '../../../components/FormParts'
import { DAY_OPTIONS } from '../../../utils/filters'
import moment from 'moment'
import { bodyPartFilterOptions, urgencyFilterOptions } from '../../../store/constants'
import CustomDropdownCheckboxGroupField from '../../../components/FormParts/CustomDropdownCheckboxGroupField'
import { t } from 'i18next'
import { Col, Row, Typography } from 'antd'
import Text from 'antd/lib/typography/Text'
import InputSelectField from '../../../common/form-helpers/InputSelectField';
import DateRangePickerField from '../../../common/form-helpers/DateRangePickerField'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const { Title } = Typography

const getLast12Months = () => {
  const months = [];
  for (let i = 11; i >= 0; i--) {
    const month = moment().subtract(i, 'months')
      months.push({ label: month.format('MMMM YYYY'), value: month.format('YYYY-MM') });
  }
  return months;
}

const monthList = getLast12Months()

const QCDownloadForm = ({ radiologistOptions, modalityOptions, facilityOptions, bodyPartOptions, resetField }) => {

  const handleChangeDateFilter = (field) => {
    if (field === 'selectedDate') {
      resetField('selectedMonth')
      resetField('selectedDateRange')
    }
    if (field === 'selectedMonth') {
      resetField('selectedDate')
      resetField('selectedDateRange')
    }
    if (field === 'selectedDateRange') {
      resetField('selectedDate')
      resetField('selectedMonth')
    }
  }
  return (
    <Row gutter={[16, 16]}>
      <Col>
        <Row gutter={[16, 16]}>
          <Col>
            <Text className='h5'>Select Date</Text>
          </Col>
          <Col>
            <Row type='flex' gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Field
                  name='selectedDate'
                  labelStyle={{ margin: 0 }}
                  width='100%'
                  component={InputRadioButtonField(DAY_OPTIONS)}
                  buttonStyle="solid"
                  onChange={() => handleChangeDateFilter('selectedDate')}
                />
              </Col>
              <Col xs={24} md={12}>
                <Field
                  name='selectedMonth'
                  placeholder={t('dashboard.select_month')}
                  options={monthList}
                  labelStyle={{ margin: 0 }}
                  width='100%'
                  component={InputSelectField}
                  onChange={() => handleChangeDateFilter('selectedMonth')}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Field
                  name='selectedDateRange'
                  labelStyle={{ margin: 0 }}
                  width='100%'
                  component={DateRangePickerField}
                  onChange={() => handleChangeDateFilter('selectedDateRange')}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row gutter={[16, 16]}>
          <Col>
            <Text className='h5'>Filter by</Text>
          </Col>
          <Col>
            <Row type='flex' gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Field
                  name='radiologists'
                  dropDownText={t('dashboard.select_radiologist')}
                  optionList={radiologistOptions}
                  labelStyle={{ margin: 0 }}
                  width='100%'
                  component={CustomDropdownCheckboxGroupField}
                  hideSearch
                  hideDialog
                  selectAllLabel={t('table.all_reporting_radiologists')}
                />
              </Col>
              <Col xs={24} md={12}>
                <Field
                  name='facility'
                  placeholder={t('dashboard.select_facility')}
                  options={facilityOptions}
                  labelStyle={{ margin: 0 }}
                  width='100%'
                  component={InputSelectField}
                />
              </Col>
            </Row>
            <Row type='flex' gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Field
                  name='urgency'
                  placeholder={t('dashboard.select_urgency')}
                  options={urgencyFilterOptions}
                  labelStyle={{ margin: 0 }}
                  width='100%'
                  component={InputSelectField}
                />
              </Col>
              <Col xs={24} md={12}>
                <Field
                  name='modality'
                  placeholder={t('dashboard.select_modality')}
                  options={modalityOptions}
                  labelStyle={{ margin: 0 }}
                  width='100%'
                  component={InputSelectField}
                />
              </Col>
            </Row>
            <Row type='flex' gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Field
                  name='bodyPart'
                  placeholder={t('dashboard.select_body_part')}
                  options={bodyPartOptions}
                  labelStyle={{ margin: 0 }}
                  width='100%'
                  component={InputSelectField}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetField: fieldName => change(DASHBOARD_QC_DOWNLOAD_FORM, fieldName, '')
}, dispatch)



export default connect(null, mapDispatchToProps)(
  reduxForm({
    form: DASHBOARD_QC_DOWNLOAD_FORM,
    initialValues: {
      sorting: 'total_highest',
      selectedDate: 'last_30_days'
    }
  })(QCDownloadForm)
)