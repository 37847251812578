import React from 'react';
import { Button, Col, Row } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
// import { Menu } from 'antd';
// import AddInternalFacility from './components/Edition/AddInternalFacility';
// import AddExternalFacility from './components/Edition/AddExternalFacility';

import { t } from 'i18next';
import './index.less';
// import TableInternalFacilities from './components/Table/TableInternalFacilities';
// import TableExternalFacilities from './components/Table/TableExternalFacilities';
import ReferralPhysiciansChart from '../referring-physicians/components/Chart/ReferringPhysiciansChart';
import ReferralFacilitiesChart from '../referring-facilities/components/Chart/ReferringFacilitiesChart';
import AddReferringPhysicians from '../referring-physicians/components/AddReferringPhysicians';
import AddReferringFacilities from '../referring-facilities/components/AddReferringFacilities';

// const { TabPane } = Tabs;

export default () => {
  const [
    addNewRefPhysicianVisible,
    setAddNewRefPhysicianVisible
  ] = React.useState(false);
  const [selectedTab] = React.useState('1');
  const openAddRefPhysician = () => setAddNewRefPhysicianVisible(true);
  const closeAddRefPhysician = () => setAddNewRefPhysicianVisible(false);
  const [
    addNewRefFacilityVisible,
    setAddNewRefFacilityVisible
  ] = React.useState(false);
  const openAddRefFacility = () => setAddNewRefFacilityVisible(true);
  const closeAddRefFacility = () => setAddNewRefFacilityVisible(false);

  return (
    <div className='facilities-index'>
      <AddReferringPhysicians
        selectedTab={selectedTab}
        visible={addNewRefPhysicianVisible}
        close={closeAddRefPhysician}
      />
      <AddReferringFacilities
        selectedTab={selectedTab}
        visible={addNewRefFacilityVisible}
        close={closeAddRefFacility}
      />
      <Row type='flex' justify='end' gutter={12}>
        <Col>
          <div className='physicians-header'>
            <Row type='flex' justify='end' align='middle'>
              <Col /* className='page-cta-wrapper' */>
                <Button
                  className='add-physician-btn'
                  type='primary'
                  onClick={openAddRefPhysician}
                  ghost
                >
                  <PlusCircleOutlined />{' '}
                  {t('referral_patterns.add_referring_physician')}
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
        <Col>
          <div className='physicians-header'>
            <Row type='flex' justify='end' align='middle'>
              <Col /* className='page-cta-wrapper' */>
                <Button
                  className='add-physician-btn'
                  type='primary'
                  onClick={openAddRefFacility}
                  ghost
                >
                  <PlusCircleOutlined />{' '}
                  {t('referral_patterns.add_referring_facility')}
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <ReferralPhysiciansChart />
      <ReferralFacilitiesChart />
    </div>
  );
};
