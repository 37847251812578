import React from 'react'
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons'
import { Card, Col, Row, Typography } from 'antd'
import { connect } from 'react-redux'
import { selectModalityOptions, selectQCBodyPartList, selectUserOptions } from '../../store/selectors'
import QCMetricsSummaryForm from './form/QCMetricsSummaryForm'
import { t } from 'i18next'

const { Text, Title } = Typography

const QCMetricsSummary = ({ 
  qcMetricsSummary, 
  modalityOptions, 
  radiologistOptions, 
  bodyPartOptions,
}) => {

  const qcData = [{
    data: qcMetricsSummary.data?.total_studies,
    percentage: qcMetricsSummary.data?.trends?.total_studies,
    title: t('dashboard.total_studies_reported'),
    description: t('dashboard.compared_to_previous_period'),
    upColor: '#52C41A',
    downColor: '#FF7875',
  }, {
    data: qcMetricsSummary.data?.flagged_studies_percent + '%',
    percentage: qcMetricsSummary.data?.trends?.flagged_studies_percent,
    title: t('dashboard.studies_flagged_for_qc'),
    description: t('dashboard.compared_to_previous_period'),
    downColor: '#52C41A',
    upColor: '#FF7875',
  }, {
    data: qcMetricsSummary.data?.average_qc_score,
    percentage: qcMetricsSummary.data?.trends?.average_qc_score,
    title: t('dashboard.average_qc_score'),
    description: t('dashboard.score_closer_to_1_indicates_highest_quality'),
    downColor: '#52C41A',
    upColor: '#FF7875',
  }, {
    data: qcMetricsSummary.data?.unresolved_cases,
    percentage: qcMetricsSummary.data?.trends?.unresolved_cases,
    title: t('dashboard.unresolved_qc_case'),
    description: t('dashboard.compared_to_previous_period'),
    downColor: '#52C41A',
    upColor: '#FF7875',
  }, {
    data: qcMetricsSummary.data?.average_resolution_time,
    percentage: qcMetricsSummary.data?.trends?.average_resolution_time,
    title: t('dashboard.average_resolution_time'),
    description: t('dashboard.compared_to_previous_period'),
    downColor: '#52C41A',
    upColor: '#FF7875',
  }]

  return (
    <Row gutter={[16, 16]}>
      <Col><Title level={4}>{t('dashboard.qc_metrics_summary')}</Title></Col>
      <Col>
        <QCMetricsSummaryForm
          modalityOptions={modalityOptions}
          radiologistOptions={radiologistOptions}
          bodyPartOptions={bodyPartOptions}
        />
      </Col>
      <Col>
        <Row type='flex' gutter={[16, 16]}>
          {qcData.map((qcData, key) => (
            <Col key={key} xs={24} md={12} xxl={4}>
              <Card>
                <Row type='flex' gutter={16}>
                  <Col><Title level={3}>{qcData.data}</Title></Col>
                  {qcData.percentage && (
                    <Col>
                      {qcData.percentage?.startsWith('-') ? <CaretDownFilled style={{ color: qcData.downColor }} /> : <CaretUpFilled style={{ color: qcData.upColor }} />}
                      <Text style={{ color: qcData.percentage?.startsWith('-') ? qcData.downColor : qcData.upColor }}>{qcData.percentage}</Text>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                    <Text>{qcData.title}</Text>
                  </Col>
                  <Col style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                    <Text style={{ 
                      color: '#00000073', 
                      fontSize: 10,
                    }}>
                      {qcData.description}
                    </Text>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => ({
  qcMetricsSummary: state.QCDashboard.qcMetricsSummary,
  modalityOptions: selectModalityOptions(state),
  radiologistOptions: selectUserOptions(state),
  bodyPartOptions: selectQCBodyPartList('qcMetricsSummary')(state),
})

export default connect(mapStateToProps)(QCMetricsSummary)