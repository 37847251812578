/* eslint-disable import/prefer-default-export */

// Dashboard page
export const ACTIVE_STUDIES_CARD_FORM_NAME = 'activeStudiesCardForm';
export const PROVIDER_PERFORMANCE_CARD_FORM_NAME =
  'providerPerformanceCardForm';
export const OVERVIEW_CARD_FORM_NAME = 'overviewCardForm';
export const STUDY_DETAILS_CARD_FORM_NAME = 'studyDetailsCardForm';
export const PROCEDURE_OVERVIEW_CARD_FORM_NAME = 'procedureOverviewCardForm';
export const STUDY_OVERVIEW_CARD_FORM_NAME = 'studyOverviewCardForm';
export const PROCEDURE_DETAILS_CARD_FORM_NAME = 'procedureDetailsCardForm';
export const SOURCE_FACILITIES_CHART_FORM_NAME = 'sourceFacilitiesChartForm';
export const SOURCE_REFERRING_FACILITIES_CHART_FORM_NAME =
  'sourceReferringFacilitiesChartForm';
export const SOURCE_REFERRING_PHYSICIANS_CHART_FORM_NAME =
  'sourceReferringPhysiciansChartForm';
export const SOURCE_REFERRING_PHYSICIANS_FORM_NAME =
  'sourceReferringPhysiciansForm';
export const SOURCE_REFERRING_FACILITIES_FORM_NAME =
  'sourceReferringFacilitiesForm';
export const RADIOLOGIST_PERFORMANCE_CARD_FORM_NAME =
  'radiologistPerformanceCardForm';

// Routing Preferences
export const ROUTES_FILTER_CARD_FORM_NAME = 'routesFilterCardForm';
export const ROUTES_CREATE_CARD_FORM_NAME = 'routesCreateCardForm';

export const ONBOARDING_FORM = 'onBoardingForm';
export const DASHBOARD_QC_METRICS_SUMMARY_FORM = 'dashboardQCMetricsSummaryForm';
export const DASHBOARD_MODALITY_BODYPART_INSIGHT_FORM = 'dashboardModalityBodyPartInsightForm';
export const DASHBOARD_QC_RADIOLOGIST_PERFORMANCE_FORM = 'dashboardQCRadiologistPerformanceForm';
export const DASHBOARD_QC_DOWNLOAD_FORM = 'dashboardQCDownloadForm';
