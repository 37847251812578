import * as React from 'react';
import { Button, Col, Row, Tabs } from 'antd';
import TablePhysicians from './components/TablePhysicians';
import TableExternalPhysicians from './components/TableExternalPhysicians';
import AddPhysician from './components/AddPhysician';
// import SourcePhysicianChart from './SourcePhysicianChart';

import './index.less';

const { TabPane } = Tabs;
export default () => {
  const [addNewVisible, setAddNewVisible] = React.useState(false);
  const [selectedTab, setTab] = React.useState('1');
  const openAdd = () => setAddNewVisible(true);
  const closeAdd = () => setAddNewVisible(false);

  const addButton = (
    <div className='physicians-header'>
      <Row type='flex' justify='end' align='middle'>
        <Col /* className='page-cta-wrapper' */>
          <Button
            className='add-physician-btn'
            type='primary'
            onClick={openAdd}
          >
            Add New Physician
          </Button>
        </Col>
      </Row>
    </div>
  );

  return (
    <div className='physicians-index'>
      <AddPhysician
        selectedTab={selectedTab}
        visible={addNewVisible}
        close={closeAdd}
      />
      {/* <SourcePhysicianChart /> */}
      <Row className='card-container  table-tabs'>
        <Tabs
          onChange={val => setTab(val)}
          type='card'
          tabBarExtraContent={addButton}
        >
          <TabPane tab='Internal Physicians' key='1'>
            <TablePhysicians />
          </TabPane>
          <TabPane tab='External Physicians' key='2'>
            <TableExternalPhysicians />
          </TabPane>
        </Tabs>
      </Row>
    </div>
  );
};
