export const validateLogin = values => {
  const errors = {};
  if (!values.username) {
    errors.username = 'required';
  }
  if (!values.password) {
    errors.password = 'required';
  }
  return errors;
};

export const validateForgotPassword = values => {
  const errors = {};
  if (!values.username) {
    errors.username = 'required';
  }
  if (!values.password) {
    errors.password = 'required';
  }
  return errors;
};
