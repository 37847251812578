// @flow

import * as React from 'react';
import { Col, Form, Modal, Row } from 'antd';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { ReduxState } from '../../../../schemas';
import {
  InputTextField,
  InputSelectField
} from '../../../../components/FormParts';

import '../../index.less';
import { ONBOARDING_FORM } from '../../../../utils/form-helpers';
import { FACILITY_TYPE } from '../../../../utils/filters';
import facilityValidation from '../forms/facilityValidation';

type Props = {
  visible: Boolean,
  setVisible: Function,
  handleSubmit: any => void,
  pristine: Boolean,
  submitting: Boolean
};

const AddFacilitiesModal = ({
  visible,
  setVisible,
  handleSubmit,
  pristine,
  submitting
}: Props) => {
  const [facilityTypeOpen, openFacilityType] = React.useState(false);

  const handleCancel = e => {
    setVisible(false);
  };

  return (
    <>
      <div>
        <Modal
          title='Add Facility'
          visible={visible}
          onOk={handleSubmit}
          onCancel={handleCancel}
          okText='Add'
        >
          <Form layout='vertical'>
            <Row gutter={32}>
              <Col className='gutter-row' span={12}>
                <Field
                  name='facility.name'
                  label='Facility Name'
                  placeholder='Facility Name'
                  component={InputTextField}
                />
              </Col>
              <Col className='gutter-row' span={12}>
                <Field
                  name='facility.type'
                  label='Facility Type'
                  placeholder='Select the type of the facility...'
                  component={InputSelectField(
                    FACILITY_TYPE,
                    facilityTypeOpen,
                    () => openFacilityType(true),
                    () => openFacilityType(false)
                  )}
                />
              </Col>
            </Row>
            <Row gutter={32}>
              <Col className='gutter-row' span={12}>
                <Field
                  name='facility.email'
                  label='Email Address'
                  type='email'
                  placeholder='Email'
                  component={InputTextField}
                />
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: ONBOARDING_FORM,
    validate: facilityValidation,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false
  })(AddFacilitiesModal)
);
