/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */
/**
 * The group endpoints manage the information of the affiliated group of the user. Each user has an affiliated
 * group in its app_metadata identified by guid, but their profile just contains this reference. This methods allows
 * to get and update this affiliated group information, or even register a new group that later would be associated to
 * the user.
 */
import AuthService from '../index';
import type {
  // ApiService,
  UserProfile
} from '../../schemas';
import Logger from '../../utils/logger';

// const API_SERVICE: ApiService = 'telerad-settings';
// const BASE_ROUTE = '/group';
let API_SERVICE = '';
let BASE_ROUTE = '';

/**
 * Gets the metadata associated with the guid of the affiliated group present in the app_metadata of the calling
 * user.
 * @param profile: User profile
 * @returns {never|Promise<*>} The group information
 */
export const getSecondOpinionSettingApiCall = async (profile: UserProfile) => {
  API_SERVICE = 'embed';
  BASE_ROUTE = `/embed/${profile.appMetadata.affiliatedGroup}`;
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, BASE_ROUTE, {}).then(g => g);
};

export const updateSecondOpinionSettingApiCall = async (
  profile: UserProfile,
  data
) => {
  API_SERVICE = 'embed';
  BASE_ROUTE = `/embed/${profile.appMetadata.affiliatedGroup}`;
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, BASE_ROUTE, {
    method: 'PUT',
    body: JSON.stringify(data)
  });
};

export const getSecondOpinionListApiCall = async (profile: UserProfile) => {
  // const Auth = await AuthService(profile.accessToken.length > 0);
  // return new Auth(profile).service(API_SERVICE, BASE_ROUTE, {}).then(g => jsonToGroup(g));
  return [
    {
      id: '1',
      name: 'Shawn Robertson',
      modality: 'CT',
      date: 'March 4, 2018',
      quotation: '$282',
      status: 'Status',
      payment: 'Paid',
      report: 'Ready'
    },
    {
      id: '2',
      name: 'Eduardo Cooper',
      modality: 'DX',
      date: 'April 6, 2014',
      quotation: '$884',
      status: 'Status',
      payment: 'Paid',
      report: 'Delivered'
    },
    {
      id: '3',
      name: 'Marjorie Murphy',
      modality: 'MG',
      date: 'May 17, 2015',
      quotation: '$513',
      status: 'Status',
      payment: 'Unpaid',
      report: 'Ready'
    }
  ];
};

export const sendSecondOpinionQuotationApiCall = async (
  profile: UserProfile,
  id
) => {
  // const Auth = await AuthService(profile.accessToken.length > 0);
  // return new Auth(profile).service(API_SERVICE, BASE_ROUTE, {}).then(g => jsonToGroup(g));
  alert(`Send Quotation ${id}`);
  return true;
};

export const sendSecondOpinionPaymentRequestApiCall = async (
  profile: UserProfile,
  id
) => {
  // const Auth = await AuthService(profile.accessToken.length > 0);
  // return new Auth(profile).service(API_SERVICE, BASE_ROUTE, {}).then(g => jsonToGroup(g));
  alert(`Send Payment Request ${id}`);
  return true;
};

export const sendSecondOpinionReportApiCall = async (
  profile: UserProfile,
  id
) => {
  // const Auth = await AuthService(profile.accessToken.length > 0);
  // return new Auth(profile).service(API_SERVICE, BASE_ROUTE, {}).then(g => jsonToGroup(g));
  alert(`Send Opinion ${id}`);
  return true;
};

export const sendSecondOpinionCodeApiCall = async (profile, requestBody) => {
  try {
    Logger.log('POST invite colleagues api call with profile: ', requestBody);
    const Auth = await AuthService(profile.accessToken.length > 0);
    const embedTemplateName = 'EMBED_DEVELOPER_LINK';
    BASE_ROUTE = `/signup/email/templated/${embedTemplateName}/`;
    API_SERVICE = 'base';
    return new Auth(profile)
      .service(API_SERVICE, BASE_ROUTE, {
        method: 'POST',
        body: JSON.stringify(requestBody)
      })
      .then(r => {
        Logger.log('POST internal radiologist api call response: ', r);
        return r;
      });
  } catch (e) {
    console.error(e);
    return e;
  }
};
