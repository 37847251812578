import React from 'react';
import { Form, Input } from 'antd';

const InputField = ({
  labelStyle = {},
  label = '',
  colon = false,
  required = false,
  // fullWidth = false,
  // width = '',
  defaultValue = undefined,
  input,
  meta: { touched, error },
  placeholder
  // ...restProps
}) => {
  if (!input.value) input.value = defaultValue;

  return (
    <Form.Item
      style={labelStyle}
      colon={colon}
      label={label}
      required={required}
      validateStatus='' // 'success' 'warning' 'error' 'validating'
      help={touched && error ? error : ''}
    >
      <Input {...input} placeholder={placeholder} />
    </Form.Item>
  );
};

export default InputField;
