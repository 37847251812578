// @flow

import * as React from 'react';
import { Icon } from 'antd/lib/index';

type Props = {
  icon: 'warning' | 'icon' | 'error',
  text: string
};

const IconTitle = ({ icon, text }: Props) => (
  <div className='revoke-title'>
    {icon && icon === 'warning' && (
      <Icon
        type='exclamation-circle'
        theme='filled'
        style={{ color: '#EEAF40' }}
      />
    )}
    <span className='text'>{text}</span>
  </div>
);

export default IconTitle;
