import initialState from '../state';
import * as actions from '../actions/internalRadiologistsActions';

import type { UsersState } from '../../schemas';

export default (state: UsersState = initialState, action): UsersState => {
  switch (action.type) {
    case actions.GET_INTERNAL_RADIOLOGISTS_SUCCESS:
      return { ...state, array: action.data.array, total: action.data.total };
    case actions.GET_INTERNAL_RADIOLOGISTS_STATUS_SUCCESS:
      return { ...state, activeRads: action.data };
    case actions.GET_INTERNAL_RAD_BY_ID_SUCCESS:
      return { ...state, current: action.data };
    case actions.ADD_INTERNAL_RAD_SUCCESS:
    case actions.EDIT_INTERNAL_RAD_SUCCESS:
    case actions.DELETE_INTERNAL_RAD_SUCCESS:
      return {
        ...state,
        modifications: (state.modifications += 1)
      };
    case actions.GET_INTERNAL_RADIOLOGISTS_FAILED:
    case actions.GET_INTERNAL_RAD_BY_ID_FAILED:
    case actions.ADD_INTERNAL_RAD_FAILED:
    case actions.EDIT_INTERNAL_RAD_FAILED:
    case actions.DELETE_INTERNAL_RAD_FAILED:
      return { ...state, errors: state.errors.concat(action.error) };
    case actions.UPDATE_LAST_INTERNAL_RADIOLOGISTS_SEARCH:
      return {
        ...state,
        currentSearch: action.search
      };
    default:
      return state;
  }
};
