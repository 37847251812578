export default (
  perPage: number,
  page: number,
  sort: string,
  q: string
): string => {
  let query = '?';
  if (perPage > 0) {
    query += `per_page=${perPage}`;
    if (page >= 0) {
      query += `&page=${page}`;
    }
  }
  if (sort.length > 0) {
    if (query.length > 5) {
      query += '&';
    }
    query += `sort=${sort}`;
  }
  if (q.length > 0) {
    if (query.length > 5) {
      query += '&';
    }
    query += `q=${q}`;
  }
  return query;
};
