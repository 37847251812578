import { Button, Col, Row } from "antd";
import React from "react";

const FooterButton = ({ onPrevious, onNext, currentStep, isUpdate }) => {
    return <div className="routes-footer">
        <Row type="flex" justify="end" gutter={[16, 16]}>
            <Col><Button disabled={currentStep === 0} size="large" onClick={onPrevious}>Previous</Button></Col>
            <Col><Button size="large" type="primary" onClick={onNext}>{currentStep == 4 && isUpdate ? "Update" : currentStep == 4 ? "Submit" : "Next"}</Button></Col>
        </Row>
    </div>
};

export default FooterButton;
