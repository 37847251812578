import { call, put, takeLatest } from 'redux-saga/effects';
import getUserLocationAPI from '../../api/endpoints/userLocation';
import Logger from '../../utils/logger';
import * as actions from '../actions/locationActions';

function* getUserLocationSaga(action) {
  try {
    const response = yield call(getUserLocationAPI);
    yield put({ type: actions.GET_LOCATION_SUCCESS, data: response });
  } catch (e) {
    Logger.log('error', e);
  }
}
export default function* locationSaga() {
  yield takeLatest(actions.GET_LOCATION_REQUEST, getUserLocationSaga);
}
