/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Form, message, Modal, Row, Select, Spin } from 'antd';

import ActionLink from '../../../components/ActionLink';
import IconTitle from '../../../components/IconTitle';
import {
  getAllUsersAction,
  revokeAdminRightsAction
} from '../../../store/actions';

import './RevokeAdminRights.less';
import type { ReduxState, User } from '../../../schemas';

const { Option } = Select;

const warning =
  'The user is configured as main admin for this organisation. To proceed, please select a user in your organisation ' +
  'who will take over as the main administrator.';

type Props = {
  userId: string,
  users: Array<User>,
  modifications: number,
  revokeAction: (string, string) => void,
  logoutAction: () => void,
  getAllUsers: () => void,
  loading: boolean
};

type State = {
  value: string,
  data: Array<User>,
  visible: boolean,
  success: boolean,
  revoked: boolean,
  modifications: number
};

export default connect(
  (state: ReduxState) => ({
    users: state.users.all,
    modifications: state.users.modifications,
    loading: state.loading.getUsers
  }),
  {
    revokeAction: revokeAdminRightsAction,
    getAllUsers: getAllUsersAction
  }
)(
  class RevokeAdminRights extends Component<Props, State> {
    static transformData = (users: Array<User>, userId: string): Array<User> =>
      users
        .filter(user => userId !== user.id)
        .map(user => ({ ...user, key: user.id }));

    state = {
      value: '',
      data: RevokeAdminRights.transformData(
        this.props.users,
        this.props.userId
      ),
      visible: false,
      success: false,
      revoked: false,
      modifications: this.props.modifications
    };

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
      if (
        nextProps.modifications > prevState.modifications &&
        prevState.revoked
      ) {
        return {
          modifications: nextProps.modifications,
          success: true,
          revoked: false
        };
      }
      if (prevState.data.length === 0 && nextProps.users.length > 0) {
        return {
          data: RevokeAdminRights.transformData(
            nextProps.users,
            nextProps.userId
          )
        };
      }
      return null;
    }

    componentDidMount(): void {
      this.props.getAllUsers();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
      if (this.state.success && this.state.visible) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ visible: false });
        Modal.success({
          title: 'Admin rights revoked',
          content: 'Admin rights revoked for main user.',
          okText: 'Ok',
          onOk() {}
        });
      }
    }

    revokeAdmin = () => {
      const { revokeAction, userId } = this.props;
      const { value } = this.state;
      this.setState({ revoked: true });
      revokeAction(userId, value);
    };

    showModal = () => {
      this.setState({ visible: true });
    };

    handleCancel = () => {
      this.setState({ visible: false });
    };

    handleOk = async () =>
      this.state.value ? this.revokeAdmin() : message.warn('Please select...');

    handleChange = value => this.setState({ value });

    handleSearch = value =>
      this.setState({
        data: this.props.users
          .filter(
            user =>
              this.props.userId !== user.id &&
              user.name.toLowerCase().includes(value.toLowerCase())
          )
          .map(user => ({ ...user, key: user.id }))
      });

    render() {
      const { userId, loading } = this.props;
      const { value, data } = this.state;
      return (
        <>
          <Form.Item label='Admin Access'>
            Main{' '}
            <ActionLink click={this.showModal} text='(Revoke Admin Rights)' />
          </Form.Item>
          <Modal
            title={<IconTitle icon='warning' text='Revoke admin rights?' />}
            visible={this.state.visible}
            okText='Confirm'
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            destroyOnClose
          >
            {loading ? (
              <div style={{ textAlign: 'center' }}>
                <Spin size='large' />
              </div>
            ) : (
              <Row type='flex' justify='center'>
                <Col className='revoke-body'>
                  <p>{warning}</p>
                  <Select
                    value={value}
                    placeholder='Type to search, or select from the list below'
                    filterOption={false}
                    onChange={this.handleChange}
                    style={{ width: '100%' }}
                  >
                    {data
                      .filter(user => userId !== user.id)
                      .map(user => (
                        <Option key={user.key}>
                          {`${user.name} | Role: ${
                            user.appMetadata.admin === '1'
                              ? 'Admin'
                              : 'No Admin'
                          }`}
                        </Option>
                      ))}
                  </Select>
                </Col>
              </Row>
            )}
          </Modal>
        </>
      );
    }
  }
);
