import React, { useEffect, useState } from "react";
import { Button, Col, Row, Tooltip, Typography, Checkbox, Avatar, Tag } from 'antd';
import { ReactComponent as CsvIcon } from '../../../assets/images/empty-list.svg';
import './index.less';
import ScheduleFilter from "./components/ScheduleFilter";
import CommonHeader from "../common/CommonHeader";
import { USER_CHECKBOX_OPTIONS } from "../../../utils/filters";
import SelectUserForm from "../common/forms/SelectUserForm";
import { getInternalRadiologistsAction, getInternalRadiologistsStatusAction } from "../../../store/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import RadiologistList from "./components/RadiologistList";
import Pubnub from "pubnub";
import { upperFirst } from "lodash";

const { Text, Title } = Typography;

let scheduleDemoList = [{
  id: 1,
  name: "Pratik Sanyaja",
  date: "2020-01-01",
  fromTime: "07:00",
  toTime: "12:00",
  specialty: ["Radiologist", "Cardiologist"],
},
{
  id: 2,
  name: "Dr. Abdul  Mannan xys kjdfgkjkjn dfkj",
  date: "2020-01-01",
  fromTime: "13:00",
  toTime: "18:00",
  specialty: ["Radiologist Assistant"],
},
{
  id: 3,
  name: "Dr. Abdul",
  date: "2020-01-01",
  fromTime: "9:00",
  toTime: "16:00",
  specialty: ["Radiologist Assistant"],
},
{
  id: 4,
  name: "Sunil Kumar",
  date: "2020-01-01",
  fromTime: "14:00",
  toTime: "18:00",
  specialty: ["IT Administrator"],
},
{
  id: 5,
  name: "Michael Thompson",
  date: "2020-01-01",
  fromTime: "15:00",
  toTime: "19:00",
  specialty: ["Radiographer"],
}];
const Schedule = ({ internalRadiologists, getRadiologistsAction, getInternalRadiologistsStatus, affiliatedGroup, activeRadiologistList = [] }) => {
  const hoursArray = Array.from({ length: 20 }, (_, index) => index);
  const [scheduleList, setScheduleList] = useState({});
  const [radiologistHight, setRadiologistHight] = useState(180)
  const [radiographerHight, setRadiographerHight] = useState(180)
  const [itAdministratorHight, setItAdministratorHight] = useState(180)


  // const groupID = affiliatedGroup;
  // const pubnub = new Pubnub({
  //   subscribeKey: 'sub-c-df2ceddc-04be-11ec-ac05-0664d1b72b66',
  //   publishKey: 'pub-c-eb69a0e3-9bd7-4d9b-bd3d-914e94db7c77',
  //   logVerbosity: true,
  //   ssl: true
  // });

  useEffect(() => {
    let newScheduleList = { Radiologist: [], Radiographer: [], IT_Administrator: [] }
    scheduleDemoList.forEach((o) => {
      if (o.specialty.some(item => item.toLowerCase().includes("radiologist"))) {
        if (newScheduleList.Radiologist.length == 0) {
          newScheduleList = {
            ...newScheduleList,
            "Radiologist": [o]
          }
        } else {
          newScheduleList = {
            ...newScheduleList,
            "Radiologist": [...newScheduleList.Radiologist, o]
          }
        }
      }
      if (o.specialty.some(item => item.toLowerCase().includes("radiographer"))) {
        if (newScheduleList.Radiographer.length == 0) {
          newScheduleList = {
            ...newScheduleList,
            "Radiographer": [o]
          }
        } else {
          newScheduleList = {
            ...newScheduleList,
            "Radiographer": [...newScheduleList.Radiographer, o]
          }
        }

      }
      else if (o.specialty.some(item => item.toLowerCase().includes("it administrator"))) {
        if (newScheduleList.IT_Administrator.length == 0) {
          newScheduleList = {
            ...newScheduleList,
            "IT_Administrator": [o]
          }
        } else {
          newScheduleList = {
            ...newScheduleList,
            "IT_Administrator": [...newScheduleList.IT_Administrator, o]
          }
        }
      }
    })
    let lastRow = 1
    newScheduleList.Radiologist.length > 0 && newScheduleList.Radiologist.forEach((schedule, index) => {
      let fromTime = parseInt(schedule.fromTime.split(":")[0]);
      let checkTimeExistOnPrev = index > 0 && parseInt(newScheduleList.Radiologist[index - 1].toTime.split(":")[0]) > fromTime
      if (checkTimeExistOnPrev) {
        lastRow = lastRow + 1;
        setRadiologistHight(180 * lastRow)
      } else {
        lastRow = lastRow
      }
    })
    newScheduleList.Radiographer.length > 0 && newScheduleList.Radiographer.forEach((schedule, index) => {
      let fromTime = parseInt(schedule.fromTime.split(":")[0]);
      let checkTimeExistOnPrev = index > 0 && parseInt(newScheduleList.Radiographer[index - 1].toTime.split(":")[0]) > fromTime
      if (checkTimeExistOnPrev) {
        lastRow = lastRow + 1;
        setRadiographerHight(180 * lastRow)
      } else {
        lastRow = lastRow
      }
    })
    newScheduleList.IT_Administrator.length > 0 && newScheduleList.IT_Administrator.forEach((schedule, index) => {
      let fromTime = parseInt(schedule.fromTime.split(":")[0]);
      let checkTimeExistOnPrev = index > 0 && parseInt(newScheduleList.IT_Administrator[index - 1].toTime.split(":")[0]) > fromTime
      if (checkTimeExistOnPrev) {
        lastRow = lastRow + 1;
        setItAdministratorHight(180 * lastRow)
      } else {
        lastRow = lastRow
      }
    })
    setScheduleList(newScheduleList);
    getRadiologistsAction()
    getInternalRadiologistsStatus()
  }, []);


  const renderRadiologistCards = (list = [], header = "radiologist", divHeight = 180) => {
    let lastRow = 1
    return (
      <div>
        <div className={`common-schedule-header-${header}`}>
          <Tag className="common-schedule-header-tag">{header == "it_administrator" ? "IT Administrator" : upperFirst(header)}</Tag>
        </div>
        <div style={{ position: 'relative', height: divHeight }}>
          {list.length > 0 && list.map((schedule, index) => {
            let fromTime = parseInt(schedule.fromTime.split(":")[0]);
            let toTime = parseInt(schedule.toTime.split(":")[0])

            let checkTimeExistOnPrev = index > 0 && parseInt(list[index - 1].toTime.split(":")[0]) > fromTime
            if (checkTimeExistOnPrev) {
              lastRow = lastRow + 1;
            } else {
              lastRow = lastRow
            }
            return (
              <div className={`schedule-card ${header}-background-border`} style={{ left: `calc((100%/20 * ${fromTime}))`, width: `calc((100%/20) * ${toTime - fromTime} )`, marginTop: lastRow > 1 ? `${(lastRow) * 100}px` : `20px`, }}>
                <div className="schedule-card-content">
                  <div className="schedule-card-left">
                    <Avatar className={`${header}-avatar`}>{schedule.name && schedule.name.split(' ').map(word => word.charAt(0)).splice(0, 2).join('').toUpperCase()}</Avatar>
                  </div>
                  <div className="schedule-card-right" style={{ width: "100%", minWidth: `calc(((100%/20) * ${toTime - fromTime})`, }}>
                    <div className="schedule-card-name" style={{ minWidth: `calc(((100%/20) * ${toTime - fromTime} )- 100px)`, wordBreak: "break-all" }}>{schedule.name}</div>
                    <div className="schedule-card-specialty">
                      {schedule.specialty && schedule.specialty.length ? schedule.specialty.join(', ') : 'Radiologist'}
                    </div>
                    <div className="schedule-card-time">
                      <div>{schedule.fromTime}-{schedule.toTime}</div>
                      <div>{toTime - fromTime}h</div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
  const renderScheduleCards = () => {
    return (
      <div style={{ marginTop: 40 }}>
        {renderRadiologistCards(scheduleList.Radiologist, "radiologist", radiologistHight)}
        {renderRadiologistCards(scheduleList.Radiographer, "radiographer", radiographerHight)}
        {renderRadiologistCards(scheduleList.IT_Administrator, "it_administrator", itAdministratorHight)}
      </div>
    )
  }

  return (
    <div>
      <CommonHeader title="Schedule" description="Schedule your team based on dates, times, recurring days, and weekend availability." buttonText="Create Schedule" />
      <div>
        {scheduleList ? <div>
          <ScheduleFilter />
          <div className="schedule-wrapper">
            <div style={{ marginRight: 15 }}>
              <Text className="common-label">Show Schedule</Text>
              <div style={{ marginTop: 7 }}>
                <SelectUserForm />
              </div>
              <div>
                <RadiologistList list={internalRadiologists && internalRadiologists.array} activeList={activeRadiologistList} />
              </div>
            </div>
            <div style={{ width: "100%", position: "relative" }}>
              <div className="time-wrapper">
                {hoursArray.map((hour, index) => (
                  <div className="time-item" key={hour}>{hour.toString().length == 1 ? "0" + hour + ":00" : hour + ":00"}<div className={`time-background-${index % 2 == 0 ? "even" : "odd"}`}></div></div>
                ))}
              </div>
              {renderScheduleCards(scheduleList)}
            </div>
          </div>
        </div>
          : <div className="no-content-wrapper">
            <div className="no-content-item">
              <CsvIcon />
              <p className="description">Set up your scheduling system to efficiently manage radiologists/providers based on dates, times, recurring days, and weekend availability.</p>
              <Button className="button" type="primary">Create Schedule</Button>
            </div>
          </div>}
      </div>
    </div>);
};
const mapStateToProps = state => {
  return {
    internalRadiologists: state.internalRadiologists,
    affiliatedGroup: state.profile.appMetadata.affiliatedGroup,
    activeRadiologistList: state.internalRadiologists.activeRads
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getRadiologistsAction: getInternalRadiologistsAction, getInternalRadiologistsStatus: getInternalRadiologistsStatusAction }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
