/* eslint-disable */
import initialState from '../state';
import * as actions from '../actions/routeActions';

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_ROUTE_LIST_SUCCESS:
      return {
        ...state,
        list: action.data
      };
    case actions.GET_SINGLE_ROUTE_SUCCESS:
      return {
        ...state,
        current: action.data
      };
    case actions.GET_SCHEDULE_LIST_SUCCESS:
      return {
        ...state,
        scheduleList: action.data
      };
    case actions.GET_BODY_PART_OPTIONS_SUCCESS:
      return {
        ...state,
        bodyPartOptions: action.data
      };
    case actions.GET_MODALITY_OPTIONS_SUCCESS:
      return {
        ...state,
        modalityOptions: action.data
      };
    default:
      return state;
  }
};
