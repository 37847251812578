import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import {
  getStripeSessionAction,
} from '../../store/actions/paymentsActions';

const SubscriptionsPage = () => {
  const dispatch = useDispatch();
  const stripeData = useSelector(state => state.payments);

  React.useEffect(() => {
    if (stripeData.data?.id && !stripeData.url) dispatch(getStripeSessionAction());
    if (stripeData?.url) window.location.href = stripeData.url
  }, [stripeData]);
  
  return (
    <div style={{ textAlign: 'center' }}>
      {(stripeData && !stripeData.url) && <Spin size='large' />}
    </div>
  );
};

export default SubscriptionsPage