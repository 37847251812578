import type { ReduxState } from '../../schemas';

export const getAffiliatedGroup = (state: ReduxState) =>
  state.profile.appMetadata.affiliatedGroup;
export const getFacilityLogo = (state: ReduxState) => state.facilities.logo;
export const getFacilityLetterhead = (state: ReduxState) =>
  state.facilities.letterhead;

export const getFacilities = (state: ReduxState) =>
  state.facilities.internalArray;
