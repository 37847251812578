import isEmail from 'validator/lib/isEmail';
import isURL from 'validator/es/lib/isURL';

export default values => {
  const errors = {};
  const {
    name,
    facilityType,
    email,
    contactName,
    contactEmail,
    website
  } = values;
  if (!name) {
    errors.name = 'Required';
  }
  if (!facilityType) {
    errors.facilityType = 'Required';
  }
  // if (!email) {
  //   errors.email = 'Required';
  // } else
  if (email && !isEmail(email)) {
    errors.email = 'Wrong format';
  }
  if (!contactName) {
    errors.contactName = 'Required';
  }
  if (!contactEmail) {
    errors.contactEmail = 'Required';
  } else if (!isEmail(contactEmail)) {
    errors.contactEmail = 'Wrong format';
  }
  if (website && !isURL(website)) {
    errors.website = 'Wrong format';
  }
  return errors;
};
