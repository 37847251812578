// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { BlobURL, BlockBlobURL, Aborter } from '@azure/storage-blob';

import { Button, Icon, Upload, Typography, Spin, message } from 'antd';
import uuid from 'uuid/v4';
import './Uploader.less';
import { getAzureAddress } from '../store/actions';
import decrypt from '../utils/crypto';

import type { ReduxState } from '../schemas';
import {
  getSasTokenForFacilityLogo,
  getSasTokenForGroupLogo,
  getTeleradSettingsContainerURL
} from '../api';

const { Text } = Typography;

type Props = {
  containerName: string,
  containerPath: string,
  blobName: string,
  handleSuccess: string => void,
  showAccepted: boolean,
  token: string
};

type UploadCallback = {
  onProgress: (event: { percent: number }) => void,
  onError: (event: Error, body?: Object) => void,
  onSuccess: (body: Object) => void,
  data: Object,
  filename: string,
  /* eslint-disable-next-line */
  file: File,
  withCredentials: boolean,
  action: string,
  headers: Object
};

const transformFile = async file => {
  try {
    const fileType = file.type;
    let fileSize = file.size;
    fileSize = parseInt(parseInt(fileSize) / (1024 * 1024));
    if (fileSize > 10) {
      message.error(
        'Error: Image size exceeds maximum allowed limit of 10MB. Please upload a smaller image.'
      );
      return;
    }
    // no conversion if fileType is png or svg
    if (
      fileType === 'image/png' ||
      fileType === 'image/svg+xml' ||
      fileType === 'image/jpeg' ||
      fileType === 'image/jpg'
    ) {
      return file;
    }
    message.error(
      'Error: Unsupported file type. Please upload a PNG, SVG, JPEG, or JPG image.'
    );
    throw new Error(
      'Unsupported file type. Please upload a PNG, SVG, JPEG, or JPG image.'
    );

    // const dataURL = await fileToDataURL(file);
    // const blob = await imageToBlob(dataURL);

    // // replace the file extenstion with .png
    // blob.name = file.name.replace(/\.[^.]+$/g, '.png');

    // return blob;
  } catch (error) {
    console.error(error);
  }
};

export default connect(
  (state: ReduxState) => ({
    address: state.profile.azure,
    token: state.profile.accessToken,
    profile: state.profile
  }),
  { getAddress: getAzureAddress }
)((props: Props) => {
  const {
    containerName,
    blobName,
    showAccepted,
    token,
    profile,
    containerPath
  } = props;
  const accept = '.png,.jpg,.tiff';

  const [state, setState] = React.useState({
    loading: false
  });

  const acceptedString = (accepted: string): string =>
    showAccepted
      ? `(supported: ${accepted
          .replace(/\./g, '')
          .split(',')
          .join(', ')})`
      : '';

  const sendFile = async (callback: UploadCallback) => {
    if (!callback.file) {
      return;
    }
    let responsJSON;
    let containerURL;
    if (containerName == 'grouplogos') {
      const response = await getSasTokenForGroupLogo(profile);
      responsJSON = response;
    }
    if (containerName === 'facilitylogos') {
      const response = await getSasTokenForFacilityLogo(profile);
      responsJSON = response;
    }
    const signature = decrypt(token, responsJSON.sas_token);
    containerURL = await getTeleradSettingsContainerURL({
      containerName,
      azureAddress: responsJSON.sas_token,
      accessToken: token
    });

    containerURL.url = `${responsJSON.container_url}/${blobName}/${containerPath}?${signature}`;
    const name = `${uuid()}.${callback.file.name.split('.', -1)[1]}`;
    const blobURL = BlobURL.fromContainerURL(containerURL, name);
    const blockBlobURL = BlockBlobURL.fromBlobURL(blobURL);
    await blockBlobURL.upload(Aborter.none, callback.file, callback.file.size, {
      blobHTTPHeaders: {
        blobContentType: callback.file.type
      }
    });
    props.handleSuccess(blobURL.url.split('?')[0]);
    callback.onSuccess();
  };

  if (state.loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spin size='large' />
      </div>
    );
  }
  return (
    <Upload
      accept={accept}
      transformFile={transformFile}
      customRequest={sendFile}
      showUploadList={false}
    >
      <div className='upload-wrapper'>
        <Button className='upload-button'>
          <Icon type='upload' /> Click to Upload
        </Button>
        <Text>{acceptedString(accept)}</Text>
      </div>
    </Upload>
  );
});
