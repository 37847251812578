import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Card, Typography, Form, Row, Col, Button } from 'antd';
import Box from 'ui-box';
import copy from 'copy-to-clipboard';
import { bindActionCreators } from 'redux';
import { t } from 'i18next';
import uploaderSettingsValidation from '../forms/uploaderSettingsValidation';
import { sendSecondOpinionCodeRequest } from '../../../store/actions/secondOpinionActions';

import {
  InputTextAreaField,
  InputTextField
} from '../../../components/FormParts';

const { Title, Paragraph } = Typography;

const styles = {
  root: {
    border: '1px solid rgba(0, 0, 0, 0.2537)',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.252095)',
    minHeight: 540
  },
  title: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#404047'
    // marginBottom: '5px'
  },
  text: {
    fontSize: '14px',
    color: '#595959',
    opacity: 0.5
  }
};

function CardFormWrapper({ formName, title, text, children, value, type }) {
  // console.log('CardFormWrapper')
  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      { sendSecondOpinionCode: sendSecondOpinionCodeRequest },
      dispatch
    );

  const Component = connect(
    state => ({
      initialValues: {
        copyPasteCode: value,
        type
      }
    }),
    mapDispatchToProps
  )(
    reduxForm({
      form: formName,
      enableReinitialize: true,
      validate: uploaderSettingsValidation
    })(props => {
      const { handleSubmit, sendSecondOpinionCode } = props;
      const [isShown, setIsShown] = useState(false);

      const sendEmail = values => {
        sendSecondOpinionCode({
          code: values.copyPasteCode,
          email: values.email,
          type: values.type
        });
      };

      const copyText = () => {
        copy(value);
        setIsShown(true);
        setTimeout(() => {
          setIsShown(false);
        }, 1000);
      };

      return (
        <Card style={styles.root}>
          <Title level={4} style={styles.title}>
            {title}
          </Title>
          <Paragraph style={styles.text}>{text}</Paragraph>
          <Box marginBottom={20}>{children}</Box>
          <Form layout='vertical' onSubmit={handleSubmit(sendEmail)}>
            <Field
              name='copyPasteCode'
              label={t('uploader_settings.copy_paste_code_website')}
              placeholder='<a href= "<code><code><code><code><code><co de><code><code><code><code><code><code>...'
              component={InputTextAreaField}
              formItemStyle={{
                paddingBottom: 0,
                marginBottom: 14
              }}
            />
            <Box
              display='flex'
              textAlign='right'
              marginBottom={20}
              justifyContent='flex-end'
              alignItems='center'
            >
              <div
                style={{
                  marginRight: '5px',
                  display: isShown ? 'block' : 'none'
                }}
              >
                {t('uploader_settings.code_copied')}
              </div>
              <Button onClick={() => copyText()}>
                {t('uploader_settings.copy_code')}
              </Button>
            </Box>
            <Row type='flex' gutter={24}>
              <Col span={24}>
                <Field
                  name='email'
                  label={t(
                    'uploader_settings.send_code_your_website_developers'
                  )}
                  type='email'
                  placeholder={t('uploader_settings.input_email_address')}
                  component={InputTextField}
                  formItemStyle={{
                    paddingBottom: 0,
                    marginBottom: 14
                  }}
                />
              </Col>
              <Col span={24}>
                <Box
                  height='100%'
                  display='flex'
                  alignItems='flex-end'
                  paddingBottom='14px'
                >
                  <Button type='primary' htmlType='submit'>
                    {t('uploader_settings.send')}
                  </Button>
                </Box>
              </Col>
            </Row>
          </Form>
        </Card>
      );
    })
  );

  return <Component />;
}

export default CardFormWrapper;
