import { all, fork } from 'redux-saga/effects';

import countryListSaga from './countryListSaga';
import profileSaga from './profileSaga';
import externalRadiologistsSaga from './externalRadiologistsSaga';
import internalRadiologistsSaga from './internalRadiologistsSaga';
import usersSaga from './usersSaga';
import providersSaga from './providersSaga';
import planSaga from './planSaga';
import facilitySaga from './facilitySaga';
import referringFacilitySaga from './referringFacilitySaga';
import referringPhysicianSaga from './referringPhysicianSaga';
import groupSaga from './groupSaga';
import physiciansSaga from './physiciansSaga';
import dashboardSaga from './dashboardSaga';
import paymentsSaga from './paymentsSaga';
import secondOpinionSaga from './secondOpinionSaga';
import onboardingSaga from './onboardingSaga';
import devicesSaga from './devicesSaga';
import groupInvitationSaga from './groupInvitationSaga';
import locationSaga from './useLocationSaga';
import routeSaga from './routeSaga';
import QCDashboardSaga from './QCDashboardSaga';

export default function* rootSaga() {
  yield all(
    [
      profileSaga,
      countryListSaga,
      externalRadiologistsSaga,
      internalRadiologistsSaga,
      usersSaga,
      providersSaga,
      planSaga,
      facilitySaga,
      groupSaga,
      physiciansSaga,
      dashboardSaga,
      paymentsSaga,
      secondOpinionSaga,
      onboardingSaga,
      devicesSaga,
      groupInvitationSaga,
      locationSaga,
      referringFacilitySaga,
      referringPhysicianSaga,
      routeSaga,
      QCDashboardSaga
    ].map(fork)
  );
}
