/* eslint-disable react/no-unescaped-entities */
// @flow

import * as React from 'react';
import { Col, Divider, Form, Modal, Row, Typography } from 'antd';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { ReduxState, Facility } from '../../../../schemas';
import {
  InputCheckboxGroupFacilitiesField,
  InputPhoneNumberField,
  InputSelectField,
  InputTextField
} from '../../../../components/FormParts';
import { RAD_SPECIALTY } from '../../../../utils/filters';

import '../../index.less';
import { ONBOARDING_FORM } from '../../../../utils/form-helpers';
import radiologistValidation from '../forms/radiologistValidation';

type Props = {
  visible: Boolean,
  setVisible: Function,
  handleSubmit: any => void,
  pristine: Boolean,
  submitting: Boolean,
  facilities: Array<Facility>,
  selectedFacilities: Array<Facility>
};

const { Title } = Typography;

const InviteRadiologistsModal = ({
  visible,
  setVisible,
  handleSubmit,
  pristine,
  submitting,
  facilities,
  selectedFacilities
}: Props) => {
  const [specialtyOpen, openSpecialty] = React.useState(false);
  const [userTypeOpen, openUserType] = React.useState(false);
  const [role, setRole] = React.useState<?string>('Radiologist');

  const homeFacilities = facilities.map((f: Facility) => ({
    text: f.name,
    value: f.name
  }));
  const groupFacilities = facilities
    .filter((f: Facility) => selectedFacilities.includes(f.guid))
    .map((f: Facility) => f.name);

  const handleCancel = e => {
    setVisible(false);
  };

  const USER_TYPE = [
    { text: 'View All', value: 'Radiologist' },
    { text: 'Only Assigned', value: 'Independent Radiologist' }
  ];

  const styles = {
    title: {
      fontWeight: 500,
      fontSize: 14,
      color: 'rgba(0, 0, 0, 0.65)',
      marginBottom: 14
    },
    divider: {
      margin: '0px 0px 14px'
    }
  };

  return (
    <>
      <div>
        <Modal
          title='Invite Radiologist'
          visible={visible}
          onOk={handleSubmit}
          onCancel={handleCancel}
          okText='Invite'
        >
          <Form layout='vertical'>
            <Row gutter={32}>
              <Col xs={24} sm={12}>
                <Row gutter={8} type='flex' style={{ flexWrap: 'nowrap' }}>
                  <Col>
                    <Field
                      style={{ width: 50 }}
                      name='radiologist.title'
                      label='Title'
                      placeholder='Dr.'
                      component={InputTextField}
                    />
                  </Col>
                  <Col>
                    <Field
                      name='radiologist.name'
                      label='Name'
                      placeholder='Name'
                      component={InputTextField}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={12}>
                <Field
                  name='radiologist.credentials'
                  label='Credentials'
                  placeholder='MD, ARRT, FACR, etc'
                  component={InputTextField}
                />
              </Col>
              <Col xs={24} sm={12}>
                <Field
                  name='radiologist.userType'
                  label='Permission'
                  placeholder='Please select...'
                  component={InputSelectField(
                    USER_TYPE,
                    userTypeOpen,
                    () => openUserType(true),
                    () => openUserType(false)
                  )}
                  onChange={value => setRole(value)}
                />
              </Col>
              <Col xs={24} sm={12}>
                {role && role.toLowerCase() === 'radiologist' && (
                  <Field
                    name='radiologist.specialty'
                    label='Specialty'
                    showSearch
                    placeholder='Type to search or select'
                    component={InputSelectField(
                      RAD_SPECIALTY,
                      specialtyOpen,
                      () => openSpecialty(true),
                      () => openSpecialty(false)
                    )}
                    mode='multiple'
                  />
                )}
              </Col>
              <Col xs={24} sm={12}>
                <Field
                  name='radiologist.email'
                  label='Email Address'
                  type='email'
                  placeholder='mail@domain.com'
                  component={InputTextField}
                />
              </Col>
              <Col xs={24} sm={12}>
                <Field
                  name='radiologist.phoneNumber'
                  label='Mobile Number'
                  component={InputPhoneNumberField}
                  defaultCountry='sg'
                  disableAreaCodes
                />
              </Col>
            </Row>
            <Divider style={styles.divider} />
            <div>
              <Title level={4} style={styles.title}>
                Allow user to access studies within group&apos;s facilities:
              </Title>
              <Field
                name='radiologist.groupFacilities'
                label=''
                // component={InputCheckBoxField(HOME_FACILITIES)}
                checkboxType='table'
                props={{ selected: groupFacilities }}
                component={InputCheckboxGroupFacilitiesField(homeFacilities)}
              />
            </div>
          </Form>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  return {
    facilities: state.facilities.internalArray
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: ONBOARDING_FORM,
    validate: radiologistValidation,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false
  })(InviteRadiologistsModal)
);
