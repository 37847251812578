/* eslint-disable camelcase */

export default values => {
  const errors = {};
  const {
    name,
    studyAccess,
    country,
    phoneNumber,
    email,
    primaryContact
  } = values;
  if (!name) {
    errors.name = 'Required';
  }
  if (!studyAccess) {
    errors.studyAccess = 'Required';
  }
  if (!primaryContact) {
    errors.primaryContact = 'Required';
  }
  if (!country) {
    errors.country = 'Required';
  }
  if (!phoneNumber) {
    errors.phoneNumber = 'Required';
  }
  if (!email) {
    errors.email = 'Required';
  }
  return errors;
};
