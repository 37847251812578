/* eslint-disable */
import React from 'react';
import { Button, Col, Modal, Row } from 'antd';
import { connect } from 'react-redux';
import { Page } from '../components';
import { logout } from '../store/actions';

const NoAdmin = ({ logoutAction }) => (
  <Page inner>
    <Modal
      title='Not an admin user'
      visible
      okText='Go'
      closable={false}
      destroyOnClose
      footer={
        <Row type='flex' justify='space-around' align='middle'>
          <Col span={12} offset={12} style={{ textAlign: 'right' }}>
            <Button
              type='primary'
              onClick={() =>
                (window.location.href = 'https://connect.alem.health')
              }
            >
              Go
            </Button>
            <Button type='default' onClick={logoutAction}>
              Log out
            </Button>
          </Col>
        </Row>
      }
    >
      <div className='no_admin_content'>
        <h3>You need admin privileges to use this application.</h3>
        <p>
          Please click the "go" button below to go back to{' '}
          <a href='https://connect.alem.health'>connect.alem.health</a>, or
          click "log out" to sign out of this account and sign in to another
        </p>
      </div>
    </Modal>
  </Page>
);

export default connect(() => {}, { logoutAction: logout })(NoAdmin);
