import AuthService from '../index';
import { ApiService, UserProfile } from '../../schemas';

const API_SERVICE: ApiService = 'telerad-settings';
const BASE_ROUTE = '/options';

// eslint-disable-next-line import/prefer-default-export
export const getAzureAddressApiCall = async (profile: UserProfile) => {
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `${BASE_ROUTE}/get_azure`, {})
    .then(r => r.message);
};
