import React from 'react';
// import PropTypes from 'prop-types'
import Box from 'ui-box';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { t } from 'i18next';
import BrandingForm from '../forms/BrandingForm';
import Header from '../partials/Header';
import { updateSecondOpinionSettingRequest } from '../../../store/actions';

function Branding(props) {
  // console.log('Branding props', props)
  const { updateSecondOpinionSetting } = props;
  const onCancel = () => {};

  const onConfirm = values => {
    updateSecondOpinionSetting(values);
  };

  const headerProps = {
    title: 'Branding',
    text: t('branding.set_branding_upload_page')
  };

  const brandingFormProps = {
    onCancel,
    onConfirm
  };

  return (
    <div>
      <Header {...headerProps} />
      <Box paddingTop={10} paddingBottom={24}>
        <BrandingForm {...brandingFormProps} onSubmit={onConfirm} />
      </Box>
    </div>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateSecondOpinionSetting: updateSecondOpinionSettingRequest
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Branding);
