/* eslint-disable camelcase */

export default values => {
  const errors = {};

  // const {  } = values

  // if (!website) {
  //   errors.name = 'Required'
  // }

  return errors;
};
