/* eslint-disable */

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { showError, showSuccess } from '../helpers';
import {
  changeChargebeeBillingAddressApiCall,
  changeChargebeeCard,
  // changePlanApiCall,
  createChargebeeCustomerApiCall,
  getChargebeeUserPlanDataApiCall,
  getPortalApiCall,
  upgradeHardwareApiCall,
  upgradePlanApiCall
} from '../../api/endpoints/payments_old';
import * as actions from '../actions/planActions';
import * as lActions from '../actions/loadingActions';

import { isSameDay } from '../../utils';
import { getCurrentUser } from '../selectors';
import { setHasShownSubscriptionMigration, setShowSubscriptionMigration } from '../storage';

function* getPlanInformationSaga() {
  yield put({ type: lActions.GET_PLAN_INFORMATION_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  try {
    const data = yield call(getChargebeeUserPlanDataApiCall, profile);
    yield put({ type: actions.GET_PLAN_INFORMATION_SUCCESS, data });
  } catch (error) {
    yield put({ type: actions.GET_PLAN_INFORMATION_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: lActions.GET_PLAN_INFORMATION_LOADING, loading: false });
  }
}

function* createCustomerSaga() {
  const profile = yield select(getCurrentUser);
  try {
    const success = yield call(createChargebeeCustomerApiCall, profile);
    if (success) {
      yield put({ type: actions.CREATE_CUSTOMER_SUCCESS });
    } else {
      const error = new Error('customer not created');
      yield put({
        type: actions.CREATE_CUSTOMER_FAILED,
        error
      });
      yield call(showError, error);
    }
  } catch (error) {
    yield put({ type: actions.CREATE_CUSTOMER_FAILED, error });
    yield call(showError, error);
  }
}

function* changeBillingAddressSaga(action) {
  yield put({ type: lActions.CHANGE_BILLING_ADDRESS_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  try {
    const billingAddress = yield call(
      changeChargebeeBillingAddressApiCall,
      profile,
      action.address
    );
    yield put({ type: actions.CHANGE_BILLING_ADDRESS_SUCCESS, billingAddress });
    yield call(showSuccess, `Billing address has been updated`);
  } catch (error) {
    yield put({ type: actions.CHANGE_BILLING_ADDRESS_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({
      type: lActions.CHANGE_BILLING_ADDRESS_LOADING,
      loading: false
    });
  }
}

/*
function* getClientSecretSaga() {
  yield put({ type: lActions.GET_STRIPE_CLIENT_SECRET_LOADING, loadingBillingAddress: true });
  const profile = yield select(getCurrentUser);
  try {
    const secret = call(getStripeClientSecretApiCall, profile);
    yield put({ type: actions.GET_CLIENT_SECRET_SUCCESS, secret });
  } catch (error) {
    yield put({ type: actions.GET_CLIENT_SECRET_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({
      type: lActions.GET_STRIPE_CLIENT_SECRET_LOADING,
      loadingBillingAddress: false
    });
  }
}
*/

function* changePaymentCardSaga(action) {
  yield put({ type: lActions.CHANGE_PAYMENT_CARD_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  try {
    if (action.error) {
      yield put({
        type: actions.CHANGE_CARD_INFORMATION_FAILED,
        error: action.error
      });
      yield call(showError, action.error);
      return;
    }
    const card = yield call(changeChargebeeCard, profile, action.token.id);
    yield put({ type: actions.CHANGE_CARD_INFORMATION_SUCCESS, card });
    yield call(showSuccess, `Card has been added as payment`);
  } catch (error) {
    yield put({ type: actions.CHANGE_CARD_INFORMATION_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: lActions.CHANGE_PAYMENT_CARD_LOADING, loading: false });
  }
}

function* upgradePlanSaga(action) {
  yield put({ type: lActions.UPGRADE_PLAN_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  try {
    const response = yield call(
      upgradePlanApiCall,
      profile,
      action.data
    );
    yield put({ type: actions.UPGRADE_PLAN_SUCCESS });
    if (isSameDay(new Date(response.schedule), new Date(Date.now()))) {
      yield call(
        showSuccess,
        `Your subscription has been set to ${response.new}`
      );
    } else {
      yield call(
        showSuccess,
        `Your subscription will be updated to ${response.new}
      from the next invoicing period, starting on ${new Date(
        response.schedule
      )}`
      );
    }
  } catch (error) {
    yield put({ type: actions.UPGRADE_PLAN_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: lActions.UPGRADE_PLAN_LOADING, loading: false });
  }
}

function* upgradeHardwareSaga(action) {
  yield put({ type: lActions.UPGRADE_PLAN_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  try {
    yield call(upgradeHardwareApiCall, {
      ...action.data,
      userId: profile.id,
      email: profile.email
    });
    yield put({ type: actions.UPGRADE_HARDWARE_SUCCESS });
  } catch (error) {
    yield put({ type: actions.UPGRADE_HARDWARE_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: lActions.UPGRADE_PLAN_LOADING, loading: false });
  }
}

function* getPortalUrlSaga() {
  yield put({ type: lActions.GET_PORTAL_LOADING, loading: true });
  const profile = yield select(getCurrentUser);
  try {
    const url = yield call(getPortalApiCall, profile);
    yield put({ type: actions.GET_PORTAL_URL_SUCCESS, url });
  } catch (error) {
    yield put({ type: actions.GET_PORTAL_URL_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: lActions.GET_PORTAL_LOADING, loading: false });
  }
}

function* setBankPaymentSaga(action) {
  // const profile = yield select(getCurrentUser);
  yield put({
    type: actions.SET_BANK_PAYMENT_SUCCESS,
    amount: action.data.amount
  });
}

function* disableNotifySubMigrationSaga(action) {
  yield call(setHasShownSubscriptionMigration, true);
}

export default function* planSaga() {
  yield takeLatest(
    actions.GET_PLAN_INFORMATION_REQUEST,
    getPlanInformationSaga
  );
  yield takeLatest(actions.CREATE_CUSTOMER_REQUEST, createCustomerSaga);
  yield takeLatest(
    actions.CHANGE_BILLING_ADDRESS_REQUEST,
    changeBillingAddressSaga
  );
  yield takeLatest(
    actions.CHANGE_CARD_INFORMATION_REQUEST,
    changePaymentCardSaga
  );
  yield takeLatest(actions.UPGRADE_PLAN_REQUEST, upgradePlanSaga);
  yield takeLatest(actions.UPGRADE_HARDWARE_REQUEST, upgradeHardwareSaga);
  yield takeLatest(actions.GET_PORTAL_URL_REQUEST, getPortalUrlSaga);
  yield takeLatest(actions.SET_BANK_PAYMENT_REQUEST, setBankPaymentSaga);
  yield takeLatest(actions.DISABLE_NOTIFY_SUB_MIGRATION, disableNotifySubMigrationSaga);
}
