// @flow
import React, { useEffect } from 'react';
import { Spin, Row, Col, Empty } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormValues } from 'redux-form';
import { t } from 'i18next';
import CardWrapper from '../../../../../common/CardWrapper';
import ReferringFacilitiesTableCardForm from '../Chart/form/ReferringFacilitiesTableForm';
// import { ReduxState, StudyDetails } from '../../../../../schemas';
import { getSourceReferralsByFacilitiesAction } from '../../../../../store/actions';
import { SOURCE_REFERRING_FACILITIES_FORM_NAME } from '../../../../../utils/form-helpers';
import Table from '../../../../../components/Table';
// import physicianConverter from '../../../../../utils/physician-converter';

const styles = {
  totalTitle: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.65)',
    margin: 0,
    cursor: 'pointer'
  },
  headerCell: {
    backgroundColor: '#F7F7F7'
  }
};

function ReferringFacilitiesTable({
  loading,
  getSourceReferralsByFacilities,
  formValues = {},
  data,
  modalities,
  facilities
}) {
  useEffect(() => {
    getSourceReferralsByFacilities();
  }, []);

  // const { selectedDate, modality, facility } = formValues;

  // useEffect(() => {
  //   if (selectedDate || modality || facility) {
  //     if (selectedDate && selectedDate.length !== 0) {
  //       if (selectedDate.length === 2) {
  //         getSourceReferralsByFacilities();
  //       }
  //     } else {
  //       getSourceReferralsByFacilities();
  //     }
  //   }
  // }, [selectedDate, modality, facility]);

  // const config = {
  //   data: chartData,
  //   xField: 'date',
  //   yField: 'value',
  //   // seriesField: 'category',
  //   color: [
  //     '#6897a7',
  //     '#8bc0d6',
  //     '#60d7a7',
  //     '#dedede',
  //     '#fedca9',
  //     '#fab36f',
  //     '#d96d6f'
  //   ],
  //   legend: { position: 'top' }
  // };

  const modColumns = modalities.map(modality => ({
    title: modality,
    dataIndex: modality,
    key: modality,
    onHeaderCell: () => ({ style: styles.headerCell })
  }));

  const columns = [
    {
      title: t('referral_patterns.table.referring_facility_name'),
      dataIndex: 'facilityName',
      key: 'facilityName',
      sorter: (a, b) =>
        a.facilityName
          .toString()
          .toLowerCase()
          .localeCompare(b.facilityName.toString().toLowerCase()),
      columnSearch: true,
      searchPlaceholder: 'Search Facilities',
      align: 'left'
    },
    {
      title: t('referral_patterns.table.facility_type'),
      dataIndex: 'facilityType',
      key: 'facilityType',
      sorter: (a, b) =>
        a.facilityType && b.facilityType
          ? a.facilityType
              .toString()
              .toLowerCase()
              .localeCompare(b.facilityType.toString().toLowerCase())
          : null,
      columnSearch: true,
      searchPlaceholder: 'Select Facility Type',
      align: 'left',
      onHeaderCell: () => ({ style: styles.headerCell })
    },
    ...modColumns,
    {
      title: t('referral_patterns.table.other'),
      dataIndex: 'other',
      key: 'other',
      onHeaderCell: () => ({ style: styles.headerCell })
    },
    {
      title: t('referral_patterns.table.total'),
      dataIndex: 'total',
      key: 'total',
      onHeaderCell: () => ({ style: styles.headerCell })
    }
  ];

  const tableProps = {
    columns,
    loading,
    data: data && data.length > 0 ? data : [],
    rowKey: ({ key }) => key,
    // pagination: false,
    getAction: getSourceReferralsByFacilities,
    className: 'table-dashboard'
  };

  return (
    <div className='facility-chart'>
      <CardWrapper
        headStyle={{
          paddingTop: 10,
          paddingBottom: 10,
          borderBottom: '1px solid rgba(155, 155, 155, 0.50)'
        }}
        title={t('referral_patterns.referrals_by_facilities')}
        extra={<ReferringFacilitiesTableCardForm />}
      >
        <Spin spinning={loading}>
          <Row>
            <Col>
              <Table {...tableProps} locale={{
                  emptyText: (
                    <Empty
                      description={t("table.no_data")}
                    />
                  ),
                }}/>
            </Col>
          </Row>
        </Spin>
      </CardWrapper>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    data: state.referringFacilities.data.data,
    modalities: state.referringFacilities.data.modalities || [],
    loading: state.loading.getReferringFacilities,
    formValues: getFormValues(SOURCE_REFERRING_FACILITIES_FORM_NAME)(state),
    facilities: state.facilities
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSourceReferralsByFacilities: getSourceReferralsByFacilitiesAction
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferringFacilitiesTable);
