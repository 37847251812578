/**
 * The group endpoints manage the information of the affiliated group of the user. Each user has an affiliated
 * group in its app_metadata identified by guid, but their profile just contains this reference. This methods allows
 * to get and update this affiliated group information, or even register a new group that later would be associated to
 * the user.
 */
import AuthService from '../index';
import type { ApiService, Group, GroupCreation, User, UserProfile } from '../../schemas';
import { groupToJson, jsonToGroup } from '../transformers';

const API_SERVICE: ApiService = 'telerad-settings';
const BASE_ROUTE = '/group';

/**
 * Gets the metadata associated with the guid of the affiliated group present in the app_metadata of the calling
 * user.
 * @param profile: User profile
 * @returns {never|Promise<*>} The group information
 */
export const getUserGroupApiCall = async (profile: UserProfile) => {
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, BASE_ROUTE, {}).then(g => jsonToGroup(g));
};

/**
 * This method allows to retrieve the information of different groups in the system querying them by name. The name
 * has not to be the exact name of the group, but just any query that match the name.
 * @param profile: User profile
 * @param groupName: query to find the group
 * @returns {never|Promise<*>} A list of groups
 */
export const queryGroups = async (profile: UserProfile, groupName: string) => {
  const Auth = await AuthService(profile.accessToken.length > 0);
  new Auth(profile).service(API_SERVICE, `${BASE_ROUTE}/${groupName}`, {}).then(jsonToGroup);
};

/**
 * This method allows to create a new group. The group is not immediately associated with the user, but some process
 * is required by the Alemhealth people, so the frontend should not expect any change in the user metadata when
 * calling this method. It doesn't requires admin privileges.
 * @param profile: User profile
 * @param group: an object that usually should have just a name field, containing the name the user wants to give to
 * the group.
 * @returns {never|Promise<*>} A response that, if successful, contains the name and the group_id of the created group.
 */
export const createGroupApiCall = async (profile: UserProfile, group: GroupCreation) => {
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, BASE_ROUTE, {
    method: 'POST',
    body: JSON.stringify(groupToJson(group))
  });
};

/**
 * This method allows to update the user affiliated group and requires admin privileges.
 * @param profile: User profile
 * @param groupUpdate: the information to update in the group.
 * @returns {never|Promise<*>} The updated group information, if successful
 */
export const updateGroupAPiCall = async (profile: User, groupUpdate: Group) => {
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, BASE_ROUTE, {
    method: 'PATCH',
    body: JSON.stringify(groupToJson(groupUpdate))
  });
};
