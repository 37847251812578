export default values => {
  const errors = { radiologist: {} };
  const { radiologist } = values;
  const { name, email, userType } = radiologist;
  if (!name) {
    errors.radiologist.name = 'Required';
  }

  if (!userType) {
    errors.radiologist.userType = 'Required';
  }

  if (!email) {
    errors.radiologist.email = 'Required';
  }

  return errors;
};
