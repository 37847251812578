import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import uuid from 'uuid/v4';
import { t } from 'i18next';
import { showError, showSuccess } from '../helpers';
import * as actions from '../actions';
import {
  createDeviceApiCall,
  deleteDeviceApiCall,
  editDeviceApiCall,
  getAllDevicesApiCall,
  getDevicesStatusApiCall,
  testDeviceStatusApiCall
} from '../../api';
import { getCurrentUser } from '../selectors';

export function* getDevicesSaga(action) {
  yield put({ type: actions.GET_DEVICES_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    const devices = yield call(getAllDevicesApiCall, profile);

    yield put({
      type: actions.GET_DEVICES_SUCCESS,
      data: devices
    });
  } catch (error) {
    console.error(error.message);
    if (error.message.includes('404')) {
      yield call(showError, {
        message:
          t("user_administration.you_registered_devices_add_your_device")
      });
    } else {
      yield call(showError, { message: t("errors.failed_to_get_device") });
    }
    yield put({ type: actions.GET_DEVICES_FAILED, error });
  } finally {
    yield put({ type: actions.GET_DEVICES_LOADING, loading: false });
  }
}

function* addDeviceSaga(action) {
  yield put({ type: actions.CREATE_DEVICE_LOADING, loading: true });
  try {
    const { device } = action;
    const profile = yield select(getCurrentUser);
    const {
      appMetadata: { affiliatedGroup }
    } = profile;
    yield call(createDeviceApiCall, profile, {
      ...device,
      organizationGuid: affiliatedGroup,
      id: uuid()
    });
    yield put({ type: actions.ADD_DEVICE_SUCCESS });
    yield call(showSuccess, 'Device added');
    yield call(getDevicesSaga);
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.ADD_DEVICE_FAILED, error });
    yield call(showError, { message: t("errors.failed_to_add_device") });
  } finally {
    yield put({ type: actions.CREATE_DEVICE_LOADING, loading: false });
  }
}

function* editDeviceSaga(action) {
  yield put({ type: actions.UPDATE_DEVICE_LOADING, loading: true });
  try {
    const { device } = action;
    const profile = yield select(getCurrentUser);
    yield call(editDeviceApiCall, profile, device, device.id);
    yield put({ type: actions.EDIT_DEVICE_SUCCESS });
    yield call(showSuccess, 'Device updated');
    yield call(getDevicesSaga);
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.EDIT_DEVICE_FAILED, error });
    yield call(showError, { message: t("errors.failed_to_edit_device") });
  } finally {
    yield put({ type: actions.UPDATE_DEVICE_LOADING, loading: false });
  }
}

function* getDevicesStatusSaga() {
  yield put({ type: actions.GET_DEVICES_STATUS_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);

    const channel = yield call(getDevicesStatusApiCall, profile);
    while (true) {
      const action = yield take(channel);
    console.log("channel-------getDevicesStatusApiCall----------------------->>>", action);

      if (action.message && action.message.device_guid) {
        yield put({
          type: actions.GET_DEVICES_STATUS_SUCCESS,
          data: action.message
        });
      }
      // else if (action.occupants && action.occupants.length > 0) {
      //   const onlineFacilities = action.occupants.map(item => {
      //     if (item.state) {
      //       return { ...item.state, facilityId: item.uuid };
      //     }
      //     return null;
      //   });
      //   yield put({
      //     type: GET_FACILITIES_STATUS_SUCCESS,
      //     data: onlineFacilities
      //   });
      // }
    }
  } catch (err) {
    yield put({ type: actions.GET_DEVICES_STATUS_FAILED, err });
    yield call(showError, err);
  } finally {
    yield put({ type: actions.GET_DEVICES_STATUS_LOADING, loading: false });
  }
}

function* testDevicesStatusSaga(action) {
  yield put({ type: actions.GET_DEVICES_STATUS_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);

    const { data } = action;

    yield call(testDeviceStatusApiCall, profile, data, uuid());
    yield call(showSuccess, 'Checking Device Availability');
  } catch (err) {
    yield put({ type: actions.GET_DEVICES_STATUS_FAILED, err });
    yield call(showError, err);
  } finally {
    yield put({ type: actions.GET_DEVICES_STATUS_LOADING, loading: false });
  }
}

function* deleteDeviceSaga(action) {
  yield put({ type: actions.DELETE_DEVICE_LOADING, loading: true });
  try {
    const { deviceId } = action;

    const profile = yield select(getCurrentUser);
    yield call(deleteDeviceApiCall, profile, deviceId);

    yield call(getDevicesSaga);
    yield put({ type: actions.DELETE_DEVICE_SUCCESS });
    yield call(showSuccess, 'Device has been deleted');
  } catch (error) {
    console.error(error.message);
    yield put({ type: actions.DELETE_DEVICE_FAILED, error });
    yield call(showError, { message: t("errors.failed_to_delete_device") });
  } finally {
    yield put({ type: actions.DELETE_DEVICE_LOADING, loading: false });
  }
}

export default function* devicesSaga() {
  yield takeLatest(actions.ADD_DEVICE_REQUEST, addDeviceSaga);
  yield takeLatest(actions.GET_DEVICES_REQUEST, getDevicesSaga);
  yield takeLatest(actions.EDIT_DEVICE_REQUEST, editDeviceSaga);
  yield takeLatest(actions.GET_DEVICES_STATUS_REQUEST, getDevicesStatusSaga);
  yield takeLatest(actions.TEST_DEVICE_STATUS_REQUEST, testDevicesStatusSaga);
  yield takeLatest(actions.DELETE_DEVICE_REQUEST, deleteDeviceSaga);
}
