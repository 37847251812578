/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import Logger from '../../utils/logger';
import AuthService from '../index';
import { jsonToInvite, inviteToJson } from '../transformers';

const POST_SIGNUP_ROOT_URL_INVITATION_LIST = 'signup-invite-list';

export const getInvitionsListAPICall = async profile => {
  try {
    Logger.log('GET invitations api call');
    const organizationGuid = profile.appMetadata.affiliatedGroup;

    const Auth = await AuthService(profile.accessToken.length > 0);
    return new Auth(profile)
      .service(
        `${POST_SIGNUP_ROOT_URL_INVITATION_LIST}`,
        `/organization/${organizationGuid}/invitations/`,
        {}
      )
      .then(r => {
        Logger.log('GET invitations api call response: ', r);
        return r.map(jsonToInvite);
      });
  } catch (e) {
    console.error('ERROR: ', e);
    return e;
  }
};

export const postResendInvitionAPICall = async (profile, invitationGuid) => {
  try {
    Logger.log('POST resend invitation api call');

    const Auth = await AuthService(profile.accessToken.length > 0);
    return new Auth(profile)
      .service(
        `${POST_SIGNUP_ROOT_URL_INVITATION_LIST}`,
        `/invitations/${invitationGuid}/admin/resend-email/`,
        {
          method: 'POST',
          body: inviteToJson({
            invitationGuid
          })
        }
      )
      .then(r => {
        Logger.log('POST resend invitation api call response: ', r);
        return r;
      });
  } catch (e) {
    console.error('ERROR: ', e);
    return e;
  }
};

export const deleteRevokeInvitionAPICall = async (profile, invitationGuid) => {
  try {
    Logger.log('POST resend invitation api call');

    const Auth = await AuthService(profile.accessToken.length > 0);
    return new Auth(profile)
      .service(
        `${POST_SIGNUP_ROOT_URL_INVITATION_LIST}`,
        `/invitations/${invitationGuid}/admin/revoke/`,
        {
          method: 'DELETE',
          body: inviteToJson({
            invitationGuid
          })
        }
      )
      .then(r => {
        Logger.log('POST resend invitation api call response: ', r);
        return r;
      });
  } catch (e) {
    console.error('ERROR: ', e);
    return e;
  }
};
