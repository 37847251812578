import type { Provider } from '../../schemas';

export const GET_PROVIDERS_REQUEST = 'GET_PROVIDERS_REQUEST';
export const GET_PROVIDERS_SUCCESS = 'GET_PROVIDERS_SUCCESS';
export const GET_PROVIDERS_FAILED = 'GET_PROVIDERS_FAILED';

export const GET_PROVIDER_BY_ID_REQUEST = 'GET_PROVIDER_BY_ID_REQUEST';
export const GET_PROVIDER_BY_ID_SUCCESS = 'GET_PROVIDER_BY_ID_SUCCESS';
export const GET_PROVIDER_BY_ID_FAILED = 'GET_PROVIDER_BY_ID_FAILED';

export const ADD_PROVIDER_REQUEST = 'ADD_PROVIDER_REQUEST';
export const ADD_PROVIDER_SUCCESS = 'ADD_PROVIDER_SUCCESS';
export const ADD_PROVIDER_FAILED = 'ADD_PROVIDER_FAILED';

export const DELETE_PROVIDER_REQUEST = 'DELETE_PROVIDER_REQUEST';
export const DELETE_PROVIDER_SUCCESS = 'DELETE_PROVIDER_SUCCESS';
export const DELETE_PROVIDER_FAILED = 'DELETE_PROVIDER_FAILED';

export const UPDATE_LAST_PROVIDERS_SORT = 'UPDATE_LAST_PROVIDERS_SORT';

export const REQUEST_SERVICE_PROVIDER_REQUEST =
  'REQUEST_SERVICE_PROVIDER_REQUEST';
export const REQUEST_SERVICE_PROVIDER_SUCCESS =
  'REQUEST_SERVICE_PROVIDER_SUCCESS';
export const REQUEST_SERVICE_PROVIDER_FAILED =
  'REQUEST_SERVICE_PROVIDER_FAILED';

export const getProviders = (sort: string) => ({
  type: GET_PROVIDERS_REQUEST,
  sort
});

export const getProviderById = (providerId: string) => ({
  type: GET_PROVIDER_BY_ID_REQUEST,
  providerId
});

export const addProvider = (provider: Provider) => ({
  type: ADD_PROVIDER_REQUEST,
  provider
});

export const deleteProvider = (providerId: string, name: string) => ({
  type: DELETE_PROVIDER_REQUEST,
  providerId,
  name
});

export const requestServiceProvider = (providers, modalities) => ({
  type: REQUEST_SERVICE_PROVIDER_REQUEST,
  providers,
  modalities
});
