/* eslint-disable no-console */
import AuthService from '../index';
import Logger from '../../utils/logger';
import {
  billingAddressToJson,
  jsonToBillingAddress,
  jsonToCard,
  // eslint-disable-next-line camelcase
  _DEPRECATED_jsonToPlanInformation
} from '../transformers';

import type {
  UserProfile,
  PlanChange,
  BillingAddress,
  ApiService,
  UpgradePlanResponse,
  BankPayment
} from '../../schemas';
import type { HardwareUpgrade, PlanUpgrade } from '../../schemas/planTypes';

const API_SERVICE: ApiService = 'telerad-settings';
const BASE_ROUTE = '/payments';

export const createChargebeeCustomerApiCall = async (profile: UserProfile) => {
  Logger.log('POST create chargebee customer api called');
  const Auth = await AuthService(profile.accessToken.length > 0);
  await new Auth(profile).service(API_SERVICE, `${BASE_ROUTE}/customer`, {
    method: 'POST'
  });
  return true;
};

export const getChargebeeUserPlanDataApiCall = async (profile: UserProfile) => {
  Logger.log('GET chargebee api called');
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, BASE_ROUTE)
    .then(i => {
      return i;
    })
    .then(i => {
      return _DEPRECATED_jsonToPlanInformation(i);
    });
};

export const changeChargebeeBillingAddressApiCall = async (
  profile: UserProfile,
  address: BillingAddress
) => {
  Logger.log(
    'POST change chargebee billing address api called with address: ',
    address
  );
  const Auth = await AuthService(profile.accessToken.length > 0);
  const billingAddressJson = billingAddressToJson(address);
  return new Auth(profile)
    .service(API_SERVICE, `${BASE_ROUTE}/address`, {
      method: 'POST',
      body: billingAddressJson
    })
    .then(b => jsonToBillingAddress(b));
};

export const changeChargebeeCard = async (
  profile: UserProfile,
  cardToken: string
) => {
  Logger.log('POST change chargebee card api called with token: ', cardToken);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `${BASE_ROUTE}/card`, {
      method: 'POST',
      body: JSON.stringify({ token: cardToken })
    })
    .then(card => jsonToCard(card));
};

export const changePlanApiCall = async (
  profile: UserProfile,
  data: PlanChange
) => {
  Logger.log('POST change account api called with data: ', data);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, `${BASE_ROUTE}/plan`, {
    method: 'POST',
    body: JSON.stringify(data)
  });
};

export const upgradePlanApiCall = async (
  profile: UserProfile,
  data: PlanUpgrade
): UpgradePlanResponse => {
  Logger.log('POST upgrade subscriptions api called with data: ', data);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, `${BASE_ROUTE}/upgrade`, {
    method: 'POST',
    body: JSON.stringify(data)
  });
};

export const upgradeHardwareApiCall = async (data: HardwareUpgrade) => {
  const url = 'https://hooks.zapier.com/hooks/catch/599828/oud76gq/';
  /* eslint-disable-next-line */
  return await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

export const getPortalApiCall = async (profile: UserProfile) => {
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, `${BASE_ROUTE}/portal`, {});
};

export const setBankPaymentApiCall = async (
  profile: UserProfile,
  data: BankPayment
) => {
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, `${BASE_ROUTE}/bank`, {
    method: 'POST',
    body: JSON.stringify(data)
  });
};
