import { select, call, put, takeLatest } from 'redux-saga/effects';
import {
  deleteRevokeInvitionAPICall,
  postResendInvitionAPICall,
  getInvitionsListAPICall
} from '../../api';
import {
  POST_RESEND_MEDICAL_GROUPS_INVITATION_FAILURE,
  GET_MEDICAL_GROUPS_INVITATIONS_LIST_SUCCESS,
  GET_MEDICAL_GROUPS_INVITATIONS_LIST_FAILURE,
  GET_MEDICAL_GROUPS_INVITATIONS_LIST_REQUEST,
  POST_RESEND_MEDICAL_GROUPS_INVITATION_REQUEST,
  DELETE_REVOKE_MEDICAL_GROUPS_INVITATION_REQUEST
} from '../actions/groupInvitationActions';
import { getCurrentUser } from '../selectors';
import { showSuccess } from '../helpers';

function* getInvitionsList() {
  try {
    const profile = yield select(getCurrentUser);
    const response = yield call(getInvitionsListAPICall, profile);
    yield put({
      type: GET_MEDICAL_GROUPS_INVITATIONS_LIST_SUCCESS,
      data: response
    });
  } catch (e) {
    yield put({
      type: GET_MEDICAL_GROUPS_INVITATIONS_LIST_FAILURE,
      message: e.message
    });
  }
}

function* postResendInvition({ payload }) {
  const { invitationGuid } = payload;
  try {
    const profile = yield select(getCurrentUser);
    const response = yield call(
      postResendInvitionAPICall,
      profile,
      invitationGuid
    );
    yield call(showSuccess, response.message);
  } catch (e) {
    yield put({
      type: POST_RESEND_MEDICAL_GROUPS_INVITATION_FAILURE,
      message: e.message
    });
  }
}

function* postDeleteInvition({ payload }) {
  const { invitationGuid } = payload;
  try {
    const profile = yield select(getCurrentUser);
    yield call(deleteRevokeInvitionAPICall, profile, invitationGuid);
    yield put({
      type: GET_MEDICAL_GROUPS_INVITATIONS_LIST_REQUEST
    });
  } catch (e) {
    yield put({
      type: POST_RESEND_MEDICAL_GROUPS_INVITATION_FAILURE,
      message: e.message
    });
  }
}

export default function* groupInvitationSaga() {
  yield takeLatest(
    GET_MEDICAL_GROUPS_INVITATIONS_LIST_REQUEST,
    getInvitionsList
  );
  yield takeLatest(
    POST_RESEND_MEDICAL_GROUPS_INVITATION_REQUEST,
    postResendInvition
  );
  yield takeLatest(
    DELETE_REVOKE_MEDICAL_GROUPS_INVITATION_REQUEST,
    postDeleteInvition
  );
}
