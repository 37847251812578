/**
 * Internal radiologists are just users of the same affiliated group than the user. The only difference between
 * both endpoints is the filtering automatically done to ensure the managed profiles are from a radiologist and that
 * every user endpoint requires admin privileges, but the reading methods for internal radiologists not.
 */
import AuthService from '../index';
import Logger from '../../utils/logger';
import querier from './querier';
import { jsonToUser, userToJson } from '../transformers';
import PubNub from 'pubnub';

import type {
  User,
  UserProfile,
  MultipleInternalRadiologistsResponse,
  ApiService
} from '../../schemas';
import { eventChannel } from 'redux-saga';

const API_SERVICE: ApiService = 'telerad-settings';
const BASE_ROUTE = '/internal-radiologists';

const pubnub = new PubNub({
  subscribeKey: 'sub-c-df2ceddc-04be-11ec-ac05-0664d1b72b66',
  publishKey: 'pub-c-eb69a0e3-9bd7-4d9b-bd3d-914e94db7c77',
  logVerbosity: true,
  ssl: true
});

/**
 * Get the users of the user affiliated group that have Radiologist as their user_metadata.user_type
 * @param profile: user profile
 * @param perPage: number of results to get by page
 * @param page: page of results
 * @param sort: column to order the returned results.
 * @param query: a query string that represents additional queries to pass to limit user results. If multiple queries
 * shall be passed, they must be separated by a plus ("+") sign. Each query follows the lucene query format (key:value)
 * For example: `name:Mark+email:mark@radiologist.com`
 * @returns {never|Promise<Response>} A promise with an http response containing a list of radiologist
 */
export const getInternalRadiologistsApiCall = async (
  profile: UserProfile,
  perPage: number,
  page: number,
  sort: string,
  query: string
) => {
  let route = BASE_ROUTE;
  if (perPage > 0 || page > 0 || sort && sort.length > 0 || query && query.length > 0) {
    route += querier(perPage, page, sort, query);
  }
  Logger.log('GET Internal radiologists api call with route: ', route);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, route, {})
    .then((response: MultipleInternalRadiologistsResponse) => {
      Logger.log('GET Internal radiologists api response: ', response);
      return {
        array: response.internal_radiologists.map(r => jsonToUser(r)),
        total: response.total
      };
    });
};

/**
 * Retrieves a radiologist by id
 * @param profile: user profile
 * @param id: id of the radiologist to retrieve
 * @returns {never|Promise<*>}: the radiologist with the provided id
 */
export const getInternalRadiologistByIdApiCall = async (
  profile: UserProfile,
  id: string
) => {
  Logger.log('GET Internal radiologist by id api call with id: ', id);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `${BASE_ROUTE}/${id}`, {})
    .then(r => {
      Logger.log('GET Internal radiologist by id api response: ', r);
      return jsonToUser(r);
    });
};

/**
 * Creates a radiologist belonging to the user affiliated group. Requires admin privileges.
 * @param profile: user profile
 * @param radiologist the radiologist data
 * @returns {never|Promise<*>} Returns the created radiologist
 */
export const createInternalRadiologistApiCall = async (
  profile: UserProfile,
  radiologist: User
) => {
  Logger.log('POST internal radiologist api call with profile: ', radiologist);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, BASE_ROUTE, {
      method: 'POST',
      body: userToJson(radiologist)
    })
    .then(r => {
      Logger.log('POST internal radiologist api call response: ', r);
      return r;
    });
};

/**
 * Updates an internal radiologist with the data passed in the body.
 * @param profile: user profile
 * @param id: id of the radiologist to update
 * @param radiologistUpdate the data to update
 * @returns {never|Promise<*>} Returns the updated radiologist.
 */
export const updateInternalRadiologistApiCall = async (
  profile: UserProfile,
  id: string,
  radiologistUpdate: User
) => {
  Logger.log(
    'PATCH Internal radiologist api call with update: ',
    radiologistUpdate
  );
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `${BASE_ROUTE}/${id}`, {
      method: 'PATCH',
      body: userToJson(radiologistUpdate)
    })
    .then(r => {
      Logger.log('PATCH internal radiologist api call response: ', r);
      return r;
    });
};

/**
 * Deletes a radiologist
 * @param profile: user profile
 * @param id: id of the radiologist to delete
 * @returns {never|Promise<*>} A {message} with a confirmation response.
 */
export const deleteInternalRadiologistApiCall = async (
  profile: UserProfile,
  id: string
) => {
  Logger.log('DELETE internal radiologist api call');
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `${BASE_ROUTE}/${id}`, { method: 'DELETE' })
    .then(r => {
      Logger.log('DELETE internal radiologist api call response: ', r);
      return r;
    });
};

export const getRadiologistsStatusApiCall = (profile: UserProfile) => {
  // pubnub.setUUID(profile.id);
  const channel = `organization-${profile.appMetadata.affiliatedGroup}`;

  return eventChannel(emit => {
    pubnub.addListener({
      message: messageEvent => {
        emit(messageEvent);
      }
    });

    pubnub.subscribe({
      channels: [channel],
      withPresence: true
    });

    return () => {
      pubnub.unsubscribe({
        channels: [channel]
      });
    };
  });
};