import React from "react";
import { Avatar, Badge } from "antd"
import { upperFirst } from "lodash";

const RadiologistList = ({ list = [], activeList = [] }) => {
    return (<div>
        {list.map((rad, index) => {
            return (
                <div key={index} className="radiologist-card">
                    <div className="rad-avatar"><Avatar>{rad.name && rad.name.split(' ').map(word => word.charAt(0)).splice(0, 2).join('').toUpperCase()}</Avatar><Badge color={activeList.includes(rad.id) ? "#52C41A" : "orange"} className="badge" dot /></div>
                    <div className="rad-details">
                        <div className="rad-name">
                            <div>{upperFirst(rad.name)},</div>
                            <div></div>
                        </div>
                        {rad.userMetadata && rad.userMetadata.specialty && rad.userMetadata.specialty.length ? <div className="rad-specialty">{rad.userMetadata.specialty.join(', ')}</div> : <div className="rad-specialty">Radiologist</div>}
                    </div>
                    <div className="hrs-text">7h</div>
                </div>
            )
        })}
    </div>);
};

export default RadiologistList;
