/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-console */
/* eslint-disable no-alert */
import React from 'react';
import { Row, Breadcrumb, Col } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Search from 'antd/lib/input/Search';

import { t } from 'i18next';
import './index.less';
import TableDevices from './TableDevices';
import AddDevice from './AddDevice';
import {
  getDevicesRequest,
  getInternalFacilitiesAction
} from '../../../store/actions';

const mapStateToProps = state => {
  return {
    facilities: state.facilities.internalArray
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getFacilities: getInternalFacilitiesAction,
      getDevices: getDevicesRequest
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(({ facilities, getFacilities, getDevices }) => {
  React.useEffect(() => {
    getFacilities();
    getDevices();
  }, []);

  const [searchText, setSearchText] = React.useState('');

  return (
    <>
      <Row type='flex' justify='space-between' align='middle'>
        <Col>
          <Breadcrumb className='bread'>
            <Breadcrumb.Item>
              {t('branding.cloud_Devices_connections')}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col style={{ display: 'flex' }}>
          <Search
            placeholder={t('branding.search_facility')}
            onSearch={value => setSearchText(value)}
            enterButton
            style={{ marginRight: 10 }}
          />
          <AddDevice />
        </Col>
      </Row>

      <div>
        <Row className='card-container'>
          <Row>
            <TableDevices searchText={searchText} />
          </Row>
        </Row>
      </div>
    </>
  );
});
