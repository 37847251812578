// @flow
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getActiveStudiesRequest,
  getFacilitiesRequest,
  // getProviderPerformanceRequest,
  // getOverviewRequest,
  getStudyDetailsRequest,
  getRadiologistPerformanceRequest,
  getGroupDetailRequest,
  getModalitiesRequest,
  getUserLocationRequest
  // getReportingRadiologistRequest,
  // getSentTosRequest
} from '../../store/actions';
import Dashboard from './index';
import { LoadingState, Group, ReduxState } from '../../schemas';

type Props = {
  loading: LoadingState,
  groupDetail: Group,
  getActiveStudies: Function,
  getGroupDetail: Function,
  getStudyDetails: Function,
  getFacilities: Function,
  getProviderPerformance: Function,
  getOverview: Function,
  getStudyDetails: Function,
  getRadiologistPerformance: Function,
  getModalities: Function,
  // getReportingRadiologist: Function,
  // getSentTos: Function,
  token: string
};

function Container({
  loading,
  groupDetail,
  getActiveStudies,
  getGroupDetail,
  getFacilities,
  getProviderPerformance,
  getOverview,
  getStudyDetails,
  getRadiologistPerformance,
  getModalities,
  // getReportingRadiologist,
  // getSentTos,
  token,
  getUserLocation
}: Props) {
  useEffect(() => {
    if (token && token.length > 0) {
      getActiveStudies();
      getGroupDetail();
      getFacilities();
      getModalities();
      // getReportingRadiologist();
      // getSentTos();
      getStudyDetails();
      getRadiologistPerformance();
      getUserLocation();
    }
    // getProviderPerformance();
    // getOverview();
    // getRadiologistPerformance();
  }, [token]);

  const dashboardProps = {
    loading,
    groupDetail
  };
  return <Dashboard {...dashboardProps} />;
}

const mapStateToProps = (state: ReduxState) => ({
  groupDetail: state.dashboard.groupDetail,
  loading: state.loading,
  token: state.profile.accessToken
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getGroupDetail: getGroupDetailRequest,
      getActiveStudies: getActiveStudiesRequest,
      getFacilities: getFacilitiesRequest,
      getStudyDetails: getStudyDetailsRequest,
      // getProviderPerformance: getProviderPerformanceRequest,
      // getOverview: getOverviewRequest,
      // getStudyDetails: getStudyDetailsRequest,
      getRadiologistPerformance: getRadiologistPerformanceRequest,
      getModalities: getModalitiesRequest,
      getUserLocation: getUserLocationRequest
      // getReportingRadiologist: getReportingRadiologistRequest,
      // getSentTos: getSentTosRequest,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
