/* eslint-disable react/button-has-type */
// @flow

import React from 'react';
import { Row, Col, Typography, Tabs } from 'antd';
import Box from 'ui-box';
import { t } from 'i18next';
import ActiveStudiesCard from './activeStudiesCard';
import MyFacilitiesCard from './myFacilitiesCard';
import StudyDetailsCard from './studyDetailsCard';
import RadiologistPerformanceCard from './radiologistPerformanceCard';
// import RadiologistPerformanceCard from './radiologistPerformanceCard';
import ErrorBoundary from '../../common/ErrorBoundary';
import { Group, LoadingState } from '../../schemas';
import QCDashboard from './QCDashboard';

const { Text } = Typography;
const { TabPane } = Tabs;

function Dashboard({ loading, groupDetail }) {

  function handleChangeTabs(key) {
    console.log(key);
  }

  const renderPageTitle = () => (
    <Box marginBottom={20}>
      <Row type='flex' align='middle' justify='space-between' gutter={[24, 12]}>
        <Col>
          <p>{t('dashboard.monitor_organization_data')}</p>
        </Col>
        <Col>
          <Text>
            {!loading.groupDetail
              ? `${groupDetail.name}, ${groupDetail.location}`
              : 'Loading..'}
          </Text>
        </Col>
      </Row>
    </Box>
  );

  return (
    <div id='dashboard'>
      {renderPageTitle()}
      <Tabs defaultActiveKey="1" onChange={handleChangeTabs}>
        <TabPane tab="General" key="general">
          <Box marginBottom={24}>
            <ErrorBoundary fallback={() => {}}>
              <StudyDetailsCard />
            </ErrorBoundary>
          </Box>
          <Box marginBottom={24}>
            <ErrorBoundary fallback={() => {}}>
              <RadiologistPerformanceCard />
            </ErrorBoundary>
          </Box>
          <Box marginBottom={24}>
            <ErrorBoundary fallback={() => {}}>
              <ActiveStudiesCard />
            </ErrorBoundary>
          </Box>
          <Box marginBottom={24}>
            <ErrorBoundary fallback={() => {}}>
              <MyFacilitiesCard />
            </ErrorBoundary>
          </Box>
        </TabPane>
        <TabPane tab="QC" key="qc">
          <QCDashboard />
        </TabPane>
        {/* <TabPane tab="AI & Second Opinion" key="ai_second_opinion">
        </TabPane> */}
      </Tabs>
      {/* <Box marginBottom={24}>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={24} lg={14}>
            <ErrorBoundary>
              <ProviderPerformanceCard />
            </ErrorBoundary>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10}>
            <ErrorBoundary>
              <OverviewCard />
            </ErrorBoundary>
          </Col>
        </Row>
      </Box>
      <Box marginBottom={24}>
        <ErrorBoundary>
          <StudyDetailsCard />
        </ErrorBoundary>
      </Box>
     */}
    </div>
  );
}

export default Dashboard;
