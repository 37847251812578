import * as React from 'react';
import { connect } from 'react-redux';

import { Modal, Spin } from 'antd';
import {
  addUserAction,
  getInternalFacilitiesAction,
  loadAddInternalRadiologist
} from '../../../store/actions';
import { editUserValuesToUser } from '../../../api/transformers';
// import AddIntRadForm from './forms/AddIntRadForm';

import type {
  Facility,
  ReduxState,
  User,
  UserEditionValues
} from '../../../schemas';
import AddUserForm from './forms/AddUserForm';
// import BaseIntRadForm from './forms/BaseIntRadForm';

type Props = {
  visible: boolean,
  loading: boolean,
  affiliatedGroup: string,
  modifications: number,
  createAction: User => void,
  loadingFacilities: boolean,
  loadFacilities: () => void,
  facilities: Array<Facility>,
  closeModal: () => void
};

type State = {
  modifications: number,
  close: boolean
};

export default connect(
  (state: ReduxState) => ({
    form: state.form.addInternalRadForm,
    loading: state.loading.addInternalRad,
    modifications: state.users.modifications,
    affiliatedGroup: state.profile.appMetadata.affiliatedGroup,
    loadingFacilities: state.loading.facilities,
    facilities: state.facilities.internalArray
  }),
  {
    createAction: addUserAction,
    loadForm: loadAddInternalRadiologist,
    loadFacilities: getInternalFacilitiesAction
  }
)(
  class AddExternalRadiologist extends React.PureComponent<Props, State> {
    state = {
      modifications: this.props.modifications, // eslint-disable-line  react/destructuring-assignment
      close: false
    };

    // We check if the number of created users is incremented (i.e. if the user creation has succeed,
    // and use this information to update the state.
    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.modifications > prevState.modifications) {
        return { modifications: nextProps.modifications, visible: false };
      }
      return null;
    }

    componentDidMount(): * {
      const { loadFacilities } = this.props;
      loadFacilities();
    }

    componentDidUpdate(prevProps) {
      if (this.props.modifications > prevProps.modifications) {
        this.props.closeModal();
      }
    }

    showModal = () => this.setState({ visible: true });

    handleCancel = () => this.props.closeModal();

    handleCreate = async (values: UserEditionValues) => {
      const { createAction, affiliatedGroup, facilities } = this.props;
      const data = editUserValuesToUser(values, affiliatedGroup, facilities);
      createAction(data);
    };

    render() {
      const { loading, loadingFacilities } = this.props;
      const { visible } = this.props;
      return (
        <div>
          {/* eslint-disable-next-line */}
          {/* <div role='link' tabIndex='-1' onClick={this.showModal}>
            Internal radiologist
          </div> */}
          {visible ? ( // We have to use this trick to force form destruction
            loadingFacilities ? (
              <Modal
                visible={visible}
                title='New Internal Radiologist'
                okText='Add'
                footer={null}
                onCancel={this.handleCancel}
                wrapClassName='lateral-modal'
              >
                <div style={{ textAlign: 'center' }}>
                  <Spin size='large' />
                </div>
              </Modal>
            ) : (
              <AddUserForm
                visible={visible}
                loading={loading}
                onCancel={this.handleCancel}
                onSubmit={this.handleCreate}
                setRadiologistType={this.props.setRadiologistType}
                radiologistType={this.props.radiologistType}
              />
            )
          ) : null}
        </div>
      );
    }
  }
);
