/* eslint-disable react/default-props-match-prop-types */
// @flow

import * as React from 'react';
import { Row, Col, Typography } from 'antd';
import Box from 'ui-box';

const { Title } = Typography;

const styles = {
  box: {
    border: '1px solid #EDEDED',
    borderRadius: 2,
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)'
  },
  title: {
    fontWeight: 500,
    fontSize: 16,
    margin: 0
  },
  headStyle: {
    padding: 16,
    backgroundColor: '#fff'
  },
  extra: {
    flex: 1
  },
  bodyStyle: {
    backgroundColor: '#fff'
  }
};

type Props = {
  title?: string | React.Element<any>,
  extra?: string | React.Element<any>,
  body?: string | React.Element<any>,
  rootStyle: Object,
  headStyle: Object,
  extraStyle: Object,
  bodyStyle: Object,
  children: React.Node
};

function CardWrapper({
  title,
  extra,
  children,
  rootStyle,
  headStyle,
  extraStyle,
  bodyStyle
}: Props) {
  const renderCardHeader = () => (
    <Box {...styles.headStyle} {...headStyle}>
      <Row type='flex' align='middle' justify='space-between' gutter={[24, 12]}>
        <Col>
          <Title level={4} style={styles.title}>
            {title}
          </Title>
        </Col>
        <Col style={{ ...styles.extra, ...extraStyle }}>{extra}</Col>
      </Row>
    </Box>
  );

  return (
    <Box {...styles.box} {...rootStyle}>
      {title !== '' && renderCardHeader()}
      <Box {...styles.bodyStyle} {...bodyStyle}>
        {children}
      </Box>
    </Box>
  );
}

CardWrapper.defaultProps = {
  title: '',
  rootStyle: {},
  headStyle: {},
  extraStyle: {},
  bodyStyle: {}
};

export default CardWrapper;
