/* eslint-disable no-console */

const logFunc = process.env.NODE_ENV === 'development' ? console.log : () => {};

export default class Logger {
  static log(...args) {
    const enhancedArgs = args.map(arg => JSON.stringify(arg, null, 2));
    logFunc.apply(console, enhancedArgs);
  }
}
