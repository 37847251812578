import * as React from 'react';

import { connect } from 'react-redux';
import type { Country, Facility, ReduxState } from '../../../../schemas';
import {
  addExternalFacilityAction,
  loadAddExternalFacilityForm
} from '../../../../store/actions';

import AddExternalFacilityForm from './Form/AddExternalFacilityForm';

type Props = {
  loading: boolean,
  modifications: number,
  createAction: Facility => void,
  rawCountries: Array<Country>
};

type State = {
  visible: boolean,
  modifications: number,
  close: boolean,
  countryList: Array<{ text: string, value: string }>
};

export default connect(
  (state: ReduxState) => ({
    form: state.form.addExternalFacilityForm,
    loading: state.loading.addFacility,
    modifications: state.facilities.modifications,
    rawCountries: state.countryList
  }),
  {
    createAction: addExternalFacilityAction,
    loadForm: loadAddExternalFacilityForm
  }
)(
  class AddExternalFacility extends React.PureComponent<Props, State> {
    state = {
      visible: false,
      modifications: this.props.modifications, // eslint-disable-line  react/destructuring-assignment
      close: false,
      countryList: []
    };

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.modifications > prevState.modifications) {
        return { modifications: nextProps.modifications, visible: false };
      }
      return null;
    }

    componentDidMount(): void {
      const { rawCountries } = this.props;
      this.setState({
        countryList: rawCountries.map((c: Country) => ({
          text: c.name,
          value: c.alpha
        }))
      });
    }

    showModal = () => this.setState({ visible: true });

    handleCancel = () => this.setState({ visible: false });

    handleCreate = async values => this.props.createAction(values);

    render() {
      const { loading } = this.props;
      const { visible, countryList } = this.state;
      return (
        <div>
          {/* eslint-disable-next-line */}
          <div role='link' tabIndex='-1' onClick={this.showModal}>
            External Facility
          </div>
          {visible ? (
            <AddExternalFacilityForm
              visible={visible}
              loading={loading}
              onCancel={this.handleCancel}
              onSubmit={this.handleCreate}
              countryList={countryList}
            />
          ) : null}
        </div>
      );
    }
  }
);
