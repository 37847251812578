export default values => {
  const errors = { facility: {} };
  const { facility } = values;
  const { name, email, type } = facility;
  if (!name) {
    errors.facility.name = 'Required';
  }

  if (!type) {
    errors.facility.type = 'Required';
  }

  if (!email) {
    errors.facility.email = 'Required';
  }

  return errors;
};
