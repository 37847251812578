// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Modal, Spin } from 'antd';

import { t } from 'i18next';
import { change } from 'redux-form';
import {
  addInternalFacilityAction,
  loadAddInternalFacilityForm
} from '../../../../store/actions';
import AddReferringFacilitiesForm from './AddReferringFacilitiesForm';

export default connect(
  state => ({
    form: state.form.addInternalFacilityForm,
    addPhysicianForm: state.form.addPhysicianForm,
    loading: state.loading.addFacility,
    modifications: state.facilities.modifications,
    loadingFacilities: state.loading.facilities
  }),
  {
    createAction: addInternalFacilityAction,
    loadForm: loadAddInternalFacilityForm,
    setFacilityInAddPhysician: value =>
      change('addPhysicianForm', 'primaryFacility', value)
  }
)(
  class AddReferringFacilities extends React.PureComponent {
    state = {
      modifications: this.props.modifications // eslint-disable-line  react/destructuring-assignment
    };

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.modifications > prevState.modifications) {
        return { modifications: nextProps.modifications };
      }
      return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      const { close } = this.props;
      if (prevState.modifications < this.state.modifications) {
        close();
      }
    }

    handleCreate = values => {
      const {
        createAction,
        setFacilityInAddPhysician,
        addPhysicianForm
      } = this.props;
      createAction(values);
      if (
        addPhysicianForm &&
        addPhysicianForm.values.createNewFacility === true
      )
        setFacilityInAddPhysician(['add-new-facilities', values.name]);
    };

    render() {
      const { selectedTab, visible, loading, close } = this.props;
      if (visible)
        return (
          <AddReferringFacilitiesForm
            visible={visible}
            loading={loading}
            isInternal={selectedTab === '1'}
            onCancel={close}
            onSubmit={this.handleCreate}
          />
        );
      if (visible)
        return (
          <Modal
            visible={visible}
            title={t('referral_patterns.add_referring_facilities')}
            okText={t('button.add')}
            footer={null}
            onCancel={close}
          >
            <div style={{ textAlign: 'center' }}>
              <Spin size='large' />
            </div>
          </Modal>
        );
      return null;
    }
  }
);
