import * as React from 'react';
// import classnames from 'classnames';
import { Badge } from 'antd';
import { t } from 'i18next';
import { capitalize } from '../../../../utils';

export default (
  action: (
    text: string,
    record: { id: string, name: string }
  ) => React.ReactNode
) => [
  {
    title: t('my_facilities.table.name'),
    dataIndex: 'name',
    sorter: (a, b) =>
      a.name
        .toString()
        .toLowerCase()
        .localeCompare(b.name.toString().toLowerCase()),
    columnSearch: true,
    align: 'left'
  },
  {
    title: t('my_facilities.table.primary_contact'),
    dataIndex: 'email',
    sorter: (a, b) =>
      a.name
        .toString()
        .toLowerCase()
        .localeCompare(b.name.toString().toLowerCase()),
    columnSearch: true,
    align: 'left'
  },
  {
    title: t('my_facilities.table.phone'),
    dataIndex: 'phoneNumber',
    columnSearch: true,
    align: 'left'
  },
  {
    title: t('my_facilities.table.status'),
    dataIndex: 'status',
    render: (text, record) => {
      return (
        <div>
          <Badge
            status={
              record.status === 'active' || !record.status
                ? 'success'
                : 'default'
            }
          />
          <span className='circleSpace'>
            {record.status ? capitalize(record.status) : 'Active'}
          </span>
        </div>
      );
    },
    sorter: (a, b) =>
      a
        .toString()
        .toLowerCase()
        .localeCompare(b.name.toString().toLowerCase()),
    align: 'left'
  },
  {
    title: '',
    key: 'action',
    width: 80,
    render: (text, record) => action(text, record)
  }
];
