import React, { useMemo, useState, useCallback, useEffect } from 'react';
// import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone';
import { Icon, Button, Badge, Avatar } from 'antd';
import Box from 'ui-box';
import { t } from 'i18next';

const styles = {
  baseStyle: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    height: 250,
    border: '1px dashed rgba(0, 0, 0, 0.24)',
    borderRadius: 4,
    backgroundColor: '#EDF8FD',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  },
  activeStyle: {
    borderColor: '#2196f3'
  },
  acceptStyle: {
    borderColor: '#00e676'
  },
  rejectStyle: {
    borderColor: '#ff1744'
  },
  title: {
    fontWeight: 500,
    fontSize: 14,
    color: 'rgba(64, 64, 71, 0.65)',
    marginBottom: 25
  }
};

function UploadBranding({ input: { value, onChange } }) {
  const [logo, setLogo] = useState(value);

  useEffect(() => {
    if (typeof value === 'string') {
      setLogo(value);
    }
  }, [value]);

  const onUpload = useCallback(acceptedFiles => {
    if (acceptedFiles) {
      setLogo(URL.createObjectURL(acceptedFiles[0]));
      onChange(acceptedFiles[0]);
    }
  }, []);

  const onRemoveFile = () => {
    setLogo(null);
    onChange(null);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open
    // acceptedFiles
  } = useDropzone({
    accept: 'image/*',
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: onUpload
  });

  const { baseStyle, activeStyle, acceptStyle, rejectStyle, title } = styles;

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <>
      <div {...getRootProps({ className: 'dropzone', style })}>
        <input {...getInputProps()} />
        <Box {...title}>{t('branding.drop_logo_image')}</Box>
        <Box {...title} fontWeight='normal'>
          {t('branding.or_click')}
        </Box>
        <Button type='primary' onClick={open}>
          <Icon type='upload' /> {t('branding.upload_file')}
        </Button>
      </div>
      {logo ? (
        <div
          className='settings-image-show'
          style={{ maxWidth: '250px', marginTop: '20px' }}
        >
          <Badge
            onClick={() => onRemoveFile()}
            className='group-logo-badge'
            count={
              <Icon
                type='close-circle'
                theme='filled'
                style={{ fontSize: '24px' }}
              />
            }
          >
            <Avatar
              shape='square'
              size='large'
              src={logo}
              style={{ maxWidth: '250px' }}
            />
          </Badge>
        </div>
      ) : null}
    </>
  );
}

export default UploadBranding;
