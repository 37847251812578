import React from 'react';
import { Button, Input, Icon } from 'antd';
import Highlighter from 'react-highlight-words';

const handleSearch = (selectedKeys, confirm) => {
  confirm();
  // this.setState({ searchText: selectedKeys[0] });
};

const handleReset = clearFilters => {
  clearFilters();
  // this.setState({ searchText: '' });
};

export default (dataIndex, searchInput, searchText, placeholder) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  }) => (
    <div className='custom-filter-dropdown' style={{ padding: '8px' }}>
      <Input
        ref={node => {
          searchInput = node;
        }}
        placeholder={placeholder || `Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type='primary'
        onClick={() => handleSearch(selectedKeys, confirm)}
        icon='search'
        size='small'
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button
        onClick={() => handleReset(clearFilters)}
        size='small'
        style={{ width: 90 }}
      >
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => (
    <Icon type='search' style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilter: (value, record) => {
    return record[dataIndex]
      ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      : '';
  },
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => searchInput.select());
    }
  },
  render: (text = '') => (
    <Highlighter
      highlightStyle={{ backgroundColor: '#f20000', padding: 0 }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text.toString()}
    />
  )
});
