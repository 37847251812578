/* eslint-disable no-restricted-syntax */
import pathToRegexp from 'path-to-regexp';
import lodashIsEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';
import isEmptyFunc from 'is-empty';
import { isObject, isString, map, merge } from 'lodash';
import config from './config';
import moment from 'moment';

export { config };

export const transformObject = (
  obj,
  filter = null,
  transformKeyFunc = key => key,
  transformValueFunc = elem => elem
) => {
  let keys = Object.keys(obj);
  if (filter) {
    keys = keys.filter(key => filter.includes(key));
  }
  return Object.assign(
    ...keys.map(k => ({ [transformKeyFunc(k)]: transformValueFunc(obj[k]) }))
  );
};

export const toCamel = s =>
  s.replace(/([-_][a-z])/gi, $1 =>
    $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  );

export const transformCountries = rawCountries =>
  rawCountries.map(c => ({
    text: c.name,
    value: c.alpha
  }));

export const adaptUserData = data =>
  data.map(u => {
    if (!(u.userMetadata || { userMetadata: { name: '' } }.userMetadata).name) {
      u.userMetadata.name = u.name;
    }
    return u;
  });

export const renderCurrency = c =>
  c && typeof c === 'string' ? c.toUpperCase() : c;

export const isEmpty = element => isEmptyFunc(element);

export const isEqual = (value, other) => lodashIsEqual(value, other);

export const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

export const getPlanName = plan =>
  plan.charAt(plan.length - 3) === '-' ? plan.substr(0, plan.length - 3) : plan;

export const getStripeSubscriptionKind = name => {
  for (const [key, value] of Object.entries({
    ROUTINE: ['routine'],
    CONNECT: ['connect'],
    HUB: ['hub'],
    BOX: ['box'],
    OPINION: ['opinion']
  })) {
    for (const s of value) {
      if (name.toLowerCase().includes(s)) {
        return key;
      }
    }
  }
  return 'NONE';
};

export const formatDate = (date, locale = 'en-US') =>
  date &&
  (typeof date === 'number' ? new Date(date * 1000) : date).toLocaleDateString(
    locale
  );

export const isSameDay = (date1, date2) =>
  date1.getDay() === date2.getDay() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getFullYear() === date2.getFullYear();

export const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const upperCase = s => {
  if (typeof s !== 'string') return '';
  return s.toUpperCase();
};

export const centsToUnit = cents =>
  cents ? (cents / 100).toLocaleString() : '0';

export const formatNumber = num => (num ? num.toLocaleString('en-EN') : '-');

export function pathMatchRegexp(regexp, pathname) {
  return pathToRegexp(regexp).exec(pathname);
}

export function queryAncestors(array, current, parentId, id = 'id') {
  const result = [current];
  const hashMap = new Map();
  array.forEach(item => hashMap.set(item[id], item));

  // eslint-disable-next-line no-shadow
  const getPath = current => {
    const currentParentId = hashMap.get(current[id])[parentId];
    if (currentParentId) {
      result.push(hashMap.get(currentParentId));
      getPath(hashMap.get(currentParentId));
    }
  };

  getPath(current);
  return result;
}

export function arrayToTree(
  array,
  id = 'id',
  parentId = 'pid',
  children = 'children'
) {
  const result = [];
  const hash = {};
  const data = cloneDeep(array);

  data.forEach((item, index) => {
    hash[data[index][id]] = data[index];
  });

  data.forEach(item => {
    const hashParent = hash[item[parentId]];
    if (hashParent) {
      if (!hashParent[children]) {
        hashParent[children] = [];
      }
      hashParent[children].push(item);
    } else {
      result.push(item);
    }
  });
  return result;
}

export const setFieldOptions = (
  choices,
  resetOption = null,
  addPrefixToLabel
) => {
  let modifiedChoices = {};
  if (resetOption) {
    const { label, value } = resetOption;
    modifiedChoices[value] = label;
    merge(modifiedChoices, choices);
  } else {
    modifiedChoices = choices;
  }
  const optionIsAString =
    modifiedChoices && modifiedChoices[1] && isObject(modifiedChoices[1]);
  return map(modifiedChoices, (choice, key) => {
    return optionIsAString
      ? { label: choice, value: key }
      : addPrefixToLabel
      ? { label: `${choice.value} - ${choice.label}`, value: choice.value }
      : choice;
  });
};

export const selectFieldFilterHandler = (input, option) =>
  isString(option.props.children) &&
  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

export function queryLayout(layouts, pathname) {
  let result = 'public';

  const isMatch = regepx => {
    return regepx instanceof RegExp
      ? regepx.test(pathname)
      : pathMatchRegexp(regepx, pathname);
  };

  for (const item of layouts) {
    let include = false;
    let exclude = false;
    if (item.include) {
      for (const regepx of item.include) {
        if (isMatch(regepx)) {
          include = true;
          break;
        }
      }
    }

    if (include && item.exclude) {
      for (const regepx of item.exclude) {
        if (isMatch(regepx)) {
          exclude = true;
          break;
        }
      }
    }

    if (include && !exclude) {
      result = item.name;
      break;
    }
  }

  return result;
}

export function isValidUuid(uuid) {
  const validUuidRegex = new RegExp(
    '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$',
    'ig'
  );
  return validUuidRegex.test(uuid);
}

export function removeUUID(sentence) {
  return sentence.replace(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g, '').trim();
}

export const getDateRangeArray = (selectedDate = null, format = 'YYYY-MM') => {
  let fromMoment = moment().subtract(1, 'M')
  let toMoment = moment()
  switch (selectedDate) {
    case 'last_24_hours':
      fromMoment = moment().subtract(24, 'hours')
      break
    case 'last_7_days':
      fromMoment = moment().subtract(7, 'days')
      break
    default:
      if (moment(selectedDate).isValid()) {
        fromMoment = moment(selectedDate, format).startOf('month')
        toMoment = moment(selectedDate, format).endOf('month')
      } else {
        fromMoment = moment().subtract(1, 'M')
      }
  }
  return [fromMoment, toMoment]
}

export const getChartVersion = () => {
  try {
    const plotsVersion = require('@ant-design/plots/package.json').version;
    
    return plotsVersion
  } catch (error) {
    return null;
  }
};

export const checkIsOldChart = () => {
  const chartVersion = getChartVersion();
  if (!chartVersion) return true;
  if (chartVersion.startsWith('1')) return true;
  return false;
}

export const getScrollableChartRatio = (length) => {
  let scrollRatio = 20 / length
  if (length % 3 === 0) scrollRatio = 18 / length
  if (length % 2 === 0) scrollRatio = 20 / length
  return scrollRatio
}