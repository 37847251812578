/* eslint-disable prefer-destructuring */
/* eslint-disable no-alert */
/* eslint-disable no-console */
import Configuration from '../../utils/config';
import { getProfileFromLocalStorage } from '../../store/storage';

export const postNotificationToZapierAPICall = async ({ content }) => {
  try {
    const email = getProfileFromLocalStorage().email;
    const payload = { ...content, email };
    const config = {
      method: 'POST',
      body: JSON.stringify(payload)
    };
    const response = await window.fetch(
      `${Configuration.zapierWebHook}`,
      config
    );
    const data = await response.ok;
    return data;
  } catch (e) {
    console.error('ERROR: ', e);
    return null;
  }
};

export const postInviteToZapierAPICall = async ({ jsonContent = null }) => {
  try {
    let responseOkByZapier;
    const config = {
      method: 'POST',
      body: JSON.stringify(jsonContent)
    };
    const zapierResponse = await window.fetch(
      'https://hooks.zapier.com/hooks/catch/599828/ba95rre/',
      config
    );
    responseOkByZapier = await zapierResponse.ok;
    return { responseOk: responseOkByZapier };
  } catch (e) {
    console.log('ERROR: ', e);
    return { responseOk: false };
  }
};
