// @flow

import * as React from 'react';
import { Menu, Dropdown } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import { t } from 'i18next';
import EditInternalFacility from '../Edition/EditInternalFacility';
import ViewInternalFacility from '../View/ViewInternalFacility';

const { Item } = Menu;

type Props = {
  record: {
    guid: string,
    name: string
  },
  getAction: string => void
};

type State = {
  viewElement: boolean,
  editElement: boolean
};

export default class TableMenu extends React.PureComponent<Props, State> {
  state = {
    viewElement: false,
    editElement: false
  };

  componentDidMount() {
    const { getAction, record } = this.props;
    if (record.guid) {
      getAction(record.guid);
    }
  }

  handleCancelEdit = () => this.setState({ editElement: false });

  setViewVisibility = (visible: boolean) =>
    this.setState({ viewElement: visible });

  handleMenuClick = (e: ClickParam) => {
    const { getAction, record } = this.props;
    switch (e.key) {
      case '1':
        getAction(record.guid);
        this.setState({ viewElement: true });
        break;
      case '2':
        getAction(record.guid);
        this.setState({ editElement: true });
        break;
      case '3':
        e.item.setState({ visible: false });
        break;
      default:
        break;
    }
  };

  render() {
    const { record } = this.props;
    const { editElement, viewElement } = this.state;
    return (
      <React.Fragment>
        <EditInternalFacility
          visible={editElement}
          onCancel={this.handleCancelEdit}
        />
        <ViewInternalFacility
          visible={viewElement}
          setVisibility={this.setViewVisibility}
        />
        <Dropdown
          overlay={
            <Menu onClick={this.handleMenuClick}>
              <Item key='1' id={record.guid}>
                {t('my_facilities.view_internal_facility')}
              </Item>
              <Item key='2' id={record.guid}>
                {t('my_facilities.edit_internal_facility')}
              </Item>
              <Item key='3' id={record.guid}>
                {t('button.cancel')}
              </Item>
            </Menu>
          }
          trigger={['click']}
        >
          <a className='ant-dropdown-link'>{t('button.menu')}</a>
        </Dropdown>
      </React.Fragment>
    );
  }
}
