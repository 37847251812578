/* eslint-disable react/button-has-type */
// @flow

import React from 'react';
import { Row, Col, Typography } from 'antd';
import Box from 'ui-box';
import { t } from 'i18next';
import StudyOverviewCard from './StudyOverviewCard';
import ProcedureOverviewCard from './ProcedureOverviewCard';
// import RadiologistPerformanceCard from './radiologistPerformanceCard';
import ErrorBoundary from '../../common/ErrorBoundary';
import { Group, LoadingState } from '../../schemas';
import ProcedureDetailsCard from './ProcedureDetailsCard';

const { Text } = Typography;

type Props = {
  groupDetail: Group,
  loading: LoadingState
};

function Dashboard({ loading, groupDetail }: Props) {
  const renderPageTitle = () => (
    <Box marginBottom={20}>
      <Row type='flex' align='middle' justify='space-between' gutter={[24, 12]}>
        <Col>
          <p>{t('dashboard.monitor_organization_data')}</p>
        </Col>
        <Col>
          <Text>
            {!loading.groupDetail
              ? `${groupDetail.name}, ${groupDetail.location}`
              : 'Loading..'}
          </Text>
        </Col>
      </Row>
    </Box>
  );

  return (
    <div id='dashboard'>
      {renderPageTitle()}
      <Box marginBottom={24}>
        <ErrorBoundary fallback={() => {}}>
          <StudyOverviewCard />
        </ErrorBoundary>
      </Box>
      <Box marginBottom={24}>
        <ErrorBoundary fallback={() => {}}>
          <ProcedureOverviewCard />
        </ErrorBoundary>
      </Box>
      <Box marginBottom={24}>
        <ErrorBoundary fallback={() => {}}>
          <ProcedureDetailsCard />
        </ErrorBoundary>
      </Box>
    </div>
  );
}

export default Dashboard;
