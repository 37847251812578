import {
  LOAD_TO_EDIT_USER_FORM,
  LOAD_SETTINGS_ACCOUNT_FORM,
  LOAD_SETTINGS_NOTIFICATIONS_FORM,
  LOAD_EDIT_EXTERNAL_RADIOLOGIST,
  LOAD_EDIT_INTERNAL_RADIOLOGIST,
  LOAD_EDIT_PROVIDER,
  LOAD_ADD_EXTERNAL_RADIOLOGIST,
  LOAD_ADD_INTERNAL_RADIOLOGIST,
  LOAD_ADD_PROVIDER,
  LOAD_ADD_USER_FORM,
  LOAD_BILLING_ADDRESS_FORM,
  LOAD_ADD_EXTERNAL_FACILITY_FORM,
  LOAD_ADD_INTERNAL_FACILITY_FORM,
  LOAD_EDIT_INTERNAL_FACILITY_FORM,
  LOAD_ADD_PHYSICIAN_FORM,
  LOAD_EDIT_PHYSICIAN_FORM,
  LOAD_SETTINGS_GROUP_FORM,
  LOAD_SETTINGS_LETTERHEAD_FORM,
  LOAD_EDIT_FACILITY_FORM
} from '../actions';

export default (state = {}, action) => {
  switch (action.type) {
    case LOAD_TO_EDIT_USER_FORM:
      return {
        ...state,
        editUser: action.data
      };
    case LOAD_SETTINGS_ACCOUNT_FORM:
      return {
        ...state,
        settingsAccount: action.data
      };
    case LOAD_SETTINGS_NOTIFICATIONS_FORM:
      return {
        ...state,
        settingsNotifications: action.data
      };
    case LOAD_SETTINGS_GROUP_FORM:
      return {
        ...state,
        settingsGroup: action.data
      };
    case LOAD_EDIT_EXTERNAL_RADIOLOGIST:
      return {
        ...state,
        editExternalRad: action.data
      };
    case LOAD_EDIT_INTERNAL_RADIOLOGIST:
      return {
        ...state,
        editInternalRad: action.data
      };
    case LOAD_EDIT_PROVIDER:
      return {
        ...state,
        editProvider: action.data
      };
    case LOAD_ADD_EXTERNAL_RADIOLOGIST:
      return {
        ...state,
        addExternalRad: action.data
      };
    case LOAD_ADD_INTERNAL_RADIOLOGIST:
      return {
        ...state,
        addInternalRad: action.data
      };
    case LOAD_ADD_PROVIDER:
      return {
        ...state,
        addProvider: action.data
      };
    case LOAD_ADD_USER_FORM:
      return {
        ...state,
        addUser: action.data
      };
    case LOAD_BILLING_ADDRESS_FORM:
      return {
        ...state,
        billingAddress: action.data
      };
    case LOAD_ADD_EXTERNAL_FACILITY_FORM:
      return {
        ...state,
        addExternalFacility: action.data
      };
    case LOAD_ADD_INTERNAL_FACILITY_FORM:
      return {
        ...state,
        addInternalFacility: action.data
      };
    case LOAD_EDIT_INTERNAL_FACILITY_FORM:
      return {
        ...state,
        editInternalFacility: action.data
      };
    case LOAD_ADD_PHYSICIAN_FORM:
      return {
        ...state,
        addPhysician: action.data
      };
    case LOAD_EDIT_PHYSICIAN_FORM:
      return {
        ...state,
        editPhysician: action.data
      };
    case LOAD_EDIT_FACILITY_FORM:
      return {
        ...state,
        editFacility: action.data
      };
    case LOAD_SETTINGS_LETTERHEAD_FORM:
      return {
        ...state,
        settingsPrint: action.data
      };
    default:
      return state;
  }
};
