// @flow

import * as React from 'react';
import classnames from 'classnames';
import styles from './Page.less';

type Props = {
  className: string,
  children: React.Node,
  loading: boolean,
  inner: boolean
};

export default ({
  className,
  children,
  loading = false,
  inner = false
}: Props) => {
  const loadingStyle = {
    height: 'calc(100vh - 184px)',
    overflow: 'hidden'
  };
  return (
    <div
      className={classnames(className, {
        [styles.contentInner]: inner
      })}
      style={loading ? loadingStyle : null}
    >
      {children}
    </div>
  );
};
