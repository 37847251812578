// @flow

import React from 'react';
import { Row, Col, Table, Badge, Button, Tooltip, Empty } from 'antd';
import Box from 'ui-box';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import { t } from 'i18next';
import CardWrapper from '../../common/CardWrapper';
import { selectFacilitiesWithStatus } from '../../store/selectors';
import { ReactComponent as CsvIcon } from '../../assets/images/csv-svgrepo-com.svg';
import type { Facility, ReduxState } from '../../schemas';

type Props = {
  facilities: { list: Array<Facility>, statusList: Array<Object> },
  loading: boolean
};

type PowerConnection = {
  powerConnection: {
    isPowerConnect: boolean,
    batteryStatus: string
  }
};

type IsDeviceOnline = {
  isDeviceOnline: boolean
};

const styles = {
  badge: {
    display: 'flex'
  }
};

const DeviceOnlineStatus = ({ isDeviceOnline }: IsDeviceOnline) => {
  return (
    <Row type='flex' align='middle' gutter={[0, 6]}>
      <Col>
        <Badge
          status={isDeviceOnline ? 'success' : 'default'}
          style={styles.badge}
        />
      </Col>
      <Col>{isDeviceOnline ? 'Online' : 'Offline'}</Col>
    </Row>
  );
};

const DevicePower = ({ powerConnection }: PowerConnection) => {
  const { isPowerConnect, batteryStatus } = powerConnection || {};
  return (
    <Row type='flex' align='middle' gutter={[0, 6]}>
      <Col>
        <Badge
          status={isPowerConnect || batteryStatus ? 'processing' : 'error'}
          style={styles.badge}
        />
      </Col>
      <Col>
        {isPowerConnect
          ? 'Connected'
          : batteryStatus
          ? `Battery (${batteryStatus}%)`
          : 'Unavailable'}
      </Col>
    </Row>
  );
};

function MyFacilitiesCard({ facilities, loading }: Props) {
  const csvHeaders = [
    { label: t('dashboard.table.source_facility'), key: 'name' },
    { label: t('dashboard.table.connection_method'), key: 'connectionMethod' },
    { label: t('dashboard.table.activity'), key: 'isDeviceOnline' },
    { label: t('dashboard.table.power'), key: 'power' },
    { label: t('dashboard.table.internet'), key: 'internet' }
  ];

  const dataFacilities = () => {
    if (facilities.list.length > 0) {
      const newData = facilities.list.map(item => {
        const { isPowerConnect, batteryStatus } = item.powerConnection || {};
        const dataFacility = {
          name: item.name,
          connectionMethod: item.connectionMethod
            ? item.connectionMethod
            : 'Disconnected',
          isDeviceOnline: item.isDeviceOnline ? 'Online' : 'Offline',
          power: isPowerConnect
            ? 'Connected'
            : batteryStatus
            ? `Battery (${batteryStatus}%)`
            : 'Unavailable',
          networkType: item.networkType,
          internet: item.networkType ? item.networkType : 'N/A'
        };
        return dataFacility;
      });
      return newData;
    }

    return [];
  };
  const columns = [
    {
      title: t('dashboard.table.source_facility'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('dashboard.table.connection_method'),
      dataIndex: 'connectionMethod',
      key: 'connectionMethod',
      render: (text, record) =>
        record.connectionMethod ? record.connectionMethod : 'Disconnected'
    },
    {
      title: t('dashboard.table.activity'),
      dataIndex: 'isDeviceOnline',
      key: 'isDeviceOnline',
      render: (text, record) => (
        <DeviceOnlineStatus isDeviceOnline={record.isDeviceOnline} />
      )
    },
    {
      title: t('dashboard.table.power'),
      dataIndex: 'power',
      key: 'power',
      render: (text, record) => (
        <DevicePower powerConnection={record.powerConnection} />
      )
    },
    {
      title: t('dashboard.table.connection_speed'),
      dataIndex: 'networkType',
      key: 'networkType',
      render: (text, record) =>
        record.networkType ? record.networkType : 'N/A'
    }
  ];

  const tableProps = {
    columns,
    loading,
    dataSource: facilities.list,
    rowKey: ({ guid }) => guid,
    scroll: { x: 768 },
    pagination: {
      pageSize: 5,
      style: {
        float: 'left',
        padding: '0 16px'
      }
    }
  };

  return (
    <div>
      <Box display='flex' justifyContent='flex-end' marginBottom={10}>
        <CSVLink
          headers={csvHeaders}
          data={dataFacilities()}
          filename={`my_facilities_${new Date().toISOString().split('T')[0]}`}
        >
          <Tooltip placement='left' title={t('dashboard.download_csv')}>
            <Button
              type='default'
              ghost
              style={{ border: 'none', boxShadow: 'none', padding: 0 }}
            >
              <CsvIcon />
            </Button>
          </Tooltip>
        </CSVLink>
      </Box>
      <CardWrapper title={t('dashboard.my_connections')}>
        <Table className='table-dashboard' {...tableProps} 
                locale={{
                  emptyText: (
                    <Empty
                      description={t("table.no_data")}
                    />
                  ),
                }} />
      </CardWrapper>
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => {
  const { loading } = state;

  return {
    facilities: selectFacilitiesWithStatus(state),
    loading: loading.facilities
  };
};

export default connect(mapStateToProps, {})(MyFacilitiesCard);
