// @flow

import * as React from 'react';
import classnames from 'classnames';

import './ActionLink.less';

type Props = {
  click: () => void,
  text: string,
  red?: boolean,
  disabled?: boolean
};

export default class ActionLink extends React.PureComponent<Props> {
  handleClick = (e: any) => {
    e.preventDefault();
    const { click } = this.props;
    click();
  };

  render() {
    const { text, red, disabled, ...props } = this.props;
    delete props.click;
    return (
      <button
        type='button'
        className={classnames('link-button', {
          red: !disabled && red,
          normal: !disabled && !red,
          disabled
        })}
        onClick={disabled ? () => {} : this.handleClick}
        {...props}
      >
        {text}
      </button>
    );
  }
}
