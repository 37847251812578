import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CommonHeader from "../common/CommonHeader";
import { Button, Card, Col, Divider, Dropdown, Empty, Icon, Menu, Modal, Row, Spin, Tag } from "antd";
import RouteFilterForm from "./components/RouteFilterForm";
import "./index.less"
import Text from "antd/lib/typography/Text";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deleteRouteRequestAction, getInternalFacilitiesAction, getInternalRadiologistsAction, getProviders, getRouteListRequestAction } from "../../../store/actions";
import { pages } from "../../../utils/routes"; import { getFormValues } from "redux-form";

const RenderUrgentIcon = () => <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.4531 0.75H8.76562C8.5582 0.75 8.39062 0.917578 8.39062 1.125V10.875C8.39062 11.0824 8.5582 11.25 8.76562 11.25H10.4531C10.6605 11.25 10.8281 11.0824 10.8281 10.875V1.125C10.8281 0.917578 10.6605 0.75 10.4531 0.75Z" fill="#FFE58F" />
  <path d="M5.15625 4.125H6.84375C7.05117 4.125 7.21875 4.29258 7.21875 4.5V10.875C7.21875 11.0824 7.05117 11.25 6.84375 11.25H5.15625C4.94883 11.25 4.78125 11.0824 4.78125 10.875V4.5C4.78125 4.29258 4.94883 4.125 5.15625 4.125Z" fill="#FFE58F" />
  <path d="M3.23438 7.5H1.54688C1.33945 7.5 1.17188 7.66758 1.17188 7.875V10.875C1.17188 11.0824 1.33945 11.25 1.54688 11.25H3.23438C3.4418 11.25 3.60938 11.0824 3.60938 10.875V7.875C3.60938 7.66758 3.4418 7.5 3.23438 7.5Z" fill="#FAAD14" />
</svg>

const RenderStatIcon = () => <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.4531 0.75H8.76562C8.5582 0.75 8.39062 0.917578 8.39062 1.125V10.875C8.39062 11.0824 8.5582 11.25 8.76562 11.25H10.4531C10.6605 11.25 10.8281 11.0824 10.8281 10.875V1.125C10.8281 0.917578 10.6605 0.75 10.4531 0.75Z" fill="#FFD8BF" />
  <path d="M6.84375 4.125H5.15625C4.94883 4.125 4.78125 4.29258 4.78125 4.5V10.875C4.78125 11.0824 4.94883 11.25 5.15625 11.25H6.84375C7.05117 11.25 7.21875 11.0824 7.21875 10.875V4.5C7.21875 4.29258 7.05117 4.125 6.84375 4.125ZM3.23438 7.5H1.54688C1.33945 7.5 1.17188 7.66758 1.17188 7.875V10.875C1.17188 11.0824 1.33945 11.25 1.54688 11.25H3.23438C3.4418 11.25 3.60938 11.0824 3.60938 10.875V7.875C3.60938 7.66758 3.4418 7.5 3.23438 7.5Z" fill="#FF7A45" />
</svg>

const RenderCriticalIcon = () => <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.84375 4.125H5.15625C4.94883 4.125 4.78125 4.29258 4.78125 4.5V10.875C4.78125 11.0824 4.94883 11.25 5.15625 11.25H6.84375C7.05117 11.25 7.21875 11.0824 7.21875 10.875V4.5C7.21875 4.29258 7.05117 4.125 6.84375 4.125ZM10.4531 0.75H8.76562C8.5582 0.75 8.39062 0.917578 8.39062 1.125V10.875C8.39062 11.0824 8.5582 11.25 8.76562 11.25H10.4531C10.6605 11.25 10.8281 11.0824 10.8281 10.875V1.125C10.8281 0.917578 10.6605 0.75 10.4531 0.75ZM3.23438 7.5H1.54688C1.33945 7.5 1.17188 7.66758 1.17188 7.875V10.875C1.17188 11.0824 1.33945 11.25 1.54688 11.25H3.23438C3.4418 11.25 3.60938 11.0824 3.60938 10.875V7.875C3.60938 7.66758 3.4418 7.5 3.23438 7.5Z" fill="#FF4D4F" />
</svg>
const Routes = (props) => {

  const { getFacilities, getProviders, getRoutesList, getRadiologistsAction, routeList = [], loading, facilityList, radiologistList, providerList, routeFilterValues, deleteRouteRequest } = props;
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [initialFilterValues, setInitialFilterValues] = useState({
    search: "",
    modality: [],
    urgency: [],
    days: [],
    body_part: [],
    source_facility: [],
    radiologist: [],
  });

  const [selectedRoute, setSelectedRoute] = useState(null);
  const [filterRouteList, setFilterRouteList] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);


  useEffect(() => {
    getRoutesList();
    getRadiologistsAction()
    getFacilities();
    getProviders();
  }, []);

  useEffect(() => {
    let newFilterRouteList = [...routeList];
    if (routeFilterValues && (routeFilterValues.search || routeFilterValues.days.length > 0 || routeFilterValues.urgency.length > 0 || routeFilterValues.body_part.length > 0 || routeFilterValues.source_facility.length > 0 || routeFilterValues.radiologist.length > 0 || routeFilterValues.modality.length > 0)) {
      setFilterLoading(true);
      if (routeFilterValues.search) {
        newFilterRouteList = newFilterRouteList.filter((route) =>
          route.name.toLowerCase().includes(routeFilterValues.search.toLowerCase())
        );
      }

      if (routeFilterValues.source_facility.length > 0) {
        newFilterRouteList = newFilterRouteList.filter((route) =>
          route.source_facility.some((facility) =>
            routeFilterValues.source_facility.includes(facility)
          )
        );
      }

      if (routeFilterValues.body_part.length > 0) {
        newFilterRouteList = newFilterRouteList.filter((route) =>
          route.body_parts.some((part) =>
            routeFilterValues.body_part.includes(part)
          )
        );
      }

      if (routeFilterValues.urgency.length > 0) {
        newFilterRouteList = newFilterRouteList.filter((route) =>
          route.urgency.some((u) => routeFilterValues.urgency.includes(u))
        );
      }

      if (routeFilterValues.modality.length > 0) {
        newFilterRouteList = newFilterRouteList.filter((route) =>
          routeFilterValues.modality.some((modality) =>
            route.modality.includes(modality)
          )
        );
      }

      if (routeFilterValues.days.length > 0) {
        newFilterRouteList = newFilterRouteList.filter((route) =>
          routeFilterValues.days.some((day) =>
            route.schedule.days.includes(day)
          )
        );
      }
      setFilterRouteList(newFilterRouteList);
      setTimeout(() => {
        setFilterLoading(false);
      }, 1000);
    } else {
      setFilterRouteList([...routeList]);
      setFilterLoading(false);
    }
  }, [routeFilterValues, routeList]);


  const renderPriority = (text, index) => {
    if (text) {
      const tagColor = text === 'urgent' ? 'gold' : text === 'stat' ? 'orange' : text === 'critical' ? "red" : '';
      const tagIcon = text === 'urgent' ? <RenderUrgentIcon /> : text === 'stat' ? <RenderStatIcon /> : text === 'critical' ? <RenderCriticalIcon /> : '';
      return <Tag style={{ fontWeight: 700, textTransform: 'capitalize', marginTop: 7 }} key={index} color={tagColor}>{tagIcon} {text === "stat" ? "STAT" : text}</Tag>
    } else {
      return "-"
    }
  }

  const onClose = () => {
    setVisible(false)
    setSelectedRoute(null)
  }


  const onEditRouteHandler = (route) => {
    history.replace(pages.editRoute.replace(":routeId", route.id))
  }

  const countIdleTime = (time) => {
    let convertToMin = 0
    if (time) {
      convertToMin = time / 60
    }
    return convertToMin
  }

  return <div className="routes-root">
    <Spin spinning={loading || filterLoading}>
      <CommonHeader title="Routes" description="Select routes for radiologists/providers based on facility, schedule, urgency, modality, and body parts." buttonText="Create Route" />
      <div className="routes-content">
        <Row type="flex" gutter={[16, 16]}>
          <Col span={4}>
            <RouteFilterForm initialValues={{ ...initialFilterValues }} facilityList={facilityList} />
          </Col>
          <Col span={20}>
            {filterRouteList.length > 0
              ?
              <div>
                <Row gutter={[16, 16]} type="flex">
                  {filterRouteList.map((route, index) => {
                    return <Col span={12} key={index}>
                      <Card className="routes-card" bordered>
                        <div className="title-container">
                          <Row type="flex" justify="space-between">
                            <Col><Text className="title">{route.name}</Text></Col>
                            <Col>
                              <Dropdown overlay={
                                <Menu>
                                  <Menu.Item onClick={() => onEditRouteHandler(route)}>
                                    <div>
                                      <Icon type="edit" style={{ marginRight: 10 }} />
                                      Edit Route
                                    </div>
                                  </Menu.Item>
                                  <Menu.Item>
                                    <Button
                                      className="delete-route-btn"
                                      type="danger"
                                      ghost={true}
                                      icon="delete"
                                      onClick={() => {
                                        setSelectedRoute(route.id)
                                        setVisible(true)
                                      }}
                                    >
                                      Delete Route
                                    </Button>
                                  </Menu.Item>
                                </Menu>}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                  More
                                </a>
                              </Dropdown>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="route-description">{route.description}</div>
                            </Col>
                          </Row>
                        </div>
                        <Divider className="divider" />
                        <div className="card-detail-container">
                          <Row gutter={[16, 16]}>
                            <Col span={12}>
                              <div className="title">Study With</div>
                              <div className="item">
                                <div className="label">Modality</div>
                                <div className="value">{route.modality.join(", ")}</div>
                              </div>
                              <div className="item">
                                <div className="label">Body Part</div>
                                <div className="value">{route.body_parts.join(", ")}</div>
                              </div>
                              <div className="item">
                                <div className="label">Urgency</div>
                                <div className="priority-tags">{route.urgency.map((item, i) => {
                                  return renderPriority(item.toLowerCase(), i)
                                })}</div>
                              </div>
                              <div className="item">
                                <div className="label">Facility</div>
                                <div className="value">
                                  <ul>{route.source_facility.map((item, i) => {
                                    let facility = {}
                                    facility = facilityList.find((o) => o.guid === item)
                                    return <li key={i}>{facility && facility.name}</li>
                                  })}
                                  </ul>
                                </div>
                              </div>
                            </Col>
                            <Col span={12}>
                              <div>
                                <div className="title"> Assigned To</div>
                                <div className="assign-list">
                                  {route.assignees.map((item, i) => {
                                    let assigneeObj = {}
                                    if (radiologistList.some((radiologist) => radiologist.id === item.guid)) {
                                      assigneeObj = radiologistList.find((radiologist) => radiologist.id === item.guid)
                                    } else {
                                      assigneeObj = providerList.find((provider) => provider.id === item.guid) || {}
                                    }
                                    return <div key={i}>
                                      <Row type="flex" justify="space-between" style={{ marginBottom: 7 }}>
                                        <Col>
                                          <div className="assign-detail">
                                            <div className="icon">
                                              <Icon type="enter" className="assign-icon" rotate={360} />
                                            </div>
                                            <div>
                                              <div className="user-name">{assigneeObj.name}</div>
                                              <div className="user-type">{item.type}</div>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col><div className="assign-date">{countIdleTime(item.idle_time)} mins</div></Col>
                                      </Row>
                                    </div>
                                  })}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Col>
                  })
                  }
                </Row>
              </div>
              :
              <div className="empty-container">
                <div className="empty-box">
                  <Empty description="" />
                  <p className="description">Optimize your workflow with routes for radiologists and providers, tailored by facility, schedule, urgency, modality, and body parts.</p>
                  <Button className="btn" type="primary" onClick={() => history.push(pages.createRoute)}>Create Route</Button>
                </div>
              </div>}
          </Col>
        </Row>
      </div>
      <Modal
        title="Delete Route?"
        visible={visible}
        onOk={() => {
          deleteRouteRequest({
            id: selectedRoute, callback: (state) => {
              onClose()
              getRoutesList()
            }
          })
        }}
        onCancel={onClose}
        okText="Delete"
        okType="danger"
        cancelText="Cancel"
      >
        <p>This action will permanently delete the selected route. Are you sure you want to continue?</p>
      </Modal>
    </Spin>
  </div>;
};

const mapStateToProps = (state) => {
  return {
    routeList: state.route.list,
    loading: state.loading.facilities || state.loading.getRouteList || state.loading.deleteRouting,
    facilityList: state.facilities.internalArray,
    radiologistList: state.internalRadiologists.array,
    providerList: state.providers.array,
    routeFilterValues: getFormValues("routeFilterForm")(state),
  }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({ getRoutesList: getRouteListRequestAction, getFacilities: getInternalFacilitiesAction, getRadiologistsAction: getInternalRadiologistsAction, getProviders, deleteRouteRequest: deleteRouteRequestAction }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
