import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import AddFacilityForm from './FacilityForm';
import validation from './validation';
import FacilityFormFooter from './FacilityFormFooter';

const ADD_EXTERNAL_FACILITY_FORM_NAME = 'addExternalFacilityForm';

type Props = {
  visible: boolean,
  loading: boolean,
  onCancel: () => void,
  onSubmit: Object => void, // eslint-disable-line react/no-unused-prop-types
  handleSubmit: Object => void,
  pristine: boolean,
  submitting: boolean,
  countryList: Array<{ text: string, value: string }>
};

export default connect(
  state => ({
    initialValues: state.initialForm.addExternalFacility
  }),
  {}
)(
  reduxForm({
    form: ADD_EXTERNAL_FACILITY_FORM_NAME,
    validate: validation
  })(
    ({
      visible,
      onCancel,
      loading,
      handleSubmit,
      pristine,
      submitting,
      countryList
    }: Props) => (
      <AddFacilityForm
        title='Add external facility'
        okText='Add'
        visible={visible}
        onCancel={onCancel}
        loading={loading}
        countryList={countryList}
        internalProps={{ internal: false }}
      >
        <FacilityFormFooter
          disabled={pristine || submitting}
          handleSubmit={handleSubmit}
          loading={loading}
          okText='Add facility'
          onCancel={onCancel}
        />
      </AddFacilityForm>
    )
  )
);
