// @flow
import React, { Component, Fragment } from 'react';
import { Menu, Icon, Dropdown } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import { confirmDeletion } from '../../../components/confirmations';
import EditInternalRadiologist from './EditInternalRadiologist';

const { Item } = Menu;

type Props = {
  record: {
    id: string,
    name: string
  },
  getAction: string => void,
  deleteAction: (string, string) => void,
  recordName: string
};

type State = {
  editElement: boolean
};

export default class TableMenu extends Component<Props, State> {
  state = {
    editElement: false
  };

  componentDidMount() {
    const { getAction, record } = this.props;
    getAction(record.id);
  }

  handleCancelEdit = () => this.setState({ editElement: false });

  handleMenuClick = (e: ClickParam) => {
    const { deleteAction, getAction, record, recordName } = this.props;
    switch (e.key) {
      case '1':
        getAction(record.id);
        this.setState({ editElement: true });
        break;
      case '2':
        confirmDeletion(deleteAction, record.id, record.name, recordName);
        break;
      case '3':
        e.item.setState({ visible: false });
        break;
      default:
        break;
    }
  };

  render() {
    const { record } = this.props;
    const { editElement } = this.state;
    return (
      <Fragment>
        <EditInternalRadiologist
          visible={editElement}
          onCancel={this.handleCancelEdit}
        />
        <Dropdown
          overlay={
            <Menu onClick={this.handleMenuClick}>
              <Item key='1' id={record.id}>
                Edit Internal Radiologist
              </Item>
              <Item key='2' id={record.id} name={record.name}>
                Delete Internal Radiologist
              </Item>
              <Item key='3' id={record.id}>
                Cancel
              </Item>
            </Menu>
          }
          trigger={['click']}
        >
          <Icon
            component='button'
            type='ellipsis'
            rotate='90'
            style={{ fontSize: '32px' }}
          />
        </Dropdown>
      </Fragment>
    );
  }
}
