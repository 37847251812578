import * as React from 'react';
import { connect } from 'react-redux';

import { transformCountries } from '../../../../utils';
import {
  uploadCustomLetterhead,
  addInternalFacilityAction,
  loadAddInternalFacilityForm,
  uploadFacilityLogo
} from '../../../../store/actions';
import AddInternalFacilityForm from '../../../facilities/components/Edition/Form/AddInternalFacilityForm';

export default connect(
  state => ({
    form: state.form.addInternalFacilityForm,
    loading: state.loading.addFacility,
    modifications: state.facilities.modifications,
    rawCountries: state.countryList
  }),
  {
    createAction: addInternalFacilityAction,
    loadForm: loadAddInternalFacilityForm,
    uploadLetterheadAction: uploadCustomLetterhead,
    uploadLogoAction: uploadFacilityLogo
  }
)(
  class AddExternalFacility extends React.PureComponent {
    state = {
      visible: false,
      modifications: this.props.modifications, // eslint-disable-line  react/destructuring-assignment
      close: false,
      countryList: []
    };

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.modifications > prevState.modifications) {
        const { onClose } = nextProps;
        if (onClose) onClose();
        return { modifications: nextProps.modifications, visible: false };
      }
      return null;
    }

    componentDidMount() {
      const { rawCountries, visible } = this.props;

      this.setState({
        countryList: transformCountries(rawCountries),
        visible
      });
    }

    showModal = () => this.setState({ visible: true });

    handleCancel = () => {
      const { onClose, uploadLetterheadAction, uploadLogoAction } = this.props;
      uploadLetterheadAction();
      uploadLogoAction();
      if (onClose) onClose();
      this.setState({ visible: false });
    };

    handleCreate = async values => this.props.createAction(values);

    render() {
      const { loading } = this.props;
      const { visible, countryList } = this.state;
      return (
        <div>
          {visible ? (
            <AddInternalFacilityForm
              visible={visible}
              loading={loading}
              onCancel={this.handleCancel}
              onSubmit={this.handleCreate}
              countryList={countryList}
            />
          ) : null}
        </div>
      );
    }
  }
);
