/* eslint-disable */
export const GET_REFERRING_PHYSICIANS_REQUEST =
  'GET_REFERRING_PHYSICIANS_REQUEST';
export const GET_REFERRING_PHYSICIANS_SUCCESS =
  'GET_REFERRING_PHYSICIANS_SUCCESS';
export const GET_REFERRING_PHYSICIANS_FAILED =
  'GET_REFERRING_PHYSICIANS_FAILED';

export const GET_REFERRING_PHYSICIANS_LIST_REQUEST =
  'GET_REFERRING_PHYSICIANS_LIST_REQUEST';
export const GET_REFERRING_PHYSICIANS_LIST_SUCCESS =
  'GET_REFERRING_PHYSICIANS_LIST_SUCCESS';
export const GET_REFERRING_PHYSICIANS_LIST_FAILED =
  'GET_REFERRING_PHYSICIANS_LIST_FAILED';

export const GET_REFERRING_PHYSICIAN_BY_ID_REQUEST =
  'GET_REFERRING_PHYSICIAN_BY_ID_REQUEST';
export const GET_REFERRING_PHYSICIAN_BY_ID_SUCCESS =
  'GET_REFERRING_PHYSICIAN_BY_ID_SUCCESS';
export const GET_REFERRING_PHYSICIAN_BY_ID_FAILED =
  'GET_REFERRING_PHYSICIAN_BY_ID_FAILED';

export const ADD_REFERRING_PHYSICIAN_REQUEST =
  'ADD_REFERRING_PHYSICIAN_REQUEST';
export const ADD_REFERRING_PHYSICIAN_SUCCESS =
  'ADD_REFERRING_PHYSICIAN_SUCCESS';
export const ADD_REFERRING_PHYSICIAN_FAILED = 'ADD_REFERRING_PHYSICIAN_FAILED';

export const EDIT_REFERRING_PHYSICIAN_REQUEST =
  'EDIT_REFERRING_PHYSICIAN_REQUEST';
export const EDIT_REFERRING_PHYSICIAN_SUCCESS =
  'EDIT_REFERRING_PHYSICIAN_SUCCESS';
export const EDIT_REFERRING_PHYSICIAN_FAILED =
  'EDIT_REFERRING_PHYSICIAN_FAILED';

export const DELETE_REFERRING_PHYSICIAN_REQUEST =
  'DELETE_REFERRING_PHYSICIAN_REQUEST';
export const DELETE_REFERRING_PHYSICIAN_SUCCESS =
  'DELETE_REFERRING_PHYSICIAN_SUCCESS';
export const DELETE_REFERRING_PHYSICIAN_FAILED =
  'DELETE_REFERRING_PHYSICIAN_FAILED';

export const GET_SOURCE_REFERRALS_BY_PHYSICIANS_REQUEST =
  'GET_SOURCE_REFERRALS_BY_PHYSICIANS_REQUEST';

export const GET_SOURCE_REFERRING_PHYSICIANS_CHART_REQUEST =
  'GET_SOURCE_REFERRING_PHYSICIANS_CHART_REQUEST';
export const GET_SOURCE_REFERRING_PHYSICIANS_CHART_SUCCESS =
  'GET_SOURCE_REFERRING_PHYSICIANS_CHART_SUCCESS';
export const GET_SOURCE_REFERRING_PHYSICIANS_CHART_FAILED =
  'GET_SOURCE_REFERRING_PHYSICIANS_CHART_FAILED';

export const FILTER_REFERRING_PHYSICIANS_CHART_REQUEST =
  'FILTER_REFERRING_PHYSICIANS_CHART_REQUEST';
  
export const FILTER_REFERRING_PHYSICIANS_REQUEST =
  'FILTER_REFERRING_PHYSICIANS_REQUEST';

export const getReferringPhysiciansListAction = () => ({
  type: GET_REFERRING_PHYSICIANS_LIST_REQUEST
});

export const getReferringPhysiciansAction = () => ({
  type: GET_REFERRING_PHYSICIANS_REQUEST
});

export const getReferringPhysicianByIdAction = physicianId => ({
  type: GET_REFERRING_PHYSICIAN_BY_ID_REQUEST,
  physicianId
});

export const addReferringPhysicianAction = physician => ({
  type: ADD_REFERRING_PHYSICIAN_REQUEST,
  physician
});

export const editReferringPhysicianAction = (physician, physicianId) => ({
  type: EDIT_REFERRING_PHYSICIAN_REQUEST,
  physician,
  physicianId
});

export const deleteReferringPhysicianAction = physicianId => ({
  type: DELETE_PHYSICIAN_REQUEST,
  physicianId
});

export const getSourceReferringPhysiciansChartAction = timeFrame => ({
  type: GET_SOURCE_REFERRING_PHYSICIANS_CHART_REQUEST,
  timeFrame
});

export const getSourceReferralsByPhysiciansAction = timeFrame => ({
  type: GET_SOURCE_REFERRALS_BY_PHYSICIANS_REQUEST,
  timeFrame
});

export const filterReferringPhysiciansChartAction = () => ({
  type: FILTER_REFERRING_PHYSICIANS_CHART_REQUEST
});

export const filterReferringPhysiciansAction = () => ({
  type: FILTER_REFERRING_PHYSICIANS_REQUEST
});