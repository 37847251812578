/* eslint-disable react/button-has-type */
// @flow

import * as React from 'react';
import * as Sentry from '@sentry/browser';

type Props = {
  children: React.Node
};

type State = {
  hasError: boolean,
  eventId: string
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, eventId: '' };
  }

  static getDerivedStateFromError(error: any) {
    return {
      hasError: true,
      error
    };
  }

  componentDidCatch(error: any, info: any) {
    Sentry.withScope(scope => {
      scope.setExtras(info);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
    this.setState({ hasError: true });
  }

  render() {
    const { children } = this.props;
    const { hasError, eventId } = this.state;

    if (hasError) {
      return (
        <>
          <h1>Something went wrong.</h1>
          <button onClick={() => Sentry.showReportDialog({ eventId })}>
            Report feedback
          </button>
        </>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
