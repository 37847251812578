/* eslint-disable react/no-children-prop */

import React from 'react';
import { Form, Select } from 'antd';

const { Option } = Select;
const FormItem = Form.Item;

const renderOptions = ({ value, text, label }, index) => {
  return <Option key={index} value={value}>
    {text}
  </Option>
}


const renderFontOptions = ({ value, text, label }, index) => (
  <Option key={index} value={value}>
    <span style={{ fontFamily: value }}>{text}</span>
  </Option>
);

const InputSelectField = (Options, open, doOpen, doClose) => ({
  input,
  meta,
  hasFeedback,
  label,
  isFontOptions = false,
  ...restSelectProps
}) => {
  const hasError = meta.touched && meta.invalid;
  const defaultValue = input.value || undefined
  
  return (
    <>
      <FormItem
        label={label}
        validateStatus={hasError ? 'error' : 'success'}
        hasFeedback={hasFeedback && hasError}
        help={hasError && meta.error}
        value={defaultValue}
      >
        <Select
          {...input}
          {...restSelectProps}
        // onFocus={doOpen}
        // onBlur={doClose}
        // open={open}
        >
          {Options.map(isFontOptions ? renderFontOptions : renderOptions)}
        </Select>
      </FormItem>
    </>
  );
};

export default InputSelectField;
