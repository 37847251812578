// @flow
import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { Spin, Row, Col, Switch, Table, Empty } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { formValueSelector } from 'redux-form';
import CardWrapper from '../../common/CardWrapper';
import StudyOverviewCardForm from './form/StudyOverviewCardForm';
import { ReduxState, StudyDetails } from '../../schemas';
// import { selectStudyDetails } from '../../store/selectors';
import { STUDY_OVERVIEW_CARD_FORM_NAME } from '../../utils/form-helpers';
import { t } from 'i18next';

type Props = {
  studyDetails: StudyDetails,
  loading: boolean,
  selectedDate: string
};

const dummyData = [
  {
    key: 1,
    modality: 'MR',
    device: 'GE 2T',
    studies: '01',
    timeInUse: '10:10:45'
  }
];

const chartOptions = {
  // tooltip: {
  //     trigger: 'axis',
  //     axisPointer: {
  //         type: 'cross',
  //         label: {
  //             backgroundColor: '#6a7985'
  //         }
  //     }
  // },
  legend: {
    data: ['MR', 'CT', 'CR', 'NM', 'XR']
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      boundaryGap: false,
      data: [
        moment().format('DD-MM-YYYY'),
        moment()
          .subtract('day', 1)
          .format('DD-MM-YYYY'),
        moment()
          .subtract('day', 2)
          .format('DD-MM-YYYY'),
        moment()
          .subtract('day', 3)
          .format('DD-MM-YYYY'),
        moment()
          .subtract('day', 4)
          .format('DD-MM-YYYY'),
        moment()
          .subtract('day', 5)
          .format('DD-MM-YYYY'),
        moment()
          .subtract('day', 6)
          .format('DD-MM-YYYY')
      ]
    }
  ],
  yAxis: [
    {
      type: 'value'
    }
  ],
  series: [
    {
      name: 'MR',
      type: 'line',
      areaStyle: {},
      emphasis: {
        focus: 'series'
      },
      data: [0, 10, 10, 20, 22, 25, 50]
    },
    {
      name: 'CT',
      type: 'line',
      areaStyle: {},
      emphasis: {
        focus: 'series'
      },
      data: [0, 5, 10, 30, 50, 100, 120]
    },
    {
      name: 'CR',
      type: 'line',
      areaStyle: {},
      emphasis: {
        focus: 'series'
      },
      data: [10, 15, 20, 40, 60, 70, 80]
    },
    {
      name: 'NM',
      type: 'line',
      areaStyle: {},
      emphasis: {
        focus: 'series'
      },
      data: [10, 5, 5, 15, 22, 25, 50]
    },
    {
      name: 'XR',
      type: 'line',
      label: {
        show: true,
        position: 'top'
      },
      areaStyle: {},
      emphasis: {
        focus: 'series'
      },
      data: [5, 10, 20, 20, 30, 40, 150]
    }
  ]
};
// const chartOptions = {
//   color: '#1890FF',
//   textStyle: {
//     fontFamily: 'Noto Sans, sans-serif',
//     color: 'rgba(0, 0, 0, 0.65)'
//   },
//   tooltip: {
//     show: true,
//     // trigger: 'axis',
//     backgroundColor: '#fff',
//     textStyle: {
//       color: 'rgba(0, 0, 0, 0.65)'
//     },
//     extraCssText: 'box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15)'
//   },
//   grid: {
//     height: 200,
//     //   left: '10%',
//     //   top: 60,
//     right: 0
//     //   bottom: 60,
//   },
//   xAxis: {
//     type: 'category',
//     name: 'Studies',
//     nameLocation: 'start',
//     // nameTextStyle: {
//     //   // color: 'rgba(0, 0, 0, 0.65)',
//     // },
//     nameGap: 24,
//     nameRotate: 90,
//     // boundaryGap: false,
//     data: [],
//     axisTick: {
//       alignWithLabel: true
//     }
//   },
//   yAxis: {
//     type: 'value',
//     name: 'Volume',
//     max: 30,
//     nameGap: 24,
//     nameTextStyle: {
//       color: 'rgba(0, 0, 0, 0.85)',
//       fontWeight: 500,
//       fontSize: 14
//     },
//     splitLine: {
//       lineStyle: {
//         type: 'dashed'
//       }
//     },
//     axisLine: {
//       show: false
//     },
//     axisTick: {
//       show: false
//     }
//   },
//   dataZoom: [
//     // {
//     //   show: true,
//     //   realtime: true,
//     // },
//     {
//       type: 'inside',
//       realtime: true
//     }
//   ]
// };

const styles = {
  totalTitle: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.65)',
    margin: 0,
    cursor: 'pointer'
  },
  headerCell: {
    backgroundColor: '#F7F7F7'
  }
};

function StudyOverviewCard({ studyDetails, loading, selectedDate }: Props) {
  const { studyCount } = studyDetails;

  const isToday = moment().isSame(moment(selectedDate), 'day');
  const studyCountCounts = studyCount.map(i => i.studyCount);
  const studyCountDates = studyCount.map(i =>
    moment(i.date).format(isToday ? 'HH:mm' : 'DD-MM-YYYY')
  );

  const columns = [
    {
      title: 'Modality',
      dataIndex: 'modality',
      key: 'modality',
      render: (text, record, index) => (
        <Row type='flex' align='middle' gutter={[12, 6]}>
          <Col>{text}</Col>
        </Row>
      ),
      onHeaderCell: () => ({ style: styles.headerCell })
    },
    {
      title: 'Device',
      dataIndex: 'device',
      key: 'device',
      onHeaderCell: () => ({ style: styles.headerCell })
    },
    {
      title: 'Studies',
      dataIndex: 'studies',
      key: 'studies',
      onHeaderCell: () => ({ style: styles.headerCell })
    },
    {
      title: 'Time In Use',
      dataIndex: 'timeInUse',
      key: 'timeInUse',
      onHeaderCell: () => ({ style: styles.headerCell })
    }
  ];

  const tableProps = {
    columns,
    loading,
    dataSource: dummyData,
    rowKey: ({ id }) => id,
    // size: 'small',
    pagination: false
  };

  chartOptions.xAxis.data = studyCountDates;
  chartOptions.yAxis.max = Math.max(...studyCountCounts);

  const reactEchartsOptions = {
    ...chartOptions
    // series: [
    //   {
    //     type: 'line',
    //     name: 'Studies',
    //     symbolSize: 6,
    //     data: studyCountCounts
    //   }
    // ]
  };

  return (
    <CardWrapper
      headStyle={{
        paddingTop: 10,
        paddingBottom: 10,
        borderBottom: '1px solid rgba(155, 155, 155, 0.50)'
      }}
      bodyStyle={{ padding: 16 }}
      title='Study Overview'
      extra={<StudyOverviewCardForm />}
    >
      <Spin spinning={loading}>
        <Row gutter={[20, 12]}>
          <Col sm={24} md={24} lg={12}>
            <Row type='flex' gutter={[8, 8]} align='middle'>
              <Col>Studies</Col>
              <Col>
                <Switch defaultChecked onChange={() => {}} size='small' />
              </Col>
              <Col>Time in Use</Col>
            </Row>
            {/* {studyCountCounts.length === 0 ? ( */}
            {false ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
              <ReactEcharts
                option={reactEchartsOptions}
                opts={{ renderer: 'svg' }}
              />
            )}
          </Col>
          <Col sm={24} md={24} lg={12}>
            {<Table {...tableProps} locale={{
                  emptyText: (
                    <Empty
                      description={t("table.no_data")}
                    />
                  ),
                }}/>}
            {/* {renderTotal()} */}
          </Col>
        </Row>
      </Spin>
    </CardWrapper>
  );
}

const selector = formValueSelector(STUDY_OVERVIEW_CARD_FORM_NAME);

const mapStateToProps = (state: ReduxState) => {
  return {
    studyDetails: state.dashboard.studyDetails,
    loading: state.loading.studyDetails,
    selectedDate: selector(state, 'dateFilter')
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StudyOverviewCard);
