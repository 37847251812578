import React, { useEffect, useState } from "react";
import { reduxForm, Field, getFormValues, change } from "redux-form";
import { Form, Checkbox } from "antd";
import { USER_CHECKBOX_OPTIONS } from "../../../../utils/filters";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
const FormItem = Form.Item;
const SELECT_USER_FORM = 'selectUserForm';
const SelectUserForm = (props) => {
    const { change } = props;
    const [selectedUser, setSelectedUser] = useState([]);

    useEffect(() => {
        setSelectedUser(props.showSchedule || [])
    }, [props.showSchedule]);

    const onChange = (value) => {
        let array = [...selectedUser]
        if (array && array.length > 0 && array.includes(value)) {
            let newArray = array.filter((v) => v !== value)
            change("showSchedule", newArray)
            setSelectedUser(newArray)
        } else {
            array.push(value)
            setSelectedUser(array)
            change("showSchedule", array)
        }
    }

    const CustomCheckboxGroup = ({ options = [], meta, hasFeedback, label }) => {
        const hasError = meta.touched && meta.invalid;
        return (
            <FormItem label={label}
                help={meta.touched && meta.error}
                validateStatus={hasError ? 'error' : 'success'}
                hasFeedback={hasFeedback && hasError}>
                <div> {options.map((o, i) => {
                    return (
                        <div key={i} style={{ cursor: "pointer" }} onClick={() => onChange(o.value)}>
                            <Checkbox checked={selectedUser.includes(o.value)} value={o.value}>{o.text}</Checkbox>
                        </div>
                    )
                })}</div>
            </FormItem>
        )
    }
    return (
        <Form>
            <Field name="showSchedule" component={CustomCheckboxGroup} options={USER_CHECKBOX_OPTIONS} style={{ display: "block" }} />
        </Form>
    )
}
const mapStateToProps = (state) => {
    const formValues = getFormValues(SELECT_USER_FORM)(state)
    return {
        showSchedule: formValues ? formValues.showSchedule : []
    }
}
const mapDispatchToProps = dispatch => bindActionCreators({ change }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: SELECT_USER_FORM, destroyOnUnmount: false, keepDirtyOnReinitialize: true })(SelectUserForm));
