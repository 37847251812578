import { createStore as reduxCreateStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducer from './reducers';
import initialState from './state';
import saga from './sagas';

const middlewares = [];

const sagaMiddleware = createSagaMiddleware();

middlewares.push(sagaMiddleware);
middlewares.push(logger);

let persistedReducer = reducer;

if (
  !(process.env.NODE_ENV === `development`) ||
  process.env.REACT_APP_PERSIST === '1'
) {
  const persistConfig = {
    key: 'root',
    storage
  };
  
  persistedReducer = persistReducer(persistConfig, reducer);
}

const createStore = () => {
  const store = reduxCreateStore(
    persistedReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares))
  );

  const persistor =
    process.env.NODE_ENV === `development` ||
    process.env.REACT_APP_PERSIST === '0'
      ? null
      : persistStore(store);

  sagaMiddleware.run(saga);

  return { store, persistor };
};

export default createStore;
