import * as React from 'react';
import { Row, Col, Checkbox, Button, message } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  alemHealthDescription,
  apolloHospitalsDescription,
  maxHealthCareDescription
} from '../../utils/descriptions';
import { requestServiceProvider } from '../../store/actions';

import './RequestService.less';

const CheckboxGroup = Checkbox.Group;

type Props = {
  emailsSent: number,
  loading: boolean,
  sendRequestAction: (Array<any>, Array<any>) => void
};

type State = {
  checkedModalities: Array<any>,
  checkedProviders: Array<any>,
  emailsSent: number,
  showSuccess: boolean
};

export default connect(
  state => ({
    emailsSent: state.radiologists.emailsSent,
    loading: state.loading.requestProvider
  }),
  {
    sendRequestAction: requestServiceProvider
  }
)(
  class RequestService extends React.Component<Props, State> {
    state = {
      checkedModalities: [],
      checkedProviders: [],
      emailsSent: this.props.emailsSent,
      showSuccess: false
    };

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.emailsSent > prevState.emailsSent) {
        return {
          ...prevState,
          emailsSent: nextProps.emailsSent,
          showSuccess: true
        };
      }
      return null;
    }

    onChangeProviders = providers =>
      this.setState({ checkedProviders: providers });

    onChangeModalities = modalities =>
      this.setState({ checkedModalities: modalities });

    backToMainScreen = () => this.setState({ showSuccess: false });

    handleRequests = () => {
      const { checkedModalities, checkedProviders } = this.state;
      const { sendRequestAction } = this.props;
      // eslint-disable-next-line no-unused-expressions
      checkedModalities.length > 0 && checkedProviders.length > 0
        ? sendRequestAction(checkedProviders, checkedModalities)
        : message.warn('Please select...');
    };

    generateCheckBoxes = providers =>
      providers.map(({ name, className, description }) => (
        <>
          <Row gutter={16} type='flex' align='middle'>
            <Col offset={7}>
              <Checkbox value={name} />
            </Col>
            <Col>
              <div className={`${className}-circle`} />
            </Col>
            <Col>
              <h2 className='provider-name'>{name}</h2>
            </Col>
          </Row>
          <Row>
            <Col span={10} offset={8}>
              <p className='provider-description'>{description}</p>
            </Col>
          </Row>
        </>
      ));

    generateModalities = modalities => {
      const chunks = [];
      for (let i = 0, j = modalities.length; i < j; i += 5) {
        chunks.push(modalities.slice(i, i + 5));
      }
      return chunks.map(chunk => (
        <Col span={22} offset={6}>
          <Row className='modality-row' gutter={18} type='flex'>
            {chunk.map(val => (
              <Col span={2}>
                {' '}
                <Checkbox value={val}>{val}</Checkbox>{' '}
              </Col>
            ))}
          </Row>
        </Col>
      ));
    };

    render() {
      const {
        generateCheckBoxes,
        generateModalities,
        onChangeProviders,
        onChangeModalities,
        handleRequests,
        backToMainScreen
      } = this;
      const { loading } = this.props;
      const { showSuccess } = this.state;
      return showSuccess ? (
        <div className='success-message' key='0'>
          <h3>Request Received</h3>
          <h4>We will get in touch with you withing 24 hours.</h4>
          <Link to='/network/manage_radiologists'>
            <Button type='primary' ghost onClick={backToMainScreen}>
              Back to Manage Radiologists
            </Button>
          </Link>
        </div>
      ) : (
        <div className='request-service' key='1'>
          <p>
            Request a quote from our preferred service providers. Please select
            the providers you&apos;re interested in:
          </p>
          <div className='body'>
            <div className='providers-check-box-group'>
              <CheckboxGroup onChange={onChangeProviders}>
                {generateCheckBoxes([
                  {
                    name: 'AlemHealth US',
                    className: 'alem-health',
                    description: alemHealthDescription
                  },
                  {
                    name: 'Max Healthcare',
                    className: 'max-healthcare',
                    description: maxHealthCareDescription
                  },
                  {
                    name: 'Apollo Hospitals',
                    className: 'apollo-hospitals',
                    description: apolloHospitalsDescription
                  }
                ])}
              </CheckboxGroup>
            </div>
            <div className='modalities-check-box-group'>
              <Row>
                <Col span={10} offset={6}>
                  <p>* Please indicate the modalities required:</p>
                </Col>
              </Row>
              <CheckboxGroup
                className='modality-group'
                onChange={onChangeModalities}
              >
                {generateModalities([
                  'CT',
                  'NM',
                  'MR',
                  'DS',
                  'DR',
                  'US',
                  'OT',
                  'HGS',
                  'CR',
                  'OP'
                ])}
              </CheckboxGroup>
            </div>
            <div className='request-quotation'>
              <Col offset={9}>
                <Button
                  type='primary'
                  onClick={handleRequests}
                  loading={loading}
                >
                  Request Quotation
                </Button>
              </Col>
            </div>
          </div>
        </div>
      );
    }
  }
);
