export const GET_ROUTE_LIST_REQUEST = 'GET_ROUTE_LIST_REQUEST';
export const GET_ROUTE_LIST_SUCCESS = 'GET_ROUTE_LIST_SUCCESS';
export const GET_ROUTE_LIST_FAILED = 'GET_ROUTE_LIST_FAILED';

export const getRouteListRequestAction = (payload) => ({ type: GET_ROUTE_LIST_REQUEST, payload });
export const getRouteListSuccessAction = (data) => ({ type: GET_ROUTE_LIST_SUCCESS, data });
export const getRouteListFailedAction = () => ({ type: GET_ROUTE_LIST_FAILED });



export const POST_CREATE_ROUTE_REQUEST = 'POST_CREATE_ROUTE_REQUEST';
export const POST_CREATE_ROUTE_SUCCESS = 'POST_CREATE_ROUTE_SUCCESS';
export const POST_CREATE_ROUTE_FAILED = 'POST_CREATE_ROUTE_FAILED';

export const postCreateRouteRequestAction = (payload) => ({ type: POST_CREATE_ROUTE_REQUEST, payload });
export const postCreateRouteSuccessAction = (data) => ({ type: POST_CREATE_ROUTE_SUCCESS, data });
export const postCreateRouteFailedAction = () => ({ type: POST_CREATE_ROUTE_FAILED });



export const GET_SINGLE_ROUTE_REQUEST = 'GET_SINGLE_ROUTE_REQUEST';
export const GET_SINGLE_ROUTE_SUCCESS = 'GET_SINGLE_ROUTE_SUCCESS';
export const GET_SINGLE_ROUTE_FAILED = 'GET_SINGLE_ROUTE_FAILED';

export const getSingleRouteRequestAction = (payload) => ({ type: GET_SINGLE_ROUTE_REQUEST, payload });
export const getSingleRouteSuccessAction = (data) => ({ type: GET_SINGLE_ROUTE_SUCCESS, data });
export const getSingleRouteFailedAction = () => ({ type: GET_SINGLE_ROUTE_FAILED });



export const EDIT_ROUTE_REQUEST = 'EDIT_ROUTE_REQUEST';
export const EDIT_ROUTE_SUCCESS = 'EDIT_ROUTE_SUCCESS';
export const EDIT_ROUTE_FAILED = 'EDIT_ROUTE_FAILED';

export const editRouteRequestAction = (payload) => ({ type: EDIT_ROUTE_REQUEST, payload });
export const editRouteSuccessAction = (data) => ({ type: EDIT_ROUTE_SUCCESS, data });
export const editRouteFailedAction = () => ({ type: EDIT_ROUTE_FAILED });


export const DELETE_ROUTE_REQUEST = 'DELETE_ROUTE_REQUEST';
export const DELETE_ROUTE_SUCCESS = 'DELETE_ROUTE_SUCCESS';
export const DELETE_ROUTE_FAILED = 'DELETE_ROUTE_FAILED';

export const deleteRouteRequestAction = (payload) => ({ type: DELETE_ROUTE_REQUEST, payload });
export const deleteRouteSuccessAction = (data) => ({ type: DELETE_ROUTE_SUCCESS, data });
export const deleteRouteFailedAction = () => ({ type: DELETE_ROUTE_FAILED })


export const GET_SCHEDULE_LIST_REQUEST = 'GET_SCHEDULE_LIST_REQUEST';
export const GET_SCHEDULE_LIST_SUCCESS = 'GET_SCHEDULE_LIST_SUCCESS';
export const GET_SCHEDULE_LIST_FAILED = 'GET_SCHEDULE_LIST_FAILED';

export const getScheduleListRequestAction = (payload) => ({ type: GET_SCHEDULE_LIST_REQUEST, payload });
export const getScheduleListSuccessAction = (data) => ({ type: GET_SCHEDULE_LIST_SUCCESS, data });
export const getScheduleListFailedAction = () => ({ type: GET_SCHEDULE_LIST_FAILED });



export const POST_CREATE_SCHEDULE_REQUEST = 'POST_CREATE_SCHEDULE_REQUEST';
export const POST_CREATE_SCHEDULE_SUCCESS = 'POST_CREATE_SCHEDULE_SUCCESS';
export const POST_CREATE_SCHEDULE_FAILED = 'POST_CREATE_SCHEDULE_FAILED';

export const postCreateScheduleRequestAction = (payload) => ({ type: POST_CREATE_SCHEDULE_REQUEST, payload });
export const postCreateScheduleSuccessAction = (data) => ({ type: POST_CREATE_SCHEDULE_SUCCESS, data });
export const postCreateScheduleFailedAction = () => ({ type: POST_CREATE_SCHEDULE_FAILED });



export const GET_SINGLE_SCHEDULE_REQUEST = 'GET_SINGLE_SCHEDULE_REQUEST';
export const GET_SINGLE_SCHEDULE_SUCCESS = 'GET_SINGLE_SCHEDULE_SUCCESS';
export const GET_SINGLE_SCHEDULE_FAILED = 'GET_SINGLE_SCHEDULE_FAILED';

export const getSingleScheduleRequestAction = (payload) => ({ type: GET_SINGLE_SCHEDULE_REQUEST, payload });
export const getSingleScheduleSuccessAction = (data) => ({ type: GET_SINGLE_SCHEDULE_SUCCESS, data });
export const getSingleScheduleFailedAction = () => ({ type: GET_SINGLE_SCHEDULE_FAILED });



export const EDIT_SCHEDULE_REQUEST = 'EDIT_SCHEDULE_REQUEST';
export const EDIT_SCHEDULE_SUCCESS = 'EDIT_SCHEDULE_SUCCESS';
export const EDIT_SCHEDULE_FAILED = 'EDIT_SCHEDULE_FAILED';

export const editScheduleRequestAction = (payload) => ({ type: EDIT_SCHEDULE_REQUEST, payload });
export const editScheduleSuccessAction = (data) => ({ type: EDIT_SCHEDULE_SUCCESS, data });
export const editScheduleFailedAction = () => ({ type: EDIT_SCHEDULE_FAILED });


export const DELETE_SCHEDULE_REQUEST = 'DELETE_SCHEDULE_REQUEST';
export const DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS';
export const DELETE_SCHEDULE_FAILED = 'DELETE_SCHEDULE_FAILED';

export const deleteScheduleRequestAction = (payload) => ({ type: DELETE_SCHEDULE_REQUEST, payload });
export const deleteScheduleSuccessAction = (data) => ({ type: DELETE_SCHEDULE_SUCCESS, data });
export const deleteScheduleFailedAction = () => ({ type: DELETE_SCHEDULE_FAILED })

export const GET_BODY_PART_OPTIONS_REQUEST = 'GET_BODY_PART_OPTIONS_REQUEST';
export const GET_BODY_PART_OPTIONS_SUCCESS = 'GET_BODY_PART_OPTIONS_SUCCESS';
export const GET_BODY_PART_OPTIONS_FAILED = 'GET_BODY_PART_OPTIONS_FAILED';

export const getBodyPartOptionsRequestAction = () => ({ type: GET_BODY_PART_OPTIONS_REQUEST });
export const getBodyPartOptionsSuccessAction = (data) => ({ type: GET_BODY_PART_OPTIONS_SUCCESS, data });
export const getBodyPartOptionsFailedAction = () => ({ type: GET_BODY_PART_OPTIONS_FAILED });

export const GET_MODALITY_OPTIONS_REQUEST = 'GET_MODALITY_OPTIONS_REQUEST';
export const GET_MODALITY_OPTIONS_SUCCESS = 'GET_MODALITY_OPTIONS_SUCCESS';
export const GET_MODALITY_OPTIONS_FAILED = 'GET_MODALITY_OPTIONS_FAILED';

export const getModalityOptionsRequestAction = () => ({ type: GET_MODALITY_OPTIONS_REQUEST });
export const getModalityOptionsSuccessAction = (data) => ({ type: GET_MODALITY_OPTIONS_SUCCESS, data });
export const getModalityOptionsFailedAction = () => ({ type: GET_MODALITY_OPTIONS_FAILED });