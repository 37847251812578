import React from 'react';
import { Button, Col, Row } from 'antd';
import { t } from 'i18next';

export default ({
  pristine,
  submitting,
  loading,
  handleSave,
  handleCancel
}) => (
  <div style={{ margin: '30px 0 50px 0' , paddingBottom: '60px'}}>
    <Row type='flex' justify='start'>
      <Col span={3}>
        <Button onClick={handleCancel}>{t("button.cancel")}</Button>
      </Col>
      <Col span={3}>
        <Button
          disabled={pristine || submitting}
          type='primary'
          onClick={handleSave}
          loading={loading}
        >
          {t("button.save")}
        </Button>
      </Col>
    </Row>
  </div>
);
