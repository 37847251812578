// @flow

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal, Spin } from 'antd';

import {
  editInternalRadiologistAction,
  deleteInternalRadiologistAction,
  loadEditInternalRadiologist,
  getInternalFacilitiesAction
} from '../../../store/actions';
import { confirmDeletion } from '../../../components/confirmations';

import type { Facility, ReduxState, User } from '../../../schemas';
import EditUserForm from './forms/EditUserForm';

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  form: Object,
  radiologist: User,
  load: boolean,
  loading: boolean,
  editAction: (User, string) => void,
  deleteAction: string => void,
  loadForm: User => void,
  visible: boolean,
  onCancel: () => void,
  loadingFacilities: boolean,
  loadFacilities: () => void,
  profileId: string,
  facilities: Array<Facility>,
  initialValues: Object
};

type State = {
  radiologist: User
};

export default connect(
  (state: ReduxState) => ({
    form: state.form.editInternalRadForm,
    radiologist: state.internalRadiologists.current,
    load: state.loading.internalRad,
    loading: state.loading.editInternalRad,
    loadingFacilities: state.loading.facilities,
    profileId: state.profile.id,
    facilities: state.facilities.internalArray,
    initialValues: state.initialForm.editInternalRad
  }),
  {
    editAction: editInternalRadiologistAction,
    deleteAction: deleteInternalRadiologistAction,
    loadForm: loadEditInternalRadiologist,
    loadFacilities: getInternalFacilitiesAction
  }
)(
  class EditInternalRadiologist extends Component<Props, State> {
    state = {
      radiologist: {}
    };

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.radiologist.id !== prevState.radiologist.id) {
        return { radiologist: nextProps.radiologist };
      }
      return null;
    }

    componentDidMount() {
      const { loadFacilities } = this.props;
      loadFacilities();
      if (Object.entries(this.state.radiologist).length !== 0) {
        this.updateRadiologist();
      }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
      // eslint-disable-next-line react/destructuring-assignment
      if (prevState.radiologist.id !== this.state.radiologist.id) {
        this.updateRadiologist();
      }
      if (prevProps.loading && !this.props.loading) {
        this.props.onCancel();
      }
    }

    // eslint-disable-next-line react/destructuring-assignment
    updateRadiologist = () => {
      const { radiologist: user } = this.state;
      const { loadForm } = this.props;
      const form = {
        name: user.name,
        userType: user.userMetadata.userType,
        email: user.email,
        phoneNumber: user.userMetadata.phoneNumber,
        homeFacility: user.userMetadata.homeFacility,
        specialty: user.userMetadata.specialty,
        admin: user.appMetadata.admin,
        title: user.userMetadata.title,
        credentials: user.userMetadata.credentials,
        allowed_to_delete_study:
          user.appMetadata.allowed_to_delete_study || '1',
        availableForAssignment: user.appMetadata.availableForAssignment || '1'
      };
      loadForm(form);
    };

    // eslint-disable-next-line react/destructuring-assignment
    handleSubmit = async values => {
      const { radiologist, editAction, facilities } = this.props;
      editAction(
        {
          email: values.email,
          name: values.name,
          appMetadata: {
            ...radiologist.appMetadata,
            admin: values.admin,
            allowed_to_delete_study: values.allowed_to_delete_study,
            approvedFacilities: facilities
              .filter((f: Facility) => values.groupFacilities.includes(f.name))
              .map((f: Facility) => f.guid)
          },
          userMetadata: {
            ...radiologist.userMetadata,
            phoneNumber: values.phoneNumber,
            homeFacility: values.homeFacility,
            specialty:
              typeof values.specialty === 'string'
                ? [values.specialty]
                : values.specialty.filter(s => s)
          }
        },
        radiologist.id
      );
    };

    handleDelete = () => {
      const { radiologist, deleteAction } = this.props;
      confirmDeletion(
        deleteAction,
        radiologist.id,
        radiologist.name,
        'internal radiologist'
      );
    };

    render() {
      const {
        visible,
        onCancel,
        loading,
        radiologist,
        load,
        loadingFacilities,
        profileId,
        initialValues,
        facilities
      } = this.props;

      if (
        Object.entries(radiologist).length === 0 ||
        load ||
        loadingFacilities
      ) {
        return (
          <Fragment>
            <Modal
              visible={visible}
              title='Edit radiologist'
              okText='Edit'
              footer={null}
              onCancel={onCancel}
            >
              <div style={{ textAlign: 'center' }}>
                <Spin size='large' />
              </div>
            </Modal>
          </Fragment>
        );
      }

      let groupFacilities = [];
      if (
        radiologist.appMetadata &&
        radiologist.appMetadata.approvedFacilities
      ) {
        groupFacilities = facilities
          .filter((f: Facility) =>
            radiologist.appMetadata.approvedFacilities.includes(f.guid)
          )
          .map((f: Facility) => f.name);
      }

      return (
        <Fragment>
          <EditUserForm
            visible={visible}
            onCancel={onCancel}
            onSubmit={this.handleSubmit}
            onDelete={this.handleDelete}
            // onChangeUserType={this.onChangeUserType}
            loading={loading}
            isActiveUser={radiologist.id === profileId}
            onChangeUserType={() => {}}
            user={radiologist}
            initialValues={{ ...initialValues, groupFacilities }}
          />
        </Fragment>
      );
    }
  }
);
