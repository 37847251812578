/* eslint-disable no-shadow */
import * as React from 'react';
import { Avatar, Badge, Col, Divider, Form, Icon, Modal, Row } from 'antd';
import { Field } from 'redux-form';

import { t } from 'i18next';
import ActionLink from '../../../../../components/ActionLink';
import {
  InputPhoneNumberField,
  InputRadioButtonField,
  InputTextField
} from '../../../../../components/FormParts';
import InputSelectField from '../../../../../common/form-helpers/InputSelectField';

import { CUSTOM_LETTERHEAD, FACILITY_TYPE } from '../../../../../utils/filters';
import Uploader from '../../../../../components/Uploader';
import { Facility } from '../../../../../schemas';
import Labeled from '../../../../../components/FormParts/Labeled';

import './FacilityForm.less';
import PhoneNumberBoundary from '../../../../../components/ErrorBoundary/PhoneNumberBoundary';

export type InternalProps = {
  internal: boolean,
  facility: Facility,
  setLogoChanged: boolean => void,
  addLogoAction: string => void,
  removeLogoAction: () => void,
  logo: string,
  hasPreviousLogo: boolean,
  hasLetterhead: boolean,
  addLetterheadAction: string => void
};

type Props = {
  title: string,
  okText: string,
  visible: boolean,
  onCancel: () => void,
  children: React.ReactNode,
  countryList: Array<{ text: string, value: string }>,
  internalProps: InternalProps
};

export default ({
  title,
  okText,
  visible,
  onCancel,
  children,
  countryList,
  internalProps
}: Props) => {
  const [extraNumbers, changeExtraNumbers] = React.useState(0);
  const [country, changeDefaultCountry] = React.useState('sg');
  const renderInternal = () => {
    if (!internalProps || !internalProps.internal) {
      return null;
    }
    const {
      facility,
      setLogoChanged,
      setLetterHeadChanged,
      addLogoAction,
      removeLogoAction,
      logo,
      hasPreviousLogo,
      hasLetterhead,
      addLetterheadAction
    }: InternalProps = internalProps;
    const facilityId = facility && facility.guid ? facility.guid : 'none';
    const [updatedLogo, setUpdatedLogo] = React.useState(logo);
    const [uploadedLetterhead, setUploadedLetterhead] = React.useState(false);
    React.useEffect(() => {
      if (logo !== updatedLogo) {
        setUpdatedLogo(logo);
      }
      if (hasLetterhead) {
        setUploadedLetterhead(true);
      }
    }, [logo, updatedLogo, hasLetterhead]);

    const handleUpload = (logo: string) => {
      addLogoAction(logo);
      setLogoChanged(true);
    };

    return (
      <div className='internal-facility-data'>
        <Row gutter={32}>
          <Col className='gutter-row' span={12}>
            <Labeled label={t('my_facilities.table.facility_logo')}>
              <div className='uploader'>
                <Uploader
                  containerName='facilitylogos'
                  containerPath='logo'
                  blobName={facilityId}
                  handleSuccess={handleUpload}
                />
              </div>
            </Labeled>
          </Col>
          <Col className='gutter-row' span={12}>
            <div className='detail-image-show'>
              {hasPreviousLogo ? (
                <Badge
                  onClick={() => removeLogoAction()}
                  className='logo-badge'
                  count={
                    <Icon
                      type='close-circle'
                      theme='filled'
                      style={{ fontSize: '24px' }}
                    />
                  }
                >
                  <Avatar shape='square' size='large' src={updatedLogo} />
                </Badge>
              ) : (
                <Avatar shape='square' size='large' src={updatedLogo} />
              )}
            </div>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col className='gutter-row' span={12}>
            <Field
              name='customLetterhead'
              label={t('my_facilities.table.custom_letterhead')}
              component={InputRadioButtonField(CUSTOM_LETTERHEAD)}
            />
          </Col>
          <Col className='gutter-row' span={8}>
            <Labeled>
              <Uploader
                containerName='facilitylogos'
                containerPath='custom-letterheads'
                blobName={facilityId}
                handleSuccess={value => {
                  addLetterheadAction(value);
                  setLetterHeadChanged(true);
                }}
              />
            </Labeled>
          </Col>
          <Col span={4}>
            <Labeled>
              {uploadedLetterhead && (
                <Icon
                  type='check-circle'
                  theme='twoTone'
                  twoToneColor='#52c41a'
                  style={{ fontSize: '24px', paddingTop: '4px' }}
                />
              )}
            </Labeled>
          </Col>
        </Row>
      </div>
    );
  };
  return (
    <Modal
      visible={visible}
      title={title}
      okText={okText}
      onCancel={onCancel}
      destroyOnClose={false}
      footer={children}
      wrapClassName='lateral-modal'
    >
      <Form layout='vertical'>
        <Row gutter={32}>
          <Col className='gutter-row' span={12}>
            <Field
              name='name'
              label={t('my_facilities.facility_name')}
              placeholder='Name'
              component={InputTextField}
            />
          </Col>
          <Col className='gutter-row' span={12}>
            <Field
              name='facilityType'
              label={t('my_facilities.facility_type')}
              placeholder='Select the type of the facility...'
              options={FACILITY_TYPE}
              component={InputSelectField}
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col className='gutter-row' span={12}>
            <Field
              name='country'
              label={t('my_facilities.country')}
              placeholder='Your Country name'
              onChange={(v: string) => changeDefaultCountry(v.toLowerCase())}
              showSearch
              options={countryList}
              component={InputSelectField}
            />
          </Col>
          <Col className='gutter-row' span={12}>
            <PhoneNumberBoundary setCountry={changeDefaultCountry}>
              <Field
                name='phoneNumber'
                label={t('my_facilities.office_number')}
                component={InputPhoneNumberField}
                defaultCountry={country}
                disableAreaCodes
              />
            </PhoneNumberBoundary>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col className='gutter-row' span={24}>
            <Field
              name='location'
              label={t('my_facilities.address')}
              component={InputTextField}
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col className='gutter-row' span={12}>
            <Field
              name='email'
              label={t('my_facilities.email')}
              type='email'
              placeholder='mail@domain.com'
              component={InputTextField}
            />
          </Col>
          <Col className='gutter-row' span={12}>
            <Field
              name='website'
              label={t('my_facilities.website')}
              type='url'
              placeholder='https://www.domain.com'
              component={InputTextField}
            />
          </Col>
        </Row>
        {renderInternal()}
        <Divider>{t('my_facilities.primary_contact_details')}</Divider>
        <Row gutter={32}>
          <Col className='gutter-row' span={12}>
            <Field
              name='primaryName'
              label={t('my_facilities.table.name')}
              placeholder='Name'
              component={InputTextField}
            />
          </Col>
          <Col className='gutter-row' span={12}>
            <Field
              name='primaryEmail'
              label={t('my_facilities.email')}
              type='email'
              placeholder='mail@domain.com'
              component={InputTextField}
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col className='gutter-row' span={24}>
            <Field
              name='contactNumber1'
              label={t('my_facilities.table.contact_number_1')}
              component={InputPhoneNumberField}
              defaultCountry='sg'
              disableAreaCodes
            />
          </Col>
        </Row>
        {extraNumbers > 0 ? (
          <Row gutter={32}>
            <Col className='gutter-row' span={22}>
              <Field
                name='contactNumber2'
                component={InputPhoneNumberField}
                label={t('my_facilities.table.contact_number_2')}
                defaultCountry='sg'
                disableAreaCodes
              />
            </Col>
            <Col className='gutter-row' span={2}>
              <p>&nbsp;</p>
              <ActionLink
                click={() => changeExtraNumbers(extraNumbers - 1)}
                text='x'
              />
            </Col>
          </Row>
        ) : null}
        {extraNumbers > 1 ? (
          <Row gutter={32}>
            <Col className='gutter-row' span={22}>
              <Field
                name='contactPhoneNumber3'
                component={InputPhoneNumberField}
                label={t('my_facilities.table.contact_number_3')}
                defaultCountry='sg'
                disableAreaCodes
              />
            </Col>
            <Col className='gutter-row' span={2}>
              <p>&nbsp;</p>
              <ActionLink
                click={() => changeExtraNumbers(extraNumbers - 1)}
                text='x'
              />
            </Col>
          </Row>
        ) : null}
        {extraNumbers < 2 ? (
          <Row gutter={32}>
            <Col className='gutter-row' span={24}>
              <ActionLink
                click={() => changeExtraNumbers(extraNumbers + 1)}
                text='Add another number'
              />
            </Col>
          </Row>
        ) : null}
      </Form>
    </Modal>
  );
};
