import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Checkbox, Col, Modal, Radio, Row, Select, Table, Tooltip, Typography } from 'antd'
import { Column } from '@ant-design/plots';
import { connect } from 'react-redux'
import ModalityBodyPartInsightForm from './form/ModalityBodyPartInsightForm'
import { CSVLink } from 'react-csv'
import { t } from 'i18next'
import { getFormValues, reset } from 'redux-form'
import { DASHBOARD_MODALITY_BODYPART_INSIGHT_FORM, DASHBOARD_QC_DOWNLOAD_FORM } from '../../utils/form-helpers'
import QCDownloadForm from './form/QCDownloadForm'
import { selectFacilityOptions, selectModalityOptions, selectbodyPartOptions, selectRadiologistOptions, selectUserOptions, selectQCBodyPartList } from '../../store/selectors'
import { bindActionCreators } from 'redux'
import { downloadQCModalityBodyPartInsight, setDownloadQCModalityBodyPartInsight } from '../../store/actions/QCDashboardAction'
import { checkIsOldChart, getScrollableChartRatio } from '../../utils';

const { Text, Title } = Typography

const QCModalityAndBodyPartInsight = ({ 
  modalityBodyPartInsight, 
  formData, 
  modalityOptions, 
  radiologistOptions, 
  facilityOptions, 
  downloadQCModalityBodyPartInsight,
  setDownloadQCModalityBodyPartInsight,
  downloadableData,
  loadingChart,
  bodyPartOptions,
}) => {
  const [showChart, setShowChart] = useState(true)
  const [showTable, setShowTable] = useState(true)
  const [showQCScoreOnHover, setShowQCScoreOnHover] = useState(true)
  const [showRadiologistOnHover, setShowRadiologistOnHover] = useState(true)
  const [downloadModalVisible, setDownloadModalVisible] = useState(false)

  const csvLinkRef = useRef()
  const chartRef = useRef()

  const transformData = (data = []) => {
    return data?.flatMap(item => [
      {
        name: 'Flagged Studies',
        xField: item.body_part ?? item.modality,
        value: item.flagged_studies
      },
      {
        name: 'Total Studies',
        xField: item.body_part ?? item.modality,
        value: item.total_studies
      },
    ]);
  };

  const isOldChart = checkIsOldChart()

  const transformedData = transformData(modalityBodyPartInsight?.data)
  const chartRatio = getScrollableChartRatio(transformedData.length)
  const config = isOldChart ? {
    data: transformedData,
    isGroup: true,
    loading: loadingChart,
    xField: 'xField',
    yField: 'value',
    seriesField: 'name',
    color: ['#FF7A45', '#C2EEFF'],
    label: {
      position: 'top',
      style: {
        fill: '#8C8C8C',
        opacity: 1,
      },
    },
    xAxis: {
      label: {
        autoRotate: false,
        autoHide: false,
        fontSize: 12,
      }
    },
    yAxis: {
      grid: {
        line: {
          style: {
            lineDash: [0, 0],
            stroke: '#000000',
            strokeOpacity: 0.15
          }
        }
      }
    },
    legend: {
      position: 'bottom-left',
    },
    scrollbar: chartRatio < 1 && {
      type: 'horizontal',
      categorySize: 90,
      width: 900
    },
    width: undefined,
    height: 500,
  } : {
    ref: chartRef,
    data: transformedData,
    xField: 'xField',
    yField: 'value',
    seriesField: 'name',
    colorField: 'name',
    group: true,
    loading: loadingChart,
    axis: {
      x: {
        labelAutoRotate: false,
        labelAutoHide: false,
        labelAutoEllipsis: true,
        labelAutoWrap: true,
        labelFontSize: 12,
        labelAlign: 'horizontal',
      },
      y: {
        gridLineDash: [0, 0],
        gridStroke: '#000000',
        gridStrokeOpacity: 0.15
      }
    },
    scale: {
      color: {
        range: ['#FF7A45','#C2EEFF']
      },
    },
    label: {
      textBaseline: 'bottom',
    },
    legend: {
      color: {
        position: 'bottom',
        colPadding: 16,
        itemWidth: 130,
        itemPadding: 200,
        width: 1000,
      },
    },
    scrollbar: chartRatio < 1 && {
      x: {
        ratio: chartRatio,
        style: {
          trackSize: 8,
          paddingBottom: 20,
          marginBottom: 20,
        },
      },
      y: false
    },
    width: undefined,
    height: 500,
    marginBottom: 48,
    animate: { enter: { type: 'growInY' } },
  };

  const columns = [
    {
      title: formData?.type === 'body_part' ? 'Body Part' : 'Modality',
      dataIndex: formData?.type === 'body_part' ? 'body_part' : 'modality',
    },
    {
      title: 'Total Studies',
      dataIndex: 'total_studies',
    },
    {
      title: 'Flagged for QC',
      dataIndex: 'flagged_studies',
    },
    {
      title: 'Flagged %',
      dataIndex: 'flagged_percent',
      render: (text) => `${text} %`
    },
  ];

  const csvHeaders = [
    {
      label: formData?.type === 'body_part' ? 'Body Part' : 'Modality',
      key: formData?.type === 'body_part' ? 'body_part' : 'modality',
    },
    {
      label: 'Total Studies',
      key: 'total_studies',
    },
    {
      label: 'Flagged for QC',
      key: 'flagged_studies',
    },
    {
      label: 'Flagged %',
      key: 'flagged_percent',
    },
  ];

  const handleDownload = () => {
    downloadQCModalityBodyPartInsight(formData?.type)
  }

  const handleCancelDownload = () => {
    setDownloadModalVisible(false)
  }

  useEffect(() => {
    if (downloadableData?.length) {
      csvLinkRef.current.link.click()
      setDownloadQCModalityBodyPartInsight([])
      handleCancelDownload()
      reset(DASHBOARD_QC_DOWNLOAD_FORM)
    }
  }, [downloadableData])

  useEffect(() => {
    if (chartRef.current) chartRef.current.chart.render();
    if (chartRef.current) chartRef.current.chart.forceFit();
  }, [showChart, showTable])

  return (
    <Row gutter={[16, 16]}>
      <Col>
        <Row type='flex' style={{ justifyContent: 'space-between' }}>
          <Col><Title level={4}>{t('dashboard.modality_and_body_part_insight')}</Title></Col>
          <Col>
            <CSVLink
              ref={csvLinkRef}
              headers={csvHeaders}
              data={downloadableData ?? []}
              filename={`QC_dashboard_modality_bodypart_insight_${new Date().toISOString().split('T')[0]
                }`}
            />
            <Tooltip placement='left' title={t("dashboard.download_csv")}>
              <Button type='primary' onClick={() => setDownloadModalVisible(true)}>{t('dashboard.download_as_csv')}</Button>
            </Tooltip>
            <Modal
              title={t('dashboard.download_as_csv')}
              visible={downloadModalVisible}
              onOk={handleDownload}
              onCancel={handleCancelDownload}
              okText={t('download')}
              width={643}
            >
              <QCDownloadForm modalityOptions={modalityOptions} radiologistOptions={radiologistOptions} facilityOptions={facilityOptions} bodyPartOptions={bodyPartOptions} />
            </Modal>
          </Col>
        </Row>
      </Col>
      <Col>
        <ModalityBodyPartInsightForm bodyPartOptions={bodyPartOptions} />
      </Col>
      <Col>
        <Row type='flex' gutter={32}>
          <Col>
            <Row type='flex' gutter={8}>
              <Col>
                <Text>{t('dashboard.display_option')}</Text>
              </Col>
              <Col>
                <Checkbox checked={showChart} onChange={e => setShowChart(e.target.checked)}>{t('dashboard.label_chart')}</Checkbox>
                <Checkbox checked={showTable} onChange={e => setShowTable(e.target.checked)}>{t('dashboard.label_table')}</Checkbox>
              </Col>
            </Row>
          </Col>
          {/* <Col>
            <Row type='flex' gutter={8}>
              <Col>
                <Text>Show on Hover</Text>
              </Col>
              <Col>
                <Checkbox checked={showQCScoreOnHover} onChange={e => setShowQCScoreOnHover(e.target.checked)}>QC Score</Checkbox>
                <Checkbox checked={showRadiologistOnHover} onChange={e => setShowRadiologistOnHover(e.target.checked)}>Radiologist</Checkbox>
              </Col>
            </Row>
          </Col> */}
        </Row>
      </Col>
      <Col>
        <Row type='flex' gutter={[32, 12]}>
          {showChart && (
            <Col xs={24} lg={showTable ? 14 : 24}>
              <Column {...config} />
            </Col>
          )}
          {showTable && (
            <Col xs={24} lg={showChart ? 10 : 24} className='qc-table'>
              <Table columns={columns} dataSource={modalityBodyPartInsight?.data} size="small" />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => ({
  modalityBodyPartInsight: state.QCDashboard.modalityBodyPartInsight.data,
  downloadableData: state.QCDashboard.modalityBodyPartInsight.downloadableData?.data,
  formData: getFormValues(DASHBOARD_MODALITY_BODYPART_INSIGHT_FORM)(state),
  modalityOptions: selectModalityOptions(state),
  radiologistOptions: selectUserOptions(state),
  facilityOptions: selectFacilityOptions(state),
  bodyPartOptions: selectQCBodyPartList('modalityBodyPartInsight')(state),
  loadingChart: state.loading.getQCModalityBodyPartInsight,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ 
  downloadQCModalityBodyPartInsight, 
  setDownloadQCModalityBodyPartInsight 
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(QCModalityAndBodyPartInsight)