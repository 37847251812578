// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Modal, Spin } from 'antd';

import {
  createPhysicianAction,
  getExternalFacilitiesAction,
  getInternalFacilitiesAction,
  loadAddPhysicianForm
} from '../../../../store/actions';
import AddReferringPhysiciansForm from './AddReferringPhysiciansForm';
import { valuesToPhysician } from './Table/PhysicianForm';

export default connect(
  state => ({
    form: state.form.addPhysicianForm,
    loading: state.loading.createPhysician,
    modifications: state.physicians.modifications,
    loadingFacilities: state.loading.facilities,
    internalFacilities: state.facilities.internalArray,
    externalFacilities: state.facilities.externalArray
  }),
  {
    addPhysician: createPhysicianAction,
    loadInternalFacilities: getInternalFacilitiesAction,
    loadExternalFacilities: getExternalFacilitiesAction,
    loadForm: loadAddPhysicianForm
  }
)(
  class AddReferringPhysicians extends React.PureComponent {
    state = {
      modifications: this.props.modifications // eslint-disable-line  react/destructuring-assignment
    };

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.modifications > prevState.modifications) {
        return { modifications: nextProps.modifications };
      }
      return null;
    }

    componentDidMount() {
      const { loadInternalFacilities, loadExternalFacilities } = this.props;
      loadInternalFacilities();
      loadExternalFacilities();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      const { close } = this.props;
      if (prevState.modifications < this.state.modifications) {
        close();
      }
    }

    handleCreate = values => {
      const {
        addPhysician,
        internalFacilities,
        externalFacilities,
        selectedTab
      } = this.props;
      addPhysician(
        valuesToPhysician(
          values,
          internalFacilities.concat(externalFacilities)
          // selectedTab === '1' ? internalFacilities : externalFacilities
        ),
        selectedTab === '1'
      );
    };

    render() {
      const {
        selectedTab,
        loadingFacilities,
        visible,
        loading,
        close
      } = this.props;
      if (visible)
        return (
          <AddReferringPhysiciansForm
            visible={visible}
            loading={loading}
            isInternal={selectedTab === '1'}
            onCancel={close}
            onSubmit={this.handleCreate}
          />
        );
      if (visible)
        return (
          <Modal
            visible={visible}
            title='Add Referring Physician'
            okText='Add'
            footer={null}
            onCancel={close}
          >
            <div style={{ textAlign: 'center' }}>
              <Spin size='large' />
            </div>
          </Modal>
        );
      return null;
    }
  }
);
