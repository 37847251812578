/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
/**
 * Internal radiologists are just users of the same affiliated group than the user. The only difference between
 * both endpoints is the filtering automatically done to ensure the managed profiles are from a radiologist and that
 * every user endpoint requires admin privileges, but the reading methods for internal radiologists not.
 */
import AuthService from '../index';
import Logger from '../../utils/logger';
import { userToJson } from '../transformers';

import type { User, UserProfile, ApiService } from '../../schemas';

let API_SERVICE: ApiService = 'telerad-settings';
let BASE_ROUTE = '';

/**
 * Creates a radiologist belonging to the user affiliated group. Requires admin privileges.
 * @param profile: user profile
 * @param radiologist the radiologist data
 * @returns {never|Promise<*>} Returns the created radiologist
 */
export const inviteRadiologistApiCall = async (
  profile: UserProfile,
  radiologist: User
) => {
  Logger.log('POST internal radiologist api call with profile: ', radiologist);
  const Auth = await AuthService(profile.accessToken.length > 0);
  BASE_ROUTE = '/internal-radiologists';
  return new Auth(profile)
    .service(API_SERVICE, BASE_ROUTE, {
      method: 'POST',
      body: userToJson(radiologist)
    })
    .then(r => {
      Logger.log('POST internal radiologist api call response: ', r);
      return r;
    });
};

export const inviteColleaguesApiCall = async (profile, requestBody) => {
  try {
    Logger.log('POST invite colleagues api call with profile: ', requestBody);
    const Auth = await AuthService(profile.accessToken.length > 0);
    BASE_ROUTE = '/signup/email/invites/';
    API_SERVICE = 'base';
    return new Auth(profile)
      .service(API_SERVICE, BASE_ROUTE, {
        method: 'POST',
        body: JSON.stringify(requestBody)
      })
      .then(r => {
        Logger.log('POST internal radiologist api call response: ', r);
        return r;
      });
  } catch (e) {
    console.error(e);
    return e;
  }
};
