import initialState from '../state';
import * as actions from '../actions/providersActions';

import type { ProvidersState } from '../../schemas';

export default (
  state: ProvidersState = initialState,
  action
): ProvidersState => {
  switch (action.type) {
    case actions.GET_PROVIDERS_SUCCESS:
      return { ...state, array: action.data };
    case actions.GET_PROVIDER_BY_ID_SUCCESS:
      return { ...state, current: action.data };
    case actions.ADD_PROVIDER_SUCCESS:
    case actions.DELETE_PROVIDER_SUCCESS:
      return {
        ...state,
        modifications: (state.modifications += 1)
      };
    case actions.GET_PROVIDERS_FAILED:
    case actions.GET_PROVIDER_BY_ID_FAILED:
    case actions.ADD_PROVIDER_FAILED:
    case actions.DELETE_PROVIDER_FAILED:
      return { ...state, errors: state.errors.concat(action.error) };
    case actions.REQUEST_SERVICE_PROVIDER_SUCCESS:
      return { ...state, emailsSent: (state.emailsSent += 1) };
    case actions.UPDATE_LAST_PROVIDERS_SORT:
      return {
        ...state,
        currentSearch: action.search
      };
    default:
      return state;
  }
};
