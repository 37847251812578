/* eslint-disable react/no-children-prop */

import React from 'react';
import { Form, Switch } from 'antd';

const FormItem = Form.Item;

const InputSwitchField = ({
  input,
  meta,
  children,
  hasFeedback,
  label,
  ...rest
}) => {
  const hasError = meta.touched && meta.invalid;
  return (
    <FormItem
      validateStatus={hasError ? 'error' : 'success'}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
    >
      {input.value ? (
        <Switch
          checkedChildren='On'
          unCheckedChildren='Off'
          key='checked'
          {...input}
          {...rest}
          children={children}
          defaultChecked
        />
      ) : (
        <Switch
          checkedChildren='On'
          unCheckedChildren='Off'
          key='unchecked'
          {...input}
          {...rest}
          children={children}
        />
      )}
    </FormItem>
  );
};

export default InputSwitchField;
