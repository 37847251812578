/* eslint-disable */
import type { EmptyAction } from './types';
import type { Device } from '../../schemas';

export const ADD_DEVICE_REQUEST = 'ADD_DEVICE_REQUEST';
export const ADD_DEVICE_SUCCESS = 'ADD_DEVICE_SUCCESS';
export const ADD_DEVICE_FAILED = 'ADD_DEVICE_FAILED';

export const EDIT_DEVICE_REQUEST = 'EDIT_DEVICE_REQUEST';
export const EDIT_DEVICE_SUCCESS = 'EDIT_DEVICE_SUCCESS';
export const EDIT_DEVICE_FAILED = 'EDIT_DEVICE_FAILED';

export const DELETE_DEVICE_REQUEST = 'DELETE_DEVICE_REQUEST';
export const DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS';
export const DELETE_DEVICE_FAILED = 'DELETE_DEVICE_FAILED';

export const GET_DEVICES_BY_FACILITY_ID_REQUEST =
  'GET_DEVICES_BY_FACILITY_ID_REQUEST';
export const GET_DEVICES_BY_FACILITY_ID_SUCCESS =
  'GET_DEVICES_BY_FACILITY_ID_SUCCESS';
export const GET_DEVICES_BY_FACILITY_ID_FAILED =
  'GET_DEVICES_BY_FACILITY_ID_FAILED';

export const GET_DEVICES_REQUEST = 'GET_DEVICES_REQUEST';
export const GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS';
export const GET_DEVICES_FAILED = 'GET_DEVICES_FAILED';

export const GET_DEVICES_STATUS_REQUEST = 'GET_DEVICES_STATUS_REQUEST';
export const GET_DEVICES_STATUS_SUCCESS = 'GET_DEVICES_STATUS_SUCCESS';
export const GET_DEVICES_STATUS_FAILED = 'GET_DEVICES_STATUS_FAILED';

export const TEST_DEVICE_STATUS_REQUEST = 'TEST_DEVICE_STATUS_REQUEST';
export const TEST_DEVICE_STATUS_SUCCESS = 'TEST_DEVICE_STATUS_SUCCESS';
export const TEST_DEVICE_STATUS_FAILED = 'TEST_DEVICE_STATUS_FAILED';

export const addDeviceAction = (device: Device) => ({
  type: ADD_DEVICE_REQUEST,
  device
});

export const editDeviceAction = (device: Device) => ({
  type: EDIT_DEVICE_REQUEST,
  device
});

export const deleteDeviceAction = deviceId => ({
  type: DELETE_DEVICE_REQUEST,
  deviceId
});

export const getDevicesByFacilityIdRequest = (): EmptyAction => ({
  type: GET_DEVICES_BY_FACILITY_ID_REQUEST
});

export const getDevicesByFacilityIdSuccess = data => ({
  type: GET_DEVICES_BY_FACILITY_ID_SUCCESS,
  data
});

export const getDevicesRequest = () => ({
  type: GET_DEVICES_REQUEST
});

export const getDevicesSuccess = data => ({
  type: GET_DEVICES_SUCCESS,
  data
});

export const getDevicesStatusRequest = () => {
  return {
    type: GET_DEVICES_STATUS_REQUEST
  };
};

export const getDevicesStatusSuccess = data => ({
  type: GET_DEVICES_STATUS_SUCCESS,
  data
});

export const testDeviceStatusRequest = data => ({
  type: TEST_DEVICE_STATUS_REQUEST,
  data
});

export const testDeviceStatusSuccess = data => ({
  type: TEST_DEVICE_STATUS_SUCCESS,
  data
});
