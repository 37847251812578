/* eslint-disable react/no-children-prop */

import React, { useState } from 'react';
import { Checkbox, Form, Row, Col } from 'antd';
import Box from 'ui-box';
import './InputCheckboxGroupFacilitiesField.less';

const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;

export type InputCheckboxFieldType = {
  value: string,
  text: string,
  checked: boolean
};

const InputCheckboxGroupFacilitiesField = (
  Options: Array<InputCheckboxFieldType>
) => ({
  input: { value, onChange, onBlur, onFocus },
  meta,
  children,
  hasFeedback,
  label,
  isSelectionHide,
  ...props
}) => {
  const [state, setState] = useState({
    isSelectAll: value.length === Options.length,
    isDeselectAll: !value.length
  });

  const hasError = meta.touched && meta.invalid;

  const onSelectAll = event => {
    const isAllChecked = event.target.checked;
    setState({
      isSelectAll: isAllChecked,
      isDeselectAll: isAllChecked ? false : state.isDeselectAll
    });
    if (isAllChecked) {
      onChange(Options.map(v => v.value));
    }
  };

  const onDeselectAll = () => {
    setState({ isSelectAll: false, isDeselectAll: true });
    onChange([]);
  };

  const handleOnChange = checkedValues => {
    if (!checkedValues.length) {
      setState({
        isSelectAll: false,
        isDeselectAll: true
      });
    } else if (checkedValues.length !== Options.length) {
      setState({
        isSelectAll: false,
        isDeselectAll: false
      });
    } else if (checkedValues.length === Options.length) {
      setState({
        isSelectAll: true,
        isDeselectAll: false
      });
    }
    onChange(checkedValues);
  };

  return (
    <FormItem
      label={label}
      validateStatus={hasError ? 'error' : 'success'}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
    >
     {!isSelectionHide && <Box paddingBottom={18}>
        <Row type='flex' gutter={36}>
          <Col>
            <Checkbox checked={state.isSelectAll} onChange={onSelectAll}>
              Select All
            </Checkbox>
          </Col>
          <Col>
            <Checkbox
              disabled={Options.length !== value.length}
              checked={state.isDeselectAll}
              onChange={onDeselectAll}
            >
              Deselect All
            </Checkbox>
          </Col>
        </Row>
      </Box>}
      <CheckboxGroup value={value} onChange={handleOnChange}>
        <Row gutter={[0, 16]} className='checkbox-group-item'>
          {/* eslint-disable-next-line */}
          {Options.map(({ text, value }, index) => (
            <Col key={index}>
              <Checkbox value={value}>{text}</Checkbox>
            </Col>
          ))}
        </Row>
      </CheckboxGroup>
    </FormItem>
  );
};

export default InputCheckboxGroupFacilitiesField;
