import * as actions from '../actions/QCDashboardAction';

const initialState = {
  qcMetricsSummary: {
    data: [],
  },
  modalityBodyPartInsight: {
    data: [],
    downloadableData: [],
  },
  radiologistPerformance: {
    data: [],
    downloadableData: [],
  },
  qcBodyPartList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FILTER_QC_DASHBOARD_SUCCESS:
      return {
        ...state,
        [action.payload.section]: {
          ...state[action.payload.section],
          data: action.payload.data
        }
      };

    case actions.GET_QC_METRICS_SUMMARY_SUCCESS:
      return {
        ...state,
        qcMetricsSummary: {
          ...state.qcMetricsSummary,
          data: action.payload.data
        }
      };

    case actions.GET_QC_MODALITY_BODYPART_INSIGHT_SUCCESS:
      return {
        ...state,
        modalityBodyPartInsight: {
          ...state.modalityBodyPartInsight,
          data: action.payload.data
        }
      };

    case actions.GET_QC_RADIOLOGIST_PERFORMANCE_SUCCESS:
      return {
        ...state,
        radiologistPerformance: {
          ...state.radiologistPerformance,
          data: action.payload.data
        }
      };

    case actions.DOWNLOAD_QC_MODALITY_BODYPART_INSIGHT_SUCCESS:
      return {
        ...state,
        modalityBodyPartInsight: {
          ...state.modalityBodyPartInsight,
          downloadableData: action.payload
        }
      };

    case actions.DOWNLOAD_QC_RADIOLOGIST_PERFORMANCE_SUCCESS:
      return {
        ...state,
        radiologistPerformance: {
          ...state.radiologistPerformance,
          downloadableData: action.payload
        }
      };
    case actions.GET_QC_BODY_PART_LIST_SUCCESS:
      return {
        ...state,
        [action.payload.section]: {
          ...state[action.payload.section],
          bodyPartOptions: action.payload.data
        }
      };

    default:
      return state;
  }
};
